import { Button, ISelectItem } from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import SingleSelect from "../../components/select/SingleSelect";
import { numberToSelect } from "../../spc/component/performance-filter";
import { IDisplayTimeItem, ISubDisplayViewItem } from "../../visualization/api/subscription";
import "./display-time-filter.scss";

export interface IDisplaySelectItem extends ISelectItem<any> {
  defaultTimeFrame: number;
  maxTimeFrame: number;
}

const DisplayTimeFilter = ({
  allAvailableItems,
  editedFilters,
  onChange,
  onSearch,
  disabled = false
}: {
  allAvailableItems: ISubDisplayViewItem[];
  editedFilters: IDisplayTimeItem;
  onChange: (key: keyof IDisplayTimeItem) => (item: ISelectItem<any>) => void;
  onSearch: () => void;
  disabled?: boolean;
}) => {
  const [timeFrameItems, setTimeFrameItems] = useState<ISelectItem<any>[]>([]);

  const apiToDisplaySelect = (list: ISubDisplayViewItem[]): IDisplaySelectItem[] =>
    list.map(({ viewType, defaultTimeFrame, maxTimeFrame }) => ({
      title: viewType,
      value: viewType,
      defaultTimeFrame,
      maxTimeFrame
    }));

  useEffect(() => {
    if (allAvailableItems && editedFilters.displayViewType) {
      const maxTimeFrame =
        allAvailableItems.find(item => item.viewType === editedFilters.displayViewType)
          ?.maxTimeFrame || 0;
      setTimeFrameItems(numberToSelect(maxTimeFrame));
    }
  }, [allAvailableItems, editedFilters.displayViewType]);

  return (
    <div className="display-time-filter">
      <div className="display-time-filter-title">Display View</div>
      <SingleSelect
        items={apiToDisplaySelect(allAvailableItems)}
        label={editedFilters.displayViewType}
        placeholder="Select option"
        onChange={item => {
          const { defaultTimeFrame } = item as IDisplaySelectItem;
          onChange("timeFrame")({ title: defaultTimeFrame.toString(), value: defaultTimeFrame });
          onChange("displayViewType")(item);
        }}
      />
      <div className="display-time-filter-title">Time Frame</div>
      <SingleSelect
        items={timeFrameItems}
        label={editedFilters.timeFrame.toString()}
        placeholder="Select option"
        onChange={onChange("timeFrame")}
      />
      <Button iconName="search" onClick={onSearch} disabled={disabled}></Button>
    </div>
  );
};

export default DisplayTimeFilter;
