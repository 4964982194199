import { ESEAOResultLevel } from "../component/result/result-table";

export const SERVICE_SUPPORT_SCORES = [30, 70, 90];
export const DELIVERY_REQUEST_SCORES = [65, 80, 90];
export const DELIVERY_CONFIRMED_SCORES = [80, 90, 95];
export const LTWF_SCORES = [25, 50, 75];

export const getLevel = (value: number, levelArray: number[]): ESEAOResultLevel => {
  const [unacceptable, conditional, good] = levelArray;
  return value < unacceptable
    ? ESEAOResultLevel.unacceptable
    : value < conditional
    ? ESEAOResultLevel.conditional
    : value < good
    ? ESEAOResultLevel.good
    : ESEAOResultLevel.excellent;
};
