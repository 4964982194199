import {
  APPROVAL_ROUTE,
  ESCALATION_ADMIN_ROUTE,
  ESCALATION_DETAILS_ROUTE,
  ESCALATION_FEEDBACK_ROUTE,
  ESCALATION_PERFORMANCE_ROUTE,
  GLOBAL_OPEN_ORDER_LIST_ROUTE,
  INDIA_RESULT_ROUTE,
  INDIA_VENDOR_NOMINATE_ROUTE,
  PERFORMANCE_CU_LEVEL_ROUTE,
  PERFORMANCE_HISTORY_ROUTE,
  SEAO_RESULT_ROUTE,
  SEAO_VENDOR_EVALUATION_ROUTE,
  SEAO_VENDOR_NOMINATE_ROUTE,
  SUBSCRIPTION_ROUTE,
  SUPPLIER_FCST_RES_ROUTE,
  TICKET_LOG_ROUTE
} from "../../app-router";

export interface IRelease {
  version: string;
  releasedAt: string;
  notes: INote[];
}

export interface INote {
  feature: string;
  type: string;
  affects: Array<IAffect>;
  comment?: string;
  epic: string;
}

export interface IAffect {
  title: string;
  link: string;
}

export const Releases = (): IRelease[] => {
  const release_1_0_0: IRelease = {
    version: "1.0.0",
    releasedAt: "2022-07-07",
    notes: [
      {
        type: "FEATURE",
        feature: "Add ticket log",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Show ticket history, support download as excel, support filtering, support new escalation ticket, support edit escalation ticket; 
        Click item to show the history detail; Support uploading attachments and download attachments in detail page.`
      },
      {
        type: "FEATURE",
        feature: "Add ticket approval",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Support viewing tickets to be approved by category; Support showing details of the ticket; 
        Support the function of approving ticket.`
      },
      {
        type: "FEATURE",
        feature: "Add Escalation Feedback",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support viewing ticket Bucket List by category; Support showing details of the ticket; 
        Support the function of updating ticket information.`
      }
    ]
  };

  const release_2_0_0: IRelease = {
    version: "2.0.0",
    releasedAt: "2022-08-10",
    notes: [
      {
        type: "FEATURE",
        feature: "Add Performance History",
        epic: "Supplier Performance",
        affects: [
          {
            title: "Performance History",
            link: PERFORMANCE_HISTORY_ROUTE
          }
        ],
        comment: `Support viewing dashboard chart of Performance History; Support the function of filtering information by region, display view, time frame, 
        category, perspective.`
      },
      {
        type: "FEATURE",
        feature: "Add SEAO Vendor Nominate",
        epic: "Supplier Performance",
        affects: [
          {
            title: "SEAO Vendor Nominate",
            link: SEAO_VENDOR_NOMINATE_ROUTE
          }
        ],
        comment: `Support viewing 85% Spends information; Support viewing Escalation Frequency information; Support selecting assigned vendor.
        Support the function of filtering information by quarter.`
      },
      {
        type: "FEATURE",
        feature: "Add SEAO Vendor Evaluation",
        epic: "Supplier Performance",
        affects: [
          {
            title: "SEAO Vendor Evaluation",
            link: SEAO_VENDOR_EVALUATION_ROUTE
          }
        ],
        comment: `Support viewing Vendor Evaluation information by category; Support the function of evaluation and giving score to vendors; 
        Support the function of filtering information by quarter and vendor.`
      },
      {
        type: "FEATURE",
        feature: "Add SEAO Result",
        epic: "Supplier Performance",
        affects: [
          {
            title: "SEAO Result",
            link: SEAO_RESULT_ROUTE
          }
        ],
        comment: `Support viewing evaluation information of category; Support viewing evaluation information of vendors; 
        Support viewing detail information of category and vendors; Support exporting information as excel.`
      },
      {
        type: "FEATURE",
        feature: "Add India Vendor Nominate",
        epic: "Supplier Performance",
        affects: [
          {
            title: "India Vendor Nominate",
            link: INDIA_VENDOR_NOMINATE_ROUTE
          }
        ],
        comment: `Support viewing 85% Spends information; Support viewing Escalation Frequency information; Support the function of selecting assigned vendor.
        Support the function of filtering information by quarter.`
      },
      {
        type: "FEATURE",
        feature: "Add India Result",
        epic: "Supplier Performance",
        affects: [
          {
            title: "India Result",
            link: INDIA_RESULT_ROUTE
          }
        ],
        comment: `Support viewing evaluation information of category; Support viewing evaluation information of vendors; 
        Support viewing detail information of category and vendors; Support exporting information as excel.`
      }
    ]
  };

  const release_2_1_0: IRelease = {
    version: "2.1.0",
    releasedAt: "2022-09-25",
    notes: [
      {
        type: "FEATURE",
        feature: "Add filter function to Escalation Feedback",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support filtering feedback tickets by customer, ticket number, material, product area, priority.`
      },
      {
        type: "FEATURE",
        feature: "Add table view function to Escalation Feedback",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support showing feedback tickets by table view and card view; Support filtering feedback tickets in table view.`
      },
      {
        type: "FEATURE",
        feature: "Add show detail function to Escalation Feedback",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support showing more details to feedback tickets.`
      },
      {
        type: "FEATURE",
        feature: "Add product area to Escalation Feedback",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support editing product area in feedback dialog.`
      },
      {
        type: "FEATURE",
        feature: "Collapse and expand completed tickets",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support collapsing and expanding completed tickets.`
      },
      {
        type: "FEATURE",
        feature: "Add breadcrumb",
        epic: "Inbound Management",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Add breadcrumb function for all pages.`
      },
      {
        type: "FEATURE",
        feature: "Upload and download multiple attachments",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Support dragging upload multiple attachments; Support download multiple attachments.`
      }
    ]
  };

  const release_2_1_1: IRelease = {
    version: "2.1.1",
    releasedAt: "2022-10-25",
    notes: [
      {
        type: "FEATURE",
        feature: "Add color to status",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Add color markers to escalation status in page of ticket log and approval.`
      },
      {
        type: "FEATURE",
        feature: "Auto fill start date and due date",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Auto fill start date and due date after submit in page of Escalation Feedback.`
      },
      {
        type: "CHANGE",
        feature: "Add fill button",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Add fill button to get default assign users manually.`
      },
      {
        type: "BUGFIX",
        feature: "Fix upload problems",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Solve the problem that the same file cannot be uploaded multiple times because form is not cleared after submit.`
      },
      {
        type: "BUGFIX",
        feature: "Adjust date format",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Adjust date format for ticket information uploading; Add default format for date (dd/MM/yyyy).`
      },
      {
        type: "BUGFIX",
        feature: "Fix page number not reset",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Solve the problem that page number is not reset when switching tabs.`
      },
      {
        type: "BUGFIX",
        feature: "Fix email link problems",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          },
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Fix the incorrect jump link contained in the email.`
      },
      {
        type: "BUGFIX",
        feature: "Optimize avatar display",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          },
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Optimize display and the loading speed of avatar.`
      },
      {
        type: "BUGFIX",
        feature: "Fix not sending email",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Fix approval/assign people not send email in acc environment.`
      }
    ]
  };

  const release_2_1_2: IRelease = {
    version: "2.1.2",
    releasedAt: "2022-11-30",
    notes: [
      {
        type: "BUGFIX",
        feature: "Fixed attachments not showing",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Fixed bug with attachments not showing after drag a file.`
      },
      {
        type: "BUGFIX",
        feature: "Fixed date format",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Fixed bug with date format of ticket detail table.`
      },
      {
        type: "BUGFIX",
        feature: "Fixed bug with filter display",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Fixed bug with filter display after switching to table view.`
      },
      {
        type: "BUGFIX",
        feature: "Fixed bug with triggering Resubmit messages",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Fixed issue with triggering Resubmit messages in the specified state.`
      },
      {
        type: "BUGFIX",
        feature: "Fixed not sending email",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Fixed bug with email not sent after updating a rejected ticket.`
      },
      {
        type: "BUGFIX",
        feature: "Fixed region classification",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Adjust region, level classification in the approval view.`
      },
      {
        type: "CHANGE",
        feature: "Optimize scroll display",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Optimize the scroll display of card view of Escalation Feedback page.`
      },
      {
        type: "CHANGE",
        feature: "Set default value for vendor",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Set default value for vendor (used in the pre-escalation uploading part).`
      },
      {
        type: "FEATURE",
        feature: "Add OA field to ticket attachment",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          },
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support display and editing of OA field in ticket attachment table.`
      }
    ]
  };

  const release_2_2_0: IRelease = {
    version: "2.2.0",
    releasedAt: "2022-11-25",
    notes: [
      {
        type: "FEATURE",
        feature: "Add publish status trend",
        epic: "Supplier Performance",
        affects: [
          {
            title: "Supplier FCST Res - SM",
            link: SUPPLIER_FCST_RES_ROUTE
          }
        ],
        comment: `Support viewing publish status trend information. Support filtering by quarter, month and week.`
      },
      {
        type: "FEATURE",
        feature: "Add publish status view by week",
        epic: "Supplier Performance",
        affects: [
          {
            title: "Supplier FCST Res - SM",
            link: SUPPLIER_FCST_RES_ROUTE
          }
        ],
        comment: `Support viewing publish status by selected week. Support viewing detail information of publish status. Support filter by week.`
      },
      {
        type: "FEATURE",
        feature: "Support exporting Publish status excel",
        epic: "Supplier Performance",
        affects: [
          {
            title: "Supplier FCST Res - SM",
            link: SUPPLIER_FCST_RES_ROUTE
          }
        ],
        comment: `Support exporting excel of publish status. Support exporting history data by filter.`
      },
      {
        type: "FEATURE",
        feature: "Support uploading Publish status excel",
        epic: "Supplier Performance",
        affects: [
          {
            title: "Supplier FCST Res - SM",
            link: SUPPLIER_FCST_RES_ROUTE
          }
        ],
        comment: `Support uploading excel of publish status and processing calculation to display.`
      }
    ]
  };

  const release_2_3_0: IRelease = {
    version: "2.3.0",
    releasedAt: "2023-02-10",
    notes: [
      {
        type: "FEATURE",
        feature: "Add subscription",
        epic: "Visualization",
        affects: [
          {
            title: "Subscription",
            link: SUBSCRIPTION_ROUTE
          }
        ],
        comment: `Support subscribing email notification through Product Area Subscription, Customer Subscription, Escalation Region Subscription.`
      },
      {
        type: "FEATURE",
        feature: "Add escalation performance",
        epic: "Visualization",
        affects: [
          {
            title: "Escalation Performance",
            link: ESCALATION_PERFORMANCE_ROUTE
          }
        ],
        comment: `Support viewing monthly and historical information of escalation performance. Support filter data by product area, 
        vendor, custormer, month and display view.`
      },
      {
        type: "FEATURE",
        feature: "Add performance CU level",
        epic: "Visualization",
        affects: [
          {
            title: "Performance Trend",
            link: PERFORMANCE_CU_LEVEL_ROUTE
          }
        ],
        comment: `Support viewing information of performance CU level. Support filter data by display view.`
      },
      {
        type: "FEATURE",
        feature: "Support email subscription",
        epic: "Visualization",
        affects: [
          {
            title: "Subscription",
            link: SUBSCRIPTION_ROUTE
          }
        ],
        comment: `Support viewing overview information in email.`
      }
    ]
  };

  const release_2_4_0: IRelease = {
    version: "2.4.0",
    releasedAt: "2023-05-15",
    notes: [
      {
        type: "CHANGE",
        feature: "Change the view of progress",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Change the view of the column of progress in ticketLog table. Display the column of progress with progressBar.`
      },
      {
        type: "CHANGE",
        feature: "Change the view of new escalation dialog",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Change the view of new escalation dialog. The view is divided into two parts: SO & Item table and 
        Basic Information form. Support to add multiple rows to so-item table. Fetch some data automaticlly instead of
        upload attachment.`
      },
      {
        type: "FEATURE",
        feature: "Add check function",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Change the view of new escalation dialog. The view is divided into two parts: SO & Item table and 
        Basic Information form. Support to add multiple rows to so-item table.`
      },
      {
        type: "CHANGE",
        feature: "Change the view of ticket approval",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Change the view of approval dialog. The approval part is changed to bottom of the page. The function of assign to 
        is changed to multiple product areas.`
      },
      {
        type: "FEATURE",
        feature: "Add other list to Escalation Feedback",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support viewing ticket Bucket List by category of others.`
      },
      {
        type: "CHANGE",
        feature: "Add conclustion to weekly update",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Add elements of action and quantity to weekly update part, remove product area in feedback dialog.`
      }
    ]
  };

  const release_2_4_1: IRelease = {
    version: "2.4.1",
    releasedAt: "2023-06-20",
    notes: [
      {
        type: "CHANGE",
        feature: "Change field position",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          },
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Move the column “Confirmed MAD” from Preview page to Ticket Detail page.`
      },
      {
        type: "CHANGE",
        feature: "Change display of project name",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          },
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `When creating new tickets, column “project name” is visible on the detail page. On the approval, 
        escalation feedback pages, column “project name” is moved to Preview, so it is invisible on the detail page.`
      },
      {
        type: "FEATURE",
        feature: "Add field to preview",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          },
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Add “Latest Request MAD” to Preview.`
      },
      {
        type: "CHANGE",
        feature: "Change request delivery date",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Replace “request delivery date” with “Latest Request MAD” in Escalation Feedback view.`
      }
    ]
  };

  const release_2_4_2: IRelease = {
    version: "2.4.2",
    releasedAt: "2023-08-04",
    notes: [
      {
        type: "FEATURE",
        feature: "Add new product reporting group",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          },
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `On the ticket log, approval, escalation feedback pages, should display “Product Reporting Group” instead of 
        “Product Area” in the detail view. `
      },
      {
        type: "CHANGE",
        feature: "Split feedback card base on ticket category",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Create the card base on Ticket category. If a ticket include multiple Ticket category, create multiple card for that.`
      },
      {
        type: "FEATURE",
        feature: "Support filter of ticket category",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support filter of ticket category in card view and table view in feedback page.`
      },
      {
        type: "FEATURE",
        feature: "Support assign ticket category",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `On the approval view, support assign different Product reporting group to different Ticket category, 
        Support auto fill and manually change.`
      },
      {
        type: "CHANGE",
        feature: "Replace latest request MAD",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Replace latest request MAD to latest acceptable RFS date in escalation feedback card. `
      },
      {
        type: "CHANGE",
        feature: "Change the assign email words",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          },
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Change the assign email words, just let people know the ticket has been assign to them.`
      },
      {
        type: "FEATURE",
        feature: "Add reporting group to email",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Add reporting group to new escalation email.`
      }
    ]
  };

  const release_2_4_3: IRelease = {
    version: "2.4.3",
    releasedAt: "2023-09-22",
    notes: [
      {
        type: "FEATURE",
        feature: "Upload so & items support other information",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Upload by template available for other mandatory information. `
      },
      {
        type: "FEATURE",
        feature: "Add some fields to tick log table",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Add some fields to tick log page: a.Customer, b.User name who processes / approved / reject the escalation,
        c.Ticket category, d.Product reporting group.`
      },
      {
        type: "FEATURE",
        feature: "Add information to mail",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Add information of customer, product reporting group into mail when creating a ticket successfully.`
      }
    ]
  };

  const release_2_5_0: IRelease = {
    version: "2.5.0",
    releasedAt: "2023-10-25",
    notes: [
      {
        type: "FEATURE",
        feature: "Add filters to Escalation Performance",
        epic: "Visualization",
        affects: [
          {
            title: "Escalation Performance",
            link: ESCALATION_PERFORMANCE_ROUTE
          }
        ],
        comment: `Add filters to Escalation Performance Page as follows: Display view, Time frame, Tick category,
        Product reporting group, Customer unit, Customer, SIPP, Forecasted. `
      },
      {
        type: "FEATURE",
        feature: "Add Escalation Details page",
        epic: "Visualization",
        affects: [
          {
            title: "Escalation Details",
            link: ESCALATION_DETAILS_ROUTE
          }
        ],
        comment: `Support to display escalation details table; Support filtering, pagination and redirecting function.`
      },
      {
        type: "FEATURE",
        feature: "Add Global Open Order List page",
        epic: "Visualization",
        affects: [
          {
            title: "Global Open Order List",
            link: GLOBAL_OPEN_ORDER_LIST_ROUTE
          }
        ],
        comment: `Support to display global open order list table; Support filter and pagination function.`
      }
    ]
  };

  const release_2_6_0: IRelease = {
    version: "2.6.0",
    releasedAt: "2023-11-25",
    notes: [
      {
        type: "CHANGE",
        feature: "Change access control",
        epic: "Generic",
        affects: [],
        comment: `Change the access control for escalation feedback by market area. User should apply role of market area by IDM, apply menu
        access by connecting administrator.`
      },
      {
        type: "FEATURE",
        feature: "Add admin page",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Admin",
            link: ESCALATION_ADMIN_ROUTE
          }
        ],
        comment: `Support to display information of user and role. The administrator can grant access of pages to users.`
      },
      {
        type: "FEATURE",
        feature: "Add request name to ticket log",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Ticket Log",
            link: TICKET_LOG_ROUTE
          }
        ],
        comment: `Add column of request name in the table of ticket log.`
      }
    ]
  };

  const release_3_0_0: IRelease = {
    version: "3.0.0",
    releasedAt: "2024-01-30",
    notes: [
      {
        type: "FEATURE",
        feature: "Support global role",
        epic: "Generic",
        affects: [],
        comment: `Support global role for escalation feedback. Global user can see all information of different market areas.`
      },
      {
        type: "FEATURE",
        feature: "Change eds to ag-grid to display table",
        epic: "Generic",
        affects: [],
        comment: `Change eds to ag-grid to display table in views of ticket log, approval, escalation feedback, performance trend.`
      },
      {
        type: "FEATURE",
        feature: "Support classification by ticket category and region",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Approval",
            link: APPROVAL_ROUTE
          }
        ],
        comment: `Support classification by ticket category and region.`
      },
      {
        type: "FEATURE",
        feature: "Support buckets from server",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Support buckets from server, will not fetch data after click completed btn.`
      },
      {
        type: "CHANGE",
        feature: "Change product area to ticket category",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Subscription",
            link: SUBSCRIPTION_ROUTE
          }
        ],
        comment: `Change product area to ticket category subscription.`
      },
      {
        type: "FEATURE",
        feature: "Add gsu filter",
        epic: "Visualization",
        affects: [
          {
            title: "Escalation Performance",
            link: ESCALATION_PERFORMANCE_ROUTE
          }
        ],
        comment: `Add gsu filter to left panel when market area equals global.`
      },
      {
        type: "FEATURE",
        feature: "Add gsu column",
        epic: "Visualization",
        affects: [
          {
            title: "Performance Trend",
            link: PERFORMANCE_HISTORY_ROUTE
          }
        ],
        comment: `Add gsu column to table of Performance Trend.`
      },
      {
        type: "FEATURE",
        feature: "Add export function",
        epic: "Visualization",
        affects: [
          {
            title: "Escalation Details",
            link: ESCALATION_DETAILS_ROUTE
          }
        ],
        comment: `Add export function to Escalation Details.`
      },
      {
        type: "FEATURE",
        feature: "Add export function",
        epic: "Visualization",
        affects: [
          {
            title: "Global Open Order List",
            link: GLOBAL_OPEN_ORDER_LIST_ROUTE
          }
        ],
        comment: `Add export function to Global Open Order List.`
      }
    ]
  };

  const release_3_1_0: IRelease = {
    version: "3.1.0",
    releasedAt: "2024-07-19",
    notes: [
      {
        type: "FEATURE",
        feature: "Refactoring server-side code with .NET",
        epic: "Generic",
        affects: [],
        comment: `Refactoring server-side code with .NET instead of Java. Optimize endpoint format.`
      },
      {
        type: "FEATURE",
        feature: "Use mgt component to display avatar",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `The avatar uses microsoft/mgt-react components.`
      },
      {
        type: "FEATURE",
        feature: "Support adding weekly update manually",
        epic: "Escalation Feedback",
        affects: [
          {
            title: "Escalation Feedback",
            link: ESCALATION_FEEDBACK_ROUTE
          }
        ],
        comment: `Change add function of the weekly update from automatic to manual.`
      },
      {
        type: "FEATURE",
        feature: "Add sort function to escalation detail",
        epic: "Visualization",
        affects: [
          {
            title: "Escalation Details",
            link: ESCALATION_DETAILS_ROUTE
          }
        ],
        comment: `Add sort function to escalation detail, add more filter to escalation detail.`
      },
      {
        type: "CHANGE",
        feature: "Change polling method for GFT",
        epic: "Supplier Performance",
        affects: [
          {
            title: "Supplier FCST Res - SM",
            link: SUPPLIER_FCST_RES_ROUTE
          }
        ],
        comment: `Change GFT's polling method, uses setInterval instead of websocket.`
      }
    ]
  };

  return [
    release_3_1_0,
    release_3_0_0,
    release_2_6_0,
    release_2_5_0,
    release_2_4_3,
    release_2_4_2,
    release_2_4_1,
    release_2_4_0,
    release_2_3_0,
    release_2_2_0,
    release_2_1_2,
    release_2_1_1,
    release_2_1_0,
    release_2_0_0,
    release_1_0_0
  ];
};
