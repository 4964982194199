import { Icon } from "@react-gcc-eds/core";
import { STATUS_RED } from "../../../components/colorHelper";

const TicketError = ({ msg }: { msg?: string }) => {
  return (
    <span style={{ color: STATUS_RED }}>
      <Icon name="triangle-warning" style={{ color: "red" }} /> {msg || "Can't be Empty !"}
    </span>
  );
};

export default TicketError;
