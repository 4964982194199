import { useApiGet } from "../../api/api";
import { IDonutChartData } from "../../components/charts";

interface IIndiaVendorNominateDonuts {
  donuts: ICategoryDonutData[];
  snapshot: number;
}

interface ICategoryDonutData {
  category: string;
  data: IDonutChartData[];
}

const INDIA_VENDOR_NOMINATE = "/v1/supply-performance/vendor-nominate/indian";

export const useGetIndiaSpends = () =>
  useApiGet<IIndiaVendorNominateDonuts, { snapshot: number }>(INDIA_VENDOR_NOMINATE + "/spends");
