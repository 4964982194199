import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  Column,
  ColumnApi,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnRowGroupChangedEvent,
  ColumnState,
  ColumnVisibleEvent,
  DetailGridInfo,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  IFilter,
  IRowNode,
  IServerSideGetRowsParams,
  IsGroupOpenByDefaultParams,
  IsServerSideGroupOpenByDefaultParams,
  PaginationChangedEvent,
  RowDataChangedEvent,
  RowDataUpdatedEvent,
  RowGroupingDisplayType,
  SelectionChangedEvent,
  SortChangedEvent,
  ValueGetterParams
} from "ag-grid-community";
import "ag-grid-enterprise";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { IServerModelGetRowRequest } from "./types";
import { AUTO_GROUP_COLUMN_PREFIX, TEXT_FILTER_WITH_IS_CONTAINED_IN_OPTIONS } from "./const";
import ColumnsFiltersInfo from "./columns-filters-info";
import { ColGroupDef } from "ag-grid-community/dist/lib/entities/colDef";
import {
  IsRowSelectable,
  MenuItemDef,
  RowClassParams
} from "ag-grid-community/dist/lib/entities/gridOptions";
import { CopyFromExcelFilter } from "./copy-from-excel-filter";

export interface IGridColumnsFilters {
  [key: string]: any;
}

export interface IGridColumnsPreferences {
  columnsState: ColumnState[];
  columnGroupsState: { groupId: string; open: boolean }[];
}

export interface IGridPreferencesHandler {
  id: () => string;
  saveColumnsPreferences?: (preferences: IGridColumnsPreferences) => void;
  getColumnsPreferences?: () => Promise<IGridColumnsPreferences | undefined>;
  clearColumnsPreferences?: () => void;
  saveColumnsFilters?: (filters: IGridColumnsFilters) => void;
  getColumnsFilters?: () => IGridColumnsFilters | undefined;
  clearColumnsFilters?: () => void;
  saveColumnsPreferencesTimeout?: NodeJS.Timeout;
  timestamp?: Date;
}

export interface IMenuItem {
  name: string;
  icon?: HTMLElement | string | undefined;
  action: () => void;
}

export interface IColumnDefinition extends ColDef {
  //dataType?: ColumnDataType;
  suppressTechnicalTooltip?: boolean;
  children?: IColumnDefinition[];
  marryChildren?: boolean;
}

export interface IGetDetailRowDataParams<TMaster extends {}, TDetails extends {}> {
  node: IRowNode;
  data: TMaster;

  successCallback(rowData: TDetails[]): void;
}

export interface IGridDetailsConfig<TMaster extends {}, TDetails extends {}> {
  columns: ColDef[];
  getDetailRowData: (params: IGetDetailRowDataParams<TMaster, TDetails>) => void;
  rowKeySelector: (data: TDetails) => string;
  isRowMaster?: (data: TMaster) => boolean;
  getCustomMenuItems?: (params: GetMainMenuItemsParams) => (IMenuItem | string)[];
  preferencesHandler?: IGridPreferencesHandler;
  detailsConfig?: IGridDetailsConfig<TDetails, any>;
}

export interface IClientModel<TMaster extends {}> {
  data: TMaster[];
  rowKeySelector: (data: TMaster) => string;
}

export interface IServerModel<TMaster extends {}> {
  pageSize: number;
  getRows: (params: IServerModelGetRowRequest) => Promise<[TMaster[], number]>;
  rowKeySelector?: (data: TMaster) => string;
}

export interface IGridConfig<TMaster extends {}, TDetails extends {}> {
  columns: ColDef[];
  autoGeneratedColumnIds?: string[];
  clientModel?: IClientModel<TMaster>;
  serverModel?: IServerModel<TMaster>;
  showColumnsMenu?: boolean;
  showAggregationFuncInHeader?: boolean;
  showColumnsFiltersInfoAt?: "right" | "left";
  groupHideOpenParents?: boolean;
  groupSelectsChildren?: boolean;
  groupSelectsFiltered?: boolean;
  groupDefaultExpanded?: number;
  isGroupOpenByDefault?: (params: IsGroupOpenByDefaultParams) => boolean;
  rowGroupingDisplayType?: RowGroupingDisplayType;
  groupHeaderHeight?: number;
  getCustomMenuItems?: (params: GetMainMenuItemsParams) => (IMenuItem | string)[];
  allowGrouping?: boolean;
  columnSpecificGrouping?: boolean;
  rowClassRules?: {
    [className: string]: (row: TMaster, params: RowClassParams) => boolean;
  };
  detailsConfig?: IGridDetailsConfig<TMaster, TDetails>;
  preferencesHandler?: IGridPreferencesHandler;
  maintainColumnOrder?: boolean;
  autoGroupColumnFilter?: string;
  suppressClickEdit?: boolean;
  isRowSelectable?: IsRowSelectable;
  onRowClicked?: (row: TMaster) => void;
  onGroupedRowClicked?: (value: string) => void;
  onSortChanged?: (filedId?: string, order?: "asc" | "desc") => void;
  isServerSideGroupOpenByDefault?: (params: IsServerSideGroupOpenByDefaultParams) => boolean;
  onColumnFilterChanged?: (event: FilterChangedEvent) => void;
  suppressGroupRowsSticky?: boolean;
  loadingOverlayComponent?: any;
  getCustomContextMenuItems?: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[];
  defaultFilters?: IGridColumnsFilters;
  redirectFilters?: IGridColumnsFilters;
}

const autoGroupColumnDefConfig = {
  sortable: true,
  filterValueGetter: (p: ValueGetterParams) => {
    let colId = p.column.getColId();
    if (colId) {
      if (colId.startsWith(AUTO_GROUP_COLUMN_PREFIX)) {
        colId = colId.slice(AUTO_GROUP_COLUMN_PREFIX.length);
      }
      return p.data[colId] || "";
    }
  }
};

export const useGrid = <TMaster, TDetails = {}>({
  columns,
  autoGeneratedColumnIds,
  clientModel,
  serverModel,
  showColumnsMenu = true,
  getCustomMenuItems,
  allowGrouping,
  columnSpecificGrouping,
  rowClassRules,
  detailsConfig,
  preferencesHandler,
  showAggregationFuncInHeader = true,
  showColumnsFiltersInfoAt,
  groupHideOpenParents,
  groupSelectsChildren,
  groupSelectsFiltered = true,
  groupDefaultExpanded,
  isGroupOpenByDefault,
  rowGroupingDisplayType,
  groupHeaderHeight,
  onSortChanged,
  maintainColumnOrder = true,
  autoGroupColumnFilter,
  suppressClickEdit,
  isRowSelectable,
  onRowClicked,
  onGroupedRowClicked,
  isServerSideGroupOpenByDefault,
  suppressGroupRowsSticky,
  onColumnFilterChanged,
  loadingOverlayComponent,
  getCustomContextMenuItems,
  defaultFilters,
  redirectFilters
}: IGridConfig<TMaster, TDetails>) => {
  if (!clientModel && !serverModel) {
    throw new Error("Grid model not provided");
  }

  const [tableApi, setTableApi] = useState<{ gridApi: GridApi; columnApi: ColumnApi } | undefined>(
    undefined
  );

  let resizeTimeout: NodeJS.Timeout | undefined = undefined;

  const [tableUpdated, setTableUpdated] = useState(false);

  const applyOnChildGrids = (callback: (api: GridApi) => void, api?: GridApi) => {
    if (api && !isDestroying(api)) {
      api.forEachDetailGridInfo((info: DetailGridInfo) => {
        //console.log(api.getDetailGridInfo(info.id));
        if (info.api) {
          callback(info.api);
          applyOnChildGrids(callback, info.api);
        }
      });
    }
  };

  const removeNullPropsFromDefs = (colDefs: ColDef[]) => {
    // workaround for a bug when a key is null
    // https://github.com/ag-grid/ag-grid/issues/4242
    colDefs.forEach((d: any) => {
      Object.keys(d).forEach(key => d[key] == null && delete d[key]);
    });
  };

  useEffect(() => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return;

    const colTooltip = (field?: string) => {
      if (!detailsConfig || !detailsConfig.columns || !detailsConfig.columns.length) {
        //console.log(field, "empty");
        return {};
      }

      const col = detailsConfig.columns.find(c => c.field === field);

      if (!col) {
        //console.log(field, "no col");
        return {};
      }

      //console.log(field, col);
      return { headerTooltip: col.headerTooltip };
    };

    applyOnChildGrids((api: GridApi) => {
      const colDefs = api.getColumnDefs();
      if (colDefs) {
        removeNullPropsFromDefs(colDefs);
        api.setColumnDefs(
          colDefs.map((d: ColDef) => ({
            ...d,
            floatingFilter: d.field !== "uniqueKey",
            ...colTooltip(d.field)
          }))
        );
      }
    }, tableApi.gridApi);
  }, [detailsConfig?.columns]);

  const toggleTableUpdated = () => setTableUpdated(updated => !updated);

  const isDestroying = (api: GridApi) => api["destroyCalled"];

  const nodeChildren = (node: IRowNode): IRowNode[] => {
    if (node.group) {
      return (node.childrenAfterSort || []).reduce((nodes: IRowNode[], n: IRowNode) => {
        return nodes.concat(nodeChildren(n));
      }, []);
    } else {
      return [node];
    }
  };

  const rows = useMemo((): TMaster[] => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return [];

    const model = tableApi.gridApi.getModel();
    if (clientModel) {
      return nodeChildren((model as any).rootNode).map((r: IRowNode) => r.data);
    } else {
      //TODO: handling onelevel only no grouping
      const nodes = (model as any).nodeManager.rowNodes;
      return Object.entries(nodes).reduce((data: TMaster[], [k, v]) => {
        if (!v) return data;
        return [...data, (v as { data: TMaster }).data];
      }, []);
    }
  }, [tableApi && tableUpdated]);

  const selectedRows = useMemo((): TMaster[] => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return [];

    return tableApi.gridApi
      .getSelectedNodes()
      .filter(n => n.displayed)
      .map(n => n.data);
  }, [tableApi && tableUpdated]);

  const selectedNodes = useMemo((): IRowNode[] => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return [];

    return tableApi.gridApi.getSelectedNodes();
  }, [tableApi && tableUpdated]);

  const filtersCount = useMemo(() => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return 0;

    const filterModel = tableApi.gridApi.getFilterModel();
    return Object.entries(filterModel).length;
  }, [tableApi && tableUpdated]);

  const filteredColumns = useMemo(() => {
    const columnNames = Array<string>();
    if (!tableApi || isDestroying(tableApi.gridApi)) return;

    const filterModel = tableApi.gridApi.getFilterModel();
    Object.keys(filterModel).forEach(colKey => {
      const columnDefs = tableApi.columnApi.getColumn(colKey)?.getColDef();
      const colName = columnDefs?.headerName || columnDefs?.field;
      if (colName !== undefined) {
        columnNames.push(colName);
      }
    });
    return columnNames;
  }, [tableApi && tableUpdated]);

  const hiddenColumns = useMemo(() => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return 0;

    const columns = tableApi.gridApi.getColumnDefs();
    return (columns || []).filter((c: any) => c.hide === true && c.rowGroup !== true).length > 0;
  }, [tableApi && tableUpdated]);

  const clearFilters = useCallback(() => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return;

    tableApi.gridApi.setFilterModel(null);
  }, [tableApi]);

  const clearFilter = useCallback(
    (columnId: string, isGroup?: boolean) => {
      if (!tableApi || isDestroying(tableApi.gridApi)) return;

      const instance = isGroup
        ? tableApi.gridApi.getFilterInstance(AUTO_GROUP_COLUMN_PREFIX + columnId)
        : tableApi.gridApi.getFilterInstance(columnId);

      if (!instance) return;

      instance.setModel(null);

      tableApi.gridApi.onFilterChanged();
    },
    [tableApi]
  );

  const clearSelection = useCallback(() => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return;

    tableApi.gridApi.deselectAll();
  }, [tableApi]);

  const setFilter = useCallback(
    (columnId: string, model: any, isGroup?: boolean) => {
      if (!tableApi || isDestroying(tableApi.gridApi)) return;

      const applyModel = (filter: IFilter | null) => {
        filter?.setModel(model);
        tableApi.gridApi.onFilterChanged();
      };

      isGroup
        ? tableApi.gridApi.getFilterInstance(AUTO_GROUP_COLUMN_PREFIX + columnId, applyModel)
        : tableApi.gridApi.getFilterInstance(columnId, applyModel);
    },
    [tableApi]
  );

  const setRowVisible = useCallback(
    (rowIndex: number) => {
      if (!tableApi || isDestroying(tableApi.gridApi)) return;

      tableApi.gridApi.ensureIndexVisible(rowIndex, "top");
    },
    [tableApi]
  );

  const getEachNodeAfterFilter = useCallback(
    (func: (rowNode: IRowNode, index: number) => void) => {
      if (!tableApi || isDestroying(tableApi.gridApi)) return;
      tableApi.gridApi.forEachNodeAfterFilter(func);
    },
    [tableApi]
  );

  const getEachNode = useCallback(
    (func: (rowNode: IRowNode, index: number) => void) => {
      if (!tableApi || isDestroying(tableApi.gridApi)) return;
      tableApi.gridApi.forEachNode(func);
    },
    [tableApi]
  );

  const redrawRow = useCallback(
    (rowIndex: number) => {
      if (!tableApi || isDestroying(tableApi.gridApi)) return;

      const node = tableApi.gridApi.getDisplayedRowAtIndex(rowIndex);
      if (node) {
        tableApi.gridApi.redrawRows({ rowNodes: [node] });
      }
    },
    [tableApi]
  );

  const updateColumnsDefinition = useCallback(
    (columns: ColDef[]) => {
      if (!tableApi || isDestroying(tableApi.gridApi)) return;

      tableApi.gridApi.setColumnDefs(columns);
      tableApi.gridApi.refreshCells({ force: true });
    },
    [tableApi]
  );

  const resetColumnsDefinition = useCallback(() => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return;

    tableApi.columnApi.resetColumnState();
    tableApi.columnApi.resetColumnGroupState();
  }, [tableApi]);

  const exportAsExcel = useCallback(
    (params?: { fileName: string; columnKeys?: string[] | Column[]; skipRowGroups?: boolean }) => {
      if (!tableApi || isDestroying(tableApi.gridApi)) return;

      tableApi.gridApi.exportDataAsExcel({
        fileName: params?.fileName,
        author: "export",
        sheetName: "Data",
        columnKeys: params?.columnKeys,
        skipRowGroups: params?.skipRowGroups
      });
    },
    [tableApi]
  );

  const refreshServerSideStore = useCallback(() => {
    if (!serverModel || !tableApi || isDestroying(tableApi.gridApi)) return;

    tableApi.gridApi.refreshServerSideStore({});
  }, [tableApi]);

  const serverDataSource = useMemo(
    () => ({
      serverSideDatasource: {
        getRows: async (params: IServerSideGetRowsParams) => {
          try {
            if (serverModel) {
              const [data, nofRows] = await serverModel.getRows(params.request);
              params.success({ rowData: data || [], rowCount: nofRows });
            }
          } catch (e) {
            params.fail();
          }
        }
      }
    }),
    [serverModel?.getRows]
  );

  const serverModelProps: AgGridReactProps = serverModel
    ? {
        rowModelType: "serverSide",
        serverSideStoreType: "partial",
        pagination: true,
        paginationPageSize: serverModel.pageSize,
        cacheBlockSize: serverModel.pageSize,
        getRowId: serverModel.rowKeySelector
          ? (params: GetRowIdParams) =>
              serverModel.rowKeySelector ? serverModel.rowKeySelector(params.data) : ""
          : undefined,
        ...serverDataSource
      }
    : {};

  const modelProps = (): AgGridReactProps => {
    if (clientModel) {
      const { data, rowKeySelector } = clientModel;
      return {
        rowData: data,
        getRowId: rowKeySelector
          ? (params: GetRowIdParams) => (rowKeySelector ? rowKeySelector(params.data) : "")
          : undefined
      };
    }

    if (serverModel) {
      return serverModelProps;
    }

    return {};
  };

  const defaultColumnsDefinitions: ColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      unSortIcon: true,
      filter: "agMultiColumnFilter",
      filterParams: {
        filters: [
          {
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: TEXT_FILTER_WITH_IS_CONTAINED_IN_OPTIONS
            }
          },
          {
            filter: CopyFromExcelFilter,
            floatingFilterComponent: () => (
              <div
                className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
                role="presentation"
              >
                <input
                  className="ag-input-field-input ag-text-field-input"
                  value={"Copy from excel filter applied"}
                  type="text"
                  disabled
                />
              </div>
            )
          },
          {
            filter: "agSetColumnFilter"
          }
        ]
      },
      floatingFilter: true,
      // menuTabs: ["generalMenuTab", "columnsMenuTab"].slice(0, showColumnsMenu ? 2 : 1),
      // columnsMenuParams: { suppressColumnSelectAll: true },
      enableRowGroup: false,
      onCellClicked: (event: CellClickedEvent) => {
        if (event.node.group) {
          onGroupedRowClicked && onGroupedRowClicked(event.node.key || "");
        } else {
          onRowClicked && onRowClicked(event.data);
        }
      }
    }),
    [showColumnsMenu, onRowClicked, onGroupedRowClicked]
  );

  const defaultContextMenuItems = ["copy", "copyWithHeaders", "copyWithGroupHeaders", "paste"];

  const showLoadingOverlay = () => {
    if (tableApi) {
      tableApi.gridApi.showLoadingOverlay();
    }
  };

  const hideOverlay = () => {
    if (tableApi) {
      tableApi.gridApi.hideOverlay();
    }
  };

  const expandAll = () => {
    if (tableApi) {
      tableApi.gridApi.expandAll();
    }
  };

  const collapseAll = () => {
    if (tableApi) {
      tableApi.gridApi.collapseAll();
    }
  };

  const collapse = (level: number) => {
    if (tableApi) {
      tableApi.gridApi.forEachNode(node => {
        if (node.level <= level) {
          tableApi.gridApi.setRowNodeExpanded(node, false);
        }
      });
    }
  };

  const setRowSorting = (
    sortState: {
      colId: string;
      sort: "asc" | "desc" | null;
      sortIndex?: number;
    }[]
  ) => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return;

    tableApi.columnApi.applyColumnState({
      state: sortState,
      defaultState: { sort: null }
    });
  };

  const setColumnGroup = (
    groupStates: { colId: string; rowGroup: boolean; rowGroupIndex?: number }[]
  ) => {
    if (!tableApi || isDestroying(tableApi.gridApi)) return;
    tableApi.columnApi.applyColumnState({
      state: groupStates
    });
  };

  const setColumnVisible = (keys: string[], visible: boolean) => {
    if (tableApi?.columnApi && tableApi.gridApi) {
      const allColumns = tableApi.columnApi.getAllColumns();
      if (allColumns) {
        const columns = allColumns.filter(col => keys.indexOf(col.getColId()) > -1);
        tableApi.columnApi.setColumnsVisible(columns, visible);
      }
    }
  };

  const renderTable = () => {
    const autoSizeColumns = (api: ColumnApi) => {
      const cols = api.getAllGridColumns();

      api.autoSizeColumns(cols);
    };

    const fitOrAutoSize = (gridApi: GridApi, columnApi: ColumnApi, width: number) => {
      const cols = gridApi.getColumnDefs()?.length;
      if (!cols) return;
      if ((width > 1000 && cols < 12) || (width > 800 && cols < 10)) {
        gridApi.sizeColumnsToFit();
        return;
      }
      autoSizeColumns(columnApi);
    };

    const columnsIdentifiers = (columns: ColDef[], autoGeneratedColumnIds?: string[]) =>
      columns
        .map(c => c.field || c.colId)
        .filter(
          id => id && !(autoGeneratedColumnIds || []).includes(id) && !id.startsWith("uniqueKey")
        )
        .sort();

    const expandColGroup = (columns: ColGroupDef[]) =>
      columns.flatMap(i => {
        const colGroupDef = i as ColGroupDef;
        return colGroupDef.children ? colGroupDef.children : [i];
      });

    const restoreColumnsPreferences = async (
      columns: ColDef[] | ColGroupDef[],
      columnApi: ColumnApi,
      preferencesHandler?: IGridPreferencesHandler,
      autoGeneratedColumnIds?: string[]
    ) => {
      if (preferencesHandler && preferencesHandler.getColumnsPreferences) {
        const preferences = await preferencesHandler.getColumnsPreferences();
        // console.log(`Prefs ${preferencesHandler.id()} from handler`, preferences);

        if (preferences && preferences.columnsState && preferences.columnsState.length) {
          const columnsIds = columnsIdentifiers(
            expandColGroup(columns as ColGroupDef[]),
            autoGeneratedColumnIds
          );
          const columnsPreferenceIds = preferences.columnsState
            .filter(
              c =>
                c.colId &&
                !c.colId.startsWith(AUTO_GROUP_COLUMN_PREFIX) &&
                !c.colId.startsWith("uniqueKey")
            )
            .map(c => c.colId)
            .filter(id => id && !(autoGeneratedColumnIds || []).includes(id))
            .sort();
          // console.log(`C ${preferencesHandler.id()}`, columnsIds);
          // console.log(`P ${preferencesHandler.id()}`, columnsPreferenceIds);
          if (columnsIds.length && columnsPreferenceIds.length) {
            if (
              columnsIds.length === columnsPreferenceIds.length &&
              columnsIds.every(
                (i: string | undefined, idx: number) => i === columnsPreferenceIds[idx]
              )
            ) {
              // console.log(`Restore ${preferencesHandler.id()}`, preferences.columnsState);
              // auto generated columns are always appended
              columnApi.applyColumnState({
                state: [
                  ...preferences.columnsState,
                  ...(autoGeneratedColumnIds || []).map(colId => ({ colId }))
                ],
                applyOrder: true
              });
              columnApi.setColumnGroupState(preferences.columnGroupsState);
            } else {
              // console.log(`Reset ${preferencesHandler.id()}`);
              preferencesHandler.clearColumnsPreferences &&
                preferencesHandler.clearColumnsPreferences();
              columnApi.resetColumnState();
              columnApi.resetColumnGroupState();
            }
          }
        }
      }
    };

    const restoreColumnsFilters = async (
      columns: ColDef[],
      gridApi: GridApi,
      preferencesHandler?: IGridPreferencesHandler,
      autoGeneratedColumnIds?: string[]
    ) => {
      if (preferencesHandler && preferencesHandler.getColumnsFilters) {
        const filters = preferencesHandler.getColumnsFilters();
        if (filters) {
          const columnsIds = columnsIdentifiers(columns, autoGeneratedColumnIds);
          const filteredColumnsIds = Object.keys(filters);
          if (
            filteredColumnsIds.every(id =>
              columnsIds.includes(
                id.startsWith(AUTO_GROUP_COLUMN_PREFIX)
                  ? id.substring(AUTO_GROUP_COLUMN_PREFIX.length)
                  : id
              )
            )
          ) {
            // TODO: some tests to look into the issue explained below
            // ["storageLocation", "material"].forEach(async filterId => {
            //   const filterInstance = gridApi.getFilterInstance(filterId);
            //   if (filterInstance) {
            //     console.log("before refresh", new Date());
            //     (filterInstance as any).filters[1].refreshFilterValues();
            //     console.log("after refresh", new Date());
            //     // setTimeout(async () => {
            //     //   console.log("Applying", filters["storageLocation"]);
            //     //   await filterInstance.setModel(filters["storageLocation"]);
            //     //   gridApi.onFilterChanged();
            //     // }, 5000);
            //     console.log("setModel", new Date());
            //     await filterInstance.setModel(filters[filterId]);
            //   }
            // });
            // gridApi.onFilterChanged();
            // console.log("onFilterChanged", new Date());
            gridApi.setFilterModel(filters);
            // //TODO: rebating the filters cause the selection in the floating filter box is not shown if you set the filters before the list of available choices is loaded
            setTimeout(() => gridApi.setFilterModel(filters), 3000);
          } else {
            preferencesHandler.clearColumnsFilters && preferencesHandler.clearColumnsFilters();
          }
        }
      }
    };

    const handleGridReady = (event: GridReadyEvent) => {
      setTableApi({ gridApi: event.api, columnApi: event.columnApi });

      restoreColumnsPreferences(
        columns,
        event.columnApi,
        preferencesHandler,
        autoGeneratedColumnIds
      );

      if (redirectFilters) {
        event.api.setFilterModel(redirectFilters);
      } else {
        if (defaultFilters) {
          event.api.setFilterModel(defaultFilters);
        }
        restoreColumnsFilters(columns, event.api, preferencesHandler, autoGeneratedColumnIds);
      }
    };

    const handleDetailsGridReady =
      (columns: ColDef[], preferencesHandler?: IGridPreferencesHandler) =>
      (event: GridReadyEvent) => {
        restoreColumnsPreferences(columns, event.columnApi, preferencesHandler);
      };

    const handleFirstDataRendered = (event: FirstDataRenderedEvent) => {
      toggleTableUpdated();
    };

    const saveColumnsPreferences = (
      event: ColumnMovedEvent | ColumnPinnedEvent | ColumnVisibleEvent | ColumnRowGroupChangedEvent,
      preferencesHandler?: IGridPreferencesHandler,
      autoGeneratedColumnIds?: string[]
    ) => {
      // auto generated columns are not saved
      const columnsState = event.columnApi
        .getColumnState()
        .filter(c => c.colId && !(autoGeneratedColumnIds || []).includes(c.colId));
      const columnGroupsState = event.columnApi.getColumnGroupState();
      if ("visible" in event && columnsState.every(c => c.hide === true)) {
        // Prevent hiding all columns
        const visibleEvent = event as ColumnVisibleEvent;
        if (visibleEvent.columns && visibleEvent.columns.length) {
          event.columnApi.setColumnVisible(visibleEvent.columns[0].getColId(), true);
        }
        return;
      }

      if (preferencesHandler && preferencesHandler.saveColumnsPreferences) {
        if (preferencesHandler.saveColumnsPreferencesTimeout) {
          clearTimeout(preferencesHandler.saveColumnsPreferencesTimeout);
          preferencesHandler.saveColumnsPreferencesTimeout = undefined;
        }
        preferencesHandler.saveColumnsPreferencesTimeout = setTimeout(() => {
          preferencesHandler.saveColumnsPreferences &&
            preferencesHandler.saveColumnsPreferences({ columnsState, columnGroupsState });
        }, 2000);
      }
    };

    const handleColumnStateChanged = (
      event: ColumnMovedEvent | ColumnPinnedEvent | ColumnVisibleEvent | ColumnRowGroupChangedEvent
    ) => {
      // commenting due to menu close when user hides the column
      //event.api.refreshHeader();

      saveColumnsPreferences(event, preferencesHandler, autoGeneratedColumnIds);

      toggleTableUpdated();
    };

    const handleDetailsColumnStateChanged =
      (preferencesHandler?: IGridPreferencesHandler) =>
      (
        event:
          | ColumnMovedEvent
          | ColumnPinnedEvent
          | ColumnVisibleEvent
          | ColumnRowGroupChangedEvent
      ) => {
        saveColumnsPreferences(event, preferencesHandler);
      };

    const handleRowDataChanged = (event: RowDataChangedEvent) => {
      toggleTableUpdated();
    };

    const handleRowDataUpdated = (event: RowDataUpdatedEvent) => {
      toggleTableUpdated();
    };

    const saveFilters = (event: FilterChangedEvent) => {
      if (preferencesHandler && preferencesHandler.saveColumnsFilters) {
        const filters = event.api.getFilterModel();
        preferencesHandler.saveColumnsFilters(filters);
      }
    };

    const handleFilterChanged = (event: FilterChangedEvent) => {
      onColumnFilterChanged && onColumnFilterChanged(event);
      saveFilters(event);

      toggleTableUpdated();
    };

    const handleSortChanged = (event: SortChangedEvent) => {
      const sortChangedColumn = event.columnApi.getColumnState().filter(i => i.sort)?.[0];
      onSortChanged &&
        onSortChanged(sortChangedColumn?.colId, sortChangedColumn?.sort as "asc" | "desc");
      toggleTableUpdated();
    };

    const handlePaginationChanged = (event: PaginationChangedEvent) => {
      if (serverModel) {
        toggleTableUpdated();
      }
    };

    const handleCellValueChanged = (event: CellValueChangedEvent) => {
      toggleTableUpdated();
      // refresh entire row so cells depending on changed cell value will get refreshed
      event.api.refreshCells({ rowNodes: [event.node], force: true });
    };

    const handleSelectionChanged = (event: SelectionChangedEvent) => {
      toggleTableUpdated();
    };

    const handleGridSizeChanged = (event: GridSizeChangedEvent) => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
        resizeTimeout = undefined;
      }
      resizeTimeout = setTimeout(
        () => fitOrAutoSize(event.api, event.columnApi, event.clientWidth),
        200
      );
    };

    const buildRowClassRules = () => {
      if (!rowClassRules) return undefined;

      const rules: { [className: string]: (params: any) => boolean } = {};
      for (const [key, value] of Object.entries(rowClassRules)) {
        rules[key] = (params: any) => value(params.data, params);
      }
      return rules;
    };

    const dropCustomProps = (c: IColumnDefinition) => {
      // const { dataType, suppressTechnicalTooltip, ...rest } = c;
      // return rest;
    };

    const setNonGroupProps = (c: IColumnDefinition) => {
      return {
        ...c,
        enableRowGroup: columnSpecificGrouping ? c.enableRowGroup : allowGrouping,
        suppressMovable: false
      };
    };

    const buildDetailCellRendererParams =
      (detailsConfig?: IGridDetailsConfig<any, any>): any =>
      (parentGridParams: any) => {
        if (!detailsConfig) return undefined;

        const {
          columns,
          rowKeySelector,
          getDetailRowData,
          getCustomMenuItems,
          preferencesHandler
        } = detailsConfig;

        return {
          detailGridOptions: {
            columnDefs: columns.map(setNonGroupProps),
            defaultColDef: defaultColumnsDefinitions,
            maintainColumnOrder: maintainColumnOrder,
            getRowId: (params: GetRowIdParams) => rowKeySelector(params.data),
            onGridSizeChanged: handleGridSizeChanged,
            suppressCsvExport: true,
            // suppressExcelExport: true,
            tooltipShowDelay: 500,
            tooltipMouseTrack: true,
            rowGroupPanelShow: allowGrouping ? "always" : "never",
            groupDisplayType: "multipleColumns",
            groupMaintainOrder: true,
            autoGroupColumnDef: {
              ...autoGroupColumnDefConfig,
              filter: autoGroupColumnFilter || "agTextColumnFilter"
            },
            masterDetail: detailsConfig.detailsConfig !== undefined,
            isRowMaster: detailsConfig.detailsConfig && detailsConfig.detailsConfig.isRowMaster,
            detailCellRendererParams: buildDetailCellRendererParams(detailsConfig.detailsConfig),
            getMainMenuItems: (params: GetMainMenuItemsParams) => [
              ...params.defaultItems,
              ...(getCustomMenuItems && getCustomMenuItems(params)
                ? ["separator", ...getCustomMenuItems(params)]
                : [])
            ],
            getContextMenuItems: (params: any) => [
              ...defaultContextMenuItems,
              ...((getCustomContextMenuItems && getCustomContextMenuItems(params)) || [])
            ],
            context: { parentData: parentGridParams.data },
            onGridReady: handleDetailsGridReady(columns, preferencesHandler),
            onColumnMoved: handleDetailsColumnStateChanged(preferencesHandler),
            onColumnPinned: handleDetailsColumnStateChanged(preferencesHandler),
            onColumnVisible: handleDetailsColumnStateChanged(preferencesHandler),
            onColumnRowGroupChanged: handleDetailsColumnStateChanged(preferencesHandler)
          },
          getDetailRowData,
          keepDetailRows: false
        };
      };

    // this make sure we don't have empty columns when grid is ready
    // so prefs can be restored properly
    if (!columns || !columns.length) return null;

    return (
      <div
        className="ag-theme-alpine"
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        {showColumnsFiltersInfoAt && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flex: 0,
              justifyContent: showColumnsFiltersInfoAt === "right" ? "flex-end" : "flex-start"
            }}
          >
            <ColumnsFiltersInfo
              columnsNames={filteredColumns || []}
              position={showColumnsFiltersInfoAt === "right" ? "left" : "right"}
              onClear={clearFilters}
            />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <AgGridReact
            animateRows={false}
            columnDefs={columns}
            defaultColDef={defaultColumnsDefinitions}
            maintainColumnOrder={maintainColumnOrder}
            // suppressClickEdit
            // stopEditingWhenGridLosesFocus
            stopEditingWhenCellsLoseFocus
            suppressCsvExport
            // suppressExcelExport
            suppressRowClickSelection
            suppressClickEdit={suppressClickEdit}
            suppressAggFuncInHeader={!showAggregationFuncInHeader}
            tooltipShowDelay={500}
            tooltipMouseTrack={true}
            getMainMenuItems={(params: GetMainMenuItemsParams) => [
              ...params.defaultItems,
              ...(getCustomMenuItems &&
              getCustomMenuItems(params) &&
              getCustomMenuItems(params).length > 0
                ? ["separator", ...getCustomMenuItems(params)]
                : [])
            ]}
            getContextMenuItems={params => [
              ...defaultContextMenuItems,
              ...((getCustomContextMenuItems && getCustomContextMenuItems(params)) || [])
            ]}
            onGridReady={handleGridReady}
            onFirstDataRendered={handleFirstDataRendered}
            onRowDataChanged={handleRowDataChanged}
            onRowDataUpdated={handleRowDataUpdated}
            onFilterChanged={handleFilterChanged}
            onCellValueChanged={handleCellValueChanged}
            rowClassRules={buildRowClassRules()}
            rowGroupPanelShow={allowGrouping ? "always" : "never"}
            groupDisplayType={rowGroupingDisplayType ? rowGroupingDisplayType : "multipleColumns"}
            groupMaintainOrder={true}
            groupHideOpenParents={groupHideOpenParents}
            groupSelectsChildren={groupSelectsChildren}
            groupSelectsFiltered={groupSelectsFiltered}
            groupDefaultExpanded={groupDefaultExpanded}
            isGroupOpenByDefault={isGroupOpenByDefault}
            autoGroupColumnDef={{
              ...autoGroupColumnDefConfig,
              filter: autoGroupColumnFilter || "agTextColumnFilter"
            }}
            groupHeaderHeight={groupHeaderHeight}
            masterDetail={detailsConfig !== undefined}
            detailCellRendererParams={buildDetailCellRendererParams(detailsConfig)}
            detailRowAutoHeight={true}
            rowSelection={columns.some(c => c.checkboxSelection) ? "multiple" : undefined}
            onSelectionChanged={handleSelectionChanged}
            onGridSizeChanged={handleGridSizeChanged}
            onColumnMoved={handleColumnStateChanged}
            onColumnPinned={handleColumnStateChanged}
            onColumnVisible={handleColumnStateChanged}
            onColumnRowGroupChanged={handleColumnStateChanged}
            isRowMaster={detailsConfig && detailsConfig.isRowMaster}
            onSortChanged={handleSortChanged}
            onPaginationChanged={handlePaginationChanged}
            isRowSelectable={isRowSelectable}
            isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
            suppressGroupRowsSticky={suppressGroupRowsSticky}
            loadingOverlayComponent={loadingOverlayComponent}
            {...modelProps()}
          />
        </div>
      </div>
    );
  };

  return {
    renderTable,
    rows,
    selectedRows,
    selectedNodes,
    filtersCount,
    filteredColumns,
    hiddenColumns,
    clearFilters,
    clearFilter,
    setFilter,
    setColumnVisible,
    setColumnGroup,
    clearSelection,
    setRowVisible,
    setRowSorting,
    redrawRow,
    updateColumnsDefinition,
    resetColumnsDefinition,
    exportAsExcel,
    refreshServerSideStore,
    expandAll,
    collapseAll,
    collapse,
    getEachNodeAfterFilter,
    getEachNode,
    showLoadingOverlay,
    hideOverlay
  };
};

export const validColumnFilter = (columns: string[]) => (column: IColumnDefinition) =>
  columns.map(c => c.toLocaleUpperCase()).includes((column.field || "").toLocaleUpperCase());
