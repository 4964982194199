import { IDropdownItem } from "@react-gcc-eds/core";
import { useApiGet, useApiPost, useApiPut } from "../../api/api";
import { IActionResult } from "../../management/api/ticket";

export interface ISubscriptionItem {
  customers: string[] | null;
  escalationRegions: string[] | null;
  ticketCategories: string[] | null;
}

export enum ESubViewType {
  month = "Month",
  quarter = "Quarter",
  year = "Year"
}

export interface ISubDisplayViewItem {
  defaultTimeFrame: number;
  maxTimeFrame: number;
  viewType: ESubViewType;
}

export interface IDisplayTimeItem {
  displayViewType: ESubViewType;
  timeFrame: number;
}

export interface IDisplayViewChartItem {
  displayView: string;
  value: number;
}

export interface IDisplayViewMADItem {
  displayView: string;
  averageImprovedDays: number;
}

export interface IDisplayViewLtItem {
  displayView: string;
  averageHandlingLeadTimeInDays: number;
}

export interface IFulfillmentRatesItem {
  fulfillmentCount: number;
  displayView: string;
  notFulfillmentCount: number;
  closedCount: number;
  fulfillPercentage: number;
}

export interface IEscalationStatusesItem {
  closedCount: number;
  inProgressCount: number;
  displayView: string;
  closedPercentage: number;
  total: number;
}

export interface ISubHistoryData {
  currentMaterialAvailableDateImproveds: IDisplayViewMADItem[];
  fulfillmentRates: IFulfillmentRatesItem[];
  handlingLeadTimeAvgs: IDisplayViewLtItem[];
  historyEscalationStatuses: IEscalationStatusesItem[];
}

export interface ISubMonthData {
  currentMaterialAvailableDateImproved: IDisplayViewMADItem;
  fulfillmentRate: IFulfillmentRatesItem;
  handlingLeadTimeAvg: IDisplayViewLtItem;
  historyEscalationStatus: IEscalationStatusesItem;
}

export interface ICUPerformanceParam {
  displayViewType: string;
  timeFrame: number;
}

export interface IPaginationData<T> {
  total: number;
  data: T;
}

export interface ICUPerformanceItem {
  closedEscalation: number;
  customerUnit: string;
  handlingLtForFinishedEscalation: number;
  maDateImproved: number;
  totalEscalation: number;
  groupSupplyUnit: string;
}

export enum ESubType {
  customers = "customers",
  escalationRegions = "escalationRegions",
  ticketCategories = "ticketCategories"
}

export interface ISubCustomerInfoItem {
  customerUnit: string;
  customer: string;
  groupSupplyUnit: string;
  ticketCategory: string;
  productReportingGroup: string;
}

export interface ISubLookupItems {
  displayViews: ISubDisplayViewItem[];
  groupSupplyUnitOptions: ISubCustomerInfoItem[];
}

export interface ISubAllFilterParams extends IDisplayTimeItem {
  ticketCategories?: string[];
  productReportingGroups?: string[];
  groupSupplyUnits?: string[];
  customerUnits?: string[];
  customers?: string[];
  sippOrNonSipp?: string[];
  forecasted?: string[];
}

export interface ISubAllFilterItems {
  displayView: IDropdownItem<ESubViewType>;
  timeFrame: IDropdownItem<number>;
  ticketCategory: IDropdownItem[];
  productReportingGroup: IDropdownItem[];
  groupSupplyUnit: IDropdownItem[];
  customerUnit: IDropdownItem[];
  customer: IDropdownItem[];
  sippOrNonSipp: IDropdownItem[];
  forecasted: IDropdownItem[];
}

export interface ISubMonthHistoryData {
  history: ISubHistoryData;
  monthly: ISubMonthData;
}

const SUB = "/v1/escalation-feedback/subscription";

export const useGetSubscription = () => useApiGet<ISubscriptionItem>(SUB);

export const useUpdateSubscription = () =>
  useApiPut<ISubscriptionItem, IActionResult>(SUB + "/update");

export const useGetEpLookup = () =>
  useApiGet<ISubLookupItems>("/v1/escalation-feedback/lookup/escalation-performance");

export const useGetEpHistory = () =>
  useApiPost<ISubAllFilterParams, ISubMonthHistoryData>(
    "/v1/escalation-feedback/visualization/escalation-performance"
  );

export const useGetSubDisplayViews = () =>
  useApiGet<ISubDisplayViewItem[]>("/v1/escalation-feedback/lookup/display-views");

export const useGetSubCUPerformance = () =>
  useApiGet<ICUPerformanceItem[], ICUPerformanceParam>(
    "/v1/escalation-feedback/visualization/cu-performance"
  );
