import React, { ReactNode, useState } from "react";
import { useClickOutside } from "../../utils";
import cx from "classnames";
import { Button, Icon } from "@react-gcc-eds/core";
import { IDropdownFilterProps } from "./IDropdown";
import "./index.scss";

export type ISortStatus = undefined | "sort" | "sortDesc";

export interface IFilterWrapperProps extends IDropdownFilterProps {
  children?: ReactNode;
  hasSort?: boolean;
  hasBottomClean?: boolean;
}

const FilterWrapper = ({
  onClose,
  onOpen,
  // visible,
  sortStatus,
  onSortChange,
  onClean,
  onApply,
  style,
  className,
  position,
  children,
  appliedFilter,
  appliedSort,
  hasSort = true,
  hasBottomClean = false
}: IFilterWrapperProps) => {
  const handleOpen = () => {
    setVisible(true);
    onOpen && onOpen();
  };
  const handleClose = () => {
    setVisible(false);
    onClose && onClose();
  };
  const containerRef = useClickOutside(handleClose);
  const [visible, setVisible] = useState(false);

  return (
    <div
      ref={containerRef}
      role="presentation"
      className={cx("dropdown more i-dropdown", { open: visible }, className)}
      style={style}
      datatype="click"
      onClick={event => {
        // console.log((event.target as HTMLElement)?.getAttribute("datatype"));
        (event.target as HTMLElement)?.getAttribute("datatype") === "target-open" && visible
          ? handleClose()
          : handleOpen();
      }}
    >
      <button type="button" className="btn clickable">
        <i
          className="icon icon-filter"
          style={{ color: appliedFilter ? "#0077DB" : undefined }}
          datatype="target-open"
        />
        {appliedSort === "sort" ? (
          <i className="icon icon-sort-up" datatype="target-open" />
        ) : appliedSort === "sortDesc" ? (
          <i className="icon icon-sort-down" datatype="target-open" />
        ) : undefined}
      </button>
      <div
        className={cx("menu", { visible })}
        style={position === "left" ? { left: 0, right: "unset" } : { right: 0 }}
      >
        <div className="filter-title">Filter</div>
        {hasSort && (
          <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            <div
              role="presentation"
              className={cx("top-item", { activate: sortStatus === "sort" })}
              onClick={() => onSortChange && onSortChange("sort")}
            >
              <Icon name="sort-up" />
              <span>Sort</span>
            </div>
            <div
              role="presentation"
              className={cx("top-item", { activate: sortStatus === "sortDesc" })}
              onClick={() => onSortChange && onSortChange("sortDesc")}
            >
              <Icon name="sort-down" />
              <span>Sort By Desc</span>
            </div>
            <div
              role="presentation"
              className={cx("top-item")}
              onClick={() => {
                onClean && onClean();
                onSortChange && onSortChange(undefined);
              }}
            >
              <Icon name="filter-clear" />
              <span>Clean</span>
            </div>
          </div>
        )}
        {children}
        <div className="button-container">
          {hasBottomClean && (
            <div
              className="button-container-left"
              onClick={() => {
                onClean && onClean();
              }}
            >
              <Button iconName={"filter-clear"}>Clear</Button>
            </div>
          )}
          <div
            onClick={e => {
              e.stopPropagation();
              handleClose();
            }}
          >
            <Button iconName={"cross"} />
          </div>
          <div
            style={{ paddingLeft: "12px" }}
            onClick={e => {
              e.stopPropagation();
              setVisible(false);
              onApply();
            }}
          >
            <Button iconName={"check"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterWrapper;
