import {
  Button,
  Card,
  Icon,
  ISelectItem,
  Loader,
  MultiPanelTile,
  TileActionButton
} from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import DonutChart from "../../../components/charts/donut-chart";
import ContentLoader from "../../../components/content-loader";
import SingleSelect from "../../../components/select/SingleSelect";
import Table from "../../../components/table";
import { getFormatWeek } from "../../../utils";
import { EGftStatus, ISippItem, IStatusWeeklyItem, IWeekParam } from "../../api/supplier-fcst-res";
import { getPublishStatusTableColumns } from "../../tables/columns/gft-table";
import { CUSTOM_COLORS } from "./PublishStatusTrend";

export interface ISippRow extends ISippItem {
  total: number;
  remarks: string;
}

const PublishStatusWeekly = ({
  loadingFilter,
  loadingChart,
  loadingExport,
  allWeeksData,
  statusWeeklyData,
  onSearch,
  onExport
}: {
  loadingFilter: boolean;
  loadingChart: boolean;
  loadingExport: boolean;
  allWeeksData: number[];
  statusWeeklyData: IStatusWeeklyItem[];
  onSearch: (value: IWeekParam) => void;
  onExport: (value: IWeekParam) => void;
}) => {
  const REMARKS_WIDTH = 32;
  const getWidthsByRemark = (width: number): string[] => {
    const otherWidth = Math.floor((100 - width) / 4);
    return [`${otherWidth}%`, `${otherWidth}%`, `${otherWidth}%`, `${otherWidth}%`, `${width}%`];
  };
  //filter
  const [editedFilters, setEditedFilters] = useState<ISelectItem<any>>({ title: "", value: "" });
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const toggleFilters = () => setShowFilters(show => !show);

  useEffect(() => {
    if (allWeeksData) {
      setEditedFilters({
        title: getFormatWeek(allWeeksData[0]),
        value: allWeeksData[0]
      });
    }
  }, [allWeeksData]);

  const handleChange = (item: ISelectItem) => setEditedFilters(item);

  const reset = () => {
    if (allWeeksData) {
      setEditedFilters({
        title: getFormatWeek(allWeeksData[0]),
        value: allWeeksData[0]
      });
    }
  };

  const leftPanel = () => (
    <div className="scrollable-pane-content">
      <div className="scrollable-pane-controls">
        <SingleSelect
          items={allWeeksData.map(time => ({
            title: getFormatWeek(time),
            value: time
          }))}
          label={editedFilters.title}
          placeholder="Default"
          topLabel="Week"
          onChange={handleChange}
        />
      </div>
      <div className="scrollable-pane-actions">
        <Button onClick={reset}>Reset</Button>
        <Button primary onClick={() => onSearch({ weekTime: editedFilters.value as number })}>
          Apply
        </Button>
      </div>
    </div>
  );

  //table
  const getColumns = (weekStatus: EGftStatus, remarks = ""): ISippRow[] =>
    (statusWeeklyData.find(({ status }) => status === weekStatus)?.siteStatus || []).map(
      (item, i) => ({
        ...item,
        remarks: i === 0 ? remarks : "",
        total: item.sipp + item.nonSipp
      })
    );
  const getTotalRow = (row: ISippRow[]): ISippRow =>
    row.length > 0
      ? row.reduce((a, b) => ({
          consumingSCN: "Grand Total",
          nonSipp: a.nonSipp + b.nonSipp,
          sipp: a.sipp + b.sipp,
          total: a.total + b.total,
          remarks: ""
        }))
      : {
          consumingSCN: "Grand Total",
          nonSipp: 0,
          sipp: 0,
          total: 0,
          remarks: ""
        };
  const addTotalRow = (row: ISippRow[]): ISippRow[] => [...row, getTotalRow(row)];

  const unpublishedRow = addTotalRow(
    getColumns(EGftStatus.unpublished, "Check if need to send out FCST")
  );
  const withoutCapabilityRow = addTotalRow(
    getColumns(EGftStatus.withoutCapability, "Push vendor to make FCST confirmation")
  );
  const gapRow = addTotalRow(getColumns(EGftStatus.gap, "Check if any supply risk parts"));

  //chart
  const [showChart, setShowChart] = useState<boolean>(true);
  const toggleChart = () => setShowChart(show => !show);

  const donutData = [
    { name: EGftStatus.unpublished, value: getTotalRow(getColumns(EGftStatus.unpublished)).total },
    {
      name: EGftStatus.withoutCapability,
      value: getTotalRow(getColumns(EGftStatus.withoutCapability)).total
    },
    { name: EGftStatus.gap, value: getTotalRow(getColumns(EGftStatus.gap)).total },
    { name: EGftStatus.noIssue, value: getTotalRow(getColumns(EGftStatus.noIssue)).total }
  ];

  return (
    <MultiPanelTile
      className="supplier-fcst-bottom"
      centerTitle="Publish Status"
      centerSubtitle={editedFilters.title}
      centerLeftIcon={<Icon name="filter" onClick={toggleFilters} style={{ cursor: "pointer" }} />}
      centerRightActions={[
        <TileActionButton key="gft_weekly_donut" iconName="graph-circle" onClick={toggleChart} />,
        loadingExport ? (
          <Loader />
        ) : (
          <TileActionButton
            key="gft_weekly_download"
            iconName="download-save"
            onClick={() => onExport({ weekTime: editedFilters.value as number })}
          />
        )
      ]}
      centerContent={
        <ContentLoader
          loading={loadingChart}
          data={statusWeeklyData}
          render={
            <>
              <Card
                title={EGftStatus.unpublished}
                content={
                  <Table
                    size="tiny"
                    columns={getPublishStatusTableColumns()}
                    rows={unpublishedRow}
                    widths={getWidthsByRemark(REMARKS_WIDTH)}
                  />
                }
              />
              <Card
                title={EGftStatus.withoutCapability}
                content={
                  <Table
                    size="tiny"
                    columns={getPublishStatusTableColumns()}
                    rows={withoutCapabilityRow}
                    widths={getWidthsByRemark(REMARKS_WIDTH)}
                  />
                }
              />
              <Card
                title={EGftStatus.gap}
                content={
                  <Table
                    size="tiny"
                    columns={getPublishStatusTableColumns()}
                    rows={gapRow}
                    widths={getWidthsByRemark(REMARKS_WIDTH)}
                  />
                }
              />
            </>
          }
        />
      }
      leftTitle="Selections"
      leftClassName="scrollable-pane"
      leftContent={
        <ContentLoader
          loading={loadingFilter}
          data={allWeeksData}
          render={allWeeksData ? leftPanel() : <></>}
        />
      }
      leftVisible={showFilters}
      leftActions={<Icon name="cross" onClick={toggleFilters} style={{ cursor: "pointer" }} />}
      rightActions={<Icon name="cross" onClick={toggleChart} style={{ cursor: "pointer" }} />}
      rightContent={
        <ContentLoader
          loading={loadingChart}
          data={statusWeeklyData}
          render={
            <div className="gft-donut-area">
              <DonutChart
                data={donutData}
                unitOfMeasure="Parts"
                valueDecimals={0}
                customColors={CUSTOM_COLORS.slice(1)}
                dataWithPercentage
              />
            </div>
          }
        />
      }
      rightTitle="Total parts by WK"
      rightSubtitle={editedFilters.title}
      rightVisible={showChart}
    />
  );
};
export default PublishStatusWeekly;
