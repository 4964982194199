import {
  Button,
  Checkbox,
  Column,
  Form,
  FormElement,
  FormGroup,
  Row,
  TextArea
} from "@react-gcc-eds/core";
import "./ticket-base-form.scss";
import SingleSelect from "../../../components/select/SingleSelect";
import TicketMaterialList from "./ticket-material-list";
import { renderLabel } from "../../helpers/ef-ticket";
import {
  IGlobalStockItem,
  ITicketApprovalEditedItems,
  ITicketBaseFormItem
} from "../../api/ticket";
import { IAssignUserParams, IEscalationLookup } from "../../api/lookup";
import { ITicketError } from "../../helpers/ef-validate";
import { EscalationLevel, EscalationStatus, Tag } from "../../helper";
import { TUserBaseInfo } from "../../../types";
import { addRowNumber } from "../../../utils";
import AssignTable from "./assign-table";

const TicketBasicForm = ({
  dropdownItems,
  formValues,
  approveValues,
  userList,
  disableBasicField = false,
  disableApprovalField,
  error,
  showApproval,
  hasEscalationLevel = true,
  loadingAutoFill = false,
  onValueChange,
  onApprovalValueChange,
  onAutoFill
}: {
  formValues: ITicketBaseFormItem;
  dropdownItems: IEscalationLookup;
  approveValues?: ITicketApprovalEditedItems;
  userList?: TUserBaseInfo[];
  error: ITicketError | undefined;
  disableBasicField?: boolean;
  disableApprovalField?: boolean;
  showApproval?: boolean;
  hasEscalationLevel?: boolean;
  loadingAutoFill?: boolean;
  onValueChange: (
    key: keyof ITicketBaseFormItem
  ) => (value: string | boolean | IGlobalStockItem[]) => void;
  onApprovalValueChange: (name: keyof ITicketApprovalEditedItems) => (value: any) => void;
  onAutoFill?: (data: IAssignUserParams) => void;
}) => {
  const showMaterial = formValues.ticketMaterials.length > 0;
  const showComments = Boolean(!showApproval && formValues.comments);

  const handleAutoFill = () => {
    onAutoFill &&
      onAutoFill({
        level: approveValues?.escalationLevel || "",
        region: formValues.escalationRegion,
        productReportingGroups:
          approveValues?.assignedUsers.map(({ productReportingGroup }) => productReportingGroup) ||
          []
      });
  };

  const basicEscalationForm = () => (
    <FormGroup
      className={`column ${showMaterial ? "ticket-base-form-sm" : "ticket-base-form-md"}`}
      title="Pre Escalation"
    >
      <FormElement>
        {renderLabel(
          "Pre Escalation Activity",
          error && error.type === "preEscalationActivity",
          undefined,
          <div className="ticket-base-form-tooltip">
            <div>a. Partial shipment yes or no(EPC)</div>
            <div>
              b. Improvement with vendors or check global stock availability with other hub
              including packed ageing goods in hub(hub)
            </div>
            <div>
              c. Check alternative source solutions product substitutes available(EPC coordinate)
            </div>
            <div>
              d. Unrestricted material Buy-back or cancellation flow from another customer(EPC)
            </div>
            <div>e. Possibility of local purchase(EPC)</div>
            <div>f. Change of transportation mode(EPC)</div>
            <div>
              g. Validate for Forecast, request LT, Standard LT etc. If possible for CU to
              accept(EPC)
            </div>
          </div>,
          "right"
        )}
        <Checkbox
          disabled={disableBasicField}
          text="Yes"
          checked={formValues.preEscalationActivity}
          onChange={onValueChange("preEscalationActivity")}
          className="ticket-base-form-checkbox"
        />
      </FormElement>
      <label style={{ margin: "12px 0", fontWeight: "bold" }}>Basic information</label>
      <FormElement>
        {renderLabel("Escalation Reason", error && error.type === "escalationReason")}
        <SingleSelect
          items={dropdownItems.escalationReasons.map(i => ({ title: i, value: i }))}
          onChange={item => onValueChange("escalationReason")(item.value)}
          placeholder="Please Select an option"
          value={formValues.escalationReason}
          disabled={disableBasicField}
          type="input-single"
          clickItemTextChange={false}
        />
      </FormElement>
      <FormElement>
        {renderLabel("Escalation Region", error && error.type === "escalationRegion")}
        <SingleSelect
          onChange={item => onValueChange("escalationRegion")(item.value)}
          value={formValues.escalationRegion}
          placeholder="Please Select an option"
          items={dropdownItems.escalationRegions.map(i => ({ title: i, value: i }))}
          disabled={disableBasicField}
          type="input-single"
          clickItemTextChange={false}
        />
      </FormElement>
      <FormElement>
        {renderLabel(
          "Business impact (Net sales/Penalties)",
          error && error.type === "businessImpact",
          error?.msg
        )}
        <TextArea
          style={{ height: 160 }}
          disabled={disableBasicField}
          value={formValues.businessImpact}
          onChange={item => {
            if (item.length > 500) return;
            onValueChange("businessImpact")(item);
          }}
        />
      </FormElement>
    </FormGroup>
  );

  const materialForm = () =>
    showMaterial && dropdownItems ? (
      <FormGroup className="column ticket-base-form-global">
        <TicketMaterialList
          formValues={formValues.ticketMaterials}
          disabled={disableBasicField}
          onChange={onValueChange("ticketMaterials")}
          options={dropdownItems.availBorrowHubs}
          error={error}
        />
      </FormGroup>
    ) : (
      <></>
    );

  const commentsForm = () =>
    showComments ? (
      <FormGroup className="column ticket-base-form-md" title="Comment">
        <FormElement>
          <label>Comments from approver</label>
          <TextArea style={{ height: 500 }} value={formValues.comments || ""} disabled />
        </FormElement>
      </FormGroup>
    ) : (
      <></>
    );

  const approvalForm = () =>
    showApproval && approveValues ? (
      <FormGroup className="column ticket-base-form-approval" title="Approval List">
        <FormElement>
          <Row className="ticket-base-form-approval-row">
            <Column sm={4}>
              {renderLabel("Tag", error && error.type === "escalationTag")}
              <SingleSelect
                disabled={disableApprovalField}
                items={Object.values(Tag).map(t => ({
                  title: t,
                  value: t
                }))}
                onChange={item => onApprovalValueChange("escalationTag")(item.value)}
                placeholder="Tag"
                value={approveValues.escalationTag}
                type="input-single"
                clickItemTextChange={false}
              />
            </Column>
            <Column sm={4} style={{ display: `${hasEscalationLevel ? "inline" : "none"}` }}>
              {renderLabel("Escalation Level", error && error.type === "escalationLevel")}
              <SingleSelect
                disabled={disableApprovalField}
                items={[
                  {
                    title: EscalationLevel.region,
                    value: EscalationLevel.region
                  },
                  {
                    title: EscalationLevel.continental,
                    value: EscalationLevel.continental
                  },
                  {
                    title: EscalationLevel.global,
                    value: EscalationLevel.global
                  }
                ]}
                onChange={item => onApprovalValueChange("escalationLevel")(item.value)}
                placeholder="Escalation Level"
                value={approveValues.escalationLevel || EscalationLevel.region}
                type="input-single"
                clickItemTextChange={false}
              />
            </Column>
            <Column sm={4}>
              {renderLabel("Escalation Response", error && error.type === "escalationStatus")}
              <SingleSelect
                disabled={disableApprovalField}
                items={[
                  {
                    title: "Pending",
                    value: EscalationStatus.pending
                  },
                  {
                    title: "Approval",
                    value: EscalationStatus.approval
                  },
                  {
                    title: "Reject to Requester",
                    value: EscalationStatus.rejectToRequester
                  },
                  {
                    title: "Reject to Region",
                    value: EscalationStatus.rejectToRegion
                  }
                ]}
                onChange={item => onApprovalValueChange("escalationStatus")(item.value)}
                value={approveValues.escalationStatus}
                type="input-single"
                clickItemTextChange={false}
              />
            </Column>
          </Row>
        </FormElement>
        <FormElement>
          {renderLabel("Comments", error && error.type === "comments", error?.msg)}
          <TextArea
            disabled={disableApprovalField}
            style={{ height: 85 }}
            onChange={item => {
              if (item.length > 255) return;
              onApprovalValueChange("comments")(item);
            }}
            value={approveValues.comments || ""}
          />
        </FormElement>
        <FormElement>
          <div className="assign-title">
            <label style={{ margin: 0 }}>Assign To</label>
            {!disableApprovalField && (
              <Button
                primary
                onClick={handleAutoFill}
                disabled={disableApprovalField}
                loading={loadingAutoFill}
              >
                Auto Fill
              </Button>
            )}
          </div>
          <AssignTable
            formValues={addRowNumber(approveValues.assignedUsers)}
            disabled={disableApprovalField || loadingAutoFill}
            onChange={onApprovalValueChange("assignedUsers")}
            error={error}
            userList={userList || []}
            dropdownItems={dropdownItems}
          />
        </FormElement>
      </FormGroup>
    ) : (
      <></>
    );

  return (
    <div className="ticket-base-form">
      <Form style={{ maxWidth: "none" }}>
        {basicEscalationForm()}
        {materialForm()}
        {commentsForm()}
        {approvalForm()}
      </Form>
    </div>
  );
};

export default TicketBasicForm;
