import { Button, Dialog, FileUpload } from "@react-gcc-eds/core";
import { IAssignUserParams, IEscalationLookup } from "../../api/lookup";
import {
  IBasicSoItem,
  IGlobalStockItem,
  ITicketAttachmentItem,
  ITicketCreateParams,
  ITicketDetailSoItem,
  ITicketDetails,
  ITicketUpdateParams,
  ITicketUploadParams,
  ITicketBaseFormItem,
  ITicketApprovalEditedItems,
  IAssignedUserEditedItem
} from "../../api/ticket";
import TicketSoItemTable from "./ticket-so-item-table";
import "./ticket-base-info-dialog.scss";
import TicketBasicForm from "./ticket-base-form";
import {
  DEFAULT_APPROVAL_ITEM,
  DEFAULT_OPTIONS,
  DEFAULT_SO_ITEM,
  DEFAULT_SO_ITEMS,
  DEFAULT_TICKET_DETAIL,
  NEW_ESCALATION,
  equalSoItem
} from "../../helpers/ef-ticket";
import { useEffect, useMemo, useState } from "react";
import { addRowNumber, getISODate } from "../../../utils";
import { getApiSoItemRows, useValidateTicket } from "../../helpers/ef-validate";
import { simpleObjectEqual } from "../../../utils/user-helpers";
import TicketPreviewDialog from "./ticket-preview-dialog";
import MultipleUpload, { IUploadFile } from "../../../components/multiple-upload/MultipleUpload";
import MultipleDownload from "../../../components/multiple-download/MultipleDownload";
import { TEscalationStatus } from "../../types";
import { EscalationLevel, EscalationStatus, Tag } from "../../helper";
import { TUserBaseInfo } from "../../../types";
import { IOaItem } from "../../api/sales-order";
import Loader from "../../../components/loader";

interface ITicketBaseInfoDialog {
  show: boolean;
  showPreviewDialog?: boolean;
  title: string;
  dropdownItems?: IEscalationLookup;
  formData?: ITicketDetails;
  checkData?: ITicketDetailSoItem[];
  uploadCheckData?: ITicketDetailSoItem[];
  defaultAssignees?: IAssignedUserEditedItem[];
  userList?: TUserBaseInfo[];
  downloadAttachments?: Blob;
  showApproval?: boolean;
  hasEscalationLevel?: boolean;
  editableOA?: boolean;
  disableBasicInfo?: boolean;
  disableApprovalInfo?: boolean;
  loadingDialog?: boolean;
  loadingSoItem?: boolean;
  loadingPreview?: boolean;
  loadingDownload?: boolean;
  loadingAutoFill?: boolean;
  loadingUpdateOa?: boolean;
  onClose: () => void;
  onPreviewClose?: () => void;
  onUploadChange?: (e: any) => void;
  onCheck?: (value: IBasicSoItem[]) => void;
  onSubmit?: (
    data: ITicketCreateParams | ITicketUpdateParams | ITicketApprovalEditedItems,
    uploadParams?: ITicketUploadParams,
    formChanged?: boolean
  ) => void;
  onDownload: (attachmentIds: number[]) => void;
  onAutoFill?: (data: IAssignUserParams) => void;
  onUpdateOa?: (oaList: IOaItem[]) => void;
}

const TicketBaseInfoDialog = ({
  show,
  title,
  dropdownItems = DEFAULT_OPTIONS,
  formData,
  checkData,
  uploadCheckData,
  defaultAssignees,
  userList,
  downloadAttachments,
  showApproval,
  hasEscalationLevel,
  showPreviewDialog,
  editableOA,
  disableBasicInfo,
  disableApprovalInfo,
  loadingDialog,
  loadingSoItem,
  loadingDownload,
  loadingAutoFill,
  loadingUpdateOa,
  onClose,
  onPreviewClose,
  onUploadChange,
  onCheck,
  onSubmit,
  onDownload,
  onAutoFill,
  onUpdateOa
}: ITicketBaseInfoDialog) => {
  //common
  const { defaultFormValues, defaultSoItems, defaultApprovalValues } =
    useDefaultFormValues(formData);
  const {
    editedSoItems,
    setEditedSoItems,
    editedFormValues,
    setEditedFormValues,
    appliedSoItems,
    resetForm
  } = useEditedFormValues(defaultSoItems, defaultFormValues, checkData, uploadCheckData);
  const { editedApprovalValues, handleAssignChange } = useEditedApprovalValues(
    defaultApprovalValues,
    defaultAssignees
  );
  const { error, setError, validateSoItems, validateFormValues, validateApproveValues } =
    useValidateTicket({
      soItemData: editedSoItems,
      checkData,
      formData: editedFormValues,
      approvalData: editedApprovalValues
    });

  useEffect(() => {
    if (!show) {
      resetForm();
      setEditedUploadFiles([]);
    }
  }, [show]);

  //so item
  const handleAddSoItem = () => {
    setEditedSoItems(items => [
      ...items,
      {
        rowNumber: items.length,
        ...DEFAULT_SO_ITEM
      }
    ]);
  };

  const handleDelSoItem = (rowNumber: number) => {
    setEditedSoItems(items => addRowNumber(items.filter(item => item.rowNumber !== rowNumber)));
  };

  const handleChangeSoItem = (key: any, rowNumber: number, value: string | number) => {
    setEditedSoItems(items =>
      items.map(item => (item.rowNumber === rowNumber ? { ...item, [key]: value } : item))
    );
  };

  const handleCheckSoItem = () => {
    if (validateSoItems()) {
      setError(undefined);
      onCheck &&
        onCheck(
          getApiSoItemRows(editedSoItems).map(({ salesOrder, item, rowNumber }) => ({
            salesOrder,
            item,
            rowNumber: rowNumber as number
          }))
        );
    }
  };

  //form
  const getSoItem = (data: ITicketDetailSoItem[]) =>
    data.map(({ salesOrder, item }) => ({ salesOrder, item }));

  const handleChangeBasicForm =
    (key: keyof ITicketBaseFormItem) => (value: string | boolean | IGlobalStockItem[]) => {
      setEditedFormValues(values => ({ ...values, [key]: value }));
    };

  //preview
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (showPreviewDialog !== undefined) {
      setShowPreview(showPreviewDialog);
    }
  }, [showPreviewDialog]);

  const handlePreviewSoItem = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    onPreviewClose && onPreviewClose();
  };

  //upload
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [defaultUploadFiles, setDefaultUploadFiles] = useState<IUploadFile[]>([]);
  const [editedUploadFiles, setEditedUploadFiles] = useState<IUploadFile[]>([]);
  const attachmentsChanged = useMemo(
    () => !simpleObjectEqual(editedUploadFiles, defaultUploadFiles),
    [defaultUploadFiles, editedUploadFiles]
  );
  const disableTicketApply =
    !simpleObjectEqual(getSoItem(editedSoItems), getSoItem(appliedSoItems)) ||
    (simpleObjectEqual(defaultSoItems, editedSoItems) &&
      simpleObjectEqual(defaultFormValues, editedFormValues) &&
      !attachmentsChanged) ||
    disableBasicInfo;

  const disableApproveApply = simpleObjectEqual(defaultApprovalValues, editedApprovalValues);

  useEffect(() => {
    if (formData?.ticketAttachments) {
      const files = apiToAttachments(formData.ticketAttachments);
      setDefaultUploadFiles(files);
      setEditedUploadFiles(files);
    }
  }, [formData?.ticketAttachments]);

  const handleMultipleUploadSubmit = (files: IUploadFile[]) => {
    setEditedUploadFiles(files);
    setShowUploadDialog(false);
  };

  //download
  const [showDownloadDialog, setShowDownloadDialog] = useState<boolean>(false);

  const apiToAttachments = (attachments: ITicketAttachmentItem[]): IUploadFile[] =>
    attachments
      ? attachments.map(({ attachmentName, id }) => ({
          id,
          fileName: attachmentName
        }))
      : [];

  useEffect(() => {
    if (downloadAttachments) {
      if (downloadAttachments.size !== 0) {
        setShowDownloadDialog(false);
      }
    }
  }, [downloadAttachments]);

  //submit
  const handleSubmit = () => {
    if (showApproval) {
      if (validateApproveValues()) {
        setError(undefined);
        onSubmit && onSubmit(editedApprovalValues);
      }
    } else if (validateSoItems(true) && validateFormValues()) {
      setError(undefined);
      const { ticketMaterials, ...otherFormData } = editedFormValues;

      const formParams = {
        ...otherFormData,
        materials: ticketMaterials,
        sos: editedSoItems.map(
          ({
            salesOrder,
            item,
            minimumCriticalDemandPerWeek,
            latestAcceptableReadyForShipmentDate,
            projectNameOrDeal,
            nroOrBoxDelivery,
            partialDeliveryAcceptable
          }) => ({
            salesOrder,
            item,
            minimumCriticalDemandPerWeek,
            latestAcceptableReadyForShipmentDate: getISODate(latestAcceptableReadyForShipmentDate),
            projectNameOrDeal,
            nroOrBoxDelivery,
            partialDeliveryAcceptable
          })
        )
      };
      const formChanged =
        !simpleObjectEqual(defaultSoItems, editedSoItems) ||
        !simpleObjectEqual(defaultFormValues, editedFormValues);
      if (attachmentsChanged) {
        const newFiles = editedUploadFiles
          .filter(({ file }) => file)
          .map(({ file }) => file as File);
        const blobObjIds = editedUploadFiles.filter(({ file }) => !file).map(({ id }) => id);
        onSubmit &&
          onSubmit(
            formParams,
            {
              files: newFiles,
              blobObjIds
            },
            formChanged
          );
      } else {
        onSubmit && onSubmit(formParams, undefined, formChanged);
      }
    }
  };

  const dataReady = useMemo(() => {
    return (
      !formData ||
      (formData &&
        simpleObjectEqual(
          disableBasicInfo ? editedFormValues : defaultFormValues,
          getDefaultApiFormData(formData)
        ))
    );
  }, [formData, defaultFormValues, editedFormValues]);

  return loadingDialog ? (
    <Dialog fullscreen closeOnEscape>
      <Loader />
    </Dialog>
  ) : dataReady ? (
    <>
      <Dialog
        fullscreen
        visible={show}
        title={title}
        onClose={onClose}
        className={`new-escalation ${showApproval ? "new-escalation-approval" : ""}`}
        buttons={
          <div className="new-escalation-buttons">
            <Button onClick={onClose}>Cancel</Button>
            {!disableBasicInfo && (
              <>
                <FileUpload
                  title="Upload so & items"
                  accept=".xlsx,.xlsm"
                  onChange={onUploadChange}
                />
                <Button iconName="upload" onClick={() => setShowUploadDialog(true)}>
                  Add attachments
                </Button>
              </>
            )}
            {title !== NEW_ESCALATION && (
              <Button
                disabled={
                  !(formData && formData.ticketAttachments && formData.ticketAttachments.length > 0)
                }
                onClick={() => setShowDownloadDialog(true)}
              >
                Show attachments
              </Button>
            )}
            <Button
              primary
              submit
              onClick={handleSubmit}
              disabled={showApproval ? disableApproveApply : disableTicketApply}
            >
              Submit
            </Button>
          </div>
        }
      >
        <TicketSoItemTable
          formValues={editedSoItems}
          onCheck={handleCheckSoItem}
          onAdd={handleAddSoItem}
          onDelete={handleDelSoItem}
          onChange={handleChangeSoItem}
          onPreview={handlePreviewSoItem}
          loading={loadingSoItem}
          error={error}
          disabled={disableBasicInfo}
          showProjectName={!showApproval}
          scrollVertical={showApproval}
        />
        <TicketBasicForm
          error={error}
          dropdownItems={dropdownItems}
          formValues={editedFormValues}
          userList={userList}
          onValueChange={handleChangeBasicForm}
          onApprovalValueChange={handleAssignChange}
          disableBasicField={disableBasicInfo}
          disableApprovalField={disableApprovalInfo}
          showApproval={showApproval}
          approveValues={editedApprovalValues}
          hasEscalationLevel={hasEscalationLevel}
          loadingAutoFill={loadingAutoFill}
          onAutoFill={onAutoFill}
        />
      </Dialog>
      <TicketPreviewDialog
        loading={loadingSoItem}
        loadingSubmit={loadingUpdateOa}
        tableData={editedSoItems}
        visible={showPreview}
        onClose={handleClosePreview}
        onApply={onUpdateOa}
        hasProjectName={showApproval}
        editable={editableOA}
      />
      <MultipleUpload
        show={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        title="Upload"
        onApplied={handleMultipleUploadSubmit}
        appliedFiles={editedUploadFiles}
        maxSize={30}
      />
      <MultipleDownload
        show={showDownloadDialog}
        onClose={() => setShowDownloadDialog(false)}
        title="Download"
        onApplied={onDownload}
        appliedFiles={apiToAttachments(formData?.ticketAttachments || [])}
        loading={loadingDownload}
      />
    </>
  ) : (
    <></>
  );
};

const getDefaultApiFormData = (data: ITicketDetails) => {
  const {
    ticketSos,
    escalationStatus,
    escalationTag,
    escalationLevel,
    ticketAssignedUsers,
    ...basicData
  } = data;
  return basicData;
};

const useDefaultFormValues = (detailData: ITicketDetails | undefined) => {
  const [defaultSoItems, setDefaultSoItems] = useState<ITicketDetailSoItem[]>(DEFAULT_SO_ITEMS);
  const [defaultFormValues, setDefaultFormValues] =
    useState<ITicketBaseFormItem>(DEFAULT_TICKET_DETAIL);
  const [defaultApprovalValues, setDefaultApprovalValues] =
    useState<ITicketApprovalEditedItems>(DEFAULT_APPROVAL_ITEM);

  useEffect(() => {
    if (detailData) {
      const {
        ticketSos,
        escalationStatus,
        escalationTag,
        escalationLevel,
        comments,
        ticketAssignedUsers
      } = detailData;
      setDefaultSoItems(addRowNumber(ticketSos));
      setDefaultFormValues(getDefaultApiFormData(detailData));
      setDefaultApprovalValues({
        escalationStatus,
        escalationTag: escalationTag || Tag.A1P,
        escalationLevel: escalationLevel || EscalationLevel.region,
        comments: comments || "",
        assignedUsers:
          ticketAssignedUsers && ticketAssignedUsers.length > 0
            ? ticketAssignedUsers.map(item => ({
                ...item,
                assignedUserEmails: item.assignedUsers.map(v => v.assignedUserEmail)
              }))
            : Array.from(new Set(ticketSos.map(item => item.productReportingGroup))).map(item => ({
                productReportingGroup: item,
                assignedUserEmails: [],
                ticketCategory: ""
              }))
      });
    } else {
      setDefaultFormValues(DEFAULT_TICKET_DETAIL);
      setDefaultSoItems(DEFAULT_SO_ITEMS);
      setDefaultApprovalValues(DEFAULT_APPROVAL_ITEM);
    }
  }, [detailData]);

  return { defaultSoItems, defaultFormValues, defaultApprovalValues };
};

const useEditedFormValues = (
  defaultSoItems: ITicketDetailSoItem[],
  defaultFormValues: ITicketBaseFormItem,
  checkData: ITicketDetailSoItem[] | undefined,
  uploadCheckData: ITicketDetailSoItem[] | undefined
) => {
  const [editedSoItems, setEditedSoItems] = useState<ITicketDetailSoItem[]>(DEFAULT_SO_ITEMS);
  const [appliedSoItems, setAppliedSoItems] = useState<ITicketDetailSoItem[]>([]);
  const [editedFormValues, setEditedFormValues] =
    useState<ITicketBaseFormItem>(DEFAULT_TICKET_DETAIL);

  const resetForm = () => {
    setEditedFormValues(DEFAULT_TICKET_DETAIL);
    setEditedSoItems(DEFAULT_SO_ITEMS);
    setAppliedSoItems(DEFAULT_SO_ITEMS);
  };

  useEffect(() => {
    if (defaultSoItems) {
      setEditedSoItems(defaultSoItems);
      setAppliedSoItems(defaultSoItems);
    }
  }, [defaultSoItems]);

  useEffect(() => {
    if (defaultFormValues) {
      setEditedFormValues(defaultFormValues);
    }
  }, [defaultFormValues]);

  const handleCheckData = (checkData: ITicketDetailSoItem[], isUpload: boolean) => {
    const result = addRowNumber(
      isUpload
        ? checkData
        : getApiSoItemRows(editedSoItems).map(item => {
            const checkItem = checkData.find(data => equalSoItem(item, data)) || DEFAULT_SO_ITEM;
            const {
              latestAcceptableReadyForShipmentDate,
              minimumCriticalDemandPerWeek,
              projectNameOrDeal,
              nroOrBoxDelivery,
              partialDeliveryAcceptable
            } = item;
            return {
              ...item,
              ...checkItem,
              minimumCriticalDemandPerWeek,
              latestAcceptableReadyForShipmentDate,
              projectNameOrDeal,
              nroOrBoxDelivery,
              partialDeliveryAcceptable
            };
          })
    );
    setEditedSoItems(result);
    setAppliedSoItems(result);

    const materialList = Array.from(new Set(checkData.map(({ material }) => material)));
    const getMinRequestLtByMaterial = (material: string) =>
      checkData.find(item => item.material === material)?.minRequestLT || null;

    setEditedFormValues(item => ({
      ...item,
      ticketMaterials: materialList.map(material => ({
        material,
        minRequestLT: getMinRequestLtByMaterial(material),
        globalStockCheck: undefined,
        borrowFrom: "",
        borrowQuantity: NaN,
        preEscalationFeedback: "",
        sippOrNonSIPP: undefined,
        forecasted: undefined
      }))
    }));
  };

  useEffect(() => {
    if (checkData) {
      handleCheckData(checkData, false);
    }
  }, [checkData]);

  useEffect(() => {
    if (uploadCheckData) {
      handleCheckData(uploadCheckData, true);
    }
  }, [uploadCheckData]);

  return {
    editedSoItems,
    setEditedSoItems,
    editedFormValues,
    setEditedFormValues,
    appliedSoItems,
    setAppliedSoItems,
    resetForm
  };
};

const useEditedApprovalValues = (
  defaultAssignValues: ITicketApprovalEditedItems,
  defaultAssignees: IAssignedUserEditedItem[] | undefined
) => {
  const [editedApprovalValues, setEditedApprovalValues] =
    useState<ITicketApprovalEditedItems>(DEFAULT_APPROVAL_ITEM);

  useEffect(() => {
    if (defaultAssignValues) {
      setEditedApprovalValues(defaultAssignValues);
    }
  }, [defaultAssignValues]);

  useEffect(() => {
    if (defaultAssignees) {
      setEditedApprovalValues(f => ({ ...f, assignedUsers: defaultAssignees }));
    }
  }, [defaultAssignees]);

  const handleAssignChange =
    (name: keyof ITicketApprovalEditedItems) =>
    (value: string | TEscalationStatus | IAssignedUserEditedItem[]) => {
      setEditedApprovalValues(f => ({
        ...f,
        [name]: value,
        escalationTag:
          name === "escalationTag"
            ? (value as string)
            : name === "escalationLevel"
            ? value === EscalationLevel.region
              ? "A1P"
              : value === EscalationLevel.continental
              ? "B2P"
              : value === EscalationLevel.global
              ? "C3P"
              : f.escalationTag
            : name === "escalationStatus"
            ? value === EscalationStatus.rejectToRegion
              ? "A1P"
              : f.escalationTag
            : f.escalationTag,
        escalationLevel:
          name === "escalationStatus"
            ? value === EscalationStatus.rejectToRegion
              ? EscalationLevel.region
              : f.escalationLevel
            : name === "escalationTag"
            ? value === "A1P"
              ? EscalationLevel.region
              : value === "B2P"
              ? EscalationLevel.continental
              : value === "C3P"
              ? EscalationLevel.global
              : f.escalationLevel
            : name === "escalationLevel"
            ? (value as string)
            : f.escalationLevel,
        escalationStatus:
          name === "escalationLevel"
            ? EscalationStatus.pending
            : name === "escalationTag"
            ? EscalationStatus.pending
            : name === "escalationStatus"
            ? (value as TEscalationStatus)
            : f.escalationStatus
      }));
    };

  return {
    editedApprovalValues,
    setEditedApprovalValues,
    handleAssignChange
  };
};

export default TicketBaseInfoDialog;
