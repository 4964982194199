import { ICellRendererParams } from "ag-grid-community";
import { IColumnDefinition } from "../../../components/grid/grid";
import { ICellChanged } from "../../../helpers/table-editing";
import { cell, textCell } from "../../../helpers/grid-cells";
import MultipleDropdownFilter from "../../../components/select/MultipleDropdownFilter";
import { IDropdownItem } from "@react-gcc-eds/core";
import { EEfRoles } from "../../../types";
import "./ef-admin.scss";
import { isCellPending, onCellValueChanged } from "../../../helpers/edit-cell-helper";
import { IEfUserItem } from "../../api/ef-user";

export const ROLE_LOOKUP = [
  { title: EEfRoles.EFReadOnly, value: EEfRoles.EFReadOnly, disabled: true },
  { title: EEfRoles.EFTicketRequester, value: EEfRoles.EFTicketRequester },
  { title: EEfRoles.EFTicketApprover, value: EEfRoles.EFTicketApprover },
  { title: EEfRoles.EFSuperOwner, value: EEfRoles.EFSuperOwner }
];

export const multiFilterLabel = (available: any[], selected?: any[]) =>
  `${selected ? selected.length : available.length} of ${available.length} selected`;

const getEFAdminColumn = ({
  onCellChanged
}: {
  onCellChanged: (changes: ICellChanged<IEfUserItem>) => void;
}): IColumnDefinition[] => {
  const multipleSelectCell = (fieldId: string, headerName: string, items: IDropdownItem[]) => ({
    ...cell(fieldId, headerName),
    cellClassRules: {
      "ef-editable-cell": () => true,
      pending: isCellPending
    },
    minWidth: 200,
    filter: "agSetColumnFilter",
    onCellValueChanged: onCellValueChanged(onCellChanged),
    cellRenderer: (p: ICellRendererParams) => {
      const values = p.value || [];
      return (
        <div className="drop-down-cell">
          <MultipleDropdownFilter
            header={""}
            label={multiFilterLabel(items, values)}
            selectedItems={values.map((m: string) => ({
              title: m,
              value: m,
              disabled: m === EEfRoles.EFReadOnly
            }))}
            items={items}
            onChange={item => {
              const newValue = item.map(item => item.value);
              p.setValue && p.setValue(newValue);
            }}
          />
        </div>
      );
    }
  });

  return [
    textCell("userName", "Name"),
    textCell("userEmail", "Email"),
    { ...textCell("groupSupplyUnit", "Group supply unit"), filter: "agSetColumnFilter" },
    multipleSelectCell("escalationFeedbackRoles", "Role", ROLE_LOOKUP)
  ];
};

export default getEFAdminColumn;
