import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart as RechartRadarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps
} from "recharts";
import { ChartTooltip } from "./tooltip";

export interface IRadarChartData {
  perspective: string;
  value: number;
  fullMark?: number;
}

export interface IRadarChart {
  data: IRadarChartData[];
  units?: string[];
  lineStroke?: string;
  domain?: [number, number];
}

const RadarChart = ({
  data,
  lineStroke = "#8884d8",
  domain = [0, 100],
  units = []
}: IRadarChart) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartRadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
        <Tooltip content={(props: TooltipProps) => <ChartTooltip props={props} units={units} />} />
        <PolarGrid />
        <PolarAngleAxis dataKey="perspective" />
        <PolarRadiusAxis angle={90} dy={10} domain={domain} />
        <Radar dataKey="value" stroke={lineStroke} fillOpacity={0} />
      </RechartRadarChart>
    </ResponsiveContainer>
  );
};

export default RadarChart;
