import { useCallback, useEffect, useState } from "react";
import View from "../../views/app-view";
import {
  IEscalationDetailsItem,
  IEscalationDetailsLookupItem,
  IEscalationDetailsPagedQuery,
  useGetEscalationDetails,
  useGetEscalationDetailsLookup
} from "../api/tableau";
import EscalationDetailsEditor from "../component/escalation-details/escalation-details-editor";
import { IServerModelGetRowRequest } from "../../components/grid/types";
import { base64EncodedUri } from "../../utils";
import { buildQueryFromRequest } from "../../components/grid/query-builder";
import {
  DEFAULT_FILTER,
  IEscalationDetailsFilterItems
} from "../component/escalation-details/escalation-details-filter";
import { apiToSelect, dropdownToApi } from "../helper/tableau";
import { FilterId, ViewId, useFiltersPersistence } from "../../helpers/filters-persistence";

const PAGE_SIZE = 100;

const EscalationDetailsView = () => {
  const [escalationDetailsData, loadingEscalationDetails, getEscalationDetails] =
    useGetEscalationDetails();
  const [escalationDetailsLookupData, loadingEscalationDetailsLookup, getEscalationDetailsLookup] =
    useGetEscalationDetailsLookup();

  const { filters: persistedFilters, persistFilters } =
    useFiltersPersistence<IEscalationDetailsFilterItems>({
      viewId: ViewId.VIS_DETAIL,
      filterId: FilterId.VIS_DETAIL,
      version: 2
    });
  const { defaultFilters } = useDefaultFilters(escalationDetailsLookupData, persistedFilters);
  const { appliedFilters, setAppliedFilters } = useAppliedFilters(defaultFilters);
  const [apiFilter, setApiFilter] = useState<IEscalationDetailsPagedQuery>();

  useEffect(() => {
    getEscalationDetailsLookup();
  }, []);

  const getRows = useCallback(
    async (params: IServerModelGetRowRequest): Promise<[IEscalationDetailsItem[], number]> => {
      const { startRow } = params;
      const { filter, order } = buildQueryFromRequest(params, "sql");
      const [orderBy, orderByType] = order.split(" ");
      const { escalationStatuses, customerUnits, pcodes, excludePackages } = appliedFilters;
      const apiFilterParams = {
        offset: startRow || 0,
        limit: PAGE_SIZE,
        filterBy: base64EncodedUri(filter),
        orderBy: base64EncodedUri(orderBy),
        orderByType,
        escalationStatuses: dropdownToApi(escalationStatuses),
        customerUnits: dropdownToApi(customerUnits),
        pcodes: dropdownToApi(pcodes),
        excludePackages: dropdownToApi(excludePackages)
      };
      setApiFilter(apiFilterParams);
      const response = await getEscalationDetails(apiFilterParams);
      if (!response.data) {
        return [[], 0];
      }
      return [response.data.data, response.data.total];
    },
    [appliedFilters]
  );

  const onApply = (filters: IEscalationDetailsFilterItems) => {
    setAppliedFilters(filters);
    persistFilters(filters);
  };

  return (
    <View
      breadCrumbs={[
        { text: "Escalation Management" },
        { text: "Visualization" },
        { text: "Escalation Details" }
      ]}
    >
      <EscalationDetailsEditor
        loadingTable={loadingEscalationDetailsLookup}
        getRows={getRows}
        pageSize={PAGE_SIZE}
        lookup={escalationDetailsLookupData}
        onApply={onApply}
        appliedFilters={appliedFilters}
        defaultFilters={defaultFilters}
        apiFilters={apiFilter}
      />
    </View>
  );
};

const useDefaultFilters = (
  lookup: IEscalationDetailsLookupItem | undefined,
  persistedFilters: IEscalationDetailsFilterItems | undefined
) => {
  const [defaultFilters, setDefaultFilters] =
    useState<IEscalationDetailsFilterItems>(DEFAULT_FILTER);
  const [persistInitState, setPersistInitState] = useState<boolean>(false);

  useEffect(() => {
    if (lookup) {
      const { pcodes, customerUnits } = lookup;
      setDefaultFilters(f => ({
        ...f,
        pcodes: apiToSelect(pcodes),
        customerUnits: apiToSelect(customerUnits)
      }));
    }
  }, [lookup]);

  useEffect(() => {
    if (persistedFilters && !persistInitState) {
      setDefaultFilters(persistedFilters);
      setPersistInitState(true);
    }
  }, [persistedFilters]);

  return { defaultFilters };
};

const useAppliedFilters = (defaultFilters: IEscalationDetailsFilterItems) => {
  const [appliedFilters, setAppliedFilters] =
    useState<IEscalationDetailsFilterItems>(DEFAULT_FILTER);

  useEffect(() => {
    if (defaultFilters) {
      setAppliedFilters(defaultFilters);
    }
  }, [defaultFilters]);

  return { appliedFilters, setAppliedFilters };
};

export default EscalationDetailsView;
