import { Card } from "@react-gcc-eds/core";
import { BarChart, IBarChartData, ILineChartData, ISerieValue } from "../../components/charts";
import LineChart from "../../components/charts/line-chart";
import ContentLoader from "../../components/content-loader";
import { IDisplayViewChartItem, ISubHistoryData } from "../../visualization/api/subscription";
import "./performance-history.scss";

const PerformanceHistory = ({
  historyData,
  loading
}: {
  historyData: ISubHistoryData | undefined;
  loading: boolean;
}) => {
  const apiToLine = (list: IDisplayViewChartItem[], title: string): ILineChartData => {
    const common: string[] = [];
    const series: ISerieValue[] = [];
    list.map(({ displayView, value }) => {
      common.push(displayView);
      series.push({ value });
    });
    return {
      common,
      series: [
        {
          name: title,
          values: series
        }
      ]
    };
  };

  const apiToBar = (list: any[], keys: string[], titles: string[]): IBarChartData => {
    const common: string[] = [];
    const series1: ISerieValue[] = [];
    const series2: ISerieValue[] = [];
    list.map(item => {
      common.push(item.displayView);
      series1.push({ value: item[keys[0]] });
      series2.push({ value: item[keys[1]] });
    });
    return {
      common,
      series: [
        {
          name: titles[0],
          values: series1
        },
        {
          name: titles[1],
          values: series2
        }
      ]
    };
  };

  return (
    <div className="performance-history">
      <ContentLoader
        loading={loading}
        data={historyData}
        render={
          historyData ? (
            <>
              <Card
                title="History Escalation Status"
                content={
                  <BarChart
                    data={apiToBar(
                      historyData.historyEscalationStatuses,
                      ["closedCount", "inProgressCount"],
                      ["Closed", "In Process"]
                    )}
                    unitOfMeasure="item"
                    stacked
                    customColors={["#329864", "#DCAF01"]}
                    yAxisDecimalPlaces={1}
                  />
                }
              />
              <Card
                title="History Handling Lead Time"
                content={
                  <LineChart
                    data={apiToLine(
                      historyData.handlingLeadTimeAvgs.map(v => ({
                        ...v,
                        value: v.averageHandlingLeadTimeInDays
                      })),
                      "Escalation  Handling Lead Time"
                    )}
                    unitOfMeasure="day"
                    customColors={["#A08209"]}
                  />
                }
              />
              <Card
                title="History Fulfillment"
                content={
                  <BarChart
                    data={apiToBar(
                      historyData.fulfillmentRates,
                      ["fulfillmentCount", "notFulfillmentCount"],
                      ["Fulfillment", "Not Fulfillment"]
                    )}
                    unitOfMeasure="item"
                    stacked
                    customColors={["#329864", "#ED0E00"]}
                    yAxisDecimalPlaces={1}
                  />
                }
              />
              <Card
                title="History MADate Improved"
                content={
                  <LineChart
                    data={apiToLine(
                      historyData.currentMaterialAvailableDateImproveds.map(v => ({
                        ...v,
                        value: v.averageImprovedDays
                      })),
                      "MADate Improved"
                    )}
                    unitOfMeasure="day"
                    customColors={["#A56EBE"]}
                  />
                }
              />
            </>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export default PerformanceHistory;
