import { Column, Pill, Row, Tile, Text } from "@react-gcc-eds/core";
import View from "../app-view";
import { IAffect, INote, IRelease, Releases } from "./notes";
import "./release-notes.scss";
import { useHistory } from "react-router-dom";

const ReleaseNotesView = () => {
  const history = useHistory();
  const ReleaseNoteItem = ({ note }: { note: INote }) => {
    return (
      <Row>
        <Column lg={8}>
          <li className="release-tile-content">{note.feature}</li>
          {note.comment && (
            <Text className="release-tile-desc" size="md">
              {note.comment}
            </Text>
          )}
        </Column>
        <Column lg={1} className="release-tile-tag-epic">
          <Pill>{note.epic}</Pill>
        </Column>
        <Column lg={3} className="release-tile-tag-page">
          {note.affects.map((affect: IAffect) => (
            <Pill key={affect.title + note.comment} className="small-pill">
              <a onClick={() => history.push(affect.link)}>{affect.title}</a>
            </Pill>
          ))}
        </Column>
      </Row>
    );
  };
  const renderNotes = (header: string, notes: INote[]) => {
    if (notes.length <= 0) {
      return null;
    }
    return (
      <>
        <h2>{header}</h2>
        {notes.map((n: INote, index) => {
          return <ReleaseNoteItem key={n.comment + index.toString()} note={n} />;
        })}
      </>
    );
  };
  return (
    <View>
      {Releases().map((r: IRelease) => {
        const notes = r.notes;
        if (notes.length === 0) {
          return null;
        }
        return (
          <Tile
            key={r.version}
            style={{ marginBottom: "4px", flex: "0" }}
            className="release-tile"
            title={`v${r.version}`}
            subtitle={`@${r.releasedAt}`}
          >
            {renderNotes(
              "New features",
              notes.filter((n: { type: string }) => n.type === "FEATURE")
            )}
            {renderNotes(
              "Changes",
              notes.filter((n: { type: string }) => n.type === "CHANGE")
            )}
            {renderNotes(
              "Fixed bugs",
              notes.filter((n: { type: string }) => n.type === "BUGFIX")
            )}
          </Tile>
        );
      })}
    </View>
  );
};

export default ReleaseNotesView;
