import { Button, Checkbox, Dialog } from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import Loader from "../loader";
import { IUploadFile } from "../multiple-upload/MultipleUpload";
import "./MultipleDownload.scss";

const MultipleDownload = ({
  show,
  onClose,
  title,
  onApplied,
  appliedFiles,
  loading
}: {
  show: boolean;
  onClose: () => void;
  title: string;
  onApplied: (attachmentIds: number[]) => void;
  appliedFiles: IUploadFile[];
  loading?: boolean;
}) => {
  //check
  const [checks, setChecks] = useState<boolean[]>([]);

  useEffect(() => {
    if (appliedFiles) {
      setChecks(appliedFiles.map(() => false));
    }
  }, [appliedFiles]);

  const setChecksByIndex = (index: number) =>
    setChecks(f => f.map((item, i) => (i === index ? !checks[index] : item)));

  const noChecks = (checks: boolean[]) => checks.filter(check => !check);
  const isCheckAll = (checks: boolean[]) => noChecks(checks).length === 0;
  const isIndeterminate = (checks: boolean[]) =>
    noChecks(checks).length > 0 && noChecks(checks).length < checks.length;

  const setAllChecks = (check: boolean) => setChecks(checks => checks.map(() => check));

  const getAttachmentIds = (checks: boolean[]) =>
    appliedFiles.filter((file, index) => checks[index]).map(({ id }) => id);

  return (
    <Dialog fullscreen className="multiple-download" visible={show} onClose={onClose} title={title}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={`drop-area`}>
            {appliedFiles.length === 0 ? (
              <div className={`empty-state ${appliedFiles.length === 0 ? "visible" : ""}`}>
                <div>No data!</div>
              </div>
            ) : (
              appliedFiles.map(({ fileName, id }, index) => (
                <div
                  className={`card ${checks[index] ? "card-selected" : ""}`}
                  key={`Multiple-download-${id}`}
                  onClick={() => setChecksByIndex(index)}
                >
                  <div className="header">
                    <div className="left">
                      <div className="title">
                        <Checkbox
                          text={fileName}
                          checked={checks[index]}
                          onChange={() => setChecksByIndex(index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="multiple-download-bottom">
            <div className="multiple-download-checkAll">
              <Checkbox
                text="Check All"
                checked={isCheckAll(checks)}
                indeterminate={isIndeterminate(checks)}
                onChange={checked => setAllChecks(checked)}
              />
            </div>
            <div>
              <Button
                primary
                disabled={checks.length === 0}
                onClick={() => onApplied(getAttachmentIds(checks))}
              >
                Download
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default MultipleDownload;
