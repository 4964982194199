import { Accordion, AccordionItem, TextField } from "@react-gcc-eds/core";
import { useState } from "react";
import { EscalationPriority } from "../../management/helper";
import { simpleObjectEqual } from "../../utils/user-helpers";
import FilterWrapper from "../dropdown-filter/filter-wrapper";
import NumberField from "../number-field";
import "./index.scss";
import SelectAccordionGroup, { ISelectAccordionOption } from "./SelectAccordionGroup";

export interface IFeedbackAccordionFilters {
  customer: string;
  ticketNumber?: number;
  material: string;
  ticketCategories: string[];
  priority: EscalationPriority[];
}

const AccordionFilter = ({
  productAreaOptions,
  onApply,
  visible
}: {
  productAreaOptions: ISelectAccordionOption[];
  onApply: (params: IFeedbackAccordionFilters) => void;
  visible: boolean;
}) => {
  //default
  const DEFAULT_FILTERS = {
    customer: "",
    ticketNumber: NaN,
    material: "",
    ticketCategories: [],
    priority: []
  };

  const priorityOptions = Object.values(EscalationPriority).map(item => ({
    name: item,
    value: item,
    selected: false
  }));

  //display
  const [editFilters, setEditedFilters] = useState<IFeedbackAccordionFilters>(DEFAULT_FILTERS);
  const [editPriorityOptions, setEditedPriorityOptions] =
    useState<ISelectAccordionOption[]>(priorityOptions);
  const [editProductAreaOptions, setEditedProductAreaOptions] =
    useState<ISelectAccordionOption[]>(productAreaOptions);

  const initOptions = (options: ISelectAccordionOption[]) =>
    options.map(option => ({ ...option, selected: false }));

  const [paOpen, setPaOpen] = useState<boolean>(false);
  const [priorityOpen, setPriorityOpen] = useState<boolean>(false);

  const onValueChange =
    (key: keyof IFeedbackAccordionFilters) =>
    (value: string | ISelectAccordionOption[] | number) => {
      const getSelectedItems = (options: ISelectAccordionOption[]) =>
        options.filter(({ selected }) => selected).map(({ value }) => value);
      switch (key) {
        case "ticketCategories":
          const productAreaOptions = value as ISelectAccordionOption[];
          setEditedProductAreaOptions(productAreaOptions);
          setEditedFilters(filters => ({
            ...filters,
            [key]: getSelectedItems(productAreaOptions)
          }));
          break;
        case "priority":
          const priorityOptions = value as ISelectAccordionOption[];
          setEditedPriorityOptions(priorityOptions);
          setEditedFilters(filters => ({
            ...filters,
            [key]: getSelectedItems(priorityOptions) as EscalationPriority[]
          }));
          break;
        default:
          setEditedFilters(filters => ({ ...filters, [key]: value }));
      }
    };

  //clean
  const onClean = () => {
    setEditedFilters(DEFAULT_FILTERS);
    setEditedProductAreaOptions(options => initOptions(options));
    setEditedPriorityOptions(options => initOptions(options));
  };

  return (
    <FilterWrapper
      className="accordion-filter"
      style={{ display: visible ? "block" : "none" }}
      position="right"
      onClean={onClean}
      onApply={() => onApply(editFilters)}
      appliedFilter={!simpleObjectEqual(editFilters, DEFAULT_FILTERS)}
      hasSort={false}
      hasBottomClean={true}
    >
      <div className="accordion-filter-content">
        <TextField
          label="Customer"
          placeholder="Type to Search"
          className="accordion-filter-input"
          value={editFilters.customer}
          onChange={onValueChange("customer")}
        />
        <NumberField
          label="Ticket Number"
          placeholder="Type to Search"
          className="accordion-filter-number"
          value={editFilters.ticketNumber as number}
          onChange={onValueChange("ticketNumber")}
        />
        <TextField
          label="Material"
          placeholder="Type to Search"
          className="accordion-filter-input"
          value={editFilters.material}
          onChange={onValueChange("material")}
        />
        <Accordion>
          <AccordionItem
            title="Ticket categories"
            opened={paOpen}
            onOpen={() => setPaOpen(true)}
            onClose={() => setPaOpen(false)}
          >
            <SelectAccordionGroup
              options={editProductAreaOptions}
              onChange={onValueChange("ticketCategories")}
            />
          </AccordionItem>
          <AccordionItem
            title="Priority"
            opened={priorityOpen}
            onOpen={() => setPriorityOpen(true)}
            onClose={() => setPriorityOpen(false)}
          >
            <SelectAccordionGroup
              options={editPriorityOptions}
              onChange={onValueChange("priority")}
            />
          </AccordionItem>
        </Accordion>
      </div>
    </FilterWrapper>
  );
};

export default AccordionFilter;
