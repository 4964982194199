import { IDropdownItem } from "@react-gcc-eds/core";
import { useEffect, useRef, useState } from "react";
import { simpleObjectEqual } from "../../utils/user-helpers";
import MultipleDropdownFilter from "../../components/select/MultipleDropdownFilter";

export const filterAllLookupItems = <TFilterLookup extends {}>(
  filters: ((item: TFilterLookup) => boolean)[],
  items?: TFilterLookup[]
): TFilterLookup[] =>
  filters.reduce((items: TFilterLookup[], filter: (i: TFilterLookup) => boolean) => {
    return items.filter(filter);
  }, items || []);

export const uniqueLookupItems = <TFilterLookup extends {}>(
  items: TFilterLookup[],
  selector: (item: TFilterLookup) => string
): IDropdownItem[] => {
  const uniqueValues = new Set<string>();
  items.forEach(i => uniqueValues.add(selector(i)));
  return Array.from(uniqueValues)
    .sort()
    .map(i => ({
      title: i === null || i === undefined ? "(Unassigned)" : i === "" ? "(None)" : i,
      value: i
    }));
};

export type IUserTargetedFilter = {
  key: string;
  values: IDropdownItem[];
};

export const useLookupFilters = <
  TFilters extends {},
  TEditedFilters extends TFilters & { [key: string]: IDropdownItem | IDropdownItem[] },
  TAllFilterItems extends { [key: string]: IDropdownItem[] },
  TAssociatedFilterItems extends { [key: string]: IDropdownItem[] | string | IDropdownItem },
  TFilterLookup extends {}
>({
  defaultFilters,
  filters,
  persistedTargetedFilter,
  allFilterItems,
  newAssociatedItemsWhenWidened,
  newAssociatedItemsWhenNarrowed
}: {
  defaultFilters: TFilters;
  filters?: TFilters;
  persistedTargetedFilter?: IUserTargetedFilter[];
  allFilterItems: TAllFilterItems;
  newAssociatedItemsWhenWidened: (
    filter: (item: TFilterLookup) => boolean,
    editedFilter?: TEditedFilters
  ) => TAssociatedFilterItems;
  newAssociatedItemsWhenNarrowed: (filters: TEditedFilters) => TAssociatedFilterItems;
}) => {
  const [editedFilters, setEditedFilters] = useState<TEditedFilters>(
    defaultFilters as unknown as TEditedFilters
  );

  const [filtersItems, setFiltersItems] = useState<TAllFilterItems>(allFilterItems);

  const initialized = useRef<boolean>(false);

  useEffect(() => {
    if (simpleObjectEqual(filters as any, editedFilters) || !initialized.current) return;
    if (filters) {
      setFiltersItems(allFilterItems);
      setUserTargetFilters([]);
      setEditedFilters(filters as unknown as TEditedFilters);
    }
  }, [filters]);

  useEffect(() => {
    // Initialize persisted filters item
    // Persisted targeted filter effect this hook must before the applied filter, if other better way ?
    if (
      !initialized.current &&
      filters &&
      persistedTargetedFilter &&
      persistedTargetedFilter.length > 0
    ) {
      let newEditedFilter = filters as unknown as TEditedFilters;
      setUserTargetFilters(persistedTargetedFilter);
      const associatedFilterItems = newAssociatedItemsWhenNarrowed(newEditedFilter);
      newEditedFilter = { ...newEditedFilter, ...associatedFilterItems } as TEditedFilters;
      const otherTargetFilters = getOtherTargetFilters(
        "",
        associatedFilterItems,
        persistedTargetedFilter
      );
      const newOptions = getNewAvailableMultipleFiltersOptions(
        otherTargetFilters,
        associatedFilterItems
      );
      setFiltersItems(item => ({
        ...item,
        ...newOptions
      }));
      setEditedFilters(newEditedFilter);
      initialized.current = true;
    } else if (!initialized.current && filters !== undefined) {
      setEditedFilters(filters as unknown as TEditedFilters);
      initialized.current = true;
    }
  }, [filters, persistedTargetedFilter]);

  const [userTargetedFilters, setUserTargetFilters] = useState<IUserTargetedFilter[]>([]);

  const filterChanged =
    (filterName: string) => (value: IDropdownItem | IDropdownItem[] | string) => {
      setEditedFilters(f => ({
        ...(f || (defaultFilters as unknown as TEditedFilters)),
        [filterName]: value
      }));
    };

  const areFiltersEqual = () => simpleObjectEqual(filters as any, editedFilters);

  const handleResetFilters = () => {
    setEditedFilters(defaultFilters as unknown as TEditedFilters);
    setFiltersItems(allFilterItems);
    setUserTargetFilters([]);
  };

  const getOtherTargetFilters = (
    currentFilter: string,
    filters: TAssociatedFilterItems,
    userTargetedFilters: IUserTargetedFilter[]
  ): IUserTargetedFilter[] =>
    // Removed the item which user applied before not in current filters
    userTargetedFilters
      .filter(f => f.key !== currentFilter)
      .map(f => {
        const values = f.values.filter(i =>
          (filters[f.key] as IDropdownItem[]).find(a => a.value === i.value)
        );
        return { key: f.key, values };
      });

  const getNewAvailableMultipleFiltersOptions = (
    conditions: IUserTargetedFilter[],
    otherItems: TAssociatedFilterItems
  ) => {
    const filterKeys = Object.keys(allFilterItems);
    // Calculate targeted filter option need to exclude select all item
    const removeSelectAllCondition = Object.fromEntries(
      filterKeys.map(key => {
        const filter = conditions.find(k => k.key === key);
        if (filter && filter.values.length !== filtersItems[filter.key].length) {
          return [key, filter.values];
        } else {
          return [key, []];
        }
      })
    );
    return Object.fromEntries(
      filterKeys.map(key => {
        const newOptions = conditions.find(k => k.key === key)
          ? newAssociatedItemsWhenNarrowed({
              ...editedFilters,
              ...removeSelectAllCondition,
              [key]: []
            })
          : otherItems;
        return [key, newOptions[key]];
      })
    );
  };

  const getNewEditedFilter = (conditions: IUserTargetedFilter[]) => {
    const filterKeys = Object.keys(allFilterItems);
    const othersConditions = Object.fromEntries(
      filterKeys.map(key => {
        const filter = conditions.find(k => k.key === key);
        if (filter) {
          return [key, filter.values];
        } else {
          return [key, []];
        }
      })
    );
    return newAssociatedItemsWhenNarrowed({ ...editedFilters, ...othersConditions });
  };

  const multiFilterChanged =
    (filterName: string, selector: (item: TFilterLookup) => string) => (value: IDropdownItem[]) => {
      if (value.length === 0) {
        setEditedFilters(f => ({ ...f, [filterName]: value }));
        return;
      }
      const newTargetedFilters =
        value.length === allFilterItems[filterName].length
          ? userTargetedFilters.filter(f => f.key !== filterName)
          : userTargetedFilters.find(i => i.key === filterName)
          ? userTargetedFilters.map(i =>
              i.key === filterName ? { key: filterName, values: value } : i
            )
          : [...userTargetedFilters, { key: filterName, values: value }];

      setUserTargetFilters(newTargetedFilters);

      const { [filterName]: unused, ...newAllEditedItems } =
        value.length === 0 ||
        ((editedFilters[filterName] as IDropdownItem[]).length > 0 &&
          value.length > (editedFilters[filterName] as IDropdownItem[]).length)
          ? newAssociatedItemsWhenWidened(
              (i: TFilterLookup) => !value.length || value.some(x => x.value === selector(i)),
              { ...editedFilters, [filterName]: value }
            )
          : newAssociatedItemsWhenNarrowed({ ...editedFilters, [filterName]: value });

      // Reduce item base on previous user selected item
      const newOtherTargetFilters = getOtherTargetFilters(
        filterName,
        { ...newAllEditedItems, [filterName]: value } as TAssociatedFilterItems,
        newTargetedFilters
      );
      if (value.length !== allFilterItems[filterName].length) {
        // Optimize the performance
        newOtherTargetFilters.push({
          key: filterName,
          values: value
        });
      }

      const newEditedFilter = getNewEditedFilter(newOtherTargetFilters);
      const newOptions = getNewAvailableMultipleFiltersOptions(
        newOtherTargetFilters,
        newEditedFilter
      );

      setFiltersItems(f => ({ ...f, ...newOptions, [filterName]: f[filterName] }));
      setEditedFilters(f => ({
        ...(f || (defaultFilters as unknown as TEditedFilters)),
        ...newEditedFilter,
        [filterName]: value
      }));
    };

  const relatedFilterChange =
    (
      filterName: string,
      itemsFilter: (editedFilters: TEditedFilters, value: any) => TAssociatedFilterItems
    ) =>
    (value: any) => {
      const { [filterName]: unused, ...others } = itemsFilter(editedFilters, value);
      setEditedFilters(f => ({
        ...(f || (defaultFilters as unknown as TEditedFilters)),
        [filterName]: value,
        ...others
      }));
    };

  const multiFilterLabel = (items: IDropdownItem[], nofChoices: number) =>
    items.length === 1 ? items[0].title : `${items.length} of ${nofChoices} selected`;

  const multiFilter = (
    property: string,
    selector: (item: TFilterLookup) => string,
    label: string,
    items: IDropdownItem[],
    disabled?: boolean
  ) => (
    <MultipleDropdownFilter
      label={
        editedFilters
          ? items.length
            ? multiFilterLabel(editedFilters[property] as IDropdownItem[], items.length)
            : "No items available"
          : ""
      }
      header={label}
      selectedItems={editedFilters ? (editedFilters[property] as IDropdownItem[]) : []}
      items={items}
      disabled={disabled || !items.length}
      onChange={multiFilterChanged(property, selector)}
    />
  );

  return {
    editedFilters,
    userTargetedFilters,
    filtersItems,
    multiFilter,
    filterChanged,
    relatedFilterChange,
    setFiltersItems,
    areFiltersEqual,
    resetFilters: handleResetFilters
  };
};
