import { ISelectAccordionOption } from "../../components/accordion-filter/SelectAccordionGroup";
import { EscalationProgress } from "../helper";

export const getAccordionOptions = (list: string[]): ISelectAccordionOption[] =>
  list.map(item => ({
    name: item || "(none)",
    value: item || "(none)",
    selected: false
  }));

export const FEEDBACK_ACTIONS = [
  "Alternative Product",
  "Vendor Improvement",
  "On Hand Stock Prioritize",
  "Other Hub Sharing",
  "Change Flow",
  "Change Source"
];

export const FEEDBACK_PROGRESS = [
  {
    title: "Not Started",
    value: EscalationProgress.notStarted
  },
  {
    title: "In Progress",
    value: EscalationProgress.inProgress
  },
  {
    title: "Completed",
    value: EscalationProgress.completed
  }
];
