import { LogLevel } from "@azure/msal-browser";

const DEV_CLIENT_ID = "f618a66b-3545-4702-9f52-1dfccfc2eabc";
// e~w7Q~r5_vHbeBjLN4RFiF7qEz2vEBiyEdxTZ

const appRegistrationClientId =
  process.env.NODE_ENV === "development"
    ? DEV_CLIENT_ID
    : (window as any)["runConfig"].appRegistrationClientId;

// const appRegistrationClientId = DEV_CLIENT_ID;

export const msalConfig = {
  auth: {
    clientId: appRegistrationClientId,
    authority: "https://login.microsoftonline.com/92e84ceb-fbfd-47ab-be52-080c6b87953f",
    redirectUri: window.location.origin + "/"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          //   case LogLevel.Info:
          //     console.info(message);
          //     return;
          //   case LogLevel.Verbose:
          //     console.debug(message);
          //     return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false
    }
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [`api://${appRegistrationClientId}/access_as_user`]
};
