import { useEffect, useMemo, useState } from "react";
import View from "../../views/app-view";
import { Dialog, Tile, useNotifications, Loader as ELoader } from "@react-gcc-eds/core";
import Loader from "../../components/loader";
import { HELP_ADMINISTRATOR } from "../helper";
import FeedbackDialog from "../component/feedback-dialog";
import { EEfRoles, EMarketArea } from "../../types";
import fileDownload from "js-file-download";
import AccordionFilter, { IFeedbackAccordionFilters } from "../../components/accordion-filter";
import "./escalation-feedback-view.scss";
import FeedbackTableView from "../component/FeedbackTableView";
import FeedbackBucketView from "../component/FeedbackBucketView";
import TicketBaseInfoDialog from "../component/ticket-base-dialog/ticket-base-info-dialog";
import { useAppState } from "../../app-state";
import HelpTip from "../component/help-tip";
import { useGetAllEfUsers, useGetTicketSelections } from "../api/lookup";
import { getAccordionOptions } from "../helpers";
import {
  ITaskBucketItems,
  ITicketTaskEditItem,
  ITicketTaskItem,
  IWeeklyUpdateEditItem,
  useGetAllTask,
  useGetWeeklyUpdate,
  useUpdateEfTask,
  useUpdateWeeklyUpdate
} from "../api/task";
import { useDownloadTicketAttachments, useGetTicketDetails } from "../api/ticket";
import { IOaItem, useUpdateOa } from "../api/sales-order";

const EscalationFeedbackView = () => {
  //common
  const [ticketOptions, loadingTicketOptions, getTicketOptions] = useGetTicketSelections();
  const [allTaskData, loadingAllTask, getAllTask] = useGetAllTask();
  const [weeklyUpdateData, loadingWeeklyUpdate, getWeeklyUpdate] = useGetWeeklyUpdate();
  const [updateTaskData, loadingUpdateTask, updateTask] = useUpdateEfTask();
  const [setWeeklyUpdateData, loadingSetWeeklyUpdate, setWeeklyUpdate] = useUpdateWeeklyUpdate();
  const [ticketDetail, loadingTicketDetail, getTicketDetail] = useGetTicketDetails();
  const [allAvailableUsers, loadingAllAvailableUsers, getAllAvailableUsers] = useGetAllEfUsers();
  const [updateOAData, loadingUpdateOA, updateOA] = useUpdateOa();
  const { createNotification } = useNotifications();

  useEffect(() => {
    getAllTask();
    getAllAvailableUsers();
    getTicketOptions();
  }, []);

  //table
  const [tableView, setTableView] = useState<boolean>(false);
  const [activeTask, setActiveTask] = useState<ITicketTaskItem>();

  const handleSwitchView = () => {
    setTableView(view => !view);
  };

  const handleShowTicketDetail = (data: ITicketTaskItem) => {
    setActiveTask(data);
    setShowFeedbackDialog(true);
    getWeeklyUpdate({ ticketNumber: data.ticketNumber, ticketCategory: data.ticketCategory });
  };

  //buckets
  const [displayBuckets, setDisplayBuckets] = useState<ITaskBucketItems>();

  useEffect(() => {
    if (allTaskData) {
      setDisplayBuckets(allTaskData);
    }
  }, [allTaskData]);

  const toFilterText = (value: string | string[] | number | undefined) =>
    value ? value.toString().toLowerCase() : "";

  const filterByText =
    (key: keyof ITicketTaskItem, value: string | number | undefined) =>
    (data: ITaskBucketItems): ITaskBucketItems => {
      return value
        ? {
            buckets: data.buckets,
            allTasks: data.allTasks.filter(task =>
              toFilterText(task[key] as string).includes(toFilterText(value))
            )
          }
        : data;
    };

  const filterByArray =
    (key: keyof ITicketTaskItem, values: string[]) =>
    (data: ITaskBucketItems): ITaskBucketItems => {
      return values.length > 0
        ? {
            buckets: data.buckets,
            allTasks: data.allTasks.filter(task => values.includes(task[key] as string))
          }
        : data;
    };

  const filterByMaterial =
    (value: string) =>
    (data: ITaskBucketItems): ITaskBucketItems => {
      return value
        ? {
            buckets: data.buckets,
            allTasks: data.allTasks.filter(task =>
              task.taskMaterials.find(({ material }) =>
                toFilterText(material).includes(toFilterText(value))
              )
            )
          }
        : data;
    };

  const onAccordionFilterQuery = (params: IFeedbackAccordionFilters) => {
    const { customer, material, ticketNumber, priority, ticketCategories } = params;
    setDisplayBuckets(
      filterByArray(
        "ticketCategory",
        ticketCategories
      )(
        filterByArray(
          "priority",
          priority
        )(
          filterByText(
            "ticketNumber",
            ticketNumber
          )(filterByMaterial(material)(filterByText("customer", customer)(allTaskData)))
        )
      )
    );
  };

  //feedback dialog
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  const onFeedbackDialogClose = () => {
    setShowFeedbackDialog(false);
  };

  const handleSaveTask = (data: ITicketTaskEditItem) => {
    updateTask(data);
  };

  const handleSaveWeeklyUpdates = (data: IWeeklyUpdateEditItem[]) => {
    setWeeklyUpdate({ weeklyUpdates: data });
  };

  const handleShowDetail = (ticketNumber: number) => {
    setShowEscalationDetail(true);
    getTicketDetail({ ticketNumber });
  };

  useEffect(() => {
    if (updateTaskData?.success) {
      createNotification(`Success`, `Update success!`);
      getAllTask();
    }
  }, [updateTaskData]);

  useEffect(() => {
    if (setWeeklyUpdateData?.success) {
      createNotification(`Success`, `Update success!`);
    }
  }, [setWeeklyUpdateData]);

  const handleShowPreview = (ticketNumber: number) => {
    setShowPreviewDialog(true);
    getTicketDetail({ ticketNumber });
  };

  //ticket dialog
  const [showEscalationDetail, setShowEscalationDetail] = useState(false);

  //multiple download
  const [
    downloadAttachments,
    loadingDownloadAttachments,
    downloadMultipleAttachments,
    ,
    downloadFileName
  ] = useDownloadTicketAttachments();

  const handleMultipleDownload = (attachmentIds: number[]) => {
    downloadMultipleAttachments({ attachmentIds, ticketNumber: activeTask?.ticketNumber });
  };

  useEffect(() => {
    if (downloadAttachments) {
      if ((downloadAttachments as Blob).size !== 0) {
        fileDownload(downloadAttachments, downloadFileName || "result.zip");
      } else {
        createNotification("Warning !", "Not have any result!", "warning");
      }
    }
  }, [downloadAttachments]);

  //oa
  const [showPreviewDialog, setShowPreviewDialog] = useState<boolean>(false);

  const handleOaSubmit = (oaList: IOaItem[]) => {
    updateOA({
      ticketNumber: activeTask?.ticketNumber as number,
      improvedOrderAcknowledgeDateInfos: oaList
    });
  };

  useEffect(() => {
    if (updateOAData) {
      const { success } = updateOAData;
      if (success) {
        setShowPreviewDialog(false);
        createNotification("Succeed!", "Good job!");
      }
    }
  }, [updateOAData]);

  //role
  const [appState] = useAppState();
  const canEdit = useMemo(
    (): boolean =>
      Boolean(
        appState.user?.efRoles.find(
          role => role === EEfRoles.EFSuperOwner || role === EEfRoles.EFTicketApprover
        )
      ),
    [appState]
  );
  const hasEscalationLevel = useMemo(
    () => Boolean(!appState.user?.marketAreas.includes(EMarketArea.MMEA)),
    [appState]
  );
  const administrator = useMemo(
    () => (appState.user ? HELP_ADMINISTRATOR[appState.user.marketAreas[0]] : "administrator"),
    [appState]
  );

  return (
    <View
      breadCrumbs={[{ text: "Escalation Management" }, { text: "Escalation Feedback" }]}
      actions={
        <HelpTip
          tooltip={`In order to get new ticket permission, please contact ${administrator}.`}
        />
      }
    >
      <Tile
        style={{ paddingBottom: 0 }}
        title="Feedback"
        className="escalation-feedback"
        actions={
          <div className="escalation-feedback-actions">
            <div
              className={`icon-table-area ${tableView ? "escalation-feedback-icon-select" : ""}`}
            >
              <i className="icon icon-table" onClick={handleSwitchView} />
            </div>
            {loadingTicketOptions && <ELoader />}
            {ticketOptions && (
              <AccordionFilter
                productAreaOptions={getAccordionOptions(ticketOptions.ticketCategories)}
                onApply={onAccordionFilterQuery}
                visible={!tableView && !loadingTicketOptions}
              />
            )}
          </div>
        }
      >
        {(loadingUpdateTask || loadingSetWeeklyUpdate) && (
          <Dialog fullscreen>
            <Loader />
          </Dialog>
        )}
        {tableView ? (
          allTaskData && (
            <FeedbackTableView
              data={allTaskData.allTasks}
              loadingTable={loadingAllTask}
              onRowClick={data => handleShowTicketDetail(data)}
            />
          )
        ) : (
          <FeedbackBucketView
            bucketList={displayBuckets}
            onCardClick={data => handleShowTicketDetail(data)}
            loadingContent={loadingAllTask || loadingTicketOptions}
          />
        )}
        {loadingWeeklyUpdate || loadingSetWeeklyUpdate || loadingUpdateTask ? (
          <Dialog fullscreen closeOnEscape>
            <Loader />
          </Dialog>
        ) : (
          activeTask && (
            <FeedbackDialog
              show={showFeedbackDialog}
              formData={activeTask}
              weeklyUpdateData={weeklyUpdateData}
              onClose={onFeedbackDialogClose}
              onSaveTask={handleSaveTask}
              onSaveWeeklyUpdate={handleSaveWeeklyUpdates}
              onShowDetail={handleShowDetail}
              onShowPreview={handleShowPreview}
              canEdit={canEdit}
            />
          )
        )}
        <TicketBaseInfoDialog
          loadingDialog={loadingTicketDetail}
          showApproval
          showPreviewDialog={showPreviewDialog}
          onPreviewClose={() => setShowPreviewDialog(false)}
          disableBasicInfo={true}
          disableApprovalInfo={true}
          show={showEscalationDetail}
          formData={ticketDetail}
          title={`Approval Ticket: ${ticketDetail?.ticketNumber}`}
          onClose={() => setShowEscalationDetail(false)}
          dropdownItems={ticketOptions}
          userList={allAvailableUsers}
          downloadAttachments={downloadAttachments}
          hasEscalationLevel={hasEscalationLevel}
          onDownload={handleMultipleDownload}
          loadingUpdateOa={loadingUpdateOA}
          onUpdateOa={handleOaSubmit}
          editableOA={showPreviewDialog}
        />
      </Tile>
    </View>
  );
};

export default EscalationFeedbackView;
