import { useState, useEffect } from "react";
import { useAppState } from "../app-state";
import {
  getLocalStorageValue,
  setLocalStorageValue,
  deleteLocalStorageKey
} from "../utils/local-storage";
import { ViewId } from "./view-preferences-handler";

export { ViewId } from "./view-preferences-handler";

export enum FilterId {
  VIS_GLOBAL = "VIS_GLOBAL",
  VIS_DETAIL = "VIS_DETAIL"
}

const key = (viewId: ViewId, filterId: FilterId, version: number) =>
  `${viewId}_${filterId}_FILTERS_v${version}`;

export const useFiltersPersistence = <T extends {}>({
  viewId,
  filterId,
  version
}: {
  viewId: ViewId;
  filterId: FilterId;
  version: number;
}) => {
  const [filters, setFilters] = useState<T>();

  const [appState] = useAppState();

  // remove older versions
  useEffect(() => {
    Array.from(Array(version).keys()).forEach(v =>
      deleteLocalStorageKey(key(viewId, filterId, v), appState.user)
    );
  }, [appState.user, viewId, filterId, version]);

  // read key on mount
  useEffect(() => {
    const value = getLocalStorageValue<T>(key(viewId, filterId, version), undefined, appState.user);
    if (value) setFilters(value);
  }, [appState.user, viewId, filterId, version]);

  const persistFilters = (value: T) => {
    try {
      setLocalStorageValue<T>(value, key(viewId, filterId, version), appState.user);
    } catch {}
  };

  return { filters, persistFilters };
};
