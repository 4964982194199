import { useApiGet, useApiPut } from "../../api/api";
import { TUserBaseInfo } from "../../types";
import { IActionResult } from "./ticket";

export interface IEfUserItem extends TUserBaseInfo {
  id: number;
  groupSupplyUnit?: string[];
  escalationFeedbackRoles?: string[];

  [key: string]: any;
}

const EF_USER = "/v1/escalation-feedback/escalation-feedback-user";

export const useGetAllAvailableUsers = () => useApiGet<IEfUserItem[]>(EF_USER + "/all");

export const useUpdateEfUser = () =>
  useApiPut<{ efUserInfos: IEfUserItem[] }, IActionResult>(EF_USER + "/update");
