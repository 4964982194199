import { Icon, MultiPanelTile } from "@react-gcc-eds/core";
import { useState } from "react";
import { IBarWithLineChartData } from "../../../components/charts/types";
import VerticalBarWithLineChart from "../../../components/charts/vertical-bar-with-line-chart";
import ContentLoader from "../../../components/content-loader";
import { getFormatWeek } from "../../../utils";
import { EGftStatus, IStatusTrendItem, IWeeksParam } from "../../api/supplier-fcst-res";
import SupplierFcstTrendFilter from "./supplier-fcst-trend-filter";

export const CUSTOM_COLORS = ["#878787", "#ED0E00", "#976CF4", "#B98327", "#329864"];

const PublishStatusTrend = ({
  loadingFilter,
  loadingChart,
  allWeeksData,
  statusTrendData,
  onSearch
}: {
  loadingFilter: boolean;
  loadingChart: boolean;
  allWeeksData: number[];
  statusTrendData: IStatusTrendItem[];
  onSearch: (value: IWeeksParam) => void;
}) => {
  //filter
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const toggleFilters = () => setShowFilters(show => !show);

  const getPercentage = (value: number, total: number, valueDecimals?: number): number =>
    Number(((100 * value) / total).toFixed(valueDecimals || 0));

  const apiToChart = (apiData: IStatusTrendItem[]): IBarWithLineChartData => {
    const chartData: IBarWithLineChartData = {
      common: [],
      barChartSeries: {
        name: "Total Parts",
        values: []
      },
      lineChartSeries: [
        {
          name: EGftStatus.unpublished,
          values: []
        },
        {
          name: EGftStatus.withoutCapability,
          values: []
        },
        {
          name: EGftStatus.gap,
          values: []
        },
        {
          name: EGftStatus.noIssue,
          values: []
        }
      ]
    };
    if (apiData && apiData.length > 0) {
      const ascApiData = apiData.sort((a, b) => a.weekTime - b.weekTime);
      for (const {
        weekTime,
        total,
        unpublished,
        publishedWithoutCapability,
        receivedWithCapabilityWithGap,
        noIssuePart
      } of ascApiData) {
        chartData.common.push(getFormatWeek(weekTime));
        chartData.barChartSeries.values.push({ value: total });
        chartData.lineChartSeries[0].values.push({ value: getPercentage(unpublished, total) });
        chartData.lineChartSeries[1].values.push({
          value: getPercentage(publishedWithoutCapability, total)
        });
        chartData.lineChartSeries[2].values.push({
          value: getPercentage(receivedWithCapabilityWithGap, total)
        });
        chartData.lineChartSeries[3].values.push({ value: getPercentage(noIssuePart, total) });
      }
    }
    return chartData;
  };

  return (
    <MultiPanelTile
      className="supplier-fcst-top"
      centerTitle="Publish Status % Trend"
      centerLeftIcon={<Icon name="filter" onClick={toggleFilters} style={{ cursor: "pointer" }} />}
      leftTitle="Selections"
      leftClassName="scrollable-pane"
      leftContent={
        <ContentLoader
          loading={loadingFilter}
          data={allWeeksData}
          render={
            allWeeksData ? (
              <SupplierFcstTrendFilter defaultAvailableItems={allWeeksData} onApply={onSearch} />
            ) : (
              <></>
            )
          }
        />
      }
      leftVisible={showFilters}
      leftActions={<Icon name="cross" onClick={toggleFilters} style={{ cursor: "pointer" }} />}
      centerContent={
        <div className="supplier-chart-content">
          <VerticalBarWithLineChart
            data={apiToChart(statusTrendData)}
            barUnitOfMeasure="Item"
            lineUnitOfMeasure="%"
            customColors={CUSTOM_COLORS}
          />
        </div>
      }
    />
  );
};

export default PublishStatusTrend;
