import React, { ChangeEvent } from "react";

import cx from "classnames";

import { uniqueId, useFocus } from "../../utils";
import { INumberField } from "@react-gcc-eds/core";

interface ICustomeNumberField extends INumberField {
  unit?: string;
  placeholder?: string;
}

const NumberField = ({
  label,
  value,
  onChange,
  onBlur,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  focus,
  disabled,
  readonly,
  max = Number.MAX_SAFE_INTEGER,
  min = 0,
  step = 1,
  type,
  unit,
  className,
  style,
  dataAttributes,
  placeholder
}: ICustomeNumberField) => {
  const { inputRef } = useFocus();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    if (onChange && ((newValue >= min && newValue <= max) || !e.target.value)) {
      onChange(newValue);
    }
  };

  const onNumberInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const filteredKeys = new Set(["e", "-", "+", "."]);
    if (filteredKeys.has(key)) {
      e.preventDefault();
    }
    onKeyPress && onKeyPress(e);
  };

  const increase = () => {
    if (onChange && value + step <= max) {
      onChange((value + step) * 1);
    }
  };

  const decrease = () => {
    if (onChange && value - step >= min) {
      onChange((value - step) * 1);
    }
  };

  const id = uniqueId();

  return (
    <span
      // deleted the create data Attributes
      {...dataAttributes}
      className={cx({ spinner: type === "spinner", disabled }, className)}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <input
        style={style}
        ref={focus ? inputRef : null}
        id={id}
        className={unit && "input-number-unit"}
        type="number"
        value={isNaN(value) ? "" : value}
        onChange={onChangeHandler}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlur && onBlur(e)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(e)}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyUp && onKeyUp(e)}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => onNumberInputKeyDown(e)}
        disabled={disabled}
        max={max}
        min={min}
        step={step}
        readOnly={readonly}
        placeholder={placeholder}
      />
      {unit && <div className="suffix">{unit}</div>}
      {type === "spinner" && (
        <div className="controls">
          <i
            role="presentation"
            className={cx("icon icon-chevron-down", { disabled: value <= min })}
            onClick={!readonly ? decrease : undefined}
          />
          <i
            role="presentation"
            className={cx("icon icon-chevron-up", { disabled: value >= max })}
            onClick={!readonly ? increase : undefined}
          />
        </div>
      )}
    </span>
  );
};

export default NumberField;
