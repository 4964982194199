import { TUser } from "../types";

export const localStorageKey = (key: string, user?: TUser) =>
  `Inbound-${user ? `${user.firstName}.${user.lastName}` : "anyone"}-${key}`;

export const getLocalStorageValue = <T extends {} | undefined>(
  key: string,
  fallBack?: T,
  user?: TUser
): T | undefined => {
  const value = window.localStorage.getItem(localStorageKey(key, user));

  if (value) {
    return JSON.parse(value);
  }

  return fallBack;
};

export const setLocalStorageValue = <T extends {}>(value: T, key: string, user?: TUser) => {
  if (value) {
    window.localStorage.setItem(localStorageKey(key, user), JSON.stringify(value));
  }
};

export const deleteLocalStorageKey = (key: string, user?: TUser) => {
  window.localStorage.removeItem(localStorageKey(key, user));
};
