import { useApiGet, useApiPost, useApiPut } from "../../api/api";
import { EscalationPriority, EscalationProgress } from "../helper";
import { IActionResult } from "./ticket";

export interface ITaskBucketItems {
  buckets: string[];
  allTasks: ITicketTaskItem[];
}

export interface ITicketTaskEditItem {
  taskId: number;
  progress: EscalationProgress;
  priority: EscalationPriority;
  startDate: string;
  dueDate: string;
  taskAssignedUsers: string[];
}
export interface ITicketTaskItem extends ITicketTaskEditItem {
  ticketNumber: number;
  ticketCategory: string;
  groupSupplyUnit: string;
  escalationLevel: string;
  taskBucket: string;
  customer: string;
  productReportingGroups: string[];
  customerRegion: string;
  escalationRegion: string;
  taskMaterials: ITaskMaterialItem[];
}

export interface ITaskMaterialItem {
  material: string;
  materialDesc: string;
  totalQuantity: number;
  orderPlacedHub: string;
  currentMaterialAvailableDateRange: string;
  currentReadyForShipmentDateRange: string;
}

interface IWeeklyUpdateParams {
  ticketNumber: number;
  ticketCategory: string;
}

export interface IWeeklyUpdateBasicItem {
  comments: string;
  action: string | null;
  quantity: number;
}

export interface IWeeklyUpdateItem extends IWeeklyUpdateBasicItem, IWeeklyUpdateParams {
  weekNumber: string;
  id: number;
  lastModifiedBy: string;
}

export interface IWeeklyUpdateEditItem extends IWeeklyUpdateBasicItem, IWeeklyUpdateParams {
  id?: number;
  weekNumber?: string;
}

const EF_TASK = "/v1/escalation-feedback/task";

// get feedback card and table
export const useGetAllTask = () => useApiGet<ITaskBucketItems>(EF_TASK + "/all");

// update feedback
export const useUpdateEfTask = () => useApiPut<ITicketTaskEditItem, IActionResult>(EF_TASK);

// get weekly update
export const useGetWeeklyUpdate = () =>
  useApiGet<IWeeklyUpdateItem[], IWeeklyUpdateParams>(EF_TASK + "/weekly-update");

// update weekly update
export const useUpdateWeeklyUpdate = () =>
  useApiPost<{ weeklyUpdates: IWeeklyUpdateEditItem[] }, IActionResult>(EF_TASK + "/weekly-update");
