import { useMemo, useState } from "react";
import {
  Brush,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from "recharts";
import { roundNumberToLocale } from "../../utils";
import { useChartHelpers } from "./chart-helpers";
import LegendPills from "./legend";
import { ChartTooltip } from "./tooltip";
import { ILineChartData } from "./types";
import { useSelectedSeries } from "./selected-series";

interface ILineChart {
  data: ILineChartData;
  unitOfMeasure: string;
  rotatedXAxisLabels?: boolean;
  withBrush?: boolean;
  invertTooltipOrder?: boolean;
  yAxisDecimalPlaces?: number;
  multipleSeriesSelection?: boolean;
  customColors?: string[];
}

const LineChart = ({
  data,
  unitOfMeasure,
  rotatedXAxisLabels,
  withBrush = true,
  invertTooltipOrder,
  yAxisDecimalPlaces = 0,
  multipleSeriesSelection = false,
  customColors
}: ILineChart) => {
  const [chartSize, setChartSize] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0
  });

  const pointWidth = 30;

  const {
    selectedSeries,
    hoveredSeries,
    handleSeriesSelected,
    handleSeriesHovered,
    handleSeriesUnhovered
  } = useSelectedSeries({ allowMultipleSelection: multipleSeriesSelection });

  const { getColor, chartDataAsRechartsData } = useChartHelpers();

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  };

  const leftMargin = () => {
    const nofSamples = data.common.length;
    let max = 0;

    for (let i = 0; i < nofSamples; i++) {
      const total = data.series.map(s => s.values[i].value).reduce((sum, v) => sum + v, 0);
      if (total > max) max = total;
    }

    return max < 1000 ? 0 : max < 10000 ? 5 : max < 100000 ? 10 : 20;
  };

  const bottomMargin = () =>
    data.common.reduce((max: number, s: string) => (s.length > max ? s.length : max), 0) * 4;

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        if (entry.contentRect.height > 0 && entry.contentRect.width > 0) {
          setChartSize({ height: entry.contentRect.height, width: entry.contentRect.width });
        }
      }),
    []
  );

  return (
    <ResponsiveContainer
      ref={(obj: any) => (obj?.current ? resizeObserver.observe(obj.current) : null)}
      height={"99%"}
    >
      <RechartsLineChart
        width={100}
        height={100}
        data={chartDataAsRechartsData(data, selectedSeries)}
        margin={
          rotatedXAxisLabels
            ? { top: 40, left: leftMargin(), right: 10, bottom: bottomMargin() }
            : { top: 40, left: 0, right: 10 }
        }
      >
        <Tooltip
          cursor={{ fill: "transparent" }}
          content={(props: TooltipProps) => (
            <ChartTooltip inverted={invertTooltipOrder} units={[unitOfMeasure]} props={props} />
          )}
        />
        <CartesianGrid vertical={false} />
        <Legend
          content={
            <LegendPills
              series={data.series.map(s => ({
                name: s.name,
                selected: selectedSeries.includes(s.name),
                tooltip: s.tooltip
              }))}
              onMouseEnter={handleSeriesHovered}
              onMouseLeave={handleSeriesUnhovered}
              onClick={handleSeriesSelected}
              customColors={customColors}
            />
          }
          align="left"
          layout="horizontal"
          verticalAlign="top"
        />
        <XAxis
          tickLine={false} //eds alignment
          axisLine={false} //eds alignment
          dataKey="name"
          tick={rotatedXAxisLabels ? CustomXAxisTick : true}
          interval={rotatedXAxisLabels ? 0 : "preserveStartEnd"}
        />
        <YAxis
          tickFormatter={(val: any) => roundNumberToLocale(val, yAxisDecimalPlaces)}
          tickLine={false}
          axisLine={false}
        >
          <Label value={unitOfMeasure} position="top" offset={22} />
        </YAxis>
        {data.series.map((s, index) => (
          <Line
            type="linear"
            dataKey={s.name}
            opacity={hoveredSeries ? (s.name === hoveredSeries ? 1 : 0.2) : 1}
            stroke={getColor(index, customColors)}
            strokeWidth={3}
            key={s.name}
          />
        ))}
        {withBrush &&
          chartSize &&
          Math.round(chartSize.width / (pointWidth + 10)) < data.common.length - 1 && (
            <Brush
              y={Math.round(chartSize.height - 12)}
              endIndex={Math.round(chartSize.width / (pointWidth + 10))}
              dataKey="name"
              height={12}
            />
          )}
      </RechartsLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
