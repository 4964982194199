import { Icon } from "@react-gcc-eds/core";
import "./summary-filter.scss";

const SummaryFilter = ({
  filterActive,
  toggleFilters,
  onResetFilters
}: {
  filterActive: boolean;
  toggleFilters: () => void;
  onResetFilters: () => void;
}) => {
  const icon = () => (
    <Icon className="multipanel-filter-icon" name="filter" onClick={toggleFilters} />
  );

  return filterActive ? (
    <div className="multipanel-filter">
      <div className="multipanel-filter-icon-active">{icon()}</div>
      <div className="multipanel-filter-label" onClick={onResetFilters}>
        Reset filters
      </div>
    </div>
  ) : (
    icon()
  );
};

export default SummaryFilter;
