import { useApiGet, useApiGetForBlob, useApiPostForm } from "../../api/api";
import { IActionResult } from "../../management/api/ticket";

export interface IWeekParam {
  weekTime: number;
}

export interface IWeeksParam {
  weeksString: string;
}

export interface IStatusTrendItem extends IWeekParam {
  noIssuePart: number;
  publishedWithoutCapability: number;
  receivedWithCapabilityWithGap: number;
  total: number;
  unpublished: number;
}

export interface ISippItem {
  consumingSCN: string;
  nonSipp: number;
  sipp: number;
}

export interface IStatusWeeklyItem {
  siteStatus: ISippItem[];
  status: EGftStatus;
}

export enum EGftStatus {
  unpublished = "Unpublished",
  withoutCapability = "Published & Without Capability",
  gap = "Received with Capability with Gap",
  noIssue = "No issue part"
}

export enum EGftUploadStatus {
  RUNNING = "Pending",
  FREE = "Not Start"
}

export interface IGftUploadSocketData {
  runReport: EGftUploadStatus;
}

const GTF = "/v1/supplier-response-report/gft-response";

export const useGetAllWeeks = () => useApiGet<number[]>(GTF + "/all-weeks");
export const useExportWeekly = () =>
  useApiGetForBlob<undefined, IWeekParam>(GTF + "/export-weekly");
export const useGetStatusTrend = () =>
  useApiGet<IStatusTrendItem[], IWeeksParam>(GTF + "/status-trend");
export const useGetStatusWeekly = () =>
  useApiGet<IStatusWeeklyItem[], IWeekParam>(GTF + "/status-weekly");
export const useUploadFcst = () => useApiPostForm<IActionResult>(GTF + "/upload-fcst");
export const useGetSsrStatus = () => useApiGet<EGftUploadStatus>(GTF + "/ssr-status");
