import { TableColumn, TextArea } from "@react-gcc-eds/core";
import cx from "classnames";
import NumberField from "../../../components/number-field";

const text = (key: string, header: string) => {
  return { key, header };
};

const editableNumber = <DataType extends { [key: string]: any }>(
  key: keyof DataType,
  header: string,
  onChange: (row: DataType, key: keyof DataType) => (value: number) => void,
  invalid?: (row: DataType, key: keyof DataType) => boolean,
  errorMsg?: string,
  disabled?: boolean
): TableColumn => ({
  key: key as string,
  header,
  Cell: row => {
    const _row = row as DataType;
    return (
      <div style={{ position: "relative" }}>
        <NumberField
          className={cx({ numberError: invalid && invalid(_row, key) })}
          value={_row[key]}
          onChange={onChange(_row, key)}
          disabled={disabled}
          min={1}
          max={10}
        />
        {errorMsg && invalid && invalid(_row, key) && (
          <span className="validate-form-error">{errorMsg}</span>
        )}
      </div>
    );
  }
});

const editableTextArea = <DataType extends { [key: string]: any }>(
  key: keyof DataType,
  header: string,
  onChange: (row: DataType, key: keyof DataType) => (value: string) => void,
  invalid?: (row: DataType, key: keyof DataType) => boolean,
  errorMsg?: string,
  disabled?: boolean
): TableColumn => ({
  key: key as string,
  header,
  Cell: row => {
    const _row = row as DataType;
    return (
      <div style={{ position: "relative" }}>
        <TextArea
          style={{ height: "80px", width: "400px" }}
          className={cx({ textAreaError: invalid && invalid(_row, key) })}
          value={_row.comment}
          onChange={onChange(_row, key)}
          disabled={disabled}
        />
        {errorMsg && invalid && invalid(_row, key) && (
          <span className="validate-form-error">{errorMsg}</span>
        )}
      </div>
    );
  }
});

export const getEvaluationTableColumns = <DataType extends { [key: string]: any }>(
  onChange: (row: DataType, key: keyof DataType) => (value: number | string) => void,
  invalid?: (row: DataType, key: keyof DataType) => boolean,
  disabled?: boolean
): TableColumn[] => {
  return [
    text("label", "Field Label"),
    text("desc", "Description/Instructional Text"),
    editableNumber(
      "score",
      "Score",
      onChange,
      invalid,
      "Score must greater than 0, smaller than 10",
      disabled
    ),
    editableTextArea("comment", "Comment", onChange, invalid, "Please input comment", disabled),
    text("weight", "Weight")
  ];
};
