import { IColumnDefinition } from "../../../components/grid/grid";
import { dateCell } from "../../../helpers/grid-cells";
import "./ticket-status-progress.scss";
import { customTextCell } from "../../helpers/ef-table";
import { escalationStatusCell } from "./ticket-log";

export const getApprovalColumns = ({
  hasEscalationLevel
}: {
  hasEscalationLevel: boolean;
}): IColumnDefinition[] => {
  return [
    customTextCell("ticketNumber", "Ticket number"),
    {
      ...dateCell("raiseTime", "Raise time", false, true),
      minWidth: 180
    },
    customTextCell("customers", "Customer", false, true, 200),
    customTextCell("productReportingGroups", "Product reporting group", false, true, 180),
    customTextCell("requesterName", "Requester name"),
    customTextCell("escalationRegion", "Escalation region", false, true),
    ...(hasEscalationLevel
      ? [customTextCell("escalationLevel", "Escalation level", false, true)]
      : []),
    {
      ...customTextCell("escalationStatus", "Status", false, true, 180),
      cellRenderer: escalationStatusCell
    },
    { ...dateCell("lastModifiedAt", "Modified time", false, true), minWidth: 180 },
    customTextCell("modifyUserName", "Modified user name", false, true)
  ];
};
