import { useSettings } from "@react-gcc-eds/core";
import React, { useMemo } from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import IGauge from "./IGauge";

const GaugeChart = ({ value = 0, dataSettings }: IGauge) => {
  const GRAY_NUM = 37;
  const START_ANGLE = 225;
  const END_ANGLE = -45;
  const COLORS = {
    default: "rgba(36,36,36,0.3)",
    black: "rgba(36,36,36,1)",
    red: "#ed0e00",
    orange: "#D46617",
    yellow: "#a08209",
    green: "#329864",
    gray: "#878787",
    white: "#f2f2f2"
  };

  const { units = "%", max = 0, min = 0, limits = [] } = dataSettings;
  const { theme } = useSettings();

  const getScale = (size: string) => {
    switch (size) {
      case "small":
        return 0.75;
      case "medium":
        return 1;
      case "large":
        return 1.25;
      default:
        return 1;
    }
  };
  const scale = dataSettings.size !== undefined ? getScale(dataSettings.size) : 1;
  const innerRadius = 70 * scale;
  const activeNmu = useMemo(() => Math.round((value * GRAY_NUM) / (max - min)), [value, max, min]);

  const getChartData = (length: number) => Array.from({ length }, () => ({ value: 1 }));

  const numToAngle = (num: number) => {
    return START_ANGLE - Math.round((num * (START_ANGLE - END_ANGLE)) / (max - min));
  };

  const getActiveColor = () => {
    const active = limits.find(({ from, to, color }) => value > from && value < to && color);
    return active ? active.color : theme === "light" ? COLORS.black : COLORS.white;
  };

  const renderTotalLabel = (props: any) => {
    const {
      viewBox: { cx, cy }
    } = props;
    return (
      <g>
        <text fontSize={`${45 * scale}px`} x={cx} y={cy} textAnchor="middle" fill={COLORS.black}>
          {value}
        </text>
        <text
          fontSize={`${21 * scale}px`}
          x={cx}
          y={cy}
          dy={28 * scale}
          textAnchor="middle"
          fill={COLORS.black}
        >
          {units}
        </text>
        <text
          fontSize={`${18 * scale}px`}
          x={cx}
          y={cy}
          dx={-(innerRadius / 2 + 13.75)}
          dy={innerRadius + 10}
          textAnchor="middle"
          opacity={0.3}
        >
          {min}
        </text>
        <text
          fontSize={`${18 * scale}px`}
          x={cx}
          y={cy}
          dx={innerRadius / 2 + 13.75}
          dy={innerRadius + 10}
          textAnchor="middle"
          opacity={0.3}
        >
          {max}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={getChartData(GRAY_NUM)}
          cx="50%"
          cy="50%"
          startAngle={START_ANGLE}
          endAngle={END_ANGLE}
          innerRadius={innerRadius}
          outerRadius={innerRadius + 7}
          fill={COLORS.default}
          paddingAngle={4}
          dataKey="value"
        >
          {getChartData(activeNmu).map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getActiveColor()} />
          ))}
          <Label content={renderTotalLabel}></Label>
        </Pie>
        {limits &&
          limits.map(({ from, to, color }, index) => (
            <Pie
              data={getChartData(1)}
              cx="50%"
              cy="50%"
              startAngle={numToAngle(from)}
              endAngle={numToAngle(to)}
              innerRadius={innerRadius - 10}
              outerRadius={innerRadius - 5}
              fill={color}
              dataKey="value"
              opacity={0.3}
              key={`pie-${index}`}
            ></Pie>
          ))}
      </PieChart>
    </ResponsiveContainer>
  );
};
export default GaugeChart;
