import { ReactElement } from "react";
import Loader from "./loader";
import { EmptyState, Tile } from "@react-gcc-eds/core";

const ContentLoader = ({
  loading,
  data,
  render,
  noTile = true,
  message = "No data found !",
  className,
  withTile
}: {
  loading: boolean;
  data: any;
  render: ReactElement;
  noTile?: boolean;
  message?: string;
  className?: string | undefined;
  withTile?: boolean;
}) => {
  return loading ? (
    <Loader noTile={noTile} />
  ) : data ? (
    render
  ) : withTile ? (
    <Tile>
      <EmptyState message={message} className={className} />
    </Tile>
  ) : (
    <EmptyState message={message} className={className} />
  );
};

export default ContentLoader;
