import cx from "classnames";
import IProgressBar from "./IProgressBar";
import "./ProgressBar.scss";
import { Tooltip } from "@react-gcc-eds/core";

const ProgressBar = ({ values, colors, className, style, tooltip }: IProgressBar) => {
  const total = values.reduce((m, n) => m + n);

  return (
    <div className="progress-number">
      <div className={cx("progress-bars", className)} style={style}>
        {values.map((value, i) => (
          <div
            key={`progress-bars-${i}`}
            className="progress-bars-item"
            style={{
              width: `${(value * 100) / total}%`,
              backgroundColor: colors && colors[i] ? colors[i] : "unset"
            }}
          ></div>
        ))}
      </div>
      {tooltip ? (
        <Tooltip
          position="right"
          alwaysShown
          content={
            <div className="ticket-log-progress-tooltip">
              <div className="ticket-log-progress-tooltip-row">
                <span className="ticket-log-progress-tooltip-label">Completed:</span>
                {values[0]}
              </div>
              <div className="ticket-log-progress-tooltip-row">
                <span className="ticket-log-progress-tooltip-label">In Progress:</span>
                {values[1]}
              </div>
              <div className="ticket-log-progress-tooltip-row">
                <span className="ticket-log-progress-tooltip-label">Not Started:</span>
                {values[2]}
              </div>
            </div>
          }
          type={"pointer"}
        >
          <div className="progress-number-text">{values.map(v => v || 0).join("/")}</div>
        </Tooltip>
      ) : (
        <div className="progress-number-text">{values.map(v => v || 0).join("/")}</div>
      )}
    </div>
  );
};

export default ProgressBar;
