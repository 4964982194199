import React from "react";
import "./progress-circle.scss";
import { EscalationProgress } from "../helper";

const ProgressCircle = ({ progress }: { progress: EscalationProgress }) => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 200 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group">
          <circle id="Oval" strokeWidth="16" cx="100" cy="100" r="92" className="progress-circle" />
          {progress === EscalationProgress.completed && (
            <circle id="Oval" strokeWidth="16" cx="100" cy="100" r="82" fill="#E66E19" />
          )}
          {progress === EscalationProgress.inProgress && (
            <path
              d="M185,100 C185,146.944204 146.944204,185 100,185 C53.0557963,185 15,146.944204 15,100 L15,100 Z"
              id="Combined-Shape"
              fill="#E66E19"
            />
          )}
        </g>
      </g>
    </svg>
  );
};

export default ProgressCircle;
