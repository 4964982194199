import { useState } from "react";
import {
  IGlobalStockItem,
  ITicketApprovalEditedItems,
  ITicketBaseFormItem,
  ITicketBasicItem,
  ITicketDetailSoItem,
  ITicketValidateItem
} from "../api/ticket";
import { simpleObjectEqual } from "../../utils/user-helpers";
import { DEFAULT_SO_ITEM } from "./ef-ticket";
import { EscalationStatus } from "../helper";

export interface ITicketError {
  type: keyof ITicketValidateItem;
  msg: string;
}

export const useValidateTicket = ({
  soItemData,
  checkData,
  formData,
  approvalData
}: {
  checkData?: ITicketDetailSoItem[];
  soItemData: ITicketDetailSoItem[];
  formData: ITicketBaseFormItem;
  approvalData: ITicketApprovalEditedItems;
}) => {
  const [error, setError] = useState<ITicketError>();

  const validateSoItem = (name: keyof ITicketDetailSoItem): boolean => {
    if (name === "customer") {
      return validateCustomer();
    }
    const errorSoRow = getApiSoItemRows(soItemData).find(
      item =>
        item[name] === "" ||
        item[name] === undefined ||
        item[name] === null ||
        Number.isNaN(item[name])
    );
    if (getApiSoItemRows(soItemData).length === 0 || errorSoRow) {
      setError({ type: name, msg: "" });
      return false;
    }
    return true;
  };

  const validateCustomer = (): boolean => {
    if (checkData) {
      const customers = Array.from(new Set(checkData.map(({ customer }) => customer)));
      if (customers.length > 1) {
        setError({ type: "customer", msg: "" });
        return false;
      }
      return true;
    }
    return true;
  };

  const validateSoItemLength = (name: keyof ITicketDetailSoItem, length: number): boolean => {
    const errorSoRow = soItemData.find(
      item => item[name] && (item[name] as string).length > length
    );
    if (errorSoRow) {
      setError({ type: name, msg: `cannot input more than ${length}` });
      return false;
    }
    return true;
  };

  const validateSoItems = (checkSubmit = false) => {
    const validateCheck = (name: keyof ITicketDetailSoItem): boolean =>
      checkSubmit ? validateSoItem(name) : true;
    return (
      validateSoItem("salesOrder") &&
      validateSoItem("item") &&
      validateCheck("latestAcceptableReadyForShipmentDate") &&
      validateCheck("minimumCriticalDemandPerWeek") &&
      validateCheck("projectNameOrDeal") &&
      validateSoItemLength("projectNameOrDeal", 255) &&
      validateCheck("nroOrBoxDelivery") &&
      validateCheck("partialDeliveryAcceptable") &&
      validateCheck("customer")
    );
  };

  const validateGlobalItem = (name: keyof IGlobalStockItem): boolean => {
    const errorSoRow = formData.ticketMaterials.find(
      item =>
        item[name] === "" ||
        item[name] === undefined ||
        item[name] === null ||
        Number.isNaN(item[name])
    );
    if (errorSoRow) {
      setError({ type: name, msg: "" });
      return false;
    }
    return true;
  };

  const validateGlobalLength = (name: keyof IGlobalStockItem, length: number): boolean => {
    const errorSoRow = formData.ticketMaterials.find(
      item => item[name] && (item[name] as string).length > length
    );
    if (errorSoRow) {
      setError({ type: name, msg: `cannot input more than ${length}` });
      return false;
    }
    return true;
  };

  const validateBorrowItem = (name: keyof IGlobalStockItem): boolean => {
    const errorSoRow = formData.ticketMaterials.find(
      item =>
        item.globalStockCheck &&
        (item[name] === "" ||
          item[name] === undefined ||
          item[name] === null ||
          Number.isNaN(item[name]))
    );
    if (errorSoRow) {
      setError({ type: name, msg: "" });
      return false;
    }
    return true;
  };

  const validateBasicItem = (name: keyof ITicketBasicItem): boolean => {
    if (!formData[name]) {
      setError({ type: name, msg: "" });
      return false;
    }
    return true;
  };

  const validateFormLength = (name: keyof ITicketBasicItem, length: number): boolean => {
    if (formData[name] && (formData[name] as string).length > length) {
      setError({ type: name, msg: `cannot input more than ${length}` });
      return false;
    }
    return true;
  };

  const validateFormValues = () => {
    return (
      validateBasicItem("escalationReason") &&
      validateBasicItem("escalationRegion") &&
      validateBasicItem("businessImpact") &&
      validateFormLength("businessImpact", 500) &&
      validateGlobalItem("globalStockCheck") &&
      validateBorrowItem("borrowFrom") &&
      validateBorrowItem("borrowQuantity") &&
      validateGlobalLength("preEscalationFeedback", 255) &&
      validateGlobalItem("sippOrNonSipp") &&
      validateGlobalItem("forecasted")
    );
  };

  const validateApproveItem = (name: keyof ITicketApprovalEditedItems): boolean => {
    if (!approvalData[name]) {
      setError({ type: name, msg: "" });
      return false;
    }
    return true;
  };

  const validateComments = (name: keyof ITicketApprovalEditedItems, length: number): boolean => {
    if (
      [EscalationStatus.rejectToRegion, EscalationStatus.rejectToRequester].includes(
        approvalData.escalationStatus as EscalationStatus
      ) &&
      !approvalData[name]
    ) {
      setError({ type: name, msg: "" });
      return false;
    }
    if (approvalData[name] && (approvalData[name] as string).length > length) {
      setError({ type: name, msg: `cannot input more than ${length}` });
      return false;
    }
    return true;
  };

  const validateAssign = (): boolean => {
    const errorSoRow = approvalData.assignedUsers.find(
      item =>
        approvalData.escalationStatus === EscalationStatus.approval &&
        (!item.assignedUserEmails || item.assignedUserEmails.length === 0)
    );
    if (errorSoRow) {
      setError({ type: "assignedUserEmails", msg: "" });
      return false;
    }
    return true;
  };

  const validateTicketCategory = (): boolean => {
    const errorSoRow = approvalData.assignedUsers.find(
      item => approvalData.escalationStatus === EscalationStatus.approval && !item.ticketCategory
    );
    if (errorSoRow) {
      setError({ type: "ticketCategory", msg: "" });
      return false;
    }
    return true;
  };

  const validateApproveValues = () => {
    return (
      validateApproveItem("escalationTag") &&
      validateApproveItem("escalationLevel") &&
      validateComments("comments", 255) &&
      validateTicketCategory() &&
      validateAssign()
    );
  };

  return { error, setError, validateSoItems, validateFormValues, validateApproveValues };
};

export const getApiSoItemRows = (rows: ITicketDetailSoItem[]) => {
  return rows.filter(row => {
    const { rowNumber, ...newRow } = row;
    return !simpleObjectEqual(newRow, DEFAULT_SO_ITEM);
  });
};
