import { Button, TableColumn } from "@react-gcc-eds/core";
import { IEvaluationItem, EEvaluationKey } from "../api/seao-vendor-evaluation";
import StickyTable from "./sticky-table";
import "./evaluation-table.scss";
import { useState } from "react";
import { getEvaluationTableColumns } from "../tables/columns/evaluation-table";
import { simpleObjectEqual } from "../../utils/user-helpers";

interface IEvaluationTableBasicItem {
  label: string;
  desc: string;
  weight: number;
}

export interface IEvaluationTableRow extends IEvaluationTableBasicItem {
  score: number | undefined;
  comment: string;
  key: EEvaluationKey;
}

export interface IErrorMode {
  rowKey: EEvaluationKey;
  columnKey: keyof IEvaluationTableRow;
}

const SOFT_EVALUATION_MAP: { [key: string]: IEvaluationTableBasicItem } = {
  [EEvaluationKey.attitude]: {
    label: "2.2.1 Attitude",
    desc: "The Supplier's attitude and willingness to support Ericsson requests and share information.",
    weight: 1
  },
  [EEvaluationKey.availability]: {
    label: "2.2.2 Availability",
    desc: "The availability of contact persons at Supplier. Supplier flexibility to meet Ericsson requirements, select key achievements for scoring",
    weight: 1
  },
  [EEvaluationKey.organization]: {
    label: "2.2.3 Organization",
    desc: "The Suppliers organization/interface towards Ericsson.",
    weight: 1
  },
  [EEvaluationKey.communication]: {
    label: "2.2.4 Communication",
    desc: "The Supplier's ability to communicate (express in English both verbal and in writing)",
    weight: 2
  },
  [EEvaluationKey.escalationImprovements]: {
    label: "2.2.5 Continous Improvements (Escalation)",
    desc: "The supplier works proactively and makes suggestion to possible solutions if problems occur, critical escalation related.",
    weight: 1
  },
  [EEvaluationKey.processImprovements]: {
    label: "2.2.6 Continuous Improvements (Process)",
    desc: "The Supplier informs Ericsson in timely manner before major changes that may affect products and/or processes.",
    weight: 1
  },
  [EEvaluationKey.accountsPayable]: {
    label: "2.2.7 Accounts Receiveable/Payable",
    desc: "The Supplier provides accurate invoices and handles financial issues smoothly.",
    weight: 1
  },
  [EEvaluationKey.earlyWarning]: {
    label: "2.2.8 Early Warning",
    desc: "The Supplier sends pre-alerts if they foresee any potential problems.",
    weight: 2
  },
  [EEvaluationKey.responses]: {
    label: "2.2.9 Responses",
    desc: "The supplier sends clear and timely responses (on forecast, rework, CI/CO, daily delivery questions). Provide correct confirmation of VPO, shipping notification and customs declaration documents",
    weight: 1
  }
};

const EvaluationTable = ({
  appliedForm,
  error,
  onSave,
  disabled = false
}: {
  appliedForm: IEvaluationItem[];
  onSave: (formData: IEvaluationItem[]) => void;
  error?: IErrorMode;
  disabled?: boolean;
}) => {
  const [editedForm, setEditedForm] = useState<IEvaluationItem[]>(appliedForm);

  const onChange =
    (row: IEvaluationTableRow, key: keyof IEvaluationTableRow) => (value: string | number) => {
      setEditedForm(
        editedForm.map(item => (item.labelId === row.key ? { ...item, [key]: value } : item))
      );
    };

  const columns: TableColumn[] = getEvaluationTableColumns(
    onChange,
    (row, key) => {
      return error ? error.rowKey === row.key && error.columnKey === key : false;
    },
    disabled
  );

  const rows: IEvaluationTableRow[] = editedForm.map(({ labelId, score, comment }) => ({
    label: SOFT_EVALUATION_MAP[labelId]?.label || "",
    desc: SOFT_EVALUATION_MAP[labelId]?.desc || "",
    score,
    comment,
    weight: SOFT_EVALUATION_MAP[labelId]?.weight || 0,
    key: labelId
  }));

  return (
    <StickyTable
      className="eva-table"
      columns={columns}
      rows={rows}
      topButtons={
        <Button
          primary
          disabled={disabled || simpleObjectEqual(appliedForm, editedForm)}
          onClick={() => onSave(editedForm)}
        >
          Save
        </Button>
      }
    />
  );
};

export default EvaluationTable;
