import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import AppShell from "./app-shell";
import { useAppState } from "./app-state";
import { useAppSettings } from "./utils/app-settings";
import { useMsal } from "@azure/msal-react";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE || "");

const AppWrapper = () => {
  const [appState] = useAppState();
  const { readAppSettings } = useAppSettings();
  const { instance } = useMsal();

  useEffect(() => {
    readAppSettings();
  }, []);

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then(tokenResponse => {
        if (!tokenResponse) {
          const accounts = instance.getAllAccounts();
          if (accounts.length === 0) {
            // No user signed in
            instance.loginRedirect();
          }
        } else {
          console.log("Authenticated!");
        }
      })
      .catch(err => {
        // Handle error
        debugger;
        console.error("FATAL ERROR", err);
      });
  }, []);

  // We must ensure settings are read before rendering the app, the api url is read from settings
  if (!appState.settings || !instance.getAllAccounts().length) {
    return null;
  }

  return (
    <Switch>
      <Route path="*">
        {<AppShell version={appState.settings.version} apiUrl={appState.settings.apiUrl} />}
      </Route>
    </Switch>
  );
};

export default AppWrapper;
