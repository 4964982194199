import { Button, Tile, useNotifications } from "@react-gcc-eds/core";
import View from "../../views/app-view";
import { usePendingWorkGuard } from "../../components/pending-work-guard";
import { useEffect, useMemo } from "react";
import getEFAdminColumn from "../grid/views-columns/ef-admin";
import { useGrid } from "../../components/grid/grid";
import FullScreenPanel from "../../components/full-screen-panel";
import Loader from "../../components/loader";
import "./admin-view.scss";
import { useTableEditingWithEditedFlag } from "../../helpers/edit-cell-helper";
import { IEfUserItem, useGetAllAvailableUsers, useUpdateEfUser } from "../api/ef-user";

const EFAdminView = () => {
  const [userRoleList, loadingUserRoleList, getUserRoleList] = useGetAllAvailableUsers();
  const [updateUserRolesData, loadingUpdateUserRoles, updateUserRoles] = useUpdateEfUser();
  const { createNotification } = useNotifications();
  const { PendingWorkGuard } = usePendingWorkGuard();

  useEffect(() => {
    getUserRoleList();
  }, []);

  //table edit
  const { editedCells, pendingChanges, onCellChanged, undoChanges, saveChanges } =
    useTableEditingWithEditedFlag<IEfUserItem>(node => node.data.id);

  //table display
  const columns = useMemo(
    () =>
      getEFAdminColumn({
        onCellChanged
      }),
    []
  );

  const { renderTable } = useGrid<IEfUserItem>({
    columns,
    clientModel: {
      data: userRoleList || [],
      rowKeySelector: data => data.id.toString()
    }
  });

  //save
  const handleSave = () => {
    const editData = editedCells.map(cell => cell.data);
    updateUserRoles({ efUserInfos: editData });
  };

  useEffect(() => {
    if (updateUserRolesData) {
      const { success } = updateUserRolesData;
      if (success) {
        saveChanges();
        createNotification(`Success`, "Update success!");
      }
    }
  }, [updateUserRolesData]);

  return (
    <View breadCrumbs={[{ text: "Escalation Management" }, { text: "Admin" }]}>
      <Tile title="Admin" className="ef-admin">
        <div className="ef-admin-header">
          {pendingChanges && (
            <>
              <Button onClick={undoChanges}>Reset</Button>
              <Button primary onClick={handleSave} loading={loadingUpdateUserRoles}>
                Save
              </Button>
            </>
          )}
        </div>
        {loadingUserRoleList ? (
          <FullScreenPanel>
            <Loader noTile />
          </FullScreenPanel>
        ) : (
          <div className="ef-admin-table">{renderTable()}</div>
        )}
        <PendingWorkGuard
          when={pendingChanges}
          message={pendingChanges ? "You have unsaved work." : "You have rows selected."}
        />
      </Tile>
    </View>
  );
};

export default EFAdminView;
