import { useEffect, useMemo } from "react";
import { getGlobalOpenOrderColumns } from "../../grid/views-columns/global-open-order";
import {
  IGlobalOpenOrderFilterItem,
  IGlobalOpenOrderItem,
  IGlobalOpenOrderLookupItem,
  IPagedAnswer,
  useExportGlobalOpenOrders
} from "../../api/tableau";
import FullScreenPanel from "../../../components/full-screen-panel";
import Loader from "../../../components/loader";
import "./global-open-order-editor.scss";
import CustomerPagination from "./customer-pagination";
import { useGrid } from "../../../components/grid/grid";
import { Button, Tile, Loader as ELoader } from "@react-gcc-eds/core";
import {
  TableId,
  ViewId,
  useTablePreferencesHandler
} from "../../../helpers/table-preferences-handler";
import { useHistory } from "react-router";
import { useAppState } from "../../../app-state";
import Export from "../../../components/export";

export interface IFilterValuesModel {
  name: string;
  values: string[] | string | null;
}

const GlobalOpenOrderEditor = ({
  pageSize,
  total,
  lookup,
  tableData,
  onFilterChange,
  onSortChange,
  onApplyPage,
  page,
  loadingTable,
  apiFilters
}: {
  loadingTable: boolean;
  pageSize: number;
  total: number;
  lookup: IGlobalOpenOrderLookupItem | undefined;
  tableData: IPagedAnswer<IGlobalOpenOrderItem[]>;
  onFilterChange: (data: IFilterValuesModel[]) => void;
  onSortChange: (filedId: string | undefined, order: "asc" | "desc" | undefined) => void;
  onApplyPage: (page: number) => void;
  page: number;
  apiFilters: IGlobalOpenOrderFilterItem | undefined;
}) => {
  const columns = useMemo(() => getGlobalOpenOrderColumns({ lookup }), [lookup]);
  const history = useHistory();
  const [appState] = useAppState();

  useEffect(() => {
    hideOverlay();
  }, [tableData]);

  const { preferencesHandler } = useTablePreferencesHandler(ViewId.VIS_GLOBAL, TableId.VIS_GLOBAL);

  const { renderTable, showLoadingOverlay, hideOverlay } = useGrid<IGlobalOpenOrderItem>({
    columns,
    rowGroupingDisplayType: "custom",
    clientModel: {
      data: tableData?.data,
      rowKeySelector: data => data.id.toString()
    },
    groupHideOpenParents: true,
    groupDefaultExpanded: 5,
    loadingOverlayComponent: () => (
      <FullScreenPanel>
        <Loader />
      </FullScreenPanel>
    ),
    onColumnFilterChanged: event => {
      const filteredValues: IFilterValuesModel[] = [];

      const filterModel = event.api.getFilterModel();
      const { soCreatedOnDate } = filterModel;
      Object.entries(filterModel).forEach(([colKey, colValues]) => {
        const columnDefs = event.columnApi.getColumn(colKey)?.getColDef();
        const colName = columnDefs?.headerName || columnDefs?.field;
        const { filterType } = colValues;
        if (colName !== undefined) {
          if (filterType === "set") {
            filteredValues.push({ name: colName, values: colValues.values });
          } else if (filterType === "text") {
            filteredValues.push({ name: colName, values: colValues.filter });
          }
        }
      });
      if (soCreatedOnDate) {
        if (soCreatedOnDate.type === "lessThan" || soCreatedOnDate.type === "lessThanOrEqual") {
          filteredValues.push({ name: "soCreatedOnDateFrom", values: null });
          filteredValues.push({ name: "soCreatedOnDateTo", values: soCreatedOnDate.dateFrom });
        } else {
          filteredValues.push({ name: "soCreatedOnDateFrom", values: soCreatedOnDate.dateFrom });
          filteredValues.push({ name: "soCreatedOnDateTo", values: soCreatedOnDate.dateTo });
        }
      }
      onFilterChange(filteredValues);
      showLoadingOverlay();
    },
    onSortChanged: (colId, sortType) => {
      showLoadingOverlay();
      onSortChange(colId && colId.startsWith("Group-") ? colId.slice(6) : colId, sortType);
    },
    preferencesHandler,
    redirectFilters: (history.location.state as any)?.materialDesc
      ? { materialDescs: [(history.location.state as any).materialDesc] }
      : undefined,
    showColumnsFiltersInfoAt: "right"
  });

  const handleApplyPage = (offset: number) => {
    showLoadingOverlay();
    onApplyPage(offset);
  };

  return (
    <Tile
      className="global-open-order"
      title="Global Open Order List"
      actions={
        loadingTable ? (
          <Button loading />
        ) : (
          <Export<IGlobalOpenOrderFilterItem | undefined>
            downloader={useExportGlobalOpenOrders}
            element={<Button>Export</Button>}
            params={apiFilters}
          />
        )
      }
    >
      {loadingTable ? (
        <FullScreenPanel>
          <Loader />
        </FullScreenPanel>
      ) : (
        <div className="global-open-order-table">{renderTable()}</div>
      )}
      <CustomerPagination
        pageSize={pageSize}
        total={total}
        onApplyPage={handleApplyPage}
        page={page}
      />
    </Tile>
  );
};

export default GlobalOpenOrderEditor;
