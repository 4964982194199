import { Icon, IDropdownItem, ISelectItem, MultiPanelTile } from "@react-gcc-eds/core";
import { useCallback, useEffect, useState } from "react";
import ContentLoader from "../../components/content-loader";
import View from "../../views/app-view";
import {
  ESubViewType,
  ISubAllFilterItems,
  ISubCustomerInfoItem,
  ISubLookupItems,
  useGetEpHistory,
  useGetEpLookup
} from "../api/subscription";
import PerformanceFilter from "../component/performance-filter";
import PerformanceHistory from "../component/performance-history";
import PerformanceMonthly from "../component/performance-monthly";
import "./escalation-performance-view.scss";
import { filterAllLookupItems, uniqueLookupItems } from "../helper/lookup-filters";

export const DEFAULT_FILTER: ISubAllFilterItems = {
  displayView: { title: ESubViewType.month, value: ESubViewType.month },
  timeFrame: { title: "12", value: 12 },
  ticketCategory: [],
  productReportingGroup: [],
  groupSupplyUnit: [],
  customerUnit: [],
  customer: [],
  sippOrNonSipp: [
    { title: "Sipp", value: "sipp" },
    { title: "NonSipp", value: "nonSipp" }
  ],
  forecasted: [
    { title: "Yes", value: "yes" },
    { title: "No", value: "no" }
  ]
};

const EscalationPerformanceView = () => {
  //display
  const [epLookupData, loadingEpLookup, getEpLookup] = useGetEpLookup();
  const [historyData, loadingHistoryData, getHistory] = useGetEpHistory();

  useEffect(() => {
    getEpLookup();
  }, []);

  useEffect(() => {
    if (epLookupData) {
      const { displayViews } = epLookupData;
      if (displayViews[0]) {
        const { defaultTimeFrame, viewType } = displayViews[0];
        getHistory({
          displayViewType: viewType,
          timeFrame: defaultTimeFrame
        });
      }
    }
  }, [epLookupData]);

  //filter
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const toggleFilters = () => setShowFilters(show => !show);

  const { defaultFilters } = useDefaultFilters(epLookupData);
  const { appliedFilters, setAppliedFilters } = useAppliedFilters(defaultFilters);

  // make a unique list of items based on provided property
  const lookupItems = useCallback(
    (
      items: ISubCustomerInfoItem[],
      selector: (item: ISubCustomerInfoItem) => string
    ): IDropdownItem[] => uniqueLookupItems(items, selector),
    []
  );

  // filter lookup list cascading provided filters
  const filterLookupItems = useCallback(
    (filters: ((item: ISubCustomerInfoItem) => boolean)[]): ISubCustomerInfoItem[] =>
      filterAllLookupItems(filters, epLookupData.groupSupplyUnitOptions),
    [epLookupData]
  );

  const dropdownToList = (list: ISelectItem[]) => list.map(({ value }) => value);
  const dropdownToApi = (list: ISelectItem[]) =>
    list.length === 0 ? undefined : dropdownToList(list);

  const handleSearch = (filter: ISubAllFilterItems) => {
    setAppliedFilters(filter);
    const {
      ticketCategory,
      productReportingGroup,
      groupSupplyUnit,
      customer,
      customerUnit,
      sippOrNonSipp,
      forecasted,
      displayView,
      timeFrame
    } = filter;
    getHistory({
      ticketCategories: dropdownToApi(ticketCategory),
      productReportingGroups: dropdownToApi(productReportingGroup),
      groupSupplyUnits: dropdownToApi(groupSupplyUnit),
      customerUnits: dropdownToApi(customerUnit),
      customers: dropdownToApi(customer),
      sippOrNonSipp: dropdownToApi(sippOrNonSipp),
      forecasted: dropdownToApi(forecasted),
      displayViewType: displayView.value,
      timeFrame: timeFrame.value
    });
  };

  return (
    <View
      breadCrumbs={[
        { text: "Escalation Management" },
        { text: "Visualization" },
        { text: "Escalation Performance" }
      ]}
    >
      <MultiPanelTile
        className="escalation-performance"
        centerTitle="Monthly"
        centerLeftIcon={
          <Icon name="filter" onClick={toggleFilters} style={{ cursor: "pointer" }} />
        }
        centerContent={
          <PerformanceMonthly
            monthlyData={historyData?.monthly}
            loading={loadingHistoryData || loadingEpLookup}
          />
        }
        leftTitle="Selections"
        leftClassName="scrollable-pane"
        leftContent={
          <ContentLoader
            loading={loadingEpLookup}
            data={epLookupData}
            render={
              epLookupData ? (
                <PerformanceFilter
                  allAvailableItems={epLookupData}
                  defaultFilters={defaultFilters}
                  appliedFilters={appliedFilters}
                  onSubmit={handleSearch}
                  filterLookupItems={filterLookupItems}
                  lookupItems={lookupItems}
                />
              ) : (
                <></>
              )
            }
          />
        }
        leftVisible={showFilters}
        leftActions={<Icon name="cross" onClick={toggleFilters} style={{ cursor: "pointer" }} />}
        rightTitle="History"
        rightContent={
          <PerformanceHistory
            historyData={historyData?.history}
            loading={loadingHistoryData || loadingEpLookup}
          />
        }
        rightVisible
      />
    </View>
  );
};

const useDefaultFilters = (lookup: ISubLookupItems) => {
  const [defaultFilters, setDefaultFilters] = useState<ISubAllFilterItems>(DEFAULT_FILTER);

  useEffect(() => {
    if (lookup) {
      const { displayViews, groupSupplyUnitOptions } = lookup;
      if (displayViews[0]) {
        const { defaultTimeFrame, viewType } = displayViews[0];

        setDefaultFilters(f => ({
          ...f,
          displayView: { title: viewType, value: viewType },
          timeFrame: { title: defaultTimeFrame.toString(), value: defaultTimeFrame },
          groupSupplyUnit: uniqueLookupItems(groupSupplyUnitOptions, f => f.groupSupplyUnit),
          ticketCategory: uniqueLookupItems(groupSupplyUnitOptions, f => f.ticketCategory),
          productReportingGroup: uniqueLookupItems(
            groupSupplyUnitOptions,
            f => f.productReportingGroup
          ),
          customerUnit: uniqueLookupItems(groupSupplyUnitOptions, f => f.customerUnit),
          customer: uniqueLookupItems(groupSupplyUnitOptions, f => f.customer)
        }));
      }
    }
  }, [lookup]);

  return { defaultFilters };
};

const useAppliedFilters = (defaultFilters: ISubAllFilterItems) => {
  const [appliedFilters, setAppliedFilters] = useState<ISubAllFilterItems>(DEFAULT_FILTER);

  useEffect(() => {
    if (defaultFilters) {
      setAppliedFilters(defaultFilters);
    }
  }, [defaultFilters]);

  return { appliedFilters, setAppliedFilters };
};

export default EscalationPerformanceView;
