import { TableColumn } from "@react-gcc-eds/core";
import { ESEAOResultLevel, ISEAOResultTableRow } from "../../component/result/result-table";

export enum EResultColor {
  blue = "#1174E6",
  green = "#329864",
  yellow = "#dcb000",
  red = "#ED0E00"
}

const text = (key: keyof ISEAOResultTableRow, header: string) => {
  return {
    key,
    header,
    Cell: (row: any) => <div className="result-table-cell-text">{row[key]}</div>
  };
};

const colorCell = (key: keyof ISEAOResultTableRow, header: string): TableColumn => ({
  key: key as string,
  header,
  Cell: row => {
    const RESULT_CELL_COLOR_MAP = {
      [ESEAOResultLevel.excellent]: EResultColor.blue,
      [ESEAOResultLevel.good]: EResultColor.green,
      [ESEAOResultLevel.conditional]: EResultColor.yellow,
      [ESEAOResultLevel.unacceptable]: EResultColor.red
    };
    const value = (row as ISEAOResultTableRow)[key] as ESEAOResultLevel;
    return (
      <div
        className="result-table-cell-color"
        style={{ backgroundColor: RESULT_CELL_COLOR_MAP[value] }}
      >
        {value}
      </div>
    );
  }
});

export const getSEAOResultTableColumns = (): TableColumn[] => [
  text("vendorName", "Vendor Name"),
  colorCell("serviceSupport", "Service & Support"),
  colorCell("deliveryRequest", "On Time Delivery vs Request-PO"),
  colorCell("deliveryConfirmed", "On Time Delivery vs First Confirmed-PO"),
  colorCell("ltwf", "Actual and Contracted LTWF")
];

export const getIndiaResultTableColumns = (): TableColumn[] => [
  text("vendorName", "Vendor Name"),
  colorCell("deliveryRequest", "On Time Delivery vs Request-PO"),
  colorCell("deliveryConfirmed", "On Time Delivery vs First Confirmed-PO"),
  colorCell("ltwf", "Actual and Contracted LTWF")
];
