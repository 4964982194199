import { ICellRendererParams } from "ag-grid-community";
import { IColumnDefinition } from "../../../components/grid/grid";
import { dateCell } from "../../../helpers/grid-cells";
import { Icon, Loader } from "@react-gcc-eds/core";
import { EscalationPriority } from "../../helper";
import { customTextCell } from "../../helpers/ef-table";
import ProgressCircle from "../../component/progress-circle";
import { People } from "@microsoft/mgt-react";

export const getFeedbackColumns = ({
  hasEscalationLevel
}: {
  hasEscalationLevel: boolean;
}): IColumnDefinition[] => {
  const renderPriority = (priority: EscalationPriority) => {
    switch (priority) {
      case EscalationPriority.urgent:
        return <Icon style={{ color: "red" }} name={"notification-ring"} />;
      case EscalationPriority.important:
        return <div className="feed-icon-important">!</div>;
      case EscalationPriority.low:
        return <Icon name={"arrow-down"} />;
      case EscalationPriority.medium:
        return <Icon style={{ color: "green" }} name={"alarm-level6"} />;
    }
  };

  return [
    customTextCell("ticketNumber", "Ticket number"),
    customTextCell("customer", "Customer", false, true, 200),
    customTextCell("customerRegion", "Customer region", false, true),
    ...(hasEscalationLevel
      ? [customTextCell("escalationLevel", "Escalation level", false, true)]
      : []),
    customTextCell("ticketCategory", "Ticket category", false, true),
    customTextCell("escalationRegion", "Escalation region", false, true, 180),
    { ...dateCell("startDate", "Start date", false, true), minWidth: 180 },
    { ...dateCell("dueDate", "Due date", false, true), minWidth: 180 },
    {
      ...customTextCell("progress", "Progress", false, true),
      cellRenderer: (p: ICellRendererParams) => {
        const { value } = p;
        return <ProgressCircle progress={value} />;
      }
    },
    {
      ...customTextCell("taskAssignedUsers", "Assignees", false, true, 180),
      cellRenderer: (p: ICellRendererParams) => {
        return (
          <People
            userIds={p.data.taskAssignedUsers}
            showMax={10}
            templateContext={{
              loading: <Loader />,
              noData: "1"
            }}
            onClick={e => e.stopPropagation()}
          />
        );
      }
    },
    {
      ...customTextCell("priority", "Priority", false, true),
      cellRenderer: (p: ICellRendererParams) => {
        const { value } = p;
        return <div className="escalation-feedback-table-priority">{renderPriority(value)}</div>;
      }
    }
  ];
};
