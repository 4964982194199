import { IDropdownItem, ISelectItem } from "@react-gcc-eds/core";
import MultiSelect from "./MultiSelect";
import { booleanSorter } from "../../utils";

export const multiFilterLabel = (available: any[], selected?: any[]) =>
  `${selected ? selected.length : available.length} of ${available.length} selected`;

const MultipleDropdownFilter = <DataType extends Object>({
  header = "",
  label,
  items,
  selectedItems,
  disabled,
  keySelector,
  onChange,
  onClose,
  onOpen
}: {
  header?: string;
  label: string;
  items: IDropdownItem<DataType>[];
  selectedItems: IDropdownItem<DataType>[];
  keySelector?: (v: object) => string | number;
  disabled?: boolean;
  onChange: (item: IDropdownItem<DataType>[]) => void;
  onClose?: () => void;
  onOpen?: () => void;
}) => {
  const SELECT_ALL = "_selectAll_";
  const multiFilterItems = (
    items: IDropdownItem<DataType>[],
    selectedItems: IDropdownItem<DataType>[]
  ) =>
    items
      .map(i => ({
        ...i,
        selected:
          selectedItems.find(s =>
            keySelector ? keySelector(s.value) === keySelector(i.value) : s.value === i.value
          ) !== undefined
      }))
      .sort(booleanSorter((i: { selected: boolean }) => i.selected));
  const addAll = (items: IDropdownItem<DataType>[], selectedItems: IDropdownItem<DataType>[]) => [
    {
      title: `${selectedItems.length === items.length ? "Unselect" : "Select"} all`,
      value: SELECT_ALL
    },
    ...items
  ];
  const onItemSelected = (selected: ISelectItem<DataType>[]) => {
    if (selected.find(i => (i.value as unknown as string) === SELECT_ALL)) {
      const disabledSelectedItems = selected.filter(item => item.disabled);
      const availableItems = items.filter(item => !item.disabled);
      if (selectedItems.length === items.length) {
        onChange(disabledSelectedItems);
      } else {
        onChange([...disabledSelectedItems, ...availableItems]);
      }
    } else {
      onChange(selected);
    }
  };
  return (
    <div className="multi-select">
      {" "}
      <label>{header}</label>{" "}
      <MultiSelect
        items={addAll(multiFilterItems(items, selectedItems), selectedItems)}
        selectedItems={selectedItems}
        label={label}
        disabled={disabled}
        onChange={onItemSelected}
        type="input-multi"
        onClose={onClose}
        onOpen={onOpen}
      />{" "}
    </div>
  );
};
export default MultipleDropdownFilter;
