import GaugeChart from "../../components/charts/gauge/GaugeChart";
import { EGaugeColor } from "../../components/charts/gauge/IGauge";
import ContentLoader from "../../components/content-loader";
import { ISubMonthData } from "../../visualization/api/subscription";
import PerformanceCard from "./performance-card";
import "./performance-monthly.scss";

export const multiFilterLabel = (available: Object[], selected?: Object[]) =>
  `${selected ? selected.length : available.length} of ${available.length} selected`;

const PerformanceMonthly = ({
  monthlyData,
  loading
}: {
  monthlyData: ISubMonthData | undefined;
  loading: boolean;
}) => {
  return (
    <div className="performance-monthly">
      <ContentLoader
        loading={loading}
        data={monthlyData}
        render={
          monthlyData ? (
            <>
              <PerformanceCard
                title="Escalation Status"
                content={
                  <GaugeChart
                    dataSettings={{
                      max: 100,
                      min: 0,
                      units: "%",
                      size: "large"
                    }}
                    value={monthlyData.historyEscalationStatus.closedPercentage}
                  />
                }
                bottomLeftTitle={monthlyData.historyEscalationStatus.closedCount.toString()}
                bottomLeftSubTitle="Closed"
                bottomRightTitle={monthlyData.historyEscalationStatus.total.toString()}
                bottomRightSubTitle="Total"
              />
              <PerformanceCard
                title="Handling Lead Time(Avg)"
                content={
                  <div>
                    <span className="performance-monthly-text-lg">
                      {monthlyData.handlingLeadTimeAvg.averageHandlingLeadTimeInDays}
                    </span>
                    <span className="performance-monthly-text-sm">day</span>
                  </div>
                }
              />
              <PerformanceCard
                title="Fulfillment Rate"
                content={
                  <GaugeChart
                    dataSettings={{
                      max: 100,
                      min: 0,
                      units: "%",
                      size: "large",
                      limits: [
                        {
                          from: 60,
                          to: 80,
                          color: EGaugeColor.orange
                        },
                        {
                          from: 0,
                          to: 60,
                          color: EGaugeColor.red
                        }
                      ]
                    }}
                    value={monthlyData.fulfillmentRate.fulfillPercentage}
                  />
                }
                bottomLeftTitle={monthlyData.fulfillmentRate.fulfillmentCount.toString()}
                bottomLeftSubTitle="Fulfillment"
                bottomRightTitle={monthlyData.fulfillmentRate.closedCount.toString()}
                bottomRightSubTitle="Closed"
              />
              <PerformanceCard
                title="MADate Improved(Avg)"
                content={
                  <div>
                    <span className="performance-monthly-text-lg">
                      {monthlyData.currentMaterialAvailableDateImproved.averageImprovedDays}
                    </span>
                    <span className="performance-monthly-text-sm">day</span>
                  </div>
                }
              />
            </>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export default PerformanceMonthly;
