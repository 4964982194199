import { IColumnDefinition } from "../../../components/grid/grid";
import { cell, dateCell } from "../../../helpers/grid-cells";
import { IEscalationDetailsLookupItem } from "../../api/tableau";

export const getEscalationDetailsColumns = ({
  lookup
}: {
  lookup: IEscalationDetailsLookupItem | undefined;
}): IColumnDefinition[] => {
  const noOrderCell = (field: string, header: string) => ({
    ...cell(field, header),
    sortable: false
  });
  const noOrderDateCell = (field: string, header: string) => ({
    ...dateCell(field, header),
    sortable: false
  });

  return [
    {
      ...cell("salesOrder", "SalesOrder"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.salesOrders
      }
    },
    {
      ...cell("item", "Item"),
      filter: ""
    },
    {
      ...cell("marketArea", "MA"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.marketAreas
      }
    },
    {
      ...cell("customerDesc", "Customer"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.customerDescs
      }
    },
    {
      ...cell("plant", "Plant"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.plants
      }
    },
    {
      ...cell("plantDesc", "PlantDesc"),
      initialHide: true,
      filter: ""
    },
    {
      ...cell("materialL1", "Material L1"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.materialL1s
      }
    },
    {
      ...cell("materialDesc", "MaterialDesc"),
      filter: ""
    },
    {
      ...cell("vendorDesc", "VendorDesc"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.vendorDescs
      }
    },
    {
      ...cell("vendor", "Vendor"),
      initialHide: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.vendors
      }
    },
    {
      ...cell("vpo", "VPO"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.vpos
      }
    },
    dateCell("escalationTagDate", "EscalationTagDate"),
    {
      ...dateCell("requestMad", "RequestMAD"),
      filter: ""
    },
    {
      ...cell("latestConfMad", "LatestConfMAD"),
      filter: ""
    },
    {
      ...cell("tagItem", "TagItem"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.tagItems
      }
    },
    {
      ...cell("orderQty", "OrderQty"),
      filter: ""
    },
    {
      ...cell("remainQty", "RemainQty"),
      filter: ""
    },
    {
      ...cell("handlingLt", "Handling LT"),
      filter: ""
    },
    {
      ...cell("oaGap", "OAgap"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: lookup?.oaGaps
      }
    },
    {
      ...cell("oaGapDays", "OAgapdays"),
      filter: ""
    },
    {
      ...cell("ltReduction", "LT Reduction"),
      filter: ""
    }
  ];
};
