import { useApiGet, useApiPost } from "../../api/api";
import { IActionResult } from "../../management/api/ticket";

export enum EEvaluationKey {
  attitude = 1,
  availability = 2,
  organization = 3,
  communication = 4,
  escalationImprovements = 5,
  processImprovements = 6,
  accountsPayable = 7,
  earlyWarning = 8,
  responses = 9
}

interface IGetEvaluationParam {
  vendorId: string;
  vendorName: string;
  snapshot: number;
}

export interface IEvaluationItem {
  comment: string;
  labelId: EEvaluationKey;
  score: number | undefined;
}

interface ISetEvaluationParam {
  evaluationList: IEvaluationItem[];
  vendor: string;
  snapshot: number;
}

const SEAO_VENDOR_EVALUATION = "/v1/supply-performance/vendor-evaluation/seao";

export const useGetEvaluation = () =>
  useApiGet<IEvaluationItem[], IGetEvaluationParam>(SEAO_VENDOR_EVALUATION + "/evaluation");

export const useSetEvaluation = () =>
  useApiPost<ISetEvaluationParam, IActionResult>(SEAO_VENDOR_EVALUATION + "/evaluation");
