import { IColumnDefinition } from "../../../components/grid/grid";
import { numericCell } from "../../../helpers/grid-cells";
import { customTextCell } from "../../../management/helpers/ef-table";

export const getPerformanceTrendColumns = (): IColumnDefinition[] => {
  return [
    customTextCell("customerUnit", "Customer Unit", false, true),
    customTextCell("groupSupplyUnit", "Group supply unit", false, true),
    numericCell("totalEscalation", "Total Escalation"),
    numericCell("closedEscalation", "Closed Escalation"),
    numericCell("handlingLtForFinishedEscalation", "Handling LT For Finished Escalation"),
    numericCell("maDateImproved", "MADate Improved Status")
  ];
};
