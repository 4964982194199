import { Tile, useNotifications } from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import ContentLoader from "../../components/content-loader";
import { IListSideItem } from "../../components/listbuilder/IListBuilder";
import View from "../../views/app-view";
import { ESubType, useGetSubscription, useUpdateSubscription } from "../api/subscription";
import ListBuilderCard from "../component/list-builder-card";
import "./subscription-view.scss";
import { useGetSubscriptionOptions } from "../../management/api/lookup";

const SubscriptionView = () => {
  //common
  const { createNotification } = useNotifications();

  //display
  const [selectionData, loadingSelectionData, getSelectionData] = useGetSubscriptionOptions();
  const [subscriptionData, loadingSubscriptionData, getSubscription] = useGetSubscription();
  const [subType, setSubType] = useState<ESubType>();

  useEffect(() => {
    getSelectionData();
    getSubscription();
  }, []);

  const apiToListSideItem = (data: string[] | null): IListSideItem[] =>
    data ? data.map(item => ({ title: item })) : [];

  //save
  const [updateSubscriptionData, loadingUpdateSubscription, updateSubscription] =
    useUpdateSubscription();

  const onSave = (type: ESubType, values: string[]) => {
    setSubType(type);
    updateSubscription({ ...subscriptionData, [type]: values });
  };

  useEffect(() => {
    if (updateSubscriptionData) {
      const { success } = updateSubscriptionData;
      if (success) {
        createNotification("Save!", "Save successful!");
      }
      getSubscription();
    }
  }, [updateSubscriptionData]);

  return (
    <View
      breadCrumbs={[
        { text: "Escalation Management" },
        { text: "Visualization" },
        { text: "Subscription" }
      ]}
    >
      <Tile
        title="Subscription"
        subtitle="You can subscribe email notification through the following dimensions.(The reset will take effect next time)"
        className="subscription"
      >
        <ContentLoader
          loading={loadingSubscriptionData || loadingSelectionData}
          data={selectionData && subscriptionData}
          render={
            selectionData && subscriptionData ? (
              <>
                <ListBuilderCard
                  title="Ticket Category Subscription"
                  totalList={apiToListSideItem(selectionData.ticketCategories)}
                  selectedList={apiToListSideItem(subscriptionData.ticketCategories)}
                  onSubmit={selectedList => onSave(ESubType.ticketCategories, selectedList)}
                  onSaveLoading={subType === ESubType.ticketCategories && loadingUpdateSubscription}
                />
                <ListBuilderCard
                  title="Escalation Region Subscription"
                  totalList={apiToListSideItem(selectionData.escalationRegions)}
                  selectedList={apiToListSideItem(subscriptionData.escalationRegions)}
                  onSubmit={selectedList => onSave(ESubType.escalationRegions, selectedList)}
                  onSaveLoading={
                    subType === ESubType.escalationRegions && loadingUpdateSubscription
                  }
                />
                <ListBuilderCard
                  title="Customer Subscription"
                  totalList={apiToListSideItem(selectionData.customers)}
                  selectedList={apiToListSideItem(subscriptionData.customers)}
                  onSubmit={selectedList => onSave(ESubType.customers, selectedList)}
                  onSaveLoading={subType === ESubType.customers && loadingUpdateSubscription}
                />
              </>
            ) : (
              <></>
            )
          }
        />
      </Tile>
    </View>
  );
};

export default SubscriptionView;
