import { Column, FormElement, FormGroup, Radiobutton, Row, TextArea } from "@react-gcc-eds/core";
import SingleSelect from "../../../components/select/SingleSelect";
import NumberField from "../../../components/number-field";
import "./ticket-material-list.scss";
import { IGlobalStockItem } from "../../api/ticket";
import { renderLabel } from "../../helpers/ef-ticket";
import { ITicketError } from "../../helpers/ef-validate";

const TicketMaterialList = ({
  formValues,
  onChange,
  disabled,
  options,
  error
}: {
  formValues: IGlobalStockItem[];
  onChange?: (value: IGlobalStockItem[]) => void;
  disabled: boolean;
  options: string[];
  error?: ITicketError;
}) => {
  const handleChange =
    (key: keyof IGlobalStockItem, material: string) => (value: string | number | boolean) => {
      onChange &&
        onChange(
          formValues.map(item => (material === item.material ? { ...item, [key]: value } : item))
        );
    };

  const boolCheckBoxRender = (value: boolean | undefined, onChange: (value: boolean) => void) => {
    return (
      <div style={{ display: "flex", width: "100px", position: "relative" }}>
        <Radiobutton
          disabled={disabled}
          text="Yes"
          value={value === true}
          onChange={() => onChange(true)}
        />
        <Radiobutton
          disabled={disabled}
          text="No"
          value={value === false}
          onChange={() => onChange(false)}
        />
      </div>
    );
  };

  const getSegmentationLevel = (sipp?: boolean, forested?: boolean) => {
    if (sipp === undefined || forested === undefined) {
      return "N/A";
    } else {
      return sipp
        ? forested
          ? "Level1"
          : "No segmentation type"
        : forested
        ? "Level2"
        : "Level 3";
    }
  };

  const getSegmentationLT = (sipp?: boolean, forested?: boolean) => {
    if (sipp === undefined || forested === undefined) {
      return -2; // -2 means need to check others
    } else {
      return sipp ? (forested ? 14 : -1) : forested ? 63 : 120;
    }
  };

  const getSegmentationLTText = (sipp?: boolean, forested?: boolean) => {
    const lt = getSegmentationLT(sipp, forested);
    return lt === -2 ? "N/A" : lt === -1 ? "--" : lt + " days";
  };

  const translateNull = (data: any) => (data === null ? undefined : data);

  const validateRequestLT = (item: IGlobalStockItem) =>
    item.minRequestLT &&
    item.minRequestLT <
      getSegmentationLT(translateNull(item.sippOrNonSipp), translateNull(item.forecasted));

  const showWarning = () => formValues.find(v => validateRequestLT(v));

  return (
    <div style={{ position: "relative" }}>
      {showWarning() ? (
        <div className="global-stock-alert">
          Your materials in submitted SO are NOT align with segmentation LT critical
        </div>
      ) : (
        <></>
      )}
      <div>
        {formValues.map(i => (
          <FormGroup key={`${i.material}`} title={`Material: ${i.material}`}>
            <FormElement>
              <Row className="ticket-base-form-approval-row">
                <div style={{ marginBottom: "16px" }}>Global stock:</div>
              </Row>
              <Row className="ticket-base-form-approval-row">
                <Column md={4}>
                  {renderLabel("Global Stock Check", error && error.type === "globalStockCheck")}
                  {boolCheckBoxRender(
                    translateNull(i.globalStockCheck),
                    handleChange("globalStockCheck", i.material)
                  )}
                </Column>
                <Column md={4}>
                  {renderLabel("Borrow from", error && error.type === "borrowFrom")}
                  {i.globalStockCheck && (
                    <SingleSelect
                      className="global-table-select"
                      items={options.map(item => ({ title: item, value: item }))}
                      onChange={item => handleChange("borrowFrom", i.material)(item.value)}
                      placeholder="Please Select an option"
                      value={i.borrowFrom}
                      disabled={disabled}
                      type="input-single"
                      clickItemTextChange={false}
                    />
                  )}
                </Column>
                <Column md={4}>
                  {renderLabel("Borrow Quantity", error && error.type === "borrowQuantity")}
                  {i.globalStockCheck && (
                    <NumberField
                      value={i.borrowQuantity}
                      disabled={disabled}
                      className="global-table-number"
                      onChange={handleChange("borrowQuantity", i.material)}
                    />
                  )}
                </Column>
              </Row>
              <div>
                {renderLabel(
                  "Pre escalation feedback",
                  error && error.type === "preEscalationFeedback",
                  error?.msg
                )}
                <TextArea
                  onChange={item => {
                    if (item.length > 255) return;
                    handleChange("preEscalationFeedback", i.material)(item);
                  }}
                  value={i.preEscalationFeedback}
                  disabled={disabled}
                />
              </div>
            </FormElement>
            <FormElement>
              <Row className="ticket-base-form-approval-row">
                <div style={{ marginBottom: "16px" }}>Segmentation:</div>
              </Row>
              <Row className="ticket-base-form-approval-row">
                <Column md={4}>
                  {renderLabel("SIPP", error && error.type === "sippOrNonSipp")}
                  {boolCheckBoxRender(
                    translateNull(i.sippOrNonSipp),
                    handleChange("sippOrNonSipp", i.material)
                  )}
                </Column>
                <Column md={4}>
                  {renderLabel("Within forecasted", error && error.type === "forecasted")}
                  {boolCheckBoxRender(
                    translateNull(i.forecasted),
                    handleChange("forecasted", i.material)
                  )}
                </Column>
              </Row>
              <Row className="ticket-base-form-approval-row">
                <Column md={4}>
                  <label>Segmentation level</label>
                  {getSegmentationLevel(
                    translateNull(i.sippOrNonSipp),
                    translateNull(i.forecasted)
                  )}
                </Column>
                <Column md={4}>
                  <label>Segmentation LT</label>
                  {getSegmentationLTText(
                    translateNull(i.sippOrNonSipp),
                    translateNull(i.forecasted)
                  )}
                </Column>
                <Column md={4} style={{ color: "red" }}>
                  {validateRequestLT(i)
                    ? "Please check the SO item for this material that not meet the escalation criteria"
                    : ""}
                </Column>
              </Row>
            </FormElement>
          </FormGroup>
        ))}
      </div>
    </div>
  );
};

export default TicketMaterialList;
