import { Icon, Tooltip } from "@react-gcc-eds/core";
import "./index.scss";

const HelpTip = ({ title = "Help & Support", tooltip }: { title?: string; tooltip?: string }) => {
  return (
    <Tooltip
      content={
        <>
          <div>{tooltip}</div>
        </>
      }
      className="helpTip"
      position="bottom-end"
    >
      <div className="helpTip-content">
        <Icon name="help" className="helpTip-content-icon" />
        <span className="helpTip-content-label">{title}</span>
      </div>
    </Tooltip>
  );
};
export default HelpTip;
