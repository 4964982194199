import { TUserBaseInfo } from "../../../types";
import StickyTable from "../../../spc/component/sticky-table";
import "./assign-table.scss";
import { getEfAssignColumns } from "../../grid/views-columns/ef-assign";
import { ITicketError } from "../../helpers/ef-validate";
import { IEscalationLookup } from "../../api/lookup";
import { IAssignedUserEditedItem } from "../../api/ticket";
import { useMemo } from "react";

const AssignTable = ({
  formValues,
  onChange,
  disabled,
  error,
  userList,
  dropdownItems
}: {
  formValues: IAssignedUserEditedItem[];
  onChange: (value: IAssignedUserEditedItem[]) => void;
  disabled: boolean;
  error: ITicketError | undefined;
  userList: TUserBaseInfo[];
  dropdownItems: IEscalationLookup;
}) => {
  const handleChange =
    (key: keyof IAssignedUserEditedItem, rowNumber: number) => (value: TUserBaseInfo[]) => {
      onChange &&
        onChange(
          formValues.map((item, index) => (rowNumber === index ? { ...item, [key]: value } : item))
        );
    };

  const columns = useMemo(() => {
    return getEfAssignColumns({
      error,
      disabled,
      userList,
      dropdownItems,
      onChange: handleChange
    });
  }, [error, disabled, userList, dropdownItems, formValues]);

  return (
    <StickyTable
      rows={formValues}
      columns={columns}
      widths={["30%", "40%", "40%"]}
      className="approve-assign-table"
    />
  );
};

export default AssignTable;
