import { ISelectItem, Tile } from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import View from "../../views/app-view";
import {
  ESubViewType,
  ICUPerformanceItem,
  IDisplayTimeItem,
  useGetSubCUPerformance,
  useGetSubDisplayViews
} from "../api/subscription";
import DisplayTimeFilter from "../component/display-time-filter";
import "./performance-CU-level-view.scss";
import { useGrid } from "../../components/grid/grid";
import { getPerformanceTrendColumns } from "../grid/views-columns/performance-trend";
import Loader from "../../components/loader";

const PerformanceCULevelView = () => {
  const [cUPerformanceData, loadingCUPerformanceData, gtSubCUPerformance] =
    useGetSubCUPerformance();
  const [displayViewsData, loadingDisplayViewsData, getDisplayViews] = useGetSubDisplayViews();
  const [editedFilters, setEditedFilters] = useState<IDisplayTimeItem>({
    displayViewType: ESubViewType.month,
    timeFrame: 6
  });

  useEffect(() => {
    getDisplayViews();
  }, []);

  //table
  const columns = getPerformanceTrendColumns();
  const { renderTable } = useGrid<ICUPerformanceItem>({
    columns,
    clientModel: {
      data: cUPerformanceData,
      rowKeySelector: data => data.customerUnit.toString()
    },
    showColumnsFiltersInfoAt: "left"
  });

  useEffect(() => {
    gtSubCUPerformance({
      displayViewType: editedFilters.displayViewType,
      timeFrame: editedFilters.timeFrame
    });
  }, []);

  const onChange = (key: keyof IDisplayTimeItem) => (item: ISelectItem) =>
    setEditedFilters(filters => ({
      ...filters,
      [key]: item.value
    }));

  const onSearch = () => {
    gtSubCUPerformance({
      displayViewType: editedFilters.displayViewType,
      timeFrame: editedFilters.timeFrame
    });
  };

  useEffect(() => {
    if (displayViewsData) {
      if (displayViewsData[0]) {
        const { defaultTimeFrame, viewType } = displayViewsData[0];
        setEditedFilters(f => ({ ...f, displayViewType: viewType, timeFrame: defaultTimeFrame }));
      }
    }
  }, [displayViewsData]);

  return (
    <View
      breadCrumbs={[
        { text: "Escalation Management" },
        { text: "Visualization" },
        { text: "Performance Trend" }
      ]}
    >
      <Tile
        title="Performance Trend"
        className="performance-CU-level"
        actions={
          <DisplayTimeFilter
            allAvailableItems={displayViewsData || []}
            editedFilters={editedFilters}
            onChange={onChange}
            onSearch={onSearch}
          />
        }
      >
        {loadingCUPerformanceData ? (
          <Loader noTile />
        ) : (
          <div className="performance-CU-level-table">{renderTable()}</div>
        )}
      </Tile>
    </View>
  );
};

export default PerformanceCULevelView;
