import { ReactNode, useEffect, useState } from "react";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { roundNumberToLocale } from "../../utils";
import { useChartHelpers } from "./chart-helpers";
import LegendPills from "./legend";
import { IDonutChartData } from "./types";

const DonutChart = ({
  data,
  unitOfMeasure,
  synced = false,
  syncedSeries = undefined,
  onSeriesSelected,
  valueDecimals = 2,
  size = 1,
  hideLegends,
  dataWithPercentage,
  customColors
}: {
  data: IDonutChartData[];
  unitOfMeasure: string;
  synced?: boolean;
  syncedSeries?: string;
  onSeriesSelected?: (label: string) => void;
  valueDecimals?: number;
  size?: number;
  hideLegends?: boolean;
  dataWithPercentage?: boolean;
  customColors?: string[] | undefined;
}) => {
  const { getColor } = useChartHelpers();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [selectedSeries, setSelectedSeries] = useState("");

  useEffect(() => {
    if (synced) {
      if (syncedSeries) {
        const hasSeries = data.map(d => d.name).includes(syncedSeries);
        setSelectedSeries(hasSeries ? syncedSeries : "");
      } else {
        setSelectedSeries("");
      }
    }
    if (synced && (!syncedSeries || data.map(d => d.name).includes(syncedSeries))) {
      setSelectedSeries(syncedSeries || "");
    }
  }, [synced, syncedSeries]);

  const onPieEnter = (_: any, index: number) => {
    if (!selectedSeries) {
      setActiveIndex(index);
    }
  };
  const onPieLeave = (_: any) => {
    if (!selectedSeries) {
      setActiveIndex(-1);
    }
  };
  const handleSeriesHovered = (series: string) => {
    if (!selectedSeries) {
      setActiveIndex(data.findIndex(d => d.name === series));
    }
  };

  const handleSeriesUnhovered = () => {
    setActiveIndex(-1);
  };

  const handleSeriesSelected = (series: string) => {
    setSelectedSeries(s => {
      const newSeries = s === series ? "" : series;
      onSeriesSelected && onSeriesSelected(newSeries);
      return newSeries;
    });
    setActiveIndex(-1);
  };

  const RenderTotal = ({
    cx,
    cy,
    value,
    unitOfMeasure,
    children
  }: {
    cx: number;
    cy: number;
    value: string;
    unitOfMeasure: string;
    children?: ReactNode;
  }) => {
    return (
      <g>
        <text fontSize={`${28 * size}px`} x={cx} y={cy} textAnchor="middle">
          {value}
        </text>
        <text fontSize={`${16 * size}px`} x={cx} y={cy} dy={28 * size} textAnchor="middle">
          {unitOfMeasure}
        </text>
        {children}
      </g>
    );
  };
  const renderActiveShape = (props: any) => {
    const { cx, cy, startAngle, endAngle, fill, payload } = props;
    return (
      <RenderTotal
        cx={cx}
        cy={cy}
        value={
          dataWithPercentage
            ? `${getPercentage(payload.value)}%`
            : roundNumberToLocale(payload.value, valueDecimals)
        }
        unitOfMeasure={
          dataWithPercentage
            ? `${roundNumberToLocale(payload.value, valueDecimals)} ${unitOfMeasure}`
            : unitOfMeasure
        }
      >
        <text fontSize={`${16 * size}px`} x={cx} y={cy} dy={124 * size} textAnchor="middle">
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={80 * size}
          outerRadius={100 * size}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </RenderTotal>
    );
  };

  const getTotal = () =>
    roundNumberToLocale(
      data.reduce((sum, element) => sum + element.value, 0),
      valueDecimals
    );

  const getSelectedValue = () =>
    data.filter(d => d.name === selectedSeries).reduce((sum, element) => sum + element.value, 0);

  const getSelectedText = () => roundNumberToLocale(getSelectedValue(), valueDecimals);

  const getPercentage = (value: number) =>
    roundNumberToLocale(
      (100 * value) / data.reduce((sum, element) => sum + element.value, 0),
      valueDecimals
    );

  const renderTotalLabel = (props: any) => {
    const {
      viewBox: { cx, cy }
    } = props;
    return (
      <RenderTotal
        cx={cx}
        cy={cy}
        value={dataWithPercentage ? "100%" : getTotal()}
        unitOfMeasure={dataWithPercentage ? `${getTotal()} ${unitOfMeasure}` : unitOfMeasure}
      />
    );
  };

  const renderSeriesTotalLabel = (props: any) => {
    const {
      viewBox: { cx, cy }
    } = props;
    return (
      <RenderTotal
        cx={cx}
        cy={cy}
        value={dataWithPercentage ? `${getPercentage(getSelectedValue())}%` : getSelectedText()}
        unitOfMeasure={dataWithPercentage ? `${getSelectedText()} ${unitOfMeasure}` : unitOfMeasure}
      >
        <text fontSize={`${16 * size}px`} x={cx} y={cy} dy={124 * size} textAnchor="middle">
          {selectedSeries}
        </text>
      </RenderTotal>
    );
  };

  return (
    <ResponsiveContainer height={"99%"}>
      <PieChart className="donut">
        {!hideLegends && (
          <Legend
            content={
              <LegendPills
                series={data.map(s => ({ name: s.name, selected: s.name === selectedSeries }))}
                onMouseEnter={handleSeriesHovered}
                onMouseLeave={handleSeriesUnhovered}
                onClick={handleSeriesSelected}
                customColors={customColors}
              />
            }
            align="left"
            layout="horizontal"
            verticalAlign="top"
            wrapperStyle={{ display: "flex", overflow: "auto", top: "0px" }}
          />
        )}
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data
            .filter(d => (selectedSeries ? d.name === selectedSeries : d))
            .map(d => ({ ...d, value: selectedSeries && d.value === 0 ? 1 : d.value }))} // force a value when 0 otherwise nothing is rendered
          innerRadius={activeIndex >= 0 ? 85 * size : 80 * size}
          outerRadius={activeIndex >= 0 ? 95 * size : 100 * size}
          dataKey="value"
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          cy="50%"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                selectedSeries
                  ? getColor(
                      data.findIndex(d => d.name === selectedSeries),
                      customColors
                    )
                  : getColor(index, customColors)
              }
              opacity={activeIndex >= 0 ? 0.2 : 1}
            />
          ))}
          {selectedSeries ? (
            <Label content={renderSeriesTotalLabel}></Label>
          ) : activeIndex < 0 ? (
            <Label content={renderTotalLabel}></Label>
          ) : null}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
