import { useApiGet, useApiPost } from "../../api/api";
import { IDonutChartData } from "../../components/charts";
import { IActionResult } from "../../management/api/ticket";
import { IGeneticPrams } from "./lookup";

interface ISeaoVendorNominateDonuts {
  donuts: IDonutChartData[];
  snapshot: number;
}

interface ISetAssignedList {
  assignedVendor: { vendor: string; vendorName: string }[];
  category: string;
  snapshot: number;
}

const SEAO_VENDOR_NOMINATE = "/v1/supply-performance/vendor-nominate/seao";

export const useGetVendorSpends = () =>
  useApiGet<ISeaoVendorNominateDonuts, IGeneticPrams>(SEAO_VENDOR_NOMINATE + "/spends");

export const useGetEscalationFrequency = () =>
  useApiGet<ISeaoVendorNominateDonuts, IGeneticPrams>(SEAO_VENDOR_NOMINATE + "/frequency");

export const useSetAssignedList = () =>
  useApiPost<ISetAssignedList, IActionResult>(SEAO_VENDOR_NOMINATE + "/assigned-list");
