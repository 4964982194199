import { ICellChanged, IIndex, IMultipleCellChanged, useTableEditing } from "./table-editing";
import { CellClassParams, ICellRendererParams, NewValueParams, IRowNode } from "ag-grid-community";
import { useCallback, useState } from "react";
import { addIfNotExisting } from "../utils";

export const useTableEditingWithEditedFlag = <T extends IIndex>(
  keyPicker?: (node: IRowNode) => string | number
) => {
  const {
    onCellChanged,
    onCellMultipleChanged,
    editedCells,
    pendingChanges,
    saveChanges: originalSaveChanges,
    undoChanges
  } = useTableEditing<T>(keyPicker || (node => node.data.id));
  const saveChanges = (updateData?: (data: T) => any) => {
    editedCells.forEach(cell =>
      cell.node.setData({
        ...cell.data,
        ...(updateData ? updateData(cell.data) : {}),
        editedCol: undefined
      })
    );
    originalSaveChanges();
  };
  return {
    onCellChanged,
    onCellMultipleChanged,
    editedCells,
    pendingChanges,
    undoChanges,
    saveChanges
  };
};

export const onCellValueChanged =
  <Data extends Object>(onCellChanged: (changes: ICellChanged<Data>) => void) =>
  (event: NewValueParams) => {
    const {
      node,
      column,
      data: { editedCol, ...data },
      oldValue
    } = event;
    node?.setData({
      ...node?.data,
      editedCol: addIfNotExisting(node?.data.editedCol || [], column.getColDef().field)
    });
    onCellChanged({
      node: node || undefined,
      column: column.getColId(),
      data,
      oldValue
    });
  };

export const isCellPending = (params: CellClassParams) => {
  const { node, colDef } = params;
  return node.data?.editedCol?.findIndex((col: string) => col === colDef.field) > -1;
};

export const useGroupLevelEditingHelper = <Data extends Object>(
  onCellMultipleChanged: (cellChanges: IMultipleCellChanged<Data>[]) => void
) => {
  const [editedGroupCell, setEditedGroupCell] = useState<IRowNode[]>([]);
  const saveGroupCellChanges = (node: IRowNode) => {
    setEditedGroupCell(nodes =>
      nodes.findIndex(n => n.id === node.id) > -1 ? nodes : [...nodes, node]
    );
  };
  const onGroupCellChanged = useCallback(
    (p: ICellRendererParams, value: string) => {
      const newChanges: IMultipleCellChanged<Data>[] = [];
      p.node.allLeafChildren.forEach(c => {
        const colId = p.column?.getColId();
        if (colId) {
          const newData = { ...c.data, [colId]: value };
          const oldValue = c.data[colId];
          c.setData(newData);
          const { editedCol, ...appliedData } = newData;
          newChanges.push({
            node: c,
            data: appliedData,
            newChanges: [{ column: colId, oldValue }]
          });
        }
      });
      p.node.setData({
        ...(p.node.data || []),
        editedCol: addIfNotExisting(p.node?.data?.editedCol || [], p.column?.getColDef().field)
      });
      saveGroupCellChanges(p.node);
      onCellMultipleChanged(newChanges);
    },
    [onCellMultipleChanged]
  );

  const clearFlag = () => {
    editedGroupCell.forEach(c => c.setData(undefined));
    setEditedGroupCell([]);
  };

  const undoGroupChanges = () => {
    clearFlag();
  };

  const saveGroupChanges = () => {
    clearFlag();
  };

  return { undoGroupChanges, saveGroupChanges, onGroupCellChanged };
};
