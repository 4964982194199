import { Pagination, TableColumn } from "@react-gcc-eds/core";
import Table from "../components/table/table";
import React, { CSSProperties, ReactNode } from "react";
import { TEXT_GREY } from "../components/colorHelper";
import Loader from "../components/loader";

export interface CPaginationGeneticValues {
  style?: CSSProperties;
  row?: Object[];
  totalPage: number;
  onPageChange: (page: number) => void;
  totalValue: number;
  currentPage: number;
  tableLoading?: boolean;
  onEditedCell?(row: object, index: number): void;
  onRowClick?(row: object): void;
}

export interface CPaginationTable extends CPaginationGeneticValues {
  columns: TableColumn[];
  tableRightActions?: ReactNode;
  widths?: string[];
  scrollHorizontal?: boolean;
  size?: "normal" | "compact" | "tiny";
}

const PaginationTable = ({
  style,
  columns,
  row,
  totalPage,
  onPageChange,
  totalValue,
  currentPage,
  tableLoading,
  tableRightActions,
  onEditedCell,
  onRowClick,
  widths,
  scrollHorizontal,
  size = "normal"
}: CPaginationTable) => {
  return (
    <>
      {tableLoading ? (
        <div style={{ height: "300px" }}>
          <Loader noTile />
        </div>
      ) : row ? (
        <>
          <div style={{ flex: "0 0 auto", display: "flex" }}>
            <div style={{ flex: "1", textAlign: "left" }}>
              <label style={{ color: TEXT_GREY }}>{`${(currentPage - 1) * 10 + 1} - ${
                currentPage === totalPage ? totalValue : currentPage * 10
              } of ${totalValue} items`}</label>
            </div>
            <div style={{ flex: "0 0 auto", textAlign: "right" }}>{tableRightActions}</div>
          </div>
          <Table
            style={style}
            onRowClick={data => (onRowClick ? onRowClick(data) : undefined)}
            columns={columns}
            onEditedCell={onEditedCell}
            scrollHorizontal={scrollHorizontal}
            paginaton={
              <Pagination
                activePage={currentPage}
                onLeftClick={onPageChange}
                onPageChanged={page => {
                  if (Number.isFinite(page)) onPageChange(page);
                }}
                onPageClick={onPageChange}
                onRightClick={onPageChange}
                pages={totalPage}
                showPageSelector
              />
            }
            rows={row}
            rowsStyle="none"
            size={size}
            widths={widths}
          />
        </>
      ) : (
        "Not Data Found!"
      )}
    </>
  );
};

export default PaginationTable;
