import View from "../../../views/app-view";
import { Button, Column, Row, Tab, Tabs, Tile } from "@react-gcc-eds/core";
import { DonutChart } from "../../../components/charts";
import { ListBuilder } from "../../../components/listbuilder";
import { useEffect, useState } from "react";
import { IVendor, useGetAssignedVendorList, useGetVendorByCategory } from "../../api/lookup";
import {
  useGetEscalationFrequency,
  useGetVendorSpends,
  useSetAssignedList
} from "../../api/seao-vendor-nominate";
import ContentLoader from "../../../components/content-loader";
import { IListSideItem } from "../../../components/listbuilder/IListBuilder";
import { simpleObjectEqual } from "../../../utils/user-helpers";
import useGlobalSettings from "../../component/global-settings";
import "./seao-vendor-nominate.scss";
import { useAppState } from "../../../app-state";
import { Roles, TUser } from "../../../types";
import { SEAO_VENDOR_NOMINATE_ROUTE } from "../../../app-router";

export enum ECategory {
  EnclosureSystem = "Enclosure System",
  SitePowerBattery = "Site Power & Battery",
  Optical = "Optical",
  Installation = "Installation",
  SiteInterconnect = "Site Interconnect",
  passiveAntennaSystem = "3PP passive antenna system"
}

interface IListVendorItem extends IListSideItem {
  id: number;
  vendor: string;
  categoryName: string;
}

//time
export const formatSnapShotToSeason = (snapshot: number) => {
  const year = Math.floor(snapshot / 100);
  const month = snapshot % 100;
  const season = month < 4 ? "Q1" : month < 7 ? "Q2" : month < 10 ? "Q3" : "Q4";
  return `${year}-${season}`;
};

const SeaoVendorNominateView = () => {
  const DEFAULT_CATEGORY = ECategory.EnclosureSystem;

  const [currentTab, setCurrentTab] = useState<ECategory>(DEFAULT_CATEGORY);
  const [spends, loadingSpends, getSpends] = useGetVendorSpends();
  const [frequency, loadingFrequency, getFrequency] = useGetEscalationFrequency();
  const { currentSeaoSnapshot, SeaoSettings } = useGlobalSettings();

  const onTabSelected = (name: ECategory) => {
    setCurrentTab(name);
  };

  // Global commodity
  const [availableVendor, loadingAvailableVendor, getAvailableVendor] = useGetVendorByCategory();
  const [assignedVendorList, loadingAssignedVendorList, getAssignedVendorList] =
    useGetAssignedVendorList();
  const [leftVendorList, setLeftVendorList] = useState<IListVendorItem[]>([]);
  const [rightVendorList, setRightVendorList] = useState<IListVendorItem[]>([]);

  const getMenuVendorList = (vendorList: IVendor[]): IListVendorItem[] =>
    vendorList.map(i => ({ ...i, title: i.vendorName }));

  useEffect(() => {
    if (assignedVendorList) {
      setRightVendorList(getMenuVendorList(assignedVendorList));
    }
    if (assignedVendorList && availableVendor) {
      const assignedVendors = assignedVendorList.map(({ vendor }) => vendor);
      setLeftVendorList(
        getMenuVendorList(availableVendor.filter(({ vendor }) => !assignedVendors.includes(vendor)))
      );
    }
  }, [assignedVendorList, availableVendor]);

  //save
  const [submitAssignedRes, loadingSubmitAssignedList, submitAssignedList] = useSetAssignedList();

  const onSave = () => {
    submitAssignedList({
      assignedVendor: rightVendorList.map(({ vendor, vendorName }) => ({
        vendor,
        vendorName
      })),
      snapshot: currentSeaoSnapshot,
      category: currentTab
    });
  };

  useEffect(() => {
    if (submitAssignedRes) {
      getAssignedVendorList({ category: currentTab, snapshot: currentSeaoSnapshot });
    }
  }, [submitAssignedRes]);

  //transfer
  const includeVendorId = (list: IListVendorItem[], vendor: string): boolean =>
    list.filter(item => item.vendor === vendor).length > 0;

  const getFilterVendorList = (list: IListVendorItem[], selectedList: IListVendorItem[]) =>
    list.filter(({ vendor }) => !includeVendorId(selectedList, vendor));

  const onTransferRightClick = (selectedItems: IListSideItem[]) => {
    if (leftVendorList) {
      const _selectedItems = selectedItems as IListVendorItem[];
      setLeftVendorList(list => getFilterVendorList(list, _selectedItems));
      setRightVendorList(list => [...list, ..._selectedItems]);
    }
  };

  const onTransferLeftClick = (selectedItems: IListSideItem[]) => {
    if (rightVendorList) {
      const _selectedItems = selectedItems as IListVendorItem[];
      setRightVendorList(list => getFilterVendorList(list, _selectedItems));
      setLeftVendorList(list => [...list, ..._selectedItems]);
    }
  };

  useEffect(() => {
    getSpends({ category: currentTab, snapshot: currentSeaoSnapshot });
    getAssignedVendorList({ category: currentTab, snapshot: currentSeaoSnapshot });
    getAvailableVendor({ category: currentTab, snapshot: currentSeaoSnapshot });
    getFrequency({ category: currentTab, snapshot: currentSeaoSnapshot });
  }, [currentTab, currentSeaoSnapshot]);

  //role
  const [appState] = useAppState();

  return (
    <View
      breadCrumbs={[
        { text: "Supplier Performance" },
        { text: "SEAO" },
        { text: "Vendor Nominate", url: SEAO_VENDOR_NOMINATE_ROUTE }
      ]}
      actions={<SeaoSettings />}
    >
      <Tabs style={{ padding: "8px 0 0 8px" }}>
        {Object.entries(ECategory).map(([key, name]) => (
          <Tab
            selected={currentTab === name}
            key={key}
            title={name}
            onSelected={() => onTabSelected(name as ECategory)}
          />
        ))}
      </Tabs>
      <Row>
        <Column lg={6}>
          <Tile
            title="85% Spends"
            style={{ height: 500 }}
            subtitle={`${currentTab} ${formatSnapShotToSeason(currentSeaoSnapshot)}`}
          >
            <ContentLoader
              loading={loadingSpends}
              data={spends && spends.donuts && spends.donuts.length > 0}
              render={<DonutChart data={spends?.donuts} unitOfMeasure={"%"} valueDecimals={0} />}
            />
          </Tile>
        </Column>
        <Column lg={6}>
          <Tile
            title="Escalation Frequency"
            style={{ height: 500 }}
            subtitle={`${currentTab} ${formatSnapShotToSeason(currentSeaoSnapshot)}`}
          >
            <ContentLoader
              loading={loadingFrequency}
              data={frequency && frequency.donuts && frequency.donuts.length > 0}
              render={
                <DonutChart data={frequency?.donuts} unitOfMeasure={"Time"} valueDecimals={0} />
              }
            />
          </Tile>
        </Column>
      </Row>
      <Row>
        <Column lg={12}>
          <Tile
            title="Global requirement per commodity"
            subtitle={`${currentTab} ${formatSnapShotToSeason(currentSeaoSnapshot)}`}
            className="nominate-bottom-tile"
            actions={
              <Button
                primary
                onClick={() => onSave()}
                disabled={
                  assignedVendorList &&
                  simpleObjectEqual(rightVendorList, getMenuVendorList(assignedVendorList))
                }
              >
                Save
              </Button>
            }
          >
            <ContentLoader
              loading={
                loadingAssignedVendorList || loadingAvailableVendor || loadingSubmitAssignedList
              }
              data={availableVendor && assignedVendorList}
              render={
                <ListBuilder
                  disabled={!(appState.user as TUser).roles.includes(Roles.SPCSupplier)}
                  leftTitle="Available Vendor"
                  rightTitle="Assigned Vendor"
                  leftSideItems={leftVendorList}
                  rightSideItems={rightVendorList}
                  onTransferRightClick={onTransferRightClick}
                  onTransferLeftClick={onTransferLeftClick}
                />
              }
            />
          </Tile>
        </Column>
      </Row>
    </View>
  );
};

export default SeaoVendorNominateView;
