import Loader from "../../../components/loader";
import { Button, Dialog } from "@react-gcc-eds/core";
import "./ticket-preview-dialog.scss";
import { useEffect, useState } from "react";
import Table from "../../../components/table";
import { getEfPreviewColumns } from "../../grid/views-columns/ef-preview";
import { ITicketDetailSoItem } from "../../api/ticket";
import { IOaItem } from "../../api/sales-order";

const TicketPreviewDialog = ({
  editable = false,
  loading,
  loadingSubmit = false,
  tableData,
  onClose,
  visible,
  onApply,
  hasProjectName = true
}: {
  editable?: boolean;
  loading?: boolean;
  loadingSubmit?: boolean;
  tableData: ITicketDetailSoItem[] | undefined;
  visible: boolean;
  onClose: () => void;
  onApply?: (oaList: IOaItem[]) => void;
  hasProjectName?: boolean;
}) => {
  const [editedOaList, setEditedOaList] = useState<IOaItem[]>([]);

  useEffect(() => {
    if (tableData) {
      setEditedOaList(
        tableData.map(({ id, salesOrder, item, improvedOrderAcknowledgeDate }) => ({
          id,
          salesOrder,
          item,
          improvedOrderAcknowledgeDate: improvedOrderAcknowledgeDate || null
        }))
      );
    }
  }, [tableData]);

  const onOaChanged = (row: ITicketDetailSoItem) => (date: Date | undefined) => {
    setEditedOaList(list =>
      list?.map(item =>
        row.salesOrder === item.salesOrder && row.item === item.item
          ? { ...item, improvedOrderAcknowledgeDate: date ? date.toISOString() : null }
          : item
      )
    );
  };

  const columns = getEfPreviewColumns({
    data: editedOaList,
    showProjectName: hasProjectName,
    editable,
    onOaChanged
  });

  return (
    <>
      {loading ? (
        <Dialog fullscreen closeOnEscape>
          <Loader />
        </Dialog>
      ) : (
        tableData && (
          <Dialog
            fullscreen
            className={`ticket-attachment-dialog`}
            visible={visible}
            title={editable ? "Update OA" : `Ticket Preview`}
            closeOnEscape={false}
            onClose={onClose}
            buttons={
              editable ? (
                <Button
                  primary
                  loading={loadingSubmit}
                  onClick={() => onApply && onApply(editedOaList)}
                >
                  Submit
                </Button>
              ) : (
                <></>
              )
            }
          >
            <div className="ticket-attachment-dialog-table">
              {tableData.length > 0 ? (
                <Table
                  scrollVertical={true}
                  scrollHorizontal={true}
                  columns={columns}
                  rows={tableData}
                />
              ) : (
                <div>{"No Data find!"}</div>
              )}
            </div>
          </Dialog>
        )
      )}
    </>
  );
};

export default TicketPreviewDialog;
