import { Card, Kpi, KpiItem } from "@react-gcc-eds/core";
import { ReactNode } from "react";
import "./performance-card.scss";

const PerformanceCard = ({
  title,
  subTitle,
  content,
  bottomLeftTitle,
  bottomRightTitle,
  bottomLeftSubTitle,
  bottomRightSubTitle
}: {
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  content: ReactNode | string;
  bottomLeftTitle?: string;
  bottomLeftSubTitle?: string;
  bottomRightTitle?: string;
  bottomRightSubTitle?: string;
}) => {
  const kpiNode = (title: string | undefined, subTitle: string | undefined) =>
    title ? (
      <Kpi>
        <KpiItem inline xl>
          {title}
        </KpiItem>
        <KpiItem inline>
          <KpiItem color="gray">{subTitle}</KpiItem>
        </KpiItem>
      </Kpi>
    ) : (
      <></>
    );

  const cardContent = (
    <>
      {content}
      <div className="performance-card-bottom">
        {kpiNode(bottomLeftTitle, bottomLeftSubTitle)}
        {kpiNode(bottomRightTitle, bottomRightSubTitle)}
      </div>
    </>
  );
  return (
    <Card title={title} content={cardContent} className="performance-card" subTitle={subTitle} />
  );
};

export default PerformanceCard;
