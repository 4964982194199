import { Button, IDropdownItem, ISelectItem } from "@react-gcc-eds/core";
import "./performance-filter.scss";
import SingleSelect from "../../components/select/SingleSelect";
import {
  ISubAllFilterItems,
  ISubCustomerInfoItem,
  ISubDisplayViewItem,
  ISubLookupItems
} from "../api/subscription";
import { IDisplaySelectItem } from "./display-time-filter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { numberToSelect } from "../../spc/component/performance-filter";
import { simpleObjectEqual } from "../../utils/user-helpers";
import { useLookupFilters } from "../helper/lookup-filters";
import MultipleDropdownFilter, {
  multiFilterLabel
} from "../../components/select/MultipleDropdownFilter";
import { DEFAULT_FILTER } from "../view/escalation-performance-view";
import { useAppState } from "../../app-state";
import { EMarketArea } from "../../types";

export interface ISubCustomerInfoItems {
  customerUnit: IDropdownItem[];
  customer: IDropdownItem[];
  groupSupplyUnit: IDropdownItem[];
  ticketCategory: IDropdownItem[];
  productReportingGroup: IDropdownItem[];

  [key: string]: IDropdownItem[];
}

interface IEditedFilters extends ISubAllFilterItems {
  [key: string]: any;
}

const PerformanceFilter = ({
  allAvailableItems,
  defaultFilters,
  appliedFilters,
  filterLookupItems,
  lookupItems,
  onSubmit
}: {
  allAvailableItems: ISubLookupItems;
  defaultFilters: ISubAllFilterItems;
  appliedFilters: ISubAllFilterItems;
  filterLookupItems: (
    filters: ((item: ISubCustomerInfoItem) => boolean)[]
  ) => ISubCustomerInfoItem[];
  lookupItems: (
    items: ISubCustomerInfoItem[],
    selector: (item: ISubCustomerInfoItem) => string
  ) => IDropdownItem[];
  onSubmit: (filter: ISubAllFilterItems) => void;
}) => {
  //filter
  const multipleFilterItems = useCallback(
    (items: ISubCustomerInfoItem[]): ISubCustomerInfoItems => ({
      customer: lookupItems(items, f => f.customer),
      customerUnit: lookupItems(items, f => f.customerUnit),
      groupSupplyUnit: lookupItems(items, f => f.groupSupplyUnit),
      ticketCategory: lookupItems(items, f => f.ticketCategory),
      productReportingGroup: lookupItems(items, f => f.productReportingGroup)
    }),
    [lookupItems]
  );

  const allFilterItems: ISubCustomerInfoItems = useMemo(
    () => multipleFilterItems(filterLookupItems([])),
    [filterLookupItems, multipleFilterItems]
  );

  const lookupItemFilter = (filters: IEditedFilters) =>
    filterLookupItems([
      f =>
        !filters.customer?.length ||
        filters.customer.find(v => v.value === f.customer) !== undefined,
      f =>
        !filters.customerUnit?.length ||
        filters.customerUnit.find(v => v.value === f.customerUnit) !== undefined,
      f =>
        !filters.groupSupplyUnit?.length ||
        filters.groupSupplyUnit.find(v => v.value === f.groupSupplyUnit) !== undefined,
      f =>
        !filters.ticketCategory?.length ||
        filters.ticketCategory.find(v => v.value === f.ticketCategory) !== undefined,
      f =>
        !filters.productReportingGroup?.length ||
        filters.productReportingGroup.find(v => v.value === f.productReportingGroup) !== undefined
    ]);

  const newAssociatedItemsWhenWidened = (
    filter: (item: ISubCustomerInfoItem) => boolean
  ): ISubCustomerInfoItems => {
    const newItems = filterLookupItems([filter]);
    return multipleFilterItems(newItems);
  };

  const newAssociatedItemsWhenNarrowed = (filters: IEditedFilters): ISubCustomerInfoItems => {
    const newItems = lookupItemFilter(filters);
    return multipleFilterItems(newItems);
  };

  const { editedFilters, filtersItems, multiFilter, filterChanged, resetFilters } =
    useLookupFilters({
      defaultFilters,
      filters: appliedFilters,
      allFilterItems,
      newAssociatedItemsWhenWidened,
      newAssociatedItemsWhenNarrowed
    });
  //time iframe
  const [timeFrameItems, setTimeFrameItems] = useState<ISelectItem<any>[]>([]);

  useEffect(() => {
    if (allAvailableItems && editedFilters.displayView) {
      const maxTimeFrame =
        allAvailableItems.displayViews.find(
          item => item.viewType === editedFilters.displayView.value
        )?.maxTimeFrame || 0;
      setTimeFrameItems(numberToSelect(maxTimeFrame));
    }
  }, [allAvailableItems, editedFilters.displayView]);

  const apiToSelect = <T extends Object>(data: T[] | undefined): ISelectItem<T>[] =>
    data?.map(value => ({
      title: value.toString(),
      value
    })) || [];

  const apiToDisplaySelect = (list: ISubDisplayViewItem[]): IDisplaySelectItem[] =>
    list.map(({ viewType, defaultTimeFrame, maxTimeFrame }) => ({
      title: viewType,
      value: viewType,
      defaultTimeFrame,
      maxTimeFrame
    }));

  //button
  const onReset = () => {
    resetFilters();
  };

  //filter show
  const [appState] = useAppState();
  const hasGSUFilter = useMemo(
    () => Boolean(appState.user?.marketAreas.includes(EMarketArea.GLOBAL)),
    [appState]
  );

  return (
    <div className="scrollable-pane-content performance-filter">
      <div className="scrollable-pane-controls">
        <SingleSelect
          items={apiToDisplaySelect(allAvailableItems.displayViews)}
          topLabel="Display view"
          label={editedFilters.displayView.title}
          placeholder="Select option"
          onChange={item => {
            const { defaultTimeFrame } = item as IDisplaySelectItem;
            filterChanged("timeFrame")({
              title: defaultTimeFrame.toString(),
              value: defaultTimeFrame as unknown as string
            });
            filterChanged("displayView")(item);
          }}
        />
        <SingleSelect
          items={timeFrameItems}
          topLabel="Time frame"
          label={editedFilters.timeFrame.title}
          placeholder="Select option"
          onChange={filterChanged("timeFrame")}
        />
        {hasGSUFilter &&
          multiFilter(
            "groupSupplyUnit",
            i => i.groupSupplyUnit,
            "Group supply unit",
            filtersItems.groupSupplyUnit
          )}
        {multiFilter(
          "ticketCategory",
          i => i.ticketCategory,
          "Ticket Category",
          filtersItems.ticketCategory
        )}
        {multiFilter(
          "productReportingGroup",
          i => i.productReportingGroup,
          "Customer unit",
          filtersItems.productReportingGroup
        )}
        {multiFilter(
          "customerUnit",
          i => i.customerUnit,
          "Product reporting group",
          filtersItems.customerUnit
        )}
        {multiFilter("customer", i => i.customer, "Customer", filtersItems.customer)}
        <MultipleDropdownFilter
          items={DEFAULT_FILTER.sippOrNonSipp}
          header="SIPP"
          label={multiFilterLabel(DEFAULT_FILTER.sippOrNonSipp, editedFilters.sippOrNonSipp)}
          onChange={filterChanged("sippOrNonSipp")}
          selectedItems={editedFilters.sippOrNonSipp || []}
        />
        <MultipleDropdownFilter
          items={DEFAULT_FILTER.forecasted}
          header="Forecasted"
          label={multiFilterLabel(DEFAULT_FILTER.forecasted, editedFilters.forecasted)}
          onChange={filterChanged("forecasted")}
          selectedItems={editedFilters.forecasted || []}
        />
        <div className="scrollable-pane-actions">
          <Button onClick={onReset}>Reset</Button>
          <Button
            primary
            onClick={() => onSubmit(editedFilters)}
            disabled={simpleObjectEqual(editedFilters, appliedFilters)}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PerformanceFilter;
