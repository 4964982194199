import { Tile } from "@react-gcc-eds/core";
import RadarChart, { IRadarChartData } from "../../../components/charts/radar-chart";
import { ICategoryRadarItem, IVendorRadarItem } from "../../api/seao-result";
import { EResultColor } from "../../tables/columns/result-table";
import "./result-chart-board.scss";

const ResultChartBoard = ({
  categoryList,
  vendorList,
  onSelect,
  selectedCategory
}: {
  categoryList: ICategoryRadarItem[];
  vendorList: IVendorRadarItem[];
  onSelect: (category: ICategoryRadarItem | undefined) => void;
  selectedCategory: ICategoryRadarItem | undefined;
}) => {
  const handleSelect = (categoryItem: ICategoryRadarItem) => {
    onSelect(selectedCategory === categoryItem ? undefined : categoryItem);
  };

  const renderCard = (title: string, data: IRadarChartData[], type?: "vendor" | "category") => (
    <Tile title={title} subtitle={type === "vendor" ? "Vendor" : "Category"}>
      <div className="result-card-chart">
        <RadarChart
          data={data}
          lineStroke={type === "vendor" ? EResultColor.green : undefined}
          units={["%"]}
        />
      </div>
    </Tile>
  );

  return (
    <div className="result-card">
      {selectedCategory ? (
        <>
          <div
            className="result-card-tile-selected result-cursor"
            onClick={() => handleSelect(selectedCategory)}
          >
            {renderCard(selectedCategory.category, selectedCategory.data)}
          </div>
          {vendorList.map(vendorItem => (
            <div className="result-card-tile" key={vendorItem.vendorName}>
              {renderCard(vendorItem.vendorName, vendorItem.data, "vendor")}
            </div>
          ))}
        </>
      ) : (
        categoryList.map(categoryItem => (
          <div
            key={categoryItem.category}
            className="result-card-tile result-cursor"
            onClick={() => handleSelect(categoryItem)}
          >
            {renderCard(categoryItem.category, categoryItem.data)}
          </div>
        ))
      )}
    </div>
  );
};

export default ResultChartBoard;
