import { ReactNode } from "react";
import ReactDOM from "react-dom";

const RenderInPortal = ({ children }: { children: ReactNode }) => {
  const modalRoot = document.getElementById("app-portal");
  if (modalRoot) {
    return ReactDOM.createPortal(children, modalRoot);
  }
  return null;
};

export default RenderInPortal;
