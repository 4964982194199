import { Tab, Tabs, Tile, useNotifications } from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import View from "../../../views/app-view";
import { useGetAssignedVendorList } from "../../api/lookup";
import {
  EEvaluationKey,
  IEvaluationItem,
  useGetEvaluation,
  useSetEvaluation
} from "../../api/seao-vendor-evaluation";
import EvaluationTable, { IErrorMode } from "../../component/evaluation-table";
import { ECategory } from "./seao-vendor-nominate-view";
import "./evaluation-view.scss";
import useGlobalSettings from "../../component/global-settings";
import ContentLoader from "../../../components/content-loader";
import { Roles, TUser } from "../../../types";
import { useAppState } from "../../../app-state";
import { SEAO_VENDOR_EVALUATION_ROUTE } from "../../../app-router";
import SingleSelect from "../../../components/select/SingleSelect";

const SeaoVendorEvaluationView = () => {
  //category
  const [category, setCategory] = useState<ECategory>(ECategory.EnclosureSystem);
  const { currentSeaoSnapshot, SeaoSettings } = useGlobalSettings();

  useEffect(() => {
    if (category && currentSeaoSnapshot) {
      getAssignedVendorList({ category, snapshot: currentSeaoSnapshot });
      setCurrentTab(undefined);
    }
  }, [category, currentSeaoSnapshot]);

  //tab
  const [currentTab, setCurrentTab] = useState<string>();
  const [assignedVendorList, loadingAssignedVendorList, getAssignedVendorList] =
    useGetAssignedVendorList();

  useEffect(() => {
    if (assignedVendorList && assignedVendorList.length > 0) {
      setCurrentTab(assignedVendorList[0].vendor);
    }
  }, [assignedVendorList]);

  //display table
  const [evaluationList, loadingGetEvaluation, getEvaluation] = useGetEvaluation();

  const refreshTable = (vendorId: string) => {
    getEvaluation({
      vendorId,
      vendorName: assignedVendorList.filter(({ vendor }) => vendor === vendorId)[0].vendorName,
      snapshot: currentSeaoSnapshot
    });
  };

  useEffect(() => {
    if (currentTab && currentSeaoSnapshot) {
      refreshTable(currentTab);
      setFormErrorItem(undefined);
    }
  }, [currentTab, currentSeaoSnapshot]);

  //role
  const [appState] = useAppState();

  //submit
  const [submitRes, loadingSubmitEvaluation, submitEvaluation] = useSetEvaluation();
  const [formErrorItem, setFormErrorItem] = useState<IErrorMode>();
  const { createNotification } = useNotifications();

  const submitForm = (formData: IEvaluationItem[]) => {
    setFormErrorItem(undefined);
    let invalid = false;
    for (const { score, comment, labelId } of formData) {
      const _score = score as number;
      if (isNaN(_score) || _score <= 0 || _score > 10) {
        setFormErrorItem({
          rowKey: labelId,
          columnKey: "score"
        });
        invalid = true;
        break;
      } else if (!comment) {
        setFormErrorItem({
          rowKey: labelId,
          columnKey: "comment"
        });
        invalid = true;
        break;
      }
    }
    !invalid &&
      submitEvaluation({
        evaluationList: formData,
        vendor: currentTab || "",
        snapshot: currentSeaoSnapshot
      });
  };

  useEffect(() => {
    if (submitRes && currentTab) {
      const { success } = submitRes;
      success && refreshTable(currentTab);
    }
  }, [submitRes]);

  const getDefaultEvaluationList = (): IEvaluationItem[] =>
    Object.values(EEvaluationKey)
      .filter(v => Number.isInteger(v))
      .map(value => ({
        labelId: value as EEvaluationKey,
        score: undefined,
        comment: ""
      }));

  return (
    <View
      breadCrumbs={[
        { text: "Supplier Performance" },
        { text: "SEAO" },
        { text: "Vendor Evaluation", url: SEAO_VENDOR_EVALUATION_ROUTE }
      ]}
      actions={<SeaoSettings />}
    >
      <Tile
        title="Soft Evaluation"
        actions={
          <SingleSelect
            items={Object.values(ECategory).map(key => ({
              title: key,
              value: key
            }))}
            placeholder="Please Select Category"
            value={category}
            className="tile-header-select"
            onChange={({ value }) => {
              setCategory(value as ECategory);
            }}
            type="input-single"
            clickItemTextChange={false}
          />
        }
      >
        {loadingAssignedVendorList ? (
          <Loader />
        ) : (
          assignedVendorList && (
            <>
              <Tabs className="seao-evaluation-tabs">
                {assignedVendorList.map(({ vendorName, vendor }) => (
                  <Tab
                    selected={currentTab === vendor}
                    key={vendor}
                    title={vendorName}
                    onSelected={() => setCurrentTab(vendor)}
                  />
                ))}
              </Tabs>
              <ContentLoader
                data={currentTab}
                loading={loadingGetEvaluation || loadingSubmitEvaluation}
                message="No vendors nominated !"
                render={
                  <EvaluationTable
                    appliedForm={
                      evaluationList && evaluationList.length > 0
                        ? evaluationList
                        : getDefaultEvaluationList()
                    }
                    onSave={submitForm}
                    error={formErrorItem}
                    disabled={!(appState.user as TUser).roles.includes(Roles.SPCSupplier)}
                  />
                }
              />
            </>
          )
        )}
      </Tile>
    </View>
  );
};

export default SeaoVendorEvaluationView;
