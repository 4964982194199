import { Button, ISelectItem, SingleSelect } from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import { CustomerRegion } from "../../management/helper";
import { EViewType, ISpcRegionsItem } from "../api/lookup";
import { IPerformanceParam } from "../api/performance-history";

interface IPerformanceFilters {
  regions: ISelectItem<any>[];
  displayViews: ISelectItem<any>[];
  timeFrame: ISelectItem<any>[];
  categories: ISelectItem<any>[];
  perspectives: ISelectItem<any>[];
}

interface IViewTime {
  seaoView: ISpcRegionsItem[];
  indiaView: ISpcRegionsItem[];
  seaoPerspective: ISelectItem<any>[];
  indiaPerspective: ISelectItem<any>[];
}

export const getViewLookup = (regions: ISpcRegionsItem[]) =>
  regions.map(({ viewType }) => ({ title: viewType, value: viewType }));

export const numberToSelect = (n: number) => {
  const result = [];
  for (let i = 1; i <= n; i++) {
    result.push({
      title: i.toString(),
      value: i
    });
  }
  return result;
};

export const getDefaultFilters = (
  region: CustomerRegion,
  categories: ISelectItem<any>[],
  defaultSeaoTimeFrame: number,
  defaultIndiaTimeFrame: number
) => {
  const isSeao = region === CustomerRegion.seao;
  return {
    spcRegion: isSeao ? CustomerRegion.seao : CustomerRegion.india,
    spcDisplayView: isSeao ? EViewType.quarter : EViewType.month,
    timeFrame: isSeao ? defaultSeaoTimeFrame : defaultIndiaTimeFrame,
    category: categories[0].value as string,
    spcPerspective: "TOTAL"
  };
};

const PerformanceFilter = ({
  appliedFilters,
  defaultAvailableItems,
  snapshotData,
  onApply
}: {
  appliedFilters: IPerformanceParam;
  defaultAvailableItems: IPerformanceFilters;
  snapshotData: IViewTime;
  onApply: (editedFilters: IPerformanceParam) => void;
}) => {
  const [editedFilters, setEditedFilters] = useState<IPerformanceParam>(appliedFilters);
  const defaultSeaoTimeFrame = snapshotData.seaoView[0].defaultTimeFrame;
  const defaultIndiaTimeFrame = snapshotData.indiaView[0].defaultTimeFrame;

  const getTimeFrameByView = (view: ISpcRegionsItem[], viewType: EViewType) =>
    view.filter(view => view.viewType === viewType)[0].defaultTimeFrame;

  const getTimeFrameByViewType = (viewType: EViewType, region: CustomerRegion) =>
    getTimeFrameByView(
      region === CustomerRegion.seao ? snapshotData.seaoView : snapshotData.indiaView,
      viewType
    );

  const handleChange =
    (key: keyof IPerformanceParam) =>
    ({ value }: ISelectItem<any>) =>
      key === "spcRegion"
        ? setEditedFilters(
            getDefaultFilters(
              value as CustomerRegion,
              defaultAvailableItems.categories,
              defaultSeaoTimeFrame,
              defaultIndiaTimeFrame
            )
          )
        : key === "spcDisplayView"
        ? setEditedFilters(f => ({
            ...f,
            spcDisplayView: value as EViewType,
            timeFrame: getTimeFrameByViewType(value as EViewType, f.spcRegion)
          }))
        : key === "timeFrame"
        ? setEditedFilters(f => ({ ...f, [key]: parseInt(value as string) }))
        : setEditedFilters(f => ({ ...f, [key]: value }));

  const { allAvailableItems } = useAllAvailableItems(
    defaultAvailableItems,
    editedFilters,
    snapshotData
  );
  const { defaultFilter } = useDefaultFilter(
    editedFilters.spcRegion,
    defaultAvailableItems,
    defaultSeaoTimeFrame,
    defaultIndiaTimeFrame
  );

  const reset = () => {
    if (defaultFilter) {
      setEditedFilters(defaultFilter);
    }
  };

  const getPerspectiveTitle = (value: string) => {
    const isSeao = editedFilters.spcRegion === CustomerRegion.seao;
    const perspectives = isSeao ? snapshotData.seaoPerspective : snapshotData.indiaPerspective;
    return perspectives.filter(item => item.value === value)[0].title;
  };

  return (
    <div className="scrollable-pane-content">
      <div className="scrollable-pane-controls">
        <SingleSelect
          items={allAvailableItems.regions}
          label={editedFilters.spcRegion}
          placeholder="Region"
          topLabel="Region"
          onChange={handleChange("spcRegion")}
        />
        <SingleSelect
          items={allAvailableItems.displayViews}
          label={editedFilters.spcDisplayView}
          placeholder="Display View"
          topLabel="Display View"
          onChange={handleChange("spcDisplayView")}
        />
        <SingleSelect
          items={allAvailableItems.timeFrame}
          label={editedFilters.timeFrame.toString()}
          placeholder="Time Frame"
          topLabel="Time Frame"
          onChange={handleChange("timeFrame")}
        />
        <SingleSelect
          items={allAvailableItems.categories}
          label={editedFilters.category}
          placeholder="Category"
          topLabel="Category"
          onChange={handleChange("category")}
        />
        <SingleSelect
          items={allAvailableItems.perspectives}
          label={getPerspectiveTitle(editedFilters.spcPerspective)}
          placeholder="Perspective"
          topLabel="Perspective"
          onChange={handleChange("spcPerspective")}
        />
      </div>
      <div className="scrollable-pane-actions">
        <Button onClick={reset}>Reset</Button>
        <Button primary onClick={() => onApply(editedFilters)}>
          Apply
        </Button>
      </div>
    </div>
  );
};

const useAllAvailableItems = (
  lookup: IPerformanceFilters,
  editedFilters: IPerformanceParam,
  snapshotData: IViewTime
) => {
  const [allAvailableItems, setAllAvailableItems] = useState<IPerformanceFilters>(lookup);

  useEffect(() => {
    const isSeao = editedFilters.spcRegion === CustomerRegion.seao;

    const getTimesLookup = (list: ISpcRegionsItem[]) => {
      const snapshots = list.filter(({ viewType }) => viewType === editedFilters.spcDisplayView)[0];
      return snapshots ? numberToSelect(snapshots.maxTimeFrame) : [];
    };

    setAllAvailableItems(items => ({
      ...items,
      displayViews: isSeao
        ? getViewLookup(snapshotData.seaoView)
        : getViewLookup(snapshotData.indiaView),
      timeFrame: isSeao
        ? getTimesLookup(snapshotData.seaoView)
        : getTimesLookup(snapshotData.indiaView),
      perspectives: isSeao ? snapshotData.seaoPerspective : snapshotData.indiaPerspective
    }));
  }, [editedFilters]);

  return { allAvailableItems, setAllAvailableItems };
};

const useDefaultFilter = (
  region: CustomerRegion,
  lookup: IPerformanceFilters,
  defaultSeaoTimeFrame: number,
  defaultIndiaTimeFrame: number
) => {
  const [defaultFilter, setDefaultFilter] = useState<IPerformanceParam>();

  useEffect(() => {
    setDefaultFilter(() =>
      getDefaultFilters(region, lookup.categories, defaultSeaoTimeFrame, defaultIndiaTimeFrame)
    );
  }, [region]);

  return { defaultFilter };
};

export default PerformanceFilter;
