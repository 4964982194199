import { Route, Switch } from "react-router-dom";
import { IMenuConfig, MenuId } from "./utils/user-helpers";
import ReleaseNotesView from "./views/release-notes";
import { PageNotFound } from "./components/errors";
import { Suspense } from "react";
import AppViewLoader from "./views/app-view-loader";
import ApprovalView from "./management/view/approval-view";
import TicketLogView from "./management/view/ticket-log-view";
import EscalationFeedbackView from "./management/view/escalation-feedback-view";
import SubscriptionView from "./visualization/view/subscription-view";
import EscalationPerformanceView from "./visualization/view/escalation-performance-view";
import PerformanceCULevelView from "./visualization/view/performance-CU-level-view";
import EscalationDetailsView from "./visualization/view/escalation-details-view";
import GlobalOpenOrderListView from "./visualization/view/global-open-order-list-view";
import EFAdminView from "./management/view/admin-view";
import PerformanceHistoryView from "./spc/view/performance-history-view";
import SupplierFcstResView from "./spc/view/gft/supplier-fcst-res-view";
import SeaoVendorNominateView from "./spc/view/seao/seao-vendor-nominate-view";
import SeaoVendorEvaluationView from "./spc/view/seao/seao-vendor-evaluation-view";
import SeaoResultView from "./spc/view/seao/seao-result-view";
import IndiaVendorNominateView from "./spc/view/india/india-vendor-nominate-view";
import IndiaResultView from "./spc/view/india/india-result-view";

export const HOME_ROUTE = "/";
export const RELEASE_NOTE_ROUTE = "/release-notes";

const ESCALATION = "/escalation";
export const MANAGEMENT_ROUTE = ESCALATION + "/management";
export const ESCALATION_ADMIN_ROUTE = ESCALATION + "/admin";
export const TICKET_LOG_ROUTE = MANAGEMENT_ROUTE + "/ticket-log";
export const APPROVAL_ROUTE = MANAGEMENT_ROUTE + "/approval";
export const ESCALATION_FEEDBACK_ROUTE = MANAGEMENT_ROUTE + "/escalation-feedback";

export const VISUALIZATION_ROUTE = ESCALATION + "/visualization";
export const SUBSCRIPTION_ROUTE = VISUALIZATION_ROUTE + "/subscription";
export const ESCALATION_PERFORMANCE_ROUTE = VISUALIZATION_ROUTE + "/escalation-performance";
export const PERFORMANCE_CU_LEVEL_ROUTE = VISUALIZATION_ROUTE + "/performance-CU-level";
export const ESCALATION_DETAILS_ROUTE = VISUALIZATION_ROUTE + "/escalation-details";
export const GLOBAL_OPEN_ORDER_LIST_ROUTE = VISUALIZATION_ROUTE + "/global-open-order-list";

const SPC = "/spc";
export const PERFORMANCE_HISTORY_ROUTE = SPC + "/performance-history";

const SEAO = SPC + "/seao";
export const SEAO_VENDOR_NOMINATE_ROUTE = SEAO + "/vendor-nominate";
export const SEAO_VENDOR_EVALUATION_ROUTE = SEAO + "/vendor-evaluation";
export const SEAO_RESULT_ROUTE = SEAO + "/result";

const INDIA = SPC + "/india";
export const INDIA_VENDOR_NOMINATE_ROUTE = INDIA + "/vendor-nominate";
export const INDIA_RESULT_ROUTE = INDIA + "/result";

export const SUPPLIER_FCST_RES_ROUTE = SPC + "/supplier-fcst-res";

const EfMenuConfig: IMenuConfig[] = [
  {
    id: MenuId.TicketLog,
    route: TICKET_LOG_ROUTE,
    Component: TicketLogView
  },
  {
    id: MenuId.Approval,
    route: APPROVAL_ROUTE,
    Component: ApprovalView
  },
  {
    id: MenuId.Feedback,
    route: ESCALATION_FEEDBACK_ROUTE,
    Component: EscalationFeedbackView
  },
  {
    id: MenuId.Subscription,
    route: SUBSCRIPTION_ROUTE,
    Component: SubscriptionView
  },
  {
    id: MenuId.EscalationPerformance,
    route: ESCALATION_PERFORMANCE_ROUTE,
    Component: EscalationPerformanceView
  },
  {
    id: MenuId.PerformanceTrend,
    route: PERFORMANCE_CU_LEVEL_ROUTE,
    Component: PerformanceCULevelView
  },
  {
    id: MenuId.EscalationDetails,
    route: ESCALATION_DETAILS_ROUTE,
    Component: EscalationDetailsView
  },
  {
    id: MenuId.GlobalOpenOrderList,
    route: GLOBAL_OPEN_ORDER_LIST_ROUTE,
    Component: GlobalOpenOrderListView
  },
  {
    id: MenuId.Admin,
    route: ESCALATION_ADMIN_ROUTE,
    Component: EFAdminView
  }
];

const SpcMenuConfig: IMenuConfig[] = [
  {
    id: MenuId.GftSupplierResponseReport,
    route: SUPPLIER_FCST_RES_ROUTE,
    Component: SupplierFcstResView
  },
  {
    id: MenuId.SpcPerformanceHistory,
    route: PERFORMANCE_HISTORY_ROUTE,
    Component: PerformanceHistoryView
  },
  {
    id: MenuId.SpcSeaoVendorNominate,
    route: SEAO_VENDOR_NOMINATE_ROUTE,
    Component: SeaoVendorNominateView
  },
  {
    id: MenuId.SpcSeaoVendorEvaluation,
    route: SEAO_VENDOR_EVALUATION_ROUTE,
    Component: SeaoVendorEvaluationView
  },
  {
    id: MenuId.SpcSeaoResult,
    route: SEAO_RESULT_ROUTE,
    Component: SeaoResultView
  },
  {
    id: MenuId.SpcIndiaVendorNominate,
    route: INDIA_VENDOR_NOMINATE_ROUTE,
    Component: IndiaVendorNominateView
  },
  {
    id: MenuId.SpcIndiaResult,
    route: INDIA_RESULT_ROUTE,
    Component: IndiaResultView
  }
];

const userMenus = (menus?: string[]) => {
  return [...EfMenuConfig, ...SpcMenuConfig].filter(mc => (menus || []).includes(mc.id));
};

export const homeMenu = (menus?: string[]) =>
  userMenus(menus)?.length > 0 ? userMenus(menus)[0] : undefined;

const route = (key: string, path: string, Component: React.FC) => {
  return (
    <Route key={key} exact path={path}>
      <Component />
    </Route>
  );
};

const routes = (userMenus: IMenuConfig[], homeMenu?: IMenuConfig) => {
  return [
    ...userMenus.map(m => route(m.id, m.route, m.Component)),
    <Route key="release_notes" exact path={RELEASE_NOTE_ROUTE}>
      <ReleaseNotesView />
    </Route>,
    homeMenu ? route("home", HOME_ROUTE, homeMenu.Component) : undefined,
    <Route key="notfound" path="*">
      <PageNotFound />
    </Route>
  ].filter(m => m !== undefined);
};

const AppRouter = ({ menus }: { menus: string[] }) => {
  return (
    <Suspense fallback={<AppViewLoader />}>
      <Switch>{routes(userMenus(menus), homeMenu(menus))}</Switch>
    </Suspense>
  );
};

export default AppRouter;
