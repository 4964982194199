import { useApiGet, useApiGetForBlob } from "../../api/api";
import {
  ICategoryRadarItem,
  IExportParam,
  IVendorPerformanceData,
  IVendorPerformanceParam,
  IVendorRadarItem,
  IVendorRadarParam
} from "./seao-result";

export interface IIndiaPerformanceItem {
  id: number;
  actualLeadTime: number;
  deliveryPerRequest: number;
  deliveryPerFirstConfirm: number;
  vendor: string;
  vendorName: string;
  categoryName: string;
}

const INDIA_RESULT = "/v1/supply-performance/performance-result/indian";

export const useGetIndiaCategoryRadar = () =>
  useApiGet<ICategoryRadarItem[], { snapshot: number }>(INDIA_RESULT + "/category-radar");

export const useGetIndiaVendorRadar = () =>
  useApiGet<IVendorRadarItem[], IVendorRadarParam>(INDIA_RESULT + "/vendor-radar");

export const useGetIndiaVendorPerformance = () =>
  useApiGet<IVendorPerformanceData<IIndiaPerformanceItem>, IVendorPerformanceParam>(
    INDIA_RESULT + "/vendor-performance"
  );

export const useExportIndiaResult = () =>
  useApiGetForBlob<undefined, IExportParam>(INDIA_RESULT + "/export");
