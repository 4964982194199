import { Button, Dialog, Loader, useSettings } from "@react-gcc-eds/core";
import { AxiosPromise } from "axios";
import fileDownload from "js-file-download";
import React, { ReactElement, useEffect, useState } from "react";
import RenderInPortal from "../render-in-portal";

const Export = <T extends {} | undefined>({
  element,
  style,
  disabled,
  title,
  downloader,
  onSuccess,
  params
}: {
  element: ReactElement;
  style?: React.CSSProperties;
  disabled?: boolean;
  title?: string;
  downloader: () => [
    data: any,
    loading: boolean,
    get: (params?: T | undefined) => AxiosPromise<any>,
    cancel?: () => void,
    fileName?: string
  ];
  onSuccess?: () => void;
  params?: T;
}) => {
  const [data, exportRunning, exportData, cancel, fileName] = downloader();
  const [showDialog, setShowDialog] = useState(false);
  const { theme } = useSettings();

  useEffect(() => {
    if (data && !exportRunning && fileName) {
      fileDownload(data, fileName);
      setShowDialog(false);
      onSuccess && onSuccess();
    }
  }, [data, exportRunning]);

  return (
    <>
      <a
        href="#"
        role="button"
        style={{
          ...(disabled ? { pointerEvents: "none", opacity: 0.5 } : {}),
          borderBottomStyle: "none",
          color: theme === "light" ? "#242424" : "#f2f2f2",
          ...style
        }}
        onClick={() => {
          setShowDialog(true);
          exportData(params);
        }}
      >
        {element}
      </a>
      {showDialog && (
        <RenderInPortal>
          <Dialog
            title={title || "Export data in progress"}
            buttons={[
              <Button
                key="export-btn"
                onClick={() => {
                  cancel && cancel();
                  setShowDialog(false);
                }}
                warning
              >
                Cancel
              </Button>
            ]}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Loader size="medium" style={{ marginBottom: "16px" }} />
              Please don't leave this screen. It will cancel the operation.
            </div>
          </Dialog>
        </RenderInPortal>
      )}
    </>
  );
};

export default Export;
