import { useApiGet, useApiPost } from "./api";
import { EEfRoles, EMarketArea, Roles } from "../types";

export type TApiRole = EMarketArea | Roles;
interface ICurrentUser {
  id: string;
  userName: string;
  userEmail: string;
  escalationFeedbackRoles: EEfRoles[];
  validMenus: string[];
  roles: TApiRole[];
}

interface ISnapshot {
  indianSnapshots: number[];
  seaoSnapshots: number[];
}

export const useGetCurrentUser = () =>
  useApiGet<ICurrentUser | undefined>("/v1/users/user/get-current");
export const useCheckPortrait = () => useApiPost<{ accessToken: string }>("/auth/portrait");
export const useGetSnapshot = () => useApiGet<ISnapshot>("/v1/supply-performance/lookup/snapshots");
// export const useGetValidViews = () =>
//   useApiGet<IViewAccess[] | undefined>("/v1/user/access-management/valid-views");
