import { ReactNode, useEffect, useState } from "react";
import { IUploadFile } from "../../components/multiple-upload/MultipleUpload";
import { ITooltip, Icon, Tooltip } from "@react-gcc-eds/core";
import TicketError from "../component/ticket-base-dialog/ticket-error";
import { TEXT_BLUE } from "../../components/colorHelper";
import {
  ITicketApprovalEditedItems,
  ITicketAttachmentItem,
  ITicketBaseFormItem,
  ITicketDetailSoItem
} from "../api/ticket";
import { IEscalationLookup } from "../api/lookup";
import { addRowNumber } from "../../utils";
import { EscalationLevel, Tag } from "../helper";

export const useUploadFile = (attachments: ITicketAttachmentItem[] | undefined) => {
  const [files, setFiles] = useState<IUploadFile[]>([]);

  const apiToAttachments = (attachments: ITicketAttachmentItem[]): IUploadFile[] =>
    attachments
      ? attachments.map(({ attachmentName, id }) => ({
          id,
          fileName: attachmentName
        }))
      : [];

  useEffect(() => {
    if (attachments) {
      const files = apiToAttachments(attachments);
      setFiles(files);
    }
  }, [attachments]);

  return { files, setFiles };
};

export const renderLabel = (
  header: string,
  showError = false,
  errorMsg = "",
  tips?: string | ReactNode,
  tipsPosition?: ITooltip["position"]
) => {
  return (
    <label>
      {header} <span style={{ color: TEXT_BLUE }}>*</span>
      {tips && (
        <Tooltip position={tipsPosition ? tipsPosition : "top"} content={tips} type={"pointer"}>
          <Icon name={"info"} />
        </Tooltip>
      )}
      {showError && <TicketError msg={errorMsg} />}
    </label>
  );
};

export const NEW_ESCALATION = "New Escalation";

export const DEFAULT_SO_ITEM: ITicketDetailSoItem = {
  salesOrder: "",
  item: "",
  latestAcceptableReadyForShipmentDate: "",
  minimumCriticalDemandPerWeek: NaN,
  projectNameOrDeal: "",
  nroOrBoxDelivery: "",
  partialDeliveryAcceptable: undefined,
  currentReadyForShipmentDate: "",
  currentMaterialAvailableDate: "",
  customer: "",
  orderCreationDate: "",
  productReportingGroup: "",
  material: "",
  materialDesc: "",
  requestDeliveryDate: ""
};

export const DEFAULT_OPTIONS: IEscalationLookup = {
  escalationReasons: [],
  escalationRegions: [],
  ticketCategories: [],
  availBorrowHubs: []
};

export const DEFAULT_TICKET_DETAIL: ITicketBaseFormItem = {
  escalationReason: "",
  escalationRegion: "",
  businessImpact: "",
  preEscalationActivity: false,
  comments: undefined,
  ticketMaterials: []
};

export const DEFAULT_SO_ITEMS: ITicketDetailSoItem[] = addRowNumber([
  DEFAULT_SO_ITEM,
  DEFAULT_SO_ITEM,
  DEFAULT_SO_ITEM
]);

export const DEFAULT_APPROVAL_ITEM: ITicketApprovalEditedItems = {
  escalationStatus: "",
  escalationTag: Tag.A1P,
  comments: "",
  assignedUsers: [],
  escalationLevel: EscalationLevel.region
};

export const equalSoItem = (input: ITicketDetailSoItem, api: ITicketDetailSoItem): boolean => {
  return (
    input.salesOrder.trim() === api.salesOrderUserInput?.trim() &&
    input.item.trim() === api.itemUserInput?.trim()
  );
};
