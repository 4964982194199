import {
  getIndiaResultTableColumns,
  getSEAOResultTableColumns
} from "../../tables/columns/result-table";
import "./result-table.scss";
import PaginationTable from "../../../pagination-table";

export enum ESEAOResultLevel {
  good = "Good",
  excellent = "Excellent",
  conditional = "Conditional",
  unacceptable = "Unacceptable"
}

export interface ISEAOResultTableRow {
  vendorName: string;
  serviceSupport?: ESEAOResultLevel;
  deliveryRequest: ESEAOResultLevel;
  deliveryConfirmed: ESEAOResultLevel;
  ltwf: ESEAOResultLevel;
}

const ResultTable = ({
  resultList,
  total,
  currentPage,
  onPageChange,
  withService = true
}: {
  resultList: ISEAOResultTableRow[];
  total: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  withService?: boolean;
}) => {
  const columns = withService ? getSEAOResultTableColumns() : getIndiaResultTableColumns();
  const widths = withService ? ["20%", "20%", "20%", "20%", "20%"] : ["20%", "20%", "20%", "20%"];

  return (
    <div className="result-table">
      <PaginationTable
        columns={columns}
        row={resultList}
        totalPage={Math.ceil(total / 10)}
        onPageChange={onPageChange}
        totalValue={total}
        currentPage={currentPage}
        widths={widths}
      />
    </div>
  );
};

export default ResultTable;
