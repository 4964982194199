import { ReactElement } from "react";

export default interface IGauge {
  /** Initial value of the Gauge. It's value should be between the specified min and max values. */
  value: number;
  /** Settings  */
  dataSettings: {
    /** Dec  */
    decimals?: number;
    /** Should be the lowest value (minimum) of the gauge. */
    min?: number;
    /** Should be the highest value (maximum) of the gauge. */
    max?: number;
    /** The unit of the value of the Gauge. */
    units?: string;
    /** Select a size: "small", "medium", "large". */
    size?: string;
    /** A range of values, delimited by the attributes "from" and "to" that correspond to a specific state, defined with a "color" and a "label" message for the tooltip. */
    limits?: ILimit[];
  };
}

export enum EGaugeColor {
  "red" = "red",
  "orange" = "orange",
  "yellow" = "yellow",
  "green" = "green",
  "gray" = "gray"
}

export interface ILimit {
  /** The minimum value of the corresponding limit */
  from: number;
  /** The maximum value of the corresponding limit. */
  to: number;
  /** Color to denote state o severity. */
  color: EGaugeColor;
}
