import { useApiGet } from "../../api/api";
import { TUserBaseInfo } from "../../types";
import { IAssignedUserEditedItem } from "./ticket";

export interface IEscalationLookup {
  escalationReasons: string[];
  escalationRegions: string[];
  ticketCategories: string[];
  regionCategories?: string[];
  availBorrowHubs: string[];
}

export interface ISubscriptionLookup {
  customers: string[];
  escalationRegions: string[];
  ticketCategories: string[];
}

export interface IAssignUserParams {
  level: string;
  region: string;
  productReportingGroups: string[];
}

const EF_LOOKUP = "/v1/escalation-feedback/lookup";

export const useGetTicketSelections = () =>
  useApiGet<IEscalationLookup>(EF_LOOKUP + "/ticket-basic-selections");

export const useGetDefaultAssignedUsers = () =>
  useApiGet<{ categoryDefaultAssignees: IAssignedUserEditedItem[] }, IAssignUserParams>(
    EF_LOOKUP + "/category-default-assigned-users"
  );

export const useGetAllEfUsers = () => useApiGet<TUserBaseInfo[]>(EF_LOOKUP + "/all-assigned-users");

export const useGetSubscriptionOptions = () =>
  useApiGet<ISubscriptionLookup>(EF_LOOKUP + "/subscription-basic-selections");
