import { format } from "date-fns";
import { useMemo, useState } from "react";
import {
  Bar,
  BarChart as RechartsBarChart,
  Brush,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from "recharts";
import { roundNumberToLocale } from "../../utils";
import { useChartHelpers } from "./chart-helpers";
import LegendPills from "./legend";
import { ChartTooltip } from "./tooltip";
import { IBarChartData } from "./types";
import { useSelectedSeries } from "./selected-series";

interface IBarChart {
  data: IBarChartData;
  unitOfMeasure: string;
  orientation?: "vertical" | "horizontal";
  stacked?: boolean;
  barSize?: number;
  customColors?: string[];
  changeColorOnEachBar?: boolean;
  rotatedXAxisLabels?: boolean;
  withBrush?: boolean;
  invertTooltipOrder?: boolean;
  hideLegend?: boolean;
  hideUnitOfMeasure?: boolean;
  hideYAxisTick?: boolean;
  fixedTooltipTitle?: string;
  toolTipSecondaryValues?: string[];
  yAxisDecimalPlaces?: number;
  yAxisMax?: number;
  stackedDecimalPlaces?: number;
  multipleSeriesSelection?: boolean;
  onSeriesSelected?: (series: string[]) => void;
  onTickSelected?: (tick: string) => void;
  onBarSelected?: (bar: string, tick: string) => void;
}

const BarChart = ({
  data,
  unitOfMeasure,
  orientation = "vertical",
  stacked,
  barSize = 6,
  customColors,
  changeColorOnEachBar,
  rotatedXAxisLabels,
  withBrush = true,
  invertTooltipOrder,
  hideLegend = false,
  hideUnitOfMeasure = false,
  hideYAxisTick = false,
  fixedTooltipTitle,
  toolTipSecondaryValues,
  yAxisDecimalPlaces = 0,
  yAxisMax,
  stackedDecimalPlaces = 0,
  multipleSeriesSelection = false,
  onSeriesSelected,
  onTickSelected,
  onBarSelected
}: IBarChart) => {
  const [chartSize, setChartSize] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0
  });

  const {
    selectedSeries,
    hoveredSeries,
    handleSeriesSelected,
    handleSeriesHovered,
    handleSeriesUnhovered
  } = useSelectedSeries({
    onSeriesSelected,
    allowMultipleSelection: multipleSeriesSelection
  });

  const { getColor, chartDataAsRechartsData, isMonthValue } = useChartHelpers();

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomYAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${0},${y})`}>
        <text x={x + 12} y={0 - 16} textAnchor="start">
          {payload.value}
        </text>
      </g>
    );
  };

  const leftMargin = () => {
    const nofSamples = data.common.length;
    let max = 0;

    for (let i = 0; i < nofSamples; i++) {
      const total = data.series.map(s => s.values[i].value).reduce((sum, v) => sum + v, 0);
      if (total > max) max = total;
    }

    const result = max < 1000 ? 0 : max < 10000 ? 5 : max < 100000 ? 10 : 20;
    return result;
  };

  const bottomMargin = () =>
    data.common.reduce(
      (max: number, s: string) => ((s || "").length > max ? (s || "").length : max),
      0
    ) * 4;

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        if (entry.contentRect.height > 0 && entry.contentRect.width > 0) {
          setChartSize({ height: entry.contentRect.height, width: entry.contentRect.width });
        }
      }),
    []
  );

  return (
    <ResponsiveContainer
      ref={(obj: any) => (obj?.current ? resizeObserver.observe(obj.current) : null)}
      height="99%"
    >
      <RechartsBarChart
        layout={orientation === "horizontal" ? "vertical" : "horizontal"}
        data={chartDataAsRechartsData(data, selectedSeries)}
        margin={
          rotatedXAxisLabels && orientation === "vertical"
            ? {
                top: hideLegend && hideUnitOfMeasure ? 10 : hideUnitOfMeasure ? 20 : 40,
                left: hideUnitOfMeasure ? leftMargin() - 30 : leftMargin(),
                right: 0,
                bottom: bottomMargin()
              }
            : //  TODO: -55 is not hiding the ticks just forcing graph to left alignment
              {
                top: hideUnitOfMeasure ? 0 : 40,
                left: hideLegend && hideUnitOfMeasure ? -55 : hideLegend ? -10 : 0,
                right: 0
              }
        }
      >
        <Tooltip
          cursor={{ fill: "transparent" }}
          content={(props: TooltipProps) => (
            <ChartTooltip
              fixedLabel={fixedTooltipTitle}
              inverted={invertTooltipOrder}
              props={props}
              units={[unitOfMeasure]}
              secondaryValues={toolTipSecondaryValues}
            />
          )}
        />
        <CartesianGrid
          vertical={orientation === "horizontal"}
          horizontal={orientation === "vertical"}
        />
        {!hideLegend && (
          <Legend
            content={
              <LegendPills
                series={data.series.map(s => ({
                  name: s.name,
                  selected: selectedSeries.includes(s.name),
                  tooltip: s.tooltip
                }))}
                customColors={customColors}
                onMouseEnter={handleSeriesHovered}
                onMouseLeave={handleSeriesUnhovered}
                onClick={handleSeriesSelected}
              />
            }
            align="left"
            layout="horizontal"
            verticalAlign="top"
          />
        )}
        <XAxis
          tickLine={false} //eds alignment
          axisLine={false} //eds alignment
          tick={orientation === "vertical" && rotatedXAxisLabels ? CustomXAxisTick : true}
          dataKey={orientation === "vertical" ? "name" : undefined}
          type={orientation === "vertical" ? "category" : "number"}
          domain={[0, dataMax => (orientation === "horizontal" && yAxisMax ? yAxisMax : dataMax)]}
          interval={orientation === "vertical" && rotatedXAxisLabels ? 0 : "preserveStartEnd"}
          padding={orientation === "horizontal" ? { right: 8, left: 0 } : undefined}
          onClick={
            orientation === "vertical" ? b => onTickSelected && onTickSelected(b.value) : undefined
          }
          tickFormatter={(val: any) => {
            if (orientation === "vertical") {
              let formatted = val;
              if (isMonthValue(formatted)) {
                formatted = format(new Date(val), "MMM yy");
              }
              return formatted;
            }
            return roundNumberToLocale(val, yAxisDecimalPlaces);
          }}
        >
          {!hideUnitOfMeasure && orientation === "horizontal" && (
            <Label value={unitOfMeasure} position="insideRight" />
          )}
        </XAxis>
        <YAxis
          tickLine={false}
          axisLine={false}
          tick={hideYAxisTick ? false : orientation === "horizontal" ? CustomYAxisTick : true}
          dataKey={orientation === "horizontal" ? "name" : undefined}
          type={orientation === "horizontal" ? "category" : "number"}
          tickFormatter={(val: any) =>
            orientation === "horizontal" ? val : roundNumberToLocale(val, yAxisDecimalPlaces)
          }
          domain={[0, dataMax => (orientation === "vertical" && yAxisMax ? yAxisMax : dataMax)]}
          interval="preserveStartEnd"
          onClick={
            orientation === "horizontal"
              ? b => onTickSelected && onTickSelected(b.value)
              : undefined
          }
          minTickGap={orientation === "horizontal" ? 20 : 0}
        >
          {!hideUnitOfMeasure && orientation === "vertical" && (
            <Label value={unitOfMeasure} position="top" offset={22} />
          )}
        </YAxis>
        {data.series.map((s, index) => (
          <Bar
            isAnimationActive={false}
            opacity={hoveredSeries ? (s.name === hoveredSeries ? 1 : 0.2) : 1}
            key={s.name}
            dataKey={s.name}
            stackId={stacked ? "stacked" : undefined}
            barSize={barSize}
            fill={getColor(index, customColors)}
            onClick={b => onBarSelected && onBarSelected(s.name, b.name)}
          >
            {!stacked &&
              changeColorOnEachBar &&
              data.common.map((c, index) => <Cell key={c} fill={getColor(index, customColors)} />)}
            {stacked && index === data.series.length - 1 && (
              <LabelList
                position={orientation === "vertical" ? "top" : "right"}
                valueAccessor={entry => {
                  const val = Object.entries(entry.payload).reduce(
                    (sum: number, [key, value]) => sum + (key === "name" ? 0 : (value as number)),
                    0
                  );
                  return roundNumberToLocale(val, stackedDecimalPlaces);
                }}
              />
            )}
          </Bar>
        ))}
        {orientation === "vertical" &&
          withBrush &&
          chartSize &&
          barSize &&
          Math.round(chartSize.width / (barSize + 10)) < data.common.length - 1 && (
            <Brush
              y={Math.round(chartSize.height - 12)}
              endIndex={Math.round(chartSize.width / (barSize + 10))}
              dataKey="name"
              height={12}
            />
          )}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
