import { DateField, TableColumn } from "@react-gcc-eds/core";
import { edsText } from "../../helpers/ef-table";
import { IOaItem } from "../../api/sales-order";
import { ITicketDetailSoItem } from "../../api/ticket";

export const getEfPreviewColumns = ({
  data,
  showProjectName,
  editable,
  onOaChanged
}: {
  data: IOaItem[];
  showProjectName?: boolean;
  editable?: boolean;
  onOaChanged?: (row: ITicketDetailSoItem) => (date: Date | undefined) => void;
}): TableColumn[] => {
  const getOaByRow = (row: ITicketDetailSoItem): Date | undefined => {
    const oaItem: IOaItem = data.filter(
      item => row.salesOrder === item.salesOrder && row.item === item.item
    )[0];
    return oaItem && oaItem.improvedOrderAcknowledgeDate
      ? new Date(oaItem.improvedOrderAcknowledgeDate)
      : undefined;
  };

  const oaCell = (row: any) => (
    <DateField
      selectedDate={getOaByRow(row)}
      onDateSelected={onOaChanged && onOaChanged(row)}
      onChange={text => !text && onOaChanged && onOaChanged(row)(undefined)}
    />
  );

  return [
    edsText("salesOrder", "Eab SO"),
    edsText("item", "Item"),
    edsText("customer", "Customer"),
    edsText("orderCreationDate", "Eab SO Creating Date"),
    edsText("material", "Material"),
    edsText("materialDesc", "Description For Material"),
    edsText("quantity", "Quantity"),
    edsText("vendorPo", "Vendor Po"),
    edsText("vendor", "Vendor"),
    edsText("vendorCode", "Vendor Code"),
    ...(showProjectName ? [edsText("projectNameOrDeal", "Project Name/Deal")] : []),
    ...(editable
      ? [{ ...edsText("improvedOrderAcknowledgeDate", "Improved OA"), Cell: oaCell }]
      : []),
    edsText("orderPlacedHub", "Order Placed Hub"),
    edsText("requestDeliveryDate", "Latest Request Delivery Date"),
    edsText("latestRequestMaterialAvailableDate", "Latest Request MAD")
  ];
};
