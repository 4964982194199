import { useState } from "react";
import { Prompt } from "react-router";
import { Dialog, Button } from "@react-gcc-eds/core";
import RenderInPortal from "./render-in-portal";

export const usePendingWorkGuard = () => {
  const [routeGuard, setRouteGuard] = useState<{
    active: boolean;
    message?: string;
    callback?: (leave: boolean) => void;
  }>({
    active: false,
    message: undefined,
    callback: undefined
  });

  const toggleUnsavedWork = (param: boolean, callback?: (p: boolean) => void) => () => {
    callback && callback(param);
    setRouteGuard(guard => ({ active: !guard.active, message: undefined }));
  };

  const UnsavedWork = ({
    message,
    onConfirm,
    onCancel
  }: {
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
  }) => {
    return (
      <RenderInPortal>
        <Dialog
          title="Warning"
          buttons={
            <>
              <Button onClick={onConfirm}>Leave</Button>
              <Button primary onClick={onCancel}>
                Stay
              </Button>
            </>
          }
        >
          {message}
          <div>Any change will be lost if you leave the page.</div>
        </Dialog>
      </RenderInPortal>
    );
  };

  const Guard = () =>
    routeGuard.active ? (
      <UnsavedWork
        message={routeGuard.message || ""}
        onConfirm={toggleUnsavedWork(true, routeGuard.callback)}
        onCancel={toggleUnsavedWork(false, routeGuard.callback)}
      />
    ) : null;

  const setGuard = (message: string, callback: (param: boolean) => void) =>
    setRouteGuard(guard => ({ ...guard, active: true, message, callback }));

  const PendingWorkGuard = ({ when, message }: { when: boolean; message: string }) => {
    return <Prompt message={() => (when ? message : true)} />;
  };

  return { Guard, setGuard, PendingWorkGuard };
};
