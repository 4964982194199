import { Pill, Tooltip } from "@react-gcc-eds/core";
import { useEffect, useRef, useState } from "react";
import { useChartHelpers } from "./chart-helpers";

export interface ILegend {
  series: { name: string; selected?: boolean; tooltip?: string }[];
  customColors?: string[];
  onMouseEnter?: (series: string) => void;
  onMouseLeave?: (series: string) => void;
  onClick?: (series: string) => void;
}

const Legend = ({ series, customColors, onMouseEnter, onMouseLeave, onClick }: ILegend) => {
  const mounted = useRef<{ hovered: boolean } | undefined>(undefined);
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const [hovered, setHovered] = useState({ series: "", hovered: false });

  const { getColor } = useChartHelpers();

  useEffect(() => {
    if (mounted.current?.hovered) {
      if (hovered.hovered && onMouseEnter) {
        if (timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = undefined;
        }
        onMouseEnter(hovered.series);
      } else if (onMouseLeave) {
        if (timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = undefined;
        }
        timeout.current = setTimeout(() => onMouseLeave(hovered.series), 300);
      }
    } else {
      mounted.current = {
        hovered: true
      };
    }
  }, [hovered.series, hovered.hovered]);

  const anySelected = series.some(s => s.selected);
  const renderPill = (s: { name: string; selected?: boolean }, index: number) => (
    <Pill
      key={s.name}
      className={anySelected && !s.selected ? "unselected" : ""}
      onMouseMove={() => setHovered(h => ({ ...h, series: s.name, hovered: true }))}
      onMouseLeave={() => setHovered(h => ({ ...h, series: s.name, hovered: false }))}
      onToggle={() => onClick && onClick(s.name)}
    >
      <span>
        <i className="icon icon-alarm-level5" style={{ color: getColor(index, customColors) }} />
        {s.name}
      </span>
    </Pill>
  );
  return (
    <div className="legend-wrapper-outer">
      <div className="legend-wrapper-inner">
        {series.map((s, index) =>
          s.tooltip ? (
            <Tooltip key={s.name} position="left" type="pointer" text={s.tooltip}>
              {renderPill(s, index)}
            </Tooltip>
          ) : (
            renderPill(s, index)
          )
        )}
      </div>
    </div>
  );
};

export default Legend;
