import { Menu, MenuItem, MenuNode } from "@react-gcc-eds/core";
import { ReactElement, ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  APPROVAL_ROUTE,
  ESCALATION_ADMIN_ROUTE,
  ESCALATION_FEEDBACK_ROUTE,
  HOME_ROUTE,
  SEAO_VENDOR_NOMINATE_ROUTE,
  SEAO_VENDOR_EVALUATION_ROUTE,
  SEAO_RESULT_ROUTE,
  INDIA_VENDOR_NOMINATE_ROUTE,
  INDIA_RESULT_ROUTE,
  PERFORMANCE_HISTORY_ROUTE,
  TICKET_LOG_ROUTE,
  SUPPLIER_FCST_RES_ROUTE,
  SUBSCRIPTION_ROUTE,
  ESCALATION_PERFORMANCE_ROUTE,
  PERFORMANCE_CU_LEVEL_ROUTE,
  ESCALATION_DETAILS_ROUTE,
  GLOBAL_OPEN_ORDER_LIST_ROUTE,
  homeMenu
} from "../app-router";
import { MenuId, useUserHelpers } from "../utils/user-helpers";

enum ENode {
  EscalationFeedback = "EscalationFeedback",
  EscalationFeedbackManagement = "EscalationFeedbackManagement",
  EscalationFeedbackVisualization = "EscalationFeedbackVisualization",
  SupplyPerformance = "SupplyPerformance",
  SupplyPerformanceSeao = "SupplyPerformanceSeao",
  SupplyPerformanceIndia = "SupplyPerformanceIndia"
}

export const useAppMenu = (menus: string[]) => {
  const location = useLocation();
  const { canAccessEf, canAccessSpc, canAccessView } = useUserHelpers();

  const homeRoute = () => homeMenu(menus)?.route || "";

  const isMyRoute = (node: string) => {
    const menuRoutes: { [key: string]: string[] } = {
      [ENode.EscalationFeedback]: [
        ESCALATION_FEEDBACK_ROUTE,
        APPROVAL_ROUTE,
        TICKET_LOG_ROUTE,
        SUBSCRIPTION_ROUTE,
        ESCALATION_ADMIN_ROUTE,
        ESCALATION_PERFORMANCE_ROUTE,
        PERFORMANCE_CU_LEVEL_ROUTE,
        ESCALATION_DETAILS_ROUTE,
        GLOBAL_OPEN_ORDER_LIST_ROUTE
      ],
      [ENode.EscalationFeedbackManagement]: [
        ESCALATION_FEEDBACK_ROUTE,
        APPROVAL_ROUTE,
        TICKET_LOG_ROUTE
      ],
      [ENode.SupplyPerformance]: [
        SEAO_VENDOR_NOMINATE_ROUTE,
        SEAO_VENDOR_EVALUATION_ROUTE,
        SEAO_RESULT_ROUTE,
        PERFORMANCE_HISTORY_ROUTE,
        INDIA_VENDOR_NOMINATE_ROUTE,
        INDIA_RESULT_ROUTE,
        SUPPLIER_FCST_RES_ROUTE
      ],
      [ENode.SupplyPerformanceSeao]: [
        SEAO_VENDOR_NOMINATE_ROUTE,
        SEAO_VENDOR_EVALUATION_ROUTE,
        SEAO_RESULT_ROUTE
      ],
      [ENode.SupplyPerformanceIndia]: [INDIA_VENDOR_NOMINATE_ROUTE, INDIA_RESULT_ROUTE],
      [ENode.EscalationFeedbackVisualization]: [
        ESCALATION_PERFORMANCE_ROUTE,
        PERFORMANCE_CU_LEVEL_ROUTE,
        ESCALATION_DETAILS_ROUTE,
        GLOBAL_OPEN_ORDER_LIST_ROUTE
      ],
      visibility: []
    };

    return (menuRoutes[node] || []).some(r =>
      (location.pathname === HOME_ROUTE ? homeRoute() : location.pathname).startsWith(r)
    );
  };

  const MenuTag =
    (url: string) =>
    ({ children }: { children: ReactNode }) => {
      return (
        <NavLink
          className="item"
          to={url}
          isActive={(_, location) =>
            location.pathname.startsWith(url) ||
            (location.pathname === HOME_ROUTE && homeRoute() === url)
          }
        >
          {children}
        </NavLink>
      );
    };

  const EfItems = () => {
    const items: any[] = [];

    canAccessView(MenuId.TicketLog, menus) &&
      items.push(
        <MenuItem key="ef-ticketLog" tag={MenuTag(TICKET_LOG_ROUTE)}>
          Ticket Log
        </MenuItem>
      );
    canAccessView(MenuId.Approval, menus) &&
      items.push(
        <MenuItem key="ef-approval" tag={MenuTag(APPROVAL_ROUTE)}>
          Approval
        </MenuItem>
      );
    canAccessView(MenuId.Feedback, menus) &&
      items.push(
        <MenuItem key="ef-feedback" tag={MenuTag(ESCALATION_FEEDBACK_ROUTE)}>
          Escalation Feedback
        </MenuItem>
      );
    canAccessView(MenuId.Subscription, menus) &&
      items.push(
        <MenuItem key="ef-subscription" tag={MenuTag(SUBSCRIPTION_ROUTE)}>
          Subscription
        </MenuItem>
      );
    (canAccessView(MenuId.EscalationPerformance, menus) ||
      canAccessView(MenuId.PerformanceTrend, menus) ||
      canAccessView(MenuId.EscalationDetails, menus) ||
      canAccessView(MenuId.GlobalOpenOrderList, menus)) &&
      items.push(
        <MenuNode
          key="ef-visualization"
          initialExpandedState={isMyRoute(ENode.EscalationFeedbackVisualization)}
          title="Visualization"
        >
          {
            [
              canAccessView(MenuId.EscalationPerformance, menus) && (
                <MenuItem
                  key="ef-escalation-performance"
                  tag={MenuTag(ESCALATION_PERFORMANCE_ROUTE)}
                >
                  Escalation Performance
                </MenuItem>
              ),
              canAccessView(MenuId.PerformanceTrend, menus) && (
                <MenuItem key="ef-performanceTrend" tag={MenuTag(PERFORMANCE_CU_LEVEL_ROUTE)}>
                  Performance Trend
                </MenuItem>
              ),
              canAccessView(MenuId.EscalationDetails, menus) && (
                <MenuItem key="ef-escalationDetails" tag={MenuTag(ESCALATION_DETAILS_ROUTE)}>
                  Escalation Details
                </MenuItem>
              ),
              canAccessView(MenuId.GlobalOpenOrderList, menus) && (
                <MenuItem key="ef-globalOpenOrderList" tag={MenuTag(GLOBAL_OPEN_ORDER_LIST_ROUTE)}>
                  Global Open Order List
                </MenuItem>
              )
            ] as ReactElement<typeof MenuItem>[]
          }
        </MenuNode>
      );
    canAccessView(MenuId.Admin, menus) &&
      items.push(
        <MenuItem key="ef-admin" tag={MenuTag(ESCALATION_ADMIN_ROUTE)}>
          Admin
        </MenuItem>
      );
    return items;
  };

  const SpcItems = () => {
    const items: any[] = [];

    canAccessView(MenuId.GftSupplierResponseReport, menus) &&
      items.push(
        <MenuItem key="SupplierFCSTResSM" tag={MenuTag(SUPPLIER_FCST_RES_ROUTE)}>
          Supplier FCST Res - SM
        </MenuItem>
      );
    canAccessView(MenuId.SpcPerformanceHistory, menus) &&
      items.push(
        <MenuItem key="PerformanceHistory" tag={MenuTag(PERFORMANCE_HISTORY_ROUTE)}>
          Performance History
        </MenuItem>
      );
    (canAccessView(MenuId.SpcSeaoVendorNominate, menus) ||
      canAccessView(MenuId.SpcSeaoVendorEvaluation, menus) ||
      canAccessView(MenuId.SpcSeaoResult, menus)) &&
      items.push(
        <MenuNode
          title={"SEAO"}
          key={"spc-seao"}
          initialExpandedState={isMyRoute(ENode.SupplyPerformanceSeao)}
        >
          {
            [
              canAccessView(MenuId.SpcSeaoVendorNominate, menus) && (
                <MenuItem key={"spcSeaoVendorNominate"} tag={MenuTag(SEAO_VENDOR_NOMINATE_ROUTE)}>
                  Vendor Nominate
                </MenuItem>
              ),
              canAccessView(MenuId.SpcSeaoVendorEvaluation, menus) && (
                <MenuItem
                  key={"spcSeaoVendorEvaluation"}
                  tag={MenuTag(SEAO_VENDOR_EVALUATION_ROUTE)}
                >
                  Vendor Evaluation
                </MenuItem>
              ),
              canAccessView(MenuId.SpcSeaoResult, menus) && (
                <MenuItem key={"spcSeaoResult"} tag={MenuTag(SEAO_RESULT_ROUTE)}>
                  Result
                </MenuItem>
              )
            ] as ReactElement<typeof MenuItem>[]
          }
        </MenuNode>
      );

    (canAccessView(MenuId.SpcIndiaVendorNominate, menus) ||
      canAccessView(MenuId.SpcIndiaResult, menus)) &&
      items.push(
        <MenuNode
          title={"India"}
          key={"spc-india"}
          initialExpandedState={isMyRoute(ENode.SupplyPerformanceIndia)}
        >
          {
            [
              canAccessView(MenuId.SpcIndiaVendorNominate, menus) && (
                <MenuItem key={"spcIndiaVendorNominate"} tag={MenuTag(INDIA_VENDOR_NOMINATE_ROUTE)}>
                  Vendor Nominate
                </MenuItem>
              ),
              canAccessView(MenuId.SpcIndiaResult, menus) && (
                <MenuItem key={"spcIndiaResult"} tag={MenuTag(INDIA_RESULT_ROUTE)}>
                  Result
                </MenuItem>
              )
            ] as ReactElement<typeof MenuItem>[]
          }
        </MenuNode>
      );
    return items;
  };

  return (
    <Menu>
      {canAccessEf(menus) ? (
        <MenuNode
          title="Escalation Management"
          initialExpandedState={isMyRoute(ENode.EscalationFeedback)}
        >
          {EfItems()}
        </MenuNode>
      ) : (
        <></>
      )}
      {canAccessSpc(menus) ? (
        <MenuNode
          title="Supplier Performance"
          initialExpandedState={isMyRoute(ENode.SupplyPerformance)}
        >
          {SpcItems()}
        </MenuNode>
      ) : (
        <></>
      )}
    </Menu>
  );
};
