import { useEffect, useRef } from "react";
import { IListBuilderItem } from "../components/list-builder";
import { getWeek, getQuarter, getMonth, getYear } from "date-fns";

export const stringAsListBuilderItems = (items: string[]): IListBuilderItem[] =>
  items.map(i => ({ text: i, value: i }));

export const getIEVersion = () => {
  const sAgent = window.navigator.userAgent;
  const Idx = sAgent.indexOf("MSIE");

  // If IE, return version number.
  if (Idx > 0) return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
  // If IE 11 then look for Updated user agent string.
  else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
  else return 0; //It is not IE
};

export const useClickOutside = (handler: EventListener, dependencies?: any) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseClick = (e: Event) => {
    if (containerRef.current && containerRef.current.contains(e.target as Node)) {
      // inside click
      return;
    }

    // outside click
    handler(e);
  };

  useEffect(() => {
    document.addEventListener("click", handleMouseClick, true);
    return () => {
      document.removeEventListener("click", handleMouseClick, true);
    };
  }, [dependencies]);

  return containerRef;
};

export const getRandom = (n: number, m: number) => {
  let result = Math.random() * (m + 1 - n) + n;
  while (result > m) {
    result = Math.random() * (m + 1 - n) + n;
  }
  return result;
};

export const picker = <Targets, Original extends {}>({
  obj,
  keys
}: {
  obj: Original;
  keys: (keyof Original)[];
}) => {
  const result: any = {};
  keys.forEach(i => (result[i] = obj[i]));
  return result as Targets;
};

export const keyReader = <Object,>(obj: Object) => {
  return Object.keys(obj) as unknown as (keyof Object)[];
};

export const getUTCTimestamp = (dateTime?: string | Date | number) =>
  dateTime ? new Date(dateTime).getTime() : new Date().getTime();

export const utcTimestampToLocalDateTime = (utcTimestamp: number) => new Date(utcTimestamp);

export const capitalize = (s: string): string => {
  const words = s.toString().split("");
  words[0] = words[0].toUpperCase();
  const reg = /^[A-Z]+$/;
  let preUpper = false;
  for (let i = 1; i < words.length; i++) {
    const upper: boolean = reg.test(words[i]);
    if (upper && !preUpper) {
      words[i] = " " + words[i];
    }
    preUpper = upper;
  }
  return words.join("");
};

export const roundNumberToLocale = (value: number, decimals: number): string => {
  let inValue = value;
  if (value === undefined || value === null || isNaN(value)) {
    inValue = 0;
    console.warn("value provided for formatting was not a number", value);
  }
  try {
    // parseFloat is mandatory to force the locale separator, then toFixed to set numbers of decimal
    // hence the separator is client specific and not to be forced
    const num = parseFloat(inValue.toFixed(decimals)).toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
    return num;
  } catch {
    console.log("Can't round ", inValue);
    return "";
  }
};
export const formatUnitAndToLocale = (value: number, decimals: number): string => {
  if (value > 1000000) {
    return `${roundNumberToLocale(value / 1000000, decimals)} M`;
  }
  return value > 1000
    ? `${roundNumberToLocale(value / 1000, decimals)} K`
    : roundNumberToLocale(value, decimals);
};
export const dateTimeFormatter = (d: Date, includeTime?: boolean) => {
  if (!includeTime) {
    return d.toLocaleDateString();
  }
  return d.toLocaleDateString() + " " + d.toLocaleTimeString();
};

export const column = (
  key: string,
  header: string,
  Cell?: ((row: object) => React.ReactNode) | undefined
) => {
  return { key, header, Cell };
};

export const getFileNameFromResponse = (response: any) => {
  try {
    const contentDisposition = response.headers["content-disposition"];
    let fileName = undefined;
    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }
    return fileName;
  } catch (error) {
    console.warn("Could not retrieve file name", error);
    throw error;
  }
};

export const uniqueId = (prefix?: string) => {
  return `${prefix || "id"}-${Math.random().toString(36)}`;
};

export const useFocus = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return { inputRef };
};

export const unicodeNumberId = () => new Date().getTime() + Math.random();

export const createDataAttributeObject = (dataAttribute: { [key: string]: string | number }) => {
  const dataAttribs: any = {};
  Object.keys(dataAttribute).forEach(key => {
    dataAttribs[`data-${key}`] = dataAttribute[key];
  });
  return dataAttribs;
};

export const booleanSorter =
  <T extends { [key: string]: any } | number | string>(selector: (item: T) => boolean) =>
  (a: T, b: T) => {
    if (selector(a) && !selector(b)) return -1;
    if (selector(b) && !selector(a)) return 1;
    return 0;
  };

export const getFormatWeek = (time: number) => {
  const year = getYear(time);
  const week = getWeek(time);
  return `${year}-WK${week}`;
};

export const getFormatMonth = (time: number) => {
  const year = getYear(time);
  const month = getMonth(time);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  return `${year}-${months[month]}`;
};

export const getFormatQuarter = (time: number) => {
  const year = getYear(time);
  const quarter = getQuarter(time);
  return `${year}-Q${quarter}`;
};

export const addRowNumber = (data: any[]) =>
  data.map((item, index) => ({ ...item, rowNumber: index }));

export const getUTCDate = (date: number | string): string =>
  new Date(date).toISOString().slice(0, 10).replaceAll("-", "/");

// We need to first uri encode and then base64 encode to pass through the gateway
export const base64EncodedUri = (value: string) => {
  return btoa(encodeURIComponent(value));
};

export const addIfNotExisting = <Data,>(
  arr: Data[],
  item: Data,
  keyPicker?: (arr: Data) => boolean
) => {
  if (arr.findIndex(keyPicker ? keyPicker : value => value === item) > -1) {
    return arr;
  } else {
    return [...arr, item];
  }
};

export const isValueNull = (value: any) =>
  value === undefined || value === null || isNaN(value) || value === "";

export const padStringWithZeros = (str: string, length: number) => {
  while (str.length < length) {
    str = "0" + str;
  }
  return str;
};

export const getISODate = (date: number | string): string => new Date(date).toISOString();
