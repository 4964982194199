import { Button, Tile } from "@react-gcc-eds/core";
import { useGrid } from "../../../components/grid/grid";
import Loader from "../../../components/loader";
import { getTicketLogColumns } from "../../grid/views-columns/ticket-log";
import "./ticket-log-editor.scss";
import {
  TableId,
  ViewId,
  useTablePreferencesHandler
} from "../../../helpers/table-preferences-handler";
import { ITicketHistory } from "../../api/ticket";
import Export from "../../../components/export";
import { useDownloadSoTemplate } from "../../api/sales-order";
import { useMemo } from "react";

const TicketLogEditor = ({
  data,
  loadingTable,
  loadingLookup,
  canEdit,
  onShowNewEscalation,
  onRowClick
}: {
  data: ITicketHistory[];
  loadingTable: boolean;
  loadingLookup: boolean;
  canEdit: boolean;
  onShowNewEscalation: () => void;
  onRowClick: (row: ITicketHistory) => void;
}) => {
  //persist filter
  const { preferencesHandler } = useTablePreferencesHandler(
    ViewId.VIS_TICKETLOG,
    TableId.VIS_TICKETLOG
  );

  const columns = useMemo(() => {
    return getTicketLogColumns();
  }, []);

  const { renderTable } = useGrid<ITicketHistory>({
    columns,
    clientModel: {
      data,
      rowKeySelector: data => data.ticketNumber.toString()
    },
    showColumnsFiltersInfoAt: "left",
    preferencesHandler,
    onRowClicked: row => {
      onRowClick(row);
    }
  });

  return (
    <Tile title="Ticket History" className="ticket-log">
      <div className="ticket-log-header">
        {canEdit && (
          <Button
            primary
            onClick={onShowNewEscalation}
            loading={loadingLookup}
            className="ticket-log-header-btn"
          >
            New Escalation
          </Button>
        )}
        <Export
          element={<Button>Download so & item template</Button>}
          downloader={useDownloadSoTemplate}
        />
      </div>
      <div className="ticket-log-table">{loadingTable ? <Loader noTile /> : renderTable()}</div>
    </Tile>
  );
};

export default TicketLogEditor;
