import { useApiGet, useApiGetForBlob } from "../../api/api";
import { IRadarChartData } from "../../components/charts/radar-chart";

export interface ICategoryRadarItem {
  category: string;
  data: IRadarChartData[];
}

export interface IVendorRadarItem {
  vendorName: string;
  data: IRadarChartData[];
}

export interface IVendorRadarParam {
  snapshot: number;
  category: string;
}

export interface IVendorPerformanceParam {
  snapshot: number;
  category?: string;
  offset: number;
  limit?: number;
}

export interface IVendorPerformanceItem {
  id: number;
  actualLeadTime: number;
  deliveryPerRequest: number;
  deliveryPerFirstConfirm: number;
  serviceAndSupport: number;
  vendor: string;
  vendorName: string;
  categoryName: string;
}

export interface IVendorPerformanceData<Performance> {
  total: number;
  data: Performance[];
  msg: string;
}

export interface IExportParam {
  snapshot: number;
  category?: string;
}

const SEAO_RESULT = "/v1/supply-performance/performance-result/seao";

export const useGetCategoryRadar = () =>
  useApiGet<ICategoryRadarItem[], { snapshot: number }>(SEAO_RESULT + "/category-radar");

export const useGetVendorRadar = () =>
  useApiGet<IVendorRadarItem[], IVendorRadarParam>(SEAO_RESULT + "/vendor-radar");

export const useGetVendorPerformance = () =>
  useApiGet<IVendorPerformanceData<IVendorPerformanceItem>, IVendorPerformanceParam>(
    SEAO_RESULT + "/vendor-performance"
  );

export const useExportResult = () =>
  useApiGetForBlob<undefined, IExportParam>(SEAO_RESULT + "/export");
