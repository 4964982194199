import { useMsal } from "@azure/msal-react";
import { ProviderState, Providers, SimpleProvider } from "@microsoft/mgt-element";
import { applyTheme } from "@microsoft/mgt-react";
import { useSettings } from "@react-gcc-eds/core";
import { useEffect } from "react";

const InitMgt = () => {
  const { instance, accounts } = useMsal();
  const { theme } = useSettings();

  useEffect(() => {
    applyTheme(theme as "dark" | "light");
  }, [theme]);

  useEffect(() => {
    const getToken = async () => {
      const auth = await instance.acquireTokenSilent({
        scopes: ["user.read"],
        account: accounts[0]
      });
      return auth.accessToken;
    };
    Providers.globalProvider = new SimpleProvider(getToken);
    Providers.globalProvider.setState(ProviderState.SignedIn);
  }, []);

  return <></>;
};

export default InitMgt;
