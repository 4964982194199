import "./buckets.scss";
import { EscalationProgress } from "../helper";
import FeedbackCard from "./feedbackCard";
import { Icon } from "@react-gcc-eds/core";
import { useMemo, useState } from "react";
import { ITicketTaskItem } from "../api/task";

export interface TBucketsItem {
  content: ITicketTaskItem[];
  name: string;
}

const Buckets = ({
  items,
  onCardClick
}: {
  items: TBucketsItem[];
  onCardClick: (data: ITicketTaskItem) => void;
}) => {
  return (
    <div className="bucket">
      {items.map((i, index) => (
        <div className="bucket-content" key={i.name}>
          <h3>{i.name}</h3>
          <div className="card-container">
            {i.content
              .filter(i => i.progress !== EscalationProgress.completed)
              .map((c, index) => (
                <FeedbackCard
                  taskAssignedUsers={c.taskAssignedUsers || []}
                  onClick={() => onCardClick(c)}
                  key={`${c.ticketNumber}-${index}`}
                  ticketCategory={c.ticketCategory}
                  customer={c.customer}
                  materialDetails={c.taskMaterials}
                  dueDate={c.dueDate}
                  priority={c.priority}
                  progress={c.progress}
                  ticketNumber={c.ticketNumber}
                />
              ))}
            <CompleteItems content={i.content} onCardClick={onCardClick} />
          </div>
        </div>
      ))}
    </div>
  );
};

const CompleteItems = ({
  content,
  onCardClick
}: {
  content: ITicketTaskItem[];
  onCardClick: (data: ITicketTaskItem) => void;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const completedItems = useMemo(
    () => content.filter(i => i.progress === EscalationProgress.completed),
    [content]
  );

  const handleShowChange = () => {
    setShow(show => !show);
  };

  return (
    <div className="complete-escalations">
      <div className="complete-escalations-header" onClick={handleShowChange}>
        <h4>Completed Escalations {completedItems.length}</h4>
        <Icon name={show ? "chevron-down" : "chevron-up"} />
      </div>
      {show && completedItems.length > 0 && (
        <div>
          {completedItems.map((c, index) => (
            <FeedbackCard
              taskAssignedUsers={c.taskAssignedUsers || []}
              onClick={() => onCardClick(c)}
              key={`${c.ticketNumber}-${index}`}
              ticketCategory={c.ticketCategory}
              customer={c.customer}
              materialDetails={c.taskMaterials}
              dueDate={c.dueDate}
              priority={c.priority}
              progress={c.progress}
              ticketNumber={c.ticketNumber}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Buckets;
