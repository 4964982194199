import { useAppState } from "../app-state";

export const useAppSettings = () => {
  const [_, setAppState] = useAppState();

  const readAppSettings = async () => {
    const headers = new Headers();
    headers.append("pragma", "no-cache");
    headers.append("cache-control", "no-cache");

    fetch("/appSettings.json", {
      method: "GET",
      headers
    })
      .then(response => response.json())
      .then(settings => {
        setAppState(old => ({
          ...old,
          settings
        }));
      })
      .catch(error => {
        console.error("Error reading config", error);
      });
  };

  return { readAppSettings };
};
