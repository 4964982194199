import { Tile } from "@react-gcc-eds/core";
import { useGrid } from "../../../components/grid/grid";
import Loader from "../../../components/loader";
import "./approval-editor.scss";
import { getApprovalColumns } from "../../grid/views-columns/approval";
import QuickFilter from "../quick-filter";
import { useEffect, useMemo, useState } from "react";
import {
  TableId,
  ViewId,
  useTablePreferencesHandler
} from "../../../helpers/table-preferences-handler";
import { ITicketHistory } from "../../api/ticket";

const ApprovalEditor = ({
  data,
  loadingTable,
  onRowClick,
  ticketCategories,
  regions,
  hasEscalationLevel
}: {
  data: ITicketHistory[];
  loadingTable: boolean;
  onRowClick: (row: ITicketHistory) => void;
  ticketCategories: string[];
  regions: string[];
  hasEscalationLevel: boolean;
}) => {
  //filter
  const [ticketCategoryFilters, setTicketCategoryFilters] = useState<string[]>([]);
  const [regionFilters, setRegionFilters] = useState<string[]>([]);
  const displayData = useMemo(() => {
    const filterByKey = (data: ITicketHistory[], key: keyof ITicketHistory, filters: string[]) =>
      data.filter(item => ((item[key] || []) as string[]).find(v => filters.includes(v)));
    return filterByKey(
      filterByKey(data, "ticketCategories", ticketCategoryFilters),
      "regionCategories",
      regionFilters
    );
  }, [data, ticketCategoryFilters, regionFilters]);

  useEffect(() => {
    if (ticketCategories) {
      setTicketCategoryFilters(ticketCategories);
    }
  }, [ticketCategories]);

  useEffect(() => {
    if (regions) {
      setRegionFilters(regions);
    }
  }, [regions]);

  //persist filter
  const { preferencesHandler } = useTablePreferencesHandler(
    ViewId.VIS_APPROVAL,
    TableId.VIS_APPROVAL
  );

  //table
  const columns = useMemo(() => {
    return getApprovalColumns({ hasEscalationLevel });
  }, [hasEscalationLevel]);

  const { renderTable } = useGrid<ITicketHistory>({
    columns,
    clientModel: {
      data: displayData,
      rowKeySelector: data => data.ticketNumber.toString()
    },
    showColumnsFiltersInfoAt: "left",
    preferencesHandler,
    onRowClicked: row => {
      onRowClick(row);
    }
  });

  return (
    <Tile title="Ticket History" className="approval-editor">
      {loadingTable ? (
        <Loader noTile />
      ) : (
        <>
          <div className="approval-editor-header">
            <QuickFilter
              title="Ticket category"
              onChange={setTicketCategoryFilters}
              data={ticketCategories}
            />
            <div className="quick-filter-line"></div>
            <QuickFilter title="Region" onChange={setRegionFilters} data={regions} />
          </div>
          <div className="approval-editor-table">{renderTable()}</div>
        </>
      )}
    </Tile>
  );
};

export default ApprovalEditor;
