import React, { ChangeEvent, useEffect, useState } from "react";
import { IListBuilder } from ".";
import { Button, Checkbox } from "@react-gcc-eds/core";
import { IListSideItem } from "./IListBuilder";

const ListBuilder = ({
  leftTitle,
  rightTitle,
  onLeftSearch,
  onRightSearch,
  leftSideItems,
  rightSideItems,
  buttons,
  onTransferLeftClick,
  onTransferRightClick,
  hideTransferLeftButton,
  hideTransferRightButton,
  hideTransferButtons,
  disabled
}: IListBuilder) => {
  const [selectedItemsLeft, setSelectedItemsLeft] = useState<IListSideItem[]>([]);
  const [selectedItemsRight, setSelectedItemsRight] = useState<IListSideItem[]>([]);

  const [displayItemsLeft, setDisplayItemsLeft] = useState<IListSideItem[] | undefined>(
    leftSideItems
  );
  const [displayItemsRight, setDisplayItemsRight] = useState<IListSideItem[] | undefined>(
    rightSideItems
  );

  useEffect(() => {
    setDisplayItemsLeft(leftSideItems);
  }, [leftSideItems]);

  useEffect(() => {
    setDisplayItemsRight(rightSideItems);
  }, [rightSideItems]);

  const _onLeftSearch = (value: string) => {
    onLeftSearch && onLeftSearch(value);
    setDisplayItemsLeft(
      leftSideItems
        ? leftSideItems.filter(i => i.title.toUpperCase().includes(value.toUpperCase()))
        : leftSideItems
    );
  };

  const _onRightSearch = (value: string) => {
    onRightSearch && onRightSearch(value);
    setDisplayItemsRight(
      rightSideItems
        ? rightSideItems.filter(i => i.title.toUpperCase().includes(value.toUpperCase()))
        : rightSideItems
    );
  };

  const optionItem = (
    itemTitle: IListSideItem,
    key: number,
    selectedItems: IListSideItem[],
    setSelectedItems: (selectedItems: IListSideItem[]) => void
  ) => {
    return (
      <Checkbox
        key={key}
        onChange={(checked: boolean) => {
          if (checked) {
            const newValue = selectedItems.slice(0);
            newValue.push(itemTitle);
            setSelectedItems(newValue);
          } else {
            const newValue = selectedItems.slice(0);
            const index = newValue.map(({ title }) => title).indexOf(itemTitle.title);
            if (index > -1) {
              newValue.splice(index, 1);
              setSelectedItems(newValue);
            }
          }
        }}
        text={itemTitle.title}
        checked={selectedItems.filter(({ title }) => title === itemTitle.title).length > 0}
        disabled={disabled}
      />
    );
  };

  const selectAllItems = (
    items: IListSideItem[] | undefined,
    selectedItems: IListSideItem[],
    displayItems: IListSideItem[] | undefined,
    onChange: (v: IListSideItem[]) => void
  ) => {
    return (
      <Checkbox
        key="Select all"
        onChange={(checked: boolean) => {
          if (checked) {
            if (displayItems === undefined) return;
            const newValue = displayItems.slice(0);
            onChange(newValue);
          } else {
            onChange([]);
          }
        }}
        checked={selectedItems.length === items?.length && !!items && items?.length > 0}
        indeterminate={selectedItems.length > 0 && selectedItems.length !== items?.length}
        text={"Select all items"}
        disabled={disabled}
      />
    );
  };

  return (
    <div className="list-builder">
      <div className="lb-block lb-main">
        <div className="lb-header">
          <div className="title">{leftTitle}</div>

          <div className="lb-filter">
            <input
              className="lb-filter-input lb-filter-main"
              placeholder="Type to search"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                _onLeftSearch(e.target.value);
              }}
            />
          </div>

          <div className="lb-controls">
            <div className="lb-header-cb">
              <div className="lb-primary-cb-all">
                {selectAllItems(leftSideItems, selectedItemsLeft, displayItemsLeft, value =>
                  setSelectedItemsLeft(value)
                )}
              </div>
            </div>

            <div className="lb_header-counters">
              <span className="lb-counter-selected">{selectedItemsLeft.length} of </span>
              <span className="lb-counter-total">{leftSideItems ? leftSideItems.length : 0} </span>
              selected
            </div>
          </div>
        </div>

        <div className="lb-list">
          {(!displayItemsLeft || displayItemsLeft.length === 0) && (
            <p className="lb-placeholder">The list is empty.</p>
          )}
          {displayItemsLeft &&
            displayItemsLeft.map((item, index) => {
              return (
                <div className="lb-item" key={index}>
                  {optionItem(item, index, selectedItemsLeft, setSelectedItemsLeft)}
                </div>
              );
            })}
        </div>
      </div>

      {!hideTransferButtons && (
        <div className="lb-transfer-btns">
          {!hideTransferRightButton && (
            <Button
              iconName="chevron-right"
              disabled={disabled}
              onClick={() => {
                onTransferRightClick && onTransferRightClick(selectedItemsLeft);
                setSelectedItemsLeft([]);
              }}
            />
          )}
          {!hideTransferLeftButton && (
            <Button
              iconName="chevron-left"
              disabled={disabled}
              onClick={() => {
                onTransferLeftClick && onTransferLeftClick(selectedItemsRight);
                setSelectedItemsRight([]);
              }}
            />
          )}
          {buttons}
        </div>
      )}

      <div className="lb-block">
        <div className="lb-header">
          <div className="title">{rightTitle}</div>

          <div className="lb-filter">
            <input
              className="lb-filter-input lb-filter-main"
              placeholder="Type to search"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                _onRightSearch(e.target.value);
              }}
            />
          </div>

          <div className="lb-controls">
            <div className="lb-header-cb">
              <div className="lb-primary-cb-all">
                {selectAllItems(rightSideItems, selectedItemsRight, displayItemsRight, value =>
                  setSelectedItemsRight(value)
                )}
              </div>
            </div>
            <div className="lb_header-counters">
              <span className="lb-counter-selected">{selectedItemsRight.length} of </span>
              <span className="lb-counter-total">
                {rightSideItems ? rightSideItems.length : 0}{" "}
              </span>
              selected
            </div>
          </div>
        </div>

        <div className="lb-list">
          {(!displayItemsRight || displayItemsRight.length === 0) && (
            <p className="lb-placeholder">The list is empty.</p>
          )}
          {displayItemsRight &&
            displayItemsRight.map((item, index) => {
              return (
                <div className="lb-item" key={index}>
                  {optionItem(item, index, selectedItemsRight, setSelectedItemsRight)}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ListBuilder;
