import { Button, Dialog, useNotifications } from "@react-gcc-eds/core";
import React, { useEffect, useState } from "react";
import { unicodeNumberId } from "../../utils";
import { simpleObjectEqual } from "../../utils/user-helpers";
import Loader from "../loader";
import "./MultipleUpload.scss";

export interface IUploadFile {
  id: number;
  file?: File;
  fileName: string;
}

const MultipleUpload = ({
  show,
  onClose,
  title,
  onApplied,
  appliedFiles,
  loading,
  limit = 5,
  maxSize
}: {
  show: boolean;
  onClose: () => void;
  title: string;
  onApplied: (files: IUploadFile[]) => void;
  appliedFiles: IUploadFile[];
  loading?: boolean;
  limit?: number;
  maxSize?: number;
}) => {
  //common
  const { createNotification } = useNotifications();

  //display
  const [editedFiles, setEditedFiles] = useState<IUploadFile[]>(appliedFiles);

  useEffect(() => {
    if (appliedFiles) {
      setEditedFiles(appliedFiles);
    }
  }, [appliedFiles]);

  //drag
  const [dragIn, setDragIn] = useState<boolean>(false);

  const handleOnDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  };

  const handleOnDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
    const file = e.dataTransfer.files[0];
    if (editedFiles.length >= limit) {
      createNotification("Warning", "Can't add more than 5 files!", "warning");
    } else if (maxSize && file.size >= maxSize * 1024 * 1024) {
      createNotification("Warning", `Max size is ${maxSize}Mb!`, "warning");
    } else {
      file &&
        setEditedFiles(f => [
          ...f,
          {
            id: unicodeNumberId(),
            file,
            fileName: file.name
          }
        ]);
    }
  };

  //delete
  const handleDelete = (id: number) => {
    setEditedFiles(files => files.filter(file => file.id !== id));
  };

  return (
    <Dialog
      fullscreen
      className="multiple-upload"
      visible={show}
      onClose={onClose}
      title={title}
      buttons={
        <>
          <Button
            primary
            disabled={simpleObjectEqual(editedFiles, appliedFiles)}
            onClick={() => onApplied(editedFiles)}
          >
            OK
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    >
      <div
        className={`drop-area ${dragIn ? "drop-over" : ""}`}
        onDragOver={handleOnDragOver}
        onDragLeave={() => setDragIn(false)}
        onDrop={handleOnDrop}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={`empty-state ${editedFiles.length === 0 ? "visible" : ""}`}>
              <div>
                <i className="icon icon-plus text-xl"></i>
              </div>
              <div>Upload files</div>
              <div className="color-gray">
                Add an item by dragging <br /> into this container
              </div>
            </div>
            {editedFiles.map(({ fileName, id }) => (
              <div className="card card-noPointer" key={`Multiple-upload-${id}`}>
                <div className="header">
                  <div className="left">
                    <div className="title">{fileName}</div>
                  </div>
                  <div className="right">
                    <i className="icon icon-cross" onClick={() => handleDelete(id)}></i>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default MultipleUpload;
