import ContentLoader from "../../components/content-loader";
import { ITaskBucketItems, ITicketTaskItem } from "../api/task";
import Buckets from "./Buckets";

const FeedbackBucketView = ({
  bucketList,
  onCardClick,
  loadingContent
}: {
  bucketList: ITaskBucketItems | undefined;
  onCardClick: (data: ITicketTaskItem) => void;
  loadingContent: boolean;
}) => {
  const newBucketList = bucketList?.buckets
    .map(bucket => ({
      name: bucket,
      content: bucketList.allTasks.filter(task => task.taskBucket === bucket)
    }))
    .filter(v => v.content.length > 0);
  return (
    <ContentLoader
      data={newBucketList && newBucketList.length > 0}
      loading={loadingContent}
      render={
        bucketList ? <Buckets onCardClick={onCardClick} items={newBucketList || []} /> : <></>
      }
    />
  );
};

export default FeedbackBucketView;
