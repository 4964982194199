import { useEffect, useMemo, useState } from "react";
import {
  IBasicSoItem,
  ITicketCreateParams,
  ITicketHistory,
  ITicketUpdateParams,
  ITicketUploadParams,
  useCreateEscalationTicket,
  useDownloadTicketAttachments,
  useGetTicketDetails,
  useGetTicketHistory,
  useUpdateEscalationTicket,
  useUploadAttachments
} from "../api/ticket";
import View from "../../views/app-view";
import { TICKET_LOG_ROUTE } from "../../app-router";
import HelpTip from "../component/help-tip";
import { useAppState } from "../../app-state";
import { EEfRoles } from "../../types";
import { HELP_ADMINISTRATOR } from "../helper";
import TicketLogEditor from "../component/ticket-log/ticket-log-editor";
import { useGetTicketSelections } from "../api/lookup";
import TicketBaseInfoDialog from "../component/ticket-base-dialog/ticket-base-info-dialog";
import { NEW_ESCALATION } from "../helpers/ef-ticket";
import { useCheckSoItems, useUploadSoItem } from "../api/sales-order";
import { useNotifications } from "@react-gcc-eds/core";
import fileDownload from "js-file-download";

const TicketLogView = () => {
  //common
  const [ticketHistory, loadingTicketHistory, getTicketHistory] = useGetTicketHistory();
  const [selectionData, loadingSelectionData, getSelectionData] = useGetTicketSelections();
  const [, loadingCreateEscalation, createEscalation] = useCreateEscalationTicket();
  const [, loadingUpdateEscalation, updateEscalation] = useUpdateEscalationTicket();
  const [ticketDetail, loadingTicketDetail, getTicketDetail] = useGetTicketDetails();
  const { createNotification } = useNotifications();

  useEffect(() => {
    getTicketHistory();
    getSelectionData();
  }, []);

  //role
  const [appState] = useAppState();
  const canEdit = useMemo(
    (): boolean =>
      Boolean(
        appState.user?.efRoles.find(
          role => role === EEfRoles.EFSuperOwner || role === EEfRoles.EFTicketRequester
        )
      ),
    [appState]
  );

  const administrator = useMemo(
    () => (appState.user ? HELP_ADMINISTRATOR[appState.user.marketAreas[0]] : "administrator"),
    [appState]
  );

  //table
  const [activeRow, setActiveRow] = useState<ITicketHistory>();

  const ticketRowClick = (row: ITicketHistory) => {
    setActiveRow(row);
    setShowEscalationDialog(true);
    setTicketTitle(`Ticket ${row.ticketNumber}`);
    getTicketDetail({ ticketNumber: row.ticketNumber });
  };

  //add update ticket
  const [, loadingUploadAttachments, uploadAttachments] = useUploadAttachments();
  const [showEscalationDialog, setShowEscalationDialog] = useState<boolean>(false);
  const [ticketTitle, setTicketTitle] = useState<string>(NEW_ESCALATION);

  const handleShowNewEscalation = () => {
    setShowEscalationDialog(true);
    setTicketTitle(NEW_ESCALATION);
    setActiveRow(undefined);
  };

  const translateToFormData = (uploadParams: ITicketUploadParams) => {
    const { ticketNumber, files, blobObjIds } = uploadParams as any;
    const formData = new FormData();
    formData.append("ticketNumber", ticketNumber);
    files.map((file: any) => {
      formData.append("files", file);
    });

    blobObjIds.map((id: any) => {
      formData.append("blobObjIds", id);
    });
    return formData;
  };

  const handleSubmit = (
    data: ITicketCreateParams | ITicketUpdateParams,
    uploadParams?: ITicketUploadParams,
    formChanged?: boolean
  ) => {
    const fetchUpload = (ticketNumber: number | undefined) => {
      uploadAttachments(
        translateToFormData({ ...(uploadParams as ITicketUploadParams), ticketNumber })
      ).then(res => {
        const { success } = res.data;
        if (success) {
          successCallback();
        }
      });
    };
    if (ticketTitle === NEW_ESCALATION) {
      createEscalation(data as ITicketCreateParams).then(res => {
        const { success, payload } = res.data;
        const { ticketNumber } = payload;
        if (success) {
          if (uploadParams) {
            fetchUpload(ticketNumber);
          } else {
            successCallback();
          }
        }
      });
    } else {
      if (formChanged) {
        updateEscalation(data as ITicketUpdateParams).then(res => {
          const { success } = res.data;
          if (success) {
            if (uploadParams) {
              fetchUpload(activeRow?.ticketNumber);
            } else {
              successCallback();
            }
          }
        });
      } else {
        if (uploadParams) {
          fetchUpload(activeRow?.ticketNumber);
        }
      }
    }
  };

  const successCallback = () => {
    createNotification(`Success`, `${activeRow ? "Update" : "Create"} success!`);
    getTicketHistory();
    setShowEscalationDialog(false);
  };

  //check
  const [checkTicketData, loadingCheckTicket, getCheckTicket] = useCheckSoItems();

  const handleCheckTicket = (value: IBasicSoItem[]) => {
    getCheckTicket({ soItems: trimApi(value) });
  };

  const trimApi = (value: IBasicSoItem[]) => {
    return value.map(data => ({
      ...data,
      salesOrder: data.salesOrder.trim(),
      item: data.item.trim()
    }));
  };

  //multiple download
  const [
    downloadAttachments,
    loadingDownloadAttachments,
    downloadMultipleAttachments,
    ,
    downloadFileName
  ] = useDownloadTicketAttachments();

  const handleMultipleDownload = (attachmentIds: number[]) => {
    downloadMultipleAttachments({ attachmentIds, ticketNumber: activeRow?.ticketNumber });
  };

  useEffect(() => {
    if (downloadAttachments) {
      if ((downloadAttachments as Blob).size !== 0) {
        fileDownload(downloadAttachments, downloadFileName || "result.zip");
      } else {
        createNotification("Warning !", "Not have any result!", "warning");
      }
    }
  }, [downloadAttachments]);

  //upload so item
  const [uploadItemData, loadingUploadItem, uploadItem] = useUploadSoItem();

  const onUploadChange = (e: any) => {
    e.target &&
      e.target.files &&
      uploadItem({ soItemFile: e.target.files[0] }).then(() => {
        e.target.value = "";
      });
  };

  return (
    <View
      breadCrumbs={[
        { text: "Escalation Management" },
        { text: "Ticket Log", url: TICKET_LOG_ROUTE }
      ]}
      actions={
        <HelpTip
          tooltip={`In order to get new ticket permission, please contact ${administrator}.`}
        />
      }
    >
      <TicketLogEditor
        loadingTable={loadingTicketHistory}
        loadingLookup={loadingSelectionData}
        data={ticketHistory || []}
        canEdit={canEdit}
        onShowNewEscalation={handleShowNewEscalation}
        onRowClick={ticketRowClick}
      />
      <TicketBaseInfoDialog
        loadingDialog={
          loadingTicketDetail ||
          loadingCreateEscalation ||
          loadingUpdateEscalation ||
          loadingUploadAttachments
        }
        dropdownItems={selectionData}
        title={ticketTitle}
        formData={activeRow ? ticketDetail : undefined}
        checkData={checkTicketData}
        uploadCheckData={uploadItemData?.payload}
        show={showEscalationDialog}
        onClose={() => setShowEscalationDialog(false)}
        onSubmit={(data, uploadParams, formChanged) =>
          handleSubmit(data as ITicketCreateParams | ITicketUpdateParams, uploadParams, formChanged)
        }
        onCheck={handleCheckTicket}
        onDownload={handleMultipleDownload}
        downloadAttachments={downloadAttachments}
        loadingDownload={loadingDownloadAttachments}
        loadingSoItem={loadingCheckTicket || loadingUploadItem}
        disableBasicInfo={activeRow ? !canEdit || !activeRow.canEditRequest : false}
        onUploadChange={onUploadChange}
      />
    </View>
  );
};

export default TicketLogView;
