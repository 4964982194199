import {
  DateField,
  ISelectItem,
  Icon,
  NumberField,
  Radiobutton,
  TableColumn,
  TextField
} from "@react-gcc-eds/core";
import { edsMdText, edsText, edsValidateCell } from "../../helpers/ef-table";
import { ITicketDetailSoItem, ITicketEditedSoItem } from "../../api/ticket";
import { ITicketError } from "../../helpers/ef-validate";

export const getEfSoItemColumns = ({
  formValues,
  error,
  disabled,
  showProjectName,
  onChange,
  onDelete
}: {
  formValues: ITicketDetailSoItem[];
  error?: ITicketError;
  disabled?: boolean;
  showProjectName?: boolean;
  onChange?: (key: keyof ITicketEditedSoItem, rowNumber: number, value: string | number) => void;
  onDelete?: (rowNumber: number) => void;
}): TableColumn[] => {
  const textFieldCell = (key: keyof ITicketEditedSoItem) => (row: any) => {
    return (
      <TextField
        onChange={value => onChange && onChange(key, row.rowNumber, value)}
        value={row[key]}
        disabled={disabled}
        className={["salesOrder", "item"].includes(key) ? "ticket-input-sm" : ""}
      />
    );
  };

  const radioCell =
    (key: keyof ITicketEditedSoItem, options: ISelectItem<any>[], size = "sm") =>
    (row: any) => {
      return (
        <div className={`global-table-radio ${size === "lg" ? "global-table-radio-lg" : ""}`}>
          {options.map(({ title, value }, index) => (
            <Radiobutton
              key={`global-table-radio-${index}`}
              disabled={disabled}
              text={title}
              value={row[key] === value}
              onChange={() => onChange && onChange(key, row.rowNumber, value)}
            />
          ))}
        </div>
      );
    };

  const dateCell = (key: keyof ITicketEditedSoItem) => (row: any) => {
    return (
      <DateField
        selectedDate={row[key] ? new Date(row[key]) : undefined}
        onDateSelected={date => onChange && onChange(key, row.rowNumber, date?.getTime() || "")}
        disabled={disabled}
        className="ticket-date-sm"
      />
    );
  };

  const numberCell = (key: keyof ITicketEditedSoItem) => (row: any) => {
    return (
      <NumberField
        onChange={value => onChange && onChange(key, row.rowNumber, value)}
        value={row[key]}
        disabled={disabled}
        className={["minimumCriticalDemandPerWeek"].includes(key) ? "ticket-number-sm" : ""}
      />
    );
  };

  return [
    edsValidateCell(
      "salesOrder",
      "Eab SO",
      textFieldCell("salesOrder"),
      error && error.type === "salesOrder"
    ),
    edsValidateCell("item", "Item", textFieldCell("item"), error && error.type === "item"),
    edsMdText("material", "Material"),
    edsMdText("materialDesc", "Description For Material"),
    edsText("quantity", "Quantity"),
    edsValidateCell(
      "customer",
      "Customer",
      undefined,
      error && error.type === "customer",
      "A ticket can only have one customer"
    ),
    edsMdText("productReportingGroup", "Product Reporting Group"),
    edsMdText("orderCreationDate", "Order Creation Date"),
    edsMdText("currentReadyForShipmentDate", "Expected RFS Date"),
    edsMdText("currentMaterialAvailableDate", "Confirmed MAD"),
    edsText("requestLT", "Request LT"),
    edsValidateCell(
      "latestAcceptableReadyForShipmentDate",
      "Latest acceptable RFS date",
      dateCell("latestAcceptableReadyForShipmentDate"),
      error && error.type === "latestAcceptableReadyForShipmentDate"
    ),
    edsValidateCell(
      "minimumCriticalDemandPerWeek",
      "Minimum critical demand per week",
      numberCell("minimumCriticalDemandPerWeek"),
      error && error.type === "minimumCriticalDemandPerWeek"
    ),
    ...(showProjectName
      ? [
          edsValidateCell(
            "projectNameOrDeal",
            "Project Name/Deal",
            textFieldCell("projectNameOrDeal"),
            error && error.type === "projectNameOrDeal",
            error?.msg || ""
          )
        ]
      : []),
    edsValidateCell(
      "nroOrBoxDelivery",
      "NRO/Box Delivery",
      radioCell(
        "nroOrBoxDelivery",
        [
          { title: "NRO", value: "NRO" },
          { title: "Box Delivery", value: "Box Delivery" }
        ],
        "lg"
      ),
      error && error.type === "nroOrBoxDelivery"
    ),
    edsValidateCell(
      "partialDeliveryAcceptable",
      "Partial Delivery Acceptable",
      radioCell("partialDeliveryAcceptable", [
        { title: "Yes", value: true },
        { title: "No", value: false }
      ]),
      error && error.type === "partialDeliveryAcceptable"
    ),
    {
      key: "option",
      header: "Option",
      Cell: (row: any) => (
        <div className="ticket-basic-table-option">
          <Icon
            name="trashcan"
            className="selectable"
            disabled={formValues.length === 1 || disabled}
            onClick={() => onDelete && onDelete(row.rowNumber)}
          />
        </div>
      )
    }
  ];
};
