import { useMemo, useState } from "react";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from "recharts";
import { roundNumberToLocale } from "../../utils";
import { useChartHelpers } from "./chart-helpers";
import LegendPills from "./legend";
import { ChartTooltip } from "./tooltip";
import { IBarWithLineChartData } from "./types";
import { useSelectedSeries } from "./selected-series";

interface IVerticalBarWithLineChart {
  data: IBarWithLineChartData;
  barUnitOfMeasure: string;
  lineUnitOfMeasure: string;
  barSize?: number;
  rotatedXAxisLabels?: boolean;
  withBrush?: boolean;
  invertTooltipOrder?: boolean;
  yAxisDecimalPlaces?: number;
  multipleSeriesSelection?: boolean;
  customColors?: string[] | undefined;
}

const VerticalBarWithLineChart = ({
  data,
  barUnitOfMeasure,
  lineUnitOfMeasure,
  barSize = 6,
  rotatedXAxisLabels,
  withBrush = true,
  invertTooltipOrder,
  yAxisDecimalPlaces = 0,
  multipleSeriesSelection = false,
  customColors
}: IVerticalBarWithLineChart) => {
  const [chartSize, setChartSize] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0
  });

  const {
    selectedSeries,
    hoveredSeries,
    handleSeriesSelected,
    handleSeriesHovered,
    handleSeriesUnhovered
  } = useSelectedSeries({ allowMultipleSelection: multipleSeriesSelection });

  const { getColor, chartDataAsRechartsData } = useChartHelpers();

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  };

  const leftMargin = () => {
    const nofSamples = data.common.length;
    let max = 0;

    for (let i = 0; i < nofSamples; i++) {
      const total = data.barChartSeries.values.reduce((sum, v) => sum + v.value, 0);
      if (total > max) max = total;
    }

    return max < 1000 ? 0 : max < 10000 ? 5 : max < 100000 ? 10 : 20;
  };

  const bottomMargin = () =>
    data.common.reduce((max: number, s: string) => (s.length > max ? s.length : max), 0) * 4;

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        if (entry.contentRect.height > 0 && entry.contentRect.width > 0) {
          setChartSize({ height: entry.contentRect.height, width: entry.contentRect.width });
        }
      }),
    []
  );

  const maxLineSeries = useMemo(() => {
    if (data.lineChartSeries.length > 0) {
      let maxSeriesName = data.lineChartSeries[0].name;
      let maxValue = 0;
      for (const series of data.lineChartSeries) {
        for (const value of series.values) {
          if (value.value > maxValue) {
            maxValue = value.value;
            maxSeriesName = series.name;
          }
        }
      }
      return maxSeriesName;
    } else return "";
  }, [data.lineChartSeries]);

  return (
    <ResponsiveContainer
      ref={(obj: any) => (obj?.current ? resizeObserver.observe(obj.current) : null)}
      height={"99%"}
    >
      <ComposedChart
        data={chartDataAsRechartsData(
          { common: data.common, series: [data.barChartSeries, ...data.lineChartSeries] },
          selectedSeries
        )}
        margin={
          rotatedXAxisLabels
            ? { top: 40, left: leftMargin(), right: 0, bottom: bottomMargin() }
            : { top: 40, left: 0, right: 0 }
        }
      >
        <Tooltip
          cursor={false}
          content={(props: TooltipProps) => (
            <ChartTooltip
              inverted={invertTooltipOrder}
              units={[barUnitOfMeasure, ...data.lineChartSeries.map(() => lineUnitOfMeasure)]}
              props={props}
            />
          )}
        />
        <CartesianGrid vertical={false} />
        <Legend
          content={
            <LegendPills
              series={[
                {
                  name: data.barChartSeries.name,
                  selected: selectedSeries.includes(data.barChartSeries.name)
                },
                ...data.lineChartSeries.map(series => ({
                  name: series.name,
                  selected: selectedSeries.includes(series.name)
                }))
              ]}
              onMouseEnter={handleSeriesHovered}
              onMouseLeave={handleSeriesUnhovered}
              onClick={handleSeriesSelected}
              customColors={customColors}
            />
          }
          align="left"
          layout="horizontal"
          verticalAlign="top"
        />
        <XAxis
          tickLine={false} //eds alignment
          axisLine={false} //eds alignment
          dataKey="name"
          tick={rotatedXAxisLabels ? CustomXAxisTick : true}
          interval={rotatedXAxisLabels ? 0 : "preserveStartEnd"}
        />
        <YAxis
          tickFormatter={(val: any) => roundNumberToLocale(val, yAxisDecimalPlaces)}
          tickLine={false}
          axisLine={false}
          dataKey={data.barChartSeries.name}
          yAxisId="left"
        >
          <Label value={barUnitOfMeasure} position="top" offset={22} />
        </YAxis>
        <Bar
          dataKey={data.barChartSeries.name}
          barSize={barSize}
          fill={getColor(0, customColors)}
          yAxisId="left"
          opacity={hoveredSeries ? (data.barChartSeries.name === hoveredSeries ? 1 : 0.2) : 1}
        />
        <YAxis
          tickLine={false}
          tickFormatter={(val: any) => roundNumberToLocale(val, yAxisDecimalPlaces)}
          axisLine={false}
          orientation="right"
          dataKey={selectedSeries.length > 0 ? selectedSeries[0] : maxLineSeries}
          yAxisId="right"
        >
          <Label value={lineUnitOfMeasure} position="top" offset={22} />
        </YAxis>
        {data.lineChartSeries.map((series, index) => (
          <Line
            key={series.name}
            dataKey={series.name}
            yAxisId="right"
            opacity={hoveredSeries ? (series.name === hoveredSeries ? 1 : 0.2) : 1}
            stroke={getColor(index + 1, customColors)}
            strokeWidth={3}
          />
        ))}
        {withBrush &&
          chartSize &&
          barSize &&
          Math.round(chartSize.width / (barSize + 10)) < data.common.length - 1 && (
            <Brush
              y={Math.round(chartSize.height - 12)}
              endIndex={Math.round(chartSize.width / (barSize + 10))}
              dataKey="name"
              height={12}
            />
          )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default VerticalBarWithLineChart;
