import { TableColumn } from "@react-gcc-eds/core";
import { edsText, edsValidateCell } from "../../helpers/ef-table";
import { ITicketError } from "../../helpers/ef-validate";
import MultipleDropdownFilter, {
  multiFilterLabel
} from "../../../components/select/MultipleDropdownFilter";
import { TUserBaseInfo } from "../../../types";
import { IAssignedUserEditedItem } from "../../api/ticket";
import SingleSelect from "../../../components/select/SingleSelect";
import { IEscalationLookup } from "../../api/lookup";

export const getEfAssignColumns = ({
  error,
  disabled,
  userList,
  dropdownItems,
  onChange
}: {
  error: ITicketError | undefined;
  disabled: boolean;
  userList: TUserBaseInfo[];
  dropdownItems: IEscalationLookup;
  onChange: (key: keyof IAssignedUserEditedItem, rowNumber: number) => (value: any) => void;
}): TableColumn[] => {
  const ticketCategoryCell = (row: any) => (
    <SingleSelect
      items={dropdownItems.ticketCategories.map(i => ({ title: i, value: i }))}
      onChange={item => onChange("ticketCategory", row.rowNumber)(item.value)}
      placeholder="Please Select an option"
      value={row.ticketCategory || ""}
      disabled={disabled}
      type="input-single"
      clickItemTextChange={false}
    />
  );

  const assignCell = (row: any) => {
    const options = userList.map(({ userName, userEmail }) => ({
      title: userName,
      value: userEmail
    }));
    return (
      <MultipleDropdownFilter
        items={options}
        header=""
        label={multiFilterLabel(userList, row.assignedUserEmails)}
        onChange={items =>
          onChange("assignedUserEmails", row.rowNumber)(items.map(item => item.value))
        }
        selectedItems={options.filter(({ value }) => row.assignedUserEmails.includes(value))}
        disabled={disabled}
      />
    );
  };

  return [
    edsText("productReportingGroup", "Product Reporting Group"),
    edsValidateCell(
      "ticketCategory",
      "Ticket Category",
      ticketCategoryCell,
      error && error.type === "ticketCategory"
    ),
    edsValidateCell(
      "assignedUserEmails",
      "Assign To",
      assignCell,
      error && error.type === "assignedUserEmails"
    )
  ];
};
