import { useApiGet, useApiGetForBlob, useApiPost, useApiPostForm, useApiPut } from "../../api/api";
import { IErrorResponse } from "../../types";
import { EscalationStatus } from "../helper";
import { TEscalationStatus } from "../types";

export interface IActionResult extends IErrorResponse {
  success: boolean;
}

export interface ITicketBasicItem {
  escalationReason: string;
  escalationRegion: string;
  businessImpact: string;
  preEscalationActivity: boolean;
}

export interface ITicketBaseFormItem extends ITicketBasicItem {
  ticketNumber?: number;
  comments?: string;
  ticketMaterials: IGlobalStockItem[];
}

export interface ITicketAllFormItem extends ITicketBaseFormItem, ITicketApprovalItems {}

export interface IBasicSoItem {
  salesOrder: string;
  item: string;
}

export interface ITicketEditedSoItem extends IBasicSoItem {
  latestAcceptableReadyForShipmentDate: string;
  minimumCriticalDemandPerWeek: number;
  projectNameOrDeal: string;
  nroOrBoxDelivery: string;
  partialDeliveryAcceptable: boolean | undefined;
  rowNumber?: number;
}

interface ITicketBasicSoItem {
  customer: string;
  productReportingGroup: string;
  currentMaterialAvailableDate: string;
  currentReadyForShipmentDate: string;
  orderCreationDate: string;
  material: string;
  materialDesc: string;
  requestDeliveryDate: string;
  requestLT?: number;
  id?: number;
  minRequestLT?: number | null;
  salesOrderUserInput?: string;
  itemUserInput?: string;
  quantity?: number;
  vendorPo?: string;
  vendor?: string;
  vendorCode?: string;
  orderPlacedHub?: string;
  latestRequestMaterialAvailableDate?: string;
  improvedOrderAcknowledgeDate?: string | null;
}

export interface ITicketDetailSoItem extends ITicketEditedSoItem, ITicketBasicSoItem {}

export interface IGlobalStockItem {
  material: string;
  borrowFrom: string;
  borrowQuantity: number;
  preEscalationFeedback: string;
  minRequestLT?: number | null;
  globalStockCheck?: boolean;
  sippOrNonSipp?: boolean;
  forecasted?: boolean;
}

export interface ITicketCreateParams extends ITicketBasicItem {
  sos: ITicketEditedSoItem[];
  materials: IGlobalStockItem[];
}

export interface ITicketValidateItem
  extends ITicketBasicItem,
    ITicketDetailSoItem,
    IGlobalStockItem,
    ITicketApprovalEditedItems,
    IAssignedUserEditedItem {}

export interface ITicketUpdateParams extends ITicketCreateParams {
  ticketNumber: number;
}

export interface ITicketAttachmentItem {
  id: number;
  attachmentName: string;
}

export interface ITicketDetails extends ITicketAllFormItem {
  ticketSos: ITicketDetailSoItem[];
  ticketAttachments?: ITicketAttachmentItem[];
}

export interface ITicketHistory {
  ticketNumber: number;
  requesterName: string;
  raiseTime: string;
  escalationStatus: EscalationStatus;
  completedTasks: number;
  inProgressTasks: number;
  notStartedTasks: number;
  epcTagStatus: string;
  customers: string[];
  modifyUserName: string;
  ticketCategories: string[];
  productReportingGroups: string[];
  progress: string;
  canEditRequest?: boolean;
  canEditApproval?: boolean;
  regionCategories: string;
  escalationRegion: string;
  escalationLevel: string;
  lastModifiedAt: string;
  groupSupplyUnit: string;
}

export interface ITicketUploadParams {
  ticketNumber?: number;
  files: any;
  blobObjIds: number[];
}

export interface ITicketApprovalItems {
  ticketNumber?: number;
  escalationStatus: TEscalationStatus | "";
  escalationTag: string;
  escalationLevel: string;
  comments?: string;
  ticketAssignedUsers: IAssignedUserItem[];
}

export interface ITicketApprovalEditedItems {
  ticketNumber?: number;
  escalationStatus: TEscalationStatus | "";
  escalationTag: string;
  escalationLevel: string;
  comments: string;
  assignedUsers: IAssignedUserEditedItem[];
}

export interface IAssignedUserEditedItem {
  assignedUserEmails: string[];
  productReportingGroup: string;
  ticketCategory: string | null;
}

export interface IAssignedUserInfoItem {
  assignedUserEmail: string;
  assignedUserName: string;
}

export interface IAssignedUserItem {
  assignedUsers: IAssignedUserInfoItem[];
  productReportingGroup: string;
  ticketCategory: string | null;
}

const TICKET_LOG = "/v1/escalation-feedback/ticket";

export const useGetTicketHistory = () =>
  useApiGet<ITicketHistory[]>(TICKET_LOG + "/ticket-history");

export const useCreateEscalationTicket = () =>
  useApiPost<ITicketCreateParams, IActionResult>(TICKET_LOG);

export const useUpdateEscalationTicket = () =>
  useApiPut<ITicketUpdateParams, IActionResult>(TICKET_LOG);

export const useGetTicketDetails = () =>
  useApiGet<ITicketDetails, { ticketNumber: number }>(TICKET_LOG + "/ticket-details");

export const useDownloadTicketAttachments = () =>
  useApiGetForBlob<undefined, { ticketNumber?: number; attachmentIds: number[] }>(
    TICKET_LOG + "/attachments"
  );

//upload
export const useUploadAttachments = () =>
  useApiPost<FormData, IActionResult>(TICKET_LOG + "/attachments");

//approve
export const useSetApprovalStatus = () =>
  useApiPost<ITicketApprovalEditedItems, IActionResult>(TICKET_LOG + "/status");
