import { ISelectItem } from "@react-gcc-eds/core";

export const apiToSelect = <T extends Object>(data: T[] | undefined): ISelectItem<T>[] => {
  return (
    data?.map(value => ({
      title: value === null ? "(None)" : value.toString(),
      value
    })) || []
  );
};

const dropdownToList = (list: ISelectItem<any>[]) => list.map(({ value }) => value);
export const dropdownToApi = (list: ISelectItem<any>[]) =>
  list.length === 0 ? undefined : dropdownToList(list);
