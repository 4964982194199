export interface TUserBaseInfo {
  userName: string;
  userEmail: string;
}

export interface TUser extends TUserBaseInfo {
  firstName: string;
  lastName: string;
  roles: string[];
  marketAreas: EMarketArea[];
  efRoles: EEfRoles[];
}

export interface TAppSettings {
  TODO: string;
  version: string;
  apiUrl: string;
  enableRoleVirtualization?: boolean;
}

export interface IErrorResponse {
  message: string;
  messages: string[];
  payloads: any;
  payload: any;
}

export enum Roles {
  // This role for SPC part read and edit
  SPCSupplier = "SPCChiefDataSupplier",
  // This role for SPC part readonly
  SPCOfficer = "SPCPrimeDigitalizationOfficer",
  // This role for GFT part upload and view
  GFTMaintainer = "SupplierFSCTMaintainer",
  // This role for GFT part only view
  GFTViewer = "SupplierFSCTViewer"
}

export enum EEfRoles {
  // This role for Escalation Feedback part, everyone should have readonly role after login in IDM
  EFReadOnly = "EFReadOnly",
  // This role for Escalation Feedback part, super administrator role
  EFSuperOwner = "EFSuperOwner",
  // This role for Escalation Feedback part, can add and edit request in Ticket log page
  EFTicketRequester = "EFTicketRequester",
  // This role for Escalation Feedback part, can approve ticket request in Approval page
  EFTicketApprover = "EFTicketApprover"
}

export const SPCRoles = [Roles.SPCOfficer, Roles.SPCSupplier];

export const EFRoles = [
  EEfRoles.EFReadOnly,
  EEfRoles.EFSuperOwner,
  EEfRoles.EFTicketRequester,
  EEfRoles.EFTicketApprover
];

export enum EMarketArea {
  MOAI = "InboundManagementToolMOAI",
  MMEA = "InboundManagementToolMMEA",
  MELA = "InboundManagementToolMELA",
  MNEA = "InboundManagementToolMNEA",
  MANA = "InboundManagementToolMANA",
  GLOBAL = "InboundManagementToolGlobal"
}
