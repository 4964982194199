import { ITooltip, Tooltip } from "@react-gcc-eds/core";
import "./columns-filters-info.scss";

const ColumnsFiltersInfo = ({
  columnsNames,
  position,
  onClear
}: {
  columnsNames: string[];
  position: ITooltip["position"];
  onClear: () => void;
}) => {
  const columnsCount = columnsNames.length;
  if (columnsCount === 0) return null;

  return (
    <div className="column-filters-info">
      <Tooltip
        content={columnsNames
          .filter(c => c)
          .map(c => c.charAt(0).toUpperCase() + c.slice(1))
          .sort()
          .map(c => (
            <div key={c}>{c}</div>
          ))}
        position={position}
        type="pointer"
      >
        {`${columnsCount} column${columnsCount > 1 ? "s have" : " has"} filter.`}
      </Tooltip>
      <span className="column-filters-clear" onClick={onClear}>
        Clear all
      </span>
    </div>
  );
  //}
};

export default ColumnsFiltersInfo;
