import { useApiGet, useApiPost, useApiPostForBlob } from "../../api/api";

export interface IEscalationDetailsItem {
  id: number;
  salesOrder: string;
  item: string;
  marketArea: string;
  customer: string;
  plant: string;
  materialL1: string;
  materialDesc: string;
  vendor: string;
  vendorDesc: string;
  vpo: string;
  escalationTagDate: number;
  requestMad: number;
  latestConfMad: number;
  tagItem: string;
  orderQty: number;
  actualGiQtyItem: number;
  remainQty: number;
  oaGap: string;
  oaGapDays: number;
  escalationTagYear: number;
  escalationTagMonth: number;
  customerUnit: string;
  customerDesc: string;
  escalationTagItem: string;
  pcode: string;
  excludePackage: string;
  handlingLt: number;
  ltReduction: number;
  progress: number;
  escalationProgress: string;
  plantDesc: string;
}

export interface IPagedQuery {
  offset?: number;
  limit?: number;
  filterBy?: string;
  orderBy?: string;
  groupBy?: string;
  orderByType?: string;
}

export interface IEscalationDetailsPagedQuery extends IPagedQuery {
  escalationStatuses?: number[];
  pcodes?: string[];
  customerUnits?: string[];
  excludePackages?: string[];
}

export interface IPagedAnswer<T extends {}> {
  total: number;
  data: T;
}

export interface IEscalationDetailsLookupItem {
  marketAreas: string[];
  salesOrders: string[];
  plants: string[];
  materialL1s: string[];
  vendors: string[];
  vendorDescs: string[];
  vpos: string[];
  tagItems: string[];
  oaGaps: string[];
  customerDescs: string[];
  pcodes: string[];
  excludePackages: string[];
  customerUnits: string[];
}

export interface IGlobalOpenOrderLookupItem {
  finalExternalCustomerDescs: string[];
  mdFinalExternalCustomerMarketAreas: string[];
  materialDescs: string[];
  materialL1s: string[];
  plants: string[];
  salesOrders: string[];
  vendors: string[];
  salesOrderItems: string[];
}

export interface IGlobalOpenOrderItem {
  id: number;
  materialL1: string;
  materialDesc: string;
  mdFinalExternalCustomerMarketArea: string;
  finalExternalCustomerDesc: string;
  plant: string;
  salesOrder: string;
  salesOrderItem: string;
  vendor: string;
  purchaseOrder: string;
  soCreatedOnDate: number;
  soRequestedMaDateItemLatest: number;
  soConfMaDateItem: number;
  orderQty: number;
}

type stringNull = string | null;
export interface IGlobalOpenOrderFilterItem {
  finalExternalCustomerDescs?: stringNull[];
  materialDescs?: stringNull[];
  materialL1s?: stringNull[];
  mdFinalExternalCustomerMarketAreas?: stringNull[];
  plants?: stringNull[];
  salesOrder?: stringNull;
  vendors?: stringNull[];
  soCreatedOnDateFrom?: string;
  soCreatedOnDateTo?: string;
  salesOrderItems?: string;
}

export interface IGlobalOpenOrderSortItem {
  orderBy?: string;
  orderByType?: "asc" | "desc";
}

export interface IGlobalOpenOrderParams extends IGlobalOpenOrderFilterItem, IPagedQuery {}

export const useGetEscalationDetails = () =>
  useApiPost<IEscalationDetailsPagedQuery, IPagedAnswer<IEscalationDetailsItem[]>>(
    "/v1/escalation-feedback/visualization/escalation-details"
  );

export const useGetEscalationDetailsLookup = () =>
  useApiGet<IEscalationDetailsLookupItem>("/v1/escalation-feedback/lookup/escalation-details");

export const useExportEscalationDetails = () =>
  useApiPostForBlob<IEscalationDetailsPagedQuery>(
    "/v1/escalation-feedback/visualization/export-escalation-details"
  );

export const useGetGlobalOpenOrderList = () =>
  useApiPost<IGlobalOpenOrderParams, IPagedAnswer<IGlobalOpenOrderItem[]>>(
    "/v1/escalation-feedback/visualization/global-open-orders"
  );

export const useGetGlobalOpenOrderLookup = () =>
  useApiGet<IGlobalOpenOrderLookupItem>("/v1/escalation-feedback/lookup/global-open-order");

export const useExportGlobalOpenOrders = () =>
  useApiPostForBlob<IGlobalOpenOrderFilterItem>(
    "/v1/escalation-feedback/visualization/export-global-open-orders"
  );
