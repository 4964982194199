import { Icon } from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import { TooltipPayload, TooltipProps } from "recharts";

export const ChartTooltip = ({
  props,
  units,
  inverted,
  fixedLabel,
  secondaryValues
}: {
  props: TooltipProps;
  units: string[];
  inverted?: boolean;
  fixedLabel?: string;
  secondaryValues?: string[];
}) => {
  const [items, setItems] = useState<TooltipPayload[]>(props.payload ? [...props.payload] : []);
  useEffect(() => {
    let i = props.payload ? [...props.payload] : [];
    i = inverted ? i.reverse() : i;
    setItems(i);
  }, [props.payload]);
  return (
    <div className="chart-tooltip visible">
      <table className="content">
        <tbody>
          <tr className="line">
            <td>{fixedLabel ? fixedLabel : props.label}</td>
            <td className="right-align">{units.length <= 1 ? units[0] : ""}</td>
          </tr>
          {items.map((pl: TooltipPayload, index: number) => {
            return (
              <tr key={`${pl.dataKey}`} className="item">
                <td>
                  <span style={{ color: pl.color }}>
                    <Icon name="alarm-level6" />
                  </span>
                  <span>{pl.dataKey}</span>
                </td>
                <td className="right-align">
                  <span>
                    {pl.value} {units.length > 1 && units[index]}{" "}
                    <span style={{ fontSize: "12px" }}>
                      {secondaryValues && secondaryValues[index]}
                    </span>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
