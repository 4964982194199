import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Column,
  DateField,
  Dialog,
  Form,
  FormGroup,
  FormElement,
  NumberField,
  Pagination,
  Row,
  TextArea
} from "@react-gcc-eds/core";
import { EscalationPriority } from "../helper";
import "./feedback-dialog.scss";
import SingleSelect from "../../components/select/SingleSelect";
import Table from "../../components/table/table";
import { getWeek, getYear } from "date-fns";
import { PeoplePicker } from "@microsoft/mgt-react";
import {
  ITicketTaskEditItem,
  ITicketTaskItem,
  IWeeklyUpdateEditItem,
  IWeeklyUpdateItem
} from "../api/task";
import { FEEDBACK_ACTIONS, FEEDBACK_PROGRESS } from "../helpers";
import { simpleObjectEqual } from "../../utils/user-helpers";

const FeedbackDialog = ({
  show,
  formData,
  weeklyUpdateData,
  onClose,
  onSaveTask,
  onSaveWeeklyUpdate,
  onShowDetail,
  onShowPreview,
  canEdit
}: {
  show: boolean;
  formData: ITicketTaskItem;
  weeklyUpdateData: IWeeklyUpdateItem[];
  canEdit?: boolean;
  onClose: () => void;
  onSaveTask: (data: ITicketTaskEditItem) => void;
  onSaveWeeklyUpdate: (data: IWeeklyUpdateEditItem[]) => void;
  onShowDetail: (ticketNumber: number) => void;
  onShowPreview: (ticketNumber: number) => void;
}) => {
  //form
  const [editedFormData, setEditedFormData] = useState<ITicketTaskEditItem>();
  const [editedWeeklyUpdates, setEditedWeeklyUpdates] = useState<IWeeklyUpdateItem[]>();

  const translateDetailToEditFormData = (detail: ITicketTaskItem) => {
    const { taskId, progress, priority, startDate, dueDate, taskAssignedUsers } = detail;
    return { taskId, progress, priority, startDate, dueDate, taskAssignedUsers };
  };

  useEffect(() => {
    if (formData) {
      setEditedFormData(translateDetailToEditFormData(formData));
    }
  }, [formData]);

  useEffect(() => {
    if (weeklyUpdateData) {
      setEditedWeeklyUpdates(weeklyUpdateData);
    }
  }, [weeklyUpdateData]);

  const onFormValueChange = (name: keyof ITicketTaskEditItem) => (value: any) =>
    setEditedFormData(f => f && { ...f, [name]: value });

  //material table
  const PAGE_LIMIT = 4;
  const [currentPage, setCurrentPage] = useState(1);

  //weekly updates
  const hasNewWeek = useMemo(() => {
    return Boolean(editedWeeklyUpdates?.find(v => isNaN(v.id)));
  }, [editedWeeklyUpdates]);

  const hasCurrentWeekly = useMemo(() => {
    const currentWeekNumber = getWeek(new Date());
    const hasCurrentWeekFromApi = Boolean(
      weeklyUpdateData.find(w => getWeek(new Date(w.weekNumber)) === currentWeekNumber)
    );
    return hasNewWeek || hasCurrentWeekFromApi;
  }, [editedWeeklyUpdates]);

  const handleWeeklyChange =
    (key: keyof IWeeklyUpdateItem, id: number) => (value: string | number) => {
      setEditedWeeklyUpdates(items =>
        items?.map(item =>
          item.id === id || (isNaN(id) && isNaN(item.id)) ? { ...item, [key]: value } : item
        )
      );
    };

  const addCurrentWeeklyUpdate = () => {
    const currentWeeklyUpdateItem = {
      id: NaN,
      weekNumber: new Date().toISOString(),
      lastModifiedBy: "",
      comments: "",
      action: null,
      quantity: NaN,
      ticketNumber: formData.ticketNumber,
      ticketCategory: formData.ticketCategory
    };
    setEditedWeeklyUpdates(items =>
      items ? [currentWeeklyUpdateItem, ...items] : [currentWeeklyUpdateItem]
    );
  };

  const handleSave = () => {
    onClose();
    if (
      editedFormData &&
      !simpleObjectEqual(editedFormData, translateDetailToEditFormData(formData))
    ) {
      onSaveTask(editedFormData);
    }
    if (editedWeeklyUpdates && !simpleObjectEqual(editedWeeklyUpdates, weeklyUpdateData)) {
      onSaveWeeklyUpdate(
        editedWeeklyUpdates.filter((w, index) =>
          hasNewWeek
            ? index > 0
              ? !(
                  w.action === weeklyUpdateData[index - 1].action &&
                  w.quantity === weeklyUpdateData[index - 1].quantity &&
                  w.comments === weeklyUpdateData[index - 1].comments
                )
              : true
            : !(
                w.action === weeklyUpdateData[index].action &&
                w.quantity === weeklyUpdateData[index].quantity &&
                w.comments === weeklyUpdateData[index].comments
              )
        )
      );
    }
  };

  return (
    <>
      <Dialog
        fullscreen
        title={<h3>Ticket {formData.ticketNumber}</h3>}
        visible={show}
        className={"feedback-dialog"}
        onClose={onClose}
        buttons={
          <>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => onShowDetail(formData.ticketNumber)}>Show Detail</Button>
            {canEdit && (
              <>
                <Button primary onClick={() => onShowPreview(formData.ticketNumber)}>
                  Update OA
                </Button>
                <Button primary onClick={handleSave}>
                  Save
                </Button>
              </>
            )}
          </>
        }
      >
        <div className="feedback-dialog-content">
          <Row>
            <Column>
              <h4>
                <span style={{ marginRight: "6px" }}>
                  Ticket category: {formData.ticketCategory};
                </span>
                <span style={{ marginRight: "6px" }}>
                  Product reporting group: {formData.productReportingGroups?.toString()};
                </span>
                <span>Customer: {formData.customer}</span>
              </h4>
            </Column>
          </Row>
          <Row>
            <Column className="feedback-dialog-avatars">
              {show && (
                <PeoplePicker
                  personCardInteraction="hover"
                  showPresence
                  defaultSelectedUserIds={editedFormData?.taskAssignedUsers}
                  selectionChanged={e => {
                    onFormValueChange("taskAssignedUsers")(e.detail.map((v: any) => v.mail));
                  }}
                  onClick={e => e.stopPropagation()}
                />
              )}
            </Column>
          </Row>
          <div>
            <Row>
              <Column lg={12}>
                <h4>Options:</h4>
              </Column>
            </Row>
            <Row className="option-row">
              <Column lg={3}>
                <SingleSelect
                  items={FEEDBACK_PROGRESS}
                  value={
                    FEEDBACK_PROGRESS.find(v => v.value === editedFormData?.progress)?.title || ""
                  }
                  onChange={selectedItem => onFormValueChange("progress")(selectedItem.value)}
                  topLabel="Handling Progress"
                  type="input-single"
                  clickItemTextChange={false}
                  disabled={!canEdit}
                />
              </Column>
              <Column lg={3}>
                <SingleSelect
                  items={Object.keys(EscalationPriority).map(p => ({
                    title: EscalationPriority[p as keyof typeof EscalationPriority],
                    value: EscalationPriority[p as keyof typeof EscalationPriority]
                  }))}
                  value={editedFormData?.priority}
                  onChange={selectedItem => onFormValueChange("priority")(selectedItem.value)}
                  topLabel="Priority"
                  type="input-single"
                  clickItemTextChange={false}
                  disabled={!canEdit}
                />
              </Column>
              <Column lg={3}>
                <label>Start date</label>
                <DateField
                  selectedDate={editedFormData ? new Date(editedFormData.startDate) : undefined}
                  onDateSelected={onFormValueChange("startDate")}
                  disabled={!canEdit}
                />
              </Column>
              <Column lg={3}>
                <label>Due date</label>
                <DateField
                  selectedDate={editedFormData ? new Date(editedFormData.dueDate) : undefined}
                  onDateSelected={onFormValueChange("dueDate")}
                  disabled={!canEdit}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <h4>Details:</h4>
                <Table
                  size={"compact"}
                  columns={[
                    { key: "material", header: "Material" },
                    { key: "materialDesc", header: "Description" },
                    { key: "totalQuantity", header: "Total Quantity" },
                    { key: "orderPlacedHub", header: "Order Placed Hub" },
                    { key: "currentMaterialAvailableDateRange", header: "Confirmed MAD" },
                    {
                      key: "currentReadyForShipmentDateRange",
                      header: "Latest acceptable RFS date"
                    }
                  ]}
                  rows={formData.taskMaterials.slice(
                    (currentPage - 1) * PAGE_LIMIT,
                    currentPage * PAGE_LIMIT
                  )}
                  pagination={
                    formData.taskMaterials.length > 4 ? (
                      <Pagination
                        activePage={currentPage}
                        onLeftClick={page => setCurrentPage(page)}
                        onPageClick={page => setCurrentPage(page)}
                        onRightClick={page => setCurrentPage(page)}
                        pages={Math.ceil(formData.taskMaterials.length / PAGE_LIMIT)}
                      />
                    ) : undefined
                  }
                />
              </Column>
            </Row>
            <Row>
              <Column className="feedback-dialog-weekly-update-title">
                <h4>Weekly Update:</h4>
                <Button
                  primary
                  iconName="plus"
                  onClick={addCurrentWeeklyUpdate}
                  disabled={hasCurrentWeekly || !canEdit}
                >
                  Add
                </Button>
              </Column>
              <div className="feedback-dialog-weekly-update">
                {editedWeeklyUpdates
                  ? editedWeeklyUpdates.map((w, i) => (
                      <Column key={`weekly-${i}`}>
                        <label>
                          {getYear(new Date(w.weekNumber))} WK {getWeek(new Date(w.weekNumber))}
                          <span style={{ marginLeft: "8px" }}>{w.lastModifiedBy}</span>
                        </label>
                        <Form>
                          <FormGroup title="Conclusion" sm={4}>
                            <FormElement>
                              <SingleSelect
                                items={FEEDBACK_ACTIONS.map(item => ({ title: item, value: item }))}
                                onChange={item => handleWeeklyChange("action", w.id)(item.value)}
                                value={w.action || ""}
                                placeholder="Select option"
                                topLabel="Action"
                                type="input-single"
                                clickItemTextChange={false}
                                disabled={!canEdit}
                              />
                            </FormElement>
                            <FormElement>
                              <NumberField
                                onChange={handleWeeklyChange("quantity", w.id)}
                                value={w.quantity}
                                label="Quantity"
                                disabled={!canEdit}
                              />
                            </FormElement>
                          </FormGroup>
                          <FormGroup title="Comments" sm={8}>
                            <FormElement>
                              <TextArea
                                value={w.comments || ""}
                                onChange={handleWeeklyChange("comments", w.id)}
                                disabled={!canEdit}
                              />
                            </FormElement>
                          </FormGroup>
                        </Form>
                      </Column>
                    ))
                  : "No data find!"}
              </div>
            </Row>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default FeedbackDialog;
