import { ReactElement, ReactNode } from "react";
import { Button, IComponent, Pagination } from "@react-gcc-eds/core";

export enum SortingDirection {
  None,
  Ascending,
  Descending
}

export interface TableColumn {
  /** Column property name */
  key: string;
  /** Column header */
  header: string | ReactNode;
  /** Cell renderer */
  Cell?: (row: object) => ReactNode;
  /** Sortable header */
  sortable?: boolean;
  editable?: boolean;
  /** Sorting direction */
  sortingDirection?: SortingDirection;
  /** Enable row actions */
  enableDropdownMenu?: boolean;
  freeze?: boolean;
}

export interface ITableInfo {
  show: {
    from: number;
    to: number;
  };
  total: number;
  selected: number;
}

export interface ITable extends IComponent {
  /** Columns definition */
  columns: TableColumn[];
  /** Table data. In order to use the 'selectedRows' functionality, each data item must have a
   unique "key" property */
  rows: object[];
  /** Size */
  size?: "normal" | "compact" | "tiny";
  /** Type */
  rowsStyle?: "none" | "dashed" | "striped";
  /** Type for splitcells */
  splitCellStyle?: "none" | "dashed" | "striped";
  /** On Row Click, returns entire row object. Also triggered for "keypress" events */
  onRowClick?(data: object): void;
  /** On Cell Click, returns entire row object */
  onCellClick?(data: object, rowIndex: number, columnIndex: number): void;
  /** onSortClick */
  onSort?(key: string, sortingDirection: SortingDirection): void;
  /** Selectable */
  selectable?: boolean;
  /** Selected rows. This should be an array of keys that match the "key" property of each row data
   object. Rows are only shown as selected if 'selectable' is true. */
  selectedRows?: string[];
  /** Show the table in the "disabled" variation */
  disabled?: boolean;
  /** Shows information on which rows that are selected and also which page user is at. */
  tableInfo?: ITableInfo;
  /** Pagination */
  /** @deprecated This property is deprecated, please use pagination instead. */
  paginaton?: ReactElement<typeof Pagination>;
  /** Pagination */
  pagination?: ReactElement<typeof Pagination>;
  onEditedCell?(row: object, index: number): void;
  /** Table Scrollning horizontal */
  scrollHorizontal?: boolean;
  /** Table Scrollning vertical */
  scrollVertical?: boolean;
  /** Show settings icon */
  showSettingsIcon?: boolean;
  /** onSettingsIconClick */
  onSettingsIconClick?(): void;
  /** Show settings button */
  topButtons?: ReactElement<typeof Button>[] | ReactElement<typeof Button>;
  /** Show/hide table header */
  hideHeader?: boolean;
}
