import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { IDoesFilterPassParams, IFilterParams } from "ag-grid-community";
import { TextArea } from "@react-gcc-eds/core";
import "./copy-from-excel-filter.scss";

export const CopyFromExcelFilter = forwardRef((props: IFilterParams, ref) => {
  const [text, setText] = useState<string>("");
  const initial = useRef(false);
  const timeout = useRef<NodeJS.Timeout>();

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        const value = props.valueGetter({
          api: props.api,
          node: params.node,
          data: params.data,
          column: props.column,
          columnApi: props.columnApi,
          colDef: props.colDef,
          getValue: () => 1,
          context: props.context
        });
        return (
          value &&
          text
            .split("\n")
            .map(v => v.trim().toLocaleUpperCase())
            .includes(value?.toLocaleUpperCase())
        );
      },

      isFilterActive() {
        return text !== "";
      },
      // Gets the filter state. If filter is not active, then should return null/undefined.
      // The grid calls getModel() on all active filters when gridApi.getFilterModel() is called.
      getModel() {
        return text
          ? {
              filter: text,
              filterType: "text",
              type: "copyFromExcel"
            }
          : undefined;
      },
      // Restores the filter state. Called by the grid after gridApi.setFilterModel(model) is called.
      // The grid will pass undefined/null to clear the filter.
      setModel(model: any) {
        if (model === null) {
          setText("");
        }
        if (model?.filter) {
          setText(model.filter);
        }
      }
    };
  });

  const onTextChange = (text: string) => {
    setText(text);
  };

  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      return;
    }
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        props.filterChangedCallback();
      }, 600);
    } else {
      timeout.current = setTimeout(() => {
        props.filterChangedCallback();
      }, 600);
    }
  }, [text]);

  return (
    <div className={"ag-copy-from-excel-filter-container"}>
      <div>
        <label>Paste from excel:</label>
        <TextArea value={text} onChange={onTextChange} style={{ width: "100%" }} />
      </div>
    </div>
  );
});
