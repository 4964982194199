import { Button } from "@react-gcc-eds/core";
import { useState } from "react";
import { ListBuilder } from "../../components/listbuilder";
import { IListSideItem } from "../../components/listbuilder/IListBuilder";
import { simpleObjectEqual } from "../../utils/user-helpers";
import "./list-builder-card.scss";

const getUnselectedList = (
  list: IListSideItem[],
  selectedItems: IListSideItem[]
): IListSideItem[] => {
  const selectedList = selectedItems.map(({ title }) => title);
  return list.filter(item => !selectedList.includes(item.title));
};

const ListBuilderCard = ({
  totalList,
  selectedList,
  title,
  onSubmit,
  onSaveLoading
}: {
  totalList: IListSideItem[];
  selectedList: IListSideItem[];
  title: string;
  onSubmit: (selectedList: string[]) => void;
  onSaveLoading: boolean;
}) => {
  const [leftList, setLeftList] = useState<IListSideItem[]>(
    getUnselectedList(totalList, selectedList)
  );
  const [rightList, setRightList] = useState<IListSideItem[]>(selectedList);

  const onTransferRightClick = (selectedItems: IListSideItem[]) => {
    if (leftList.length > 0) {
      setLeftList(list => getUnselectedList(list, selectedItems));
      setRightList(list => [...list, ...selectedItems]);
    }
  };

  const onTransferLeftClick = (selectedItems: IListSideItem[]) => {
    if (rightList.length > 0) {
      setRightList(list => getUnselectedList(list, selectedItems));
      setLeftList(list => [...list, ...selectedItems]);
    }
  };

  const handleSave = () => {
    onSubmit(rightList.map(({ title }) => title));
  };

  return (
    <div className="list-builder-card">
      <div className="list-builder-card-title">
        <div>{title}</div>
        <Button
          primary
          onClick={handleSave}
          disabled={simpleObjectEqual(getUnselectedList(totalList, selectedList), leftList)}
          loading={onSaveLoading}
        >
          Save
        </Button>
      </div>
      <ListBuilder
        leftTitle="List items available"
        rightTitle="List items assigned"
        leftSideItems={leftList}
        rightSideItems={rightList}
        onTransferRightClick={onTransferRightClick}
        onTransferLeftClick={onTransferLeftClick}
      />
    </div>
  );
};

export default ListBuilderCard;
