import { Button, FileUpload, useNotifications } from "@react-gcc-eds/core";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useAppState } from "../../../app-state";
import { Roles, TUser } from "../../../types";
import View from "../../../views/app-view";
import {
  EGftUploadStatus,
  IWeekParam,
  IWeeksParam,
  useExportWeekly,
  useGetAllWeeks,
  useGetSsrStatus,
  useGetStatusTrend,
  useGetStatusWeekly,
  useUploadFcst
} from "../../api/supplier-fcst-res";
import PublishStatusTrend from "../../component/gft/PublishStatusTrend";
import PublishStatusWeekly from "../../component/gft/PublishStatusWeekly";
import "./supplier-fcst-res-view.scss";

const SupplierFcstResView = () => {
  //role
  const [appState] = useAppState();

  //filter
  const [allWeeksData, loadingAllWeeks, getAllWeeks] = useGetAllWeeks();

  useEffect(() => {
    getAllWeeks();
  }, []);

  useEffect(() => {
    if (allWeeksData) {
      getStatusTrend({ weeksString: allWeeksData.slice(0, 12).toString() });
      getStatusWeekly({ weekTime: allWeeksData[0] });
    }
  }, [allWeeksData]);

  //display chart
  const [statusTrendData, loadingStatusTrend, getStatusTrend] = useGetStatusTrend();
  const [statusWeeklyData, loadingStatusWeekly, getStatusWeekly] = useGetStatusWeekly();

  const handleTrendSearch = (params: IWeeksParam) => {
    getStatusTrend(params);
  };

  const handleWeekSearch = (params: IWeekParam) => {
    getStatusWeekly(params);
  };

  //export
  const { createNotification } = useNotifications();
  const [exportExcel, loadingExportExcel, getExportExcel, , exportExcelName] = useExportWeekly();
  const handleExport = (params: IWeekParam) => {
    getExportExcel(params);
  };
  useEffect(() => {
    if (exportExcel) {
      if ((exportExcel as Blob).size !== 0) {
        fileDownload(exportExcel, exportExcelName || "Publish Status.xlsx");
      } else {
        createNotification("Warning !", "Not have any result!", "warning");
      }
    }
  }, [exportExcel]);

  //upload
  const [fcstFile, loadingFcstFile, uploadFcstFile] = useUploadFcst();
  const [ssrStatusData, loadingSsrStatus, getSsrStatus] = useGetSsrStatus();
  const [shouldTimer, setShouldTimer] = useState<boolean>(true);
  const [uploadStatus, setUploadStatus] = useState<EGftUploadStatus>();

  useEffect(() => {
    if (fcstFile) {
      const { success } = fcstFile;
      if (success) {
        createNotification(`Success`, "Upload success!");
        getAllWeeks();
      }
    }
  }, [fcstFile]);

  useEffect(() => {
    if (!shouldTimer) return;
    const interval = setInterval(() => {
      getSsrStatus();
    }, 5000);
    return () => clearInterval(interval);
  }, [shouldTimer]);

  useEffect(() => {
    if (ssrStatusData === EGftUploadStatus.FREE) {
      setShouldTimer(false);
      if (uploadStatus === EGftUploadStatus.RUNNING) {
        getAllWeeks();
      }
    }
    setUploadStatus(ssrStatusData);
  }, [ssrStatusData]);

  return (
    <View
      breadCrumbs={[{ text: "Supplier Performance" }, { text: "Supplier FCST Res - SM" }]}
      actions={
        (appState.user as TUser).roles.includes(Roles.GFTMaintainer) ? (
          <div className="supplier-fcst-upload">
            {shouldTimer || loadingFcstFile ? (
              <Button loading></Button>
            ) : (
              <FileUpload
                title="Upload"
                accept=".xlsx,.xlsm"
                onChange={(e: any) => {
                  e.target &&
                    e.target.files &&
                    uploadFcstFile({ file: e.target.files[0] }).then(() => {
                      e.target.value = "";
                    });
                }}
              />
            )}
          </div>
        ) : undefined
      }
    >
      <PublishStatusTrend
        loadingFilter={loadingAllWeeks}
        loadingChart={loadingStatusTrend}
        allWeeksData={allWeeksData}
        statusTrendData={statusTrendData || []}
        onSearch={handleTrendSearch}
      />
      <PublishStatusWeekly
        loadingFilter={loadingAllWeeks}
        loadingChart={loadingStatusWeekly}
        loadingExport={loadingExportExcel}
        allWeeksData={allWeeksData}
        onSearch={handleWeekSearch}
        statusWeeklyData={statusWeeklyData || []}
        onExport={handleExport}
      />
    </View>
  );
};

export default SupplierFcstResView;
