import { useCallback, useMemo } from "react";

const CustomerPagination = ({
  page,
  pageSize,
  total,
  onApplyPage
}: {
  page: number;
  pageSize: number;
  total: number;
  onApplyPage: (page: number) => void;
}) => {
  const getOffset = useCallback((page: number) => (page - 1) * pageSize + 1, [pageSize]);

  const offset = useMemo(() => getOffset(page), [page, getOffset]);
  const totalPage = useMemo(() => Math.ceil(total / pageSize), [pageSize, total]);
  const endPage = useMemo(() => {
    const endNumber = offset + pageSize - 1;
    return endNumber <= total ? endNumber : total;
  }, [offset, pageSize, total]);

  const applyPage = (page: number) => {
    onApplyPage(page);
  };

  return (
    <div className="ag-theme-alpine customer-pagination">
      <div className="ag-paging-panel ag-unselectable">
        <span className="ag-paging-row-summary-panel" role="status">
          <span className="ag-paging-row-summary-panel-number">{offset}</span>
          <span> to </span>
          <span className="ag-paging-row-summary-panel-number">{endPage}</span>
          <span> of </span>
          <span className="ag-paging-row-summary-panel-number">{total}</span>
        </span>
        <span className="ag-paging-page-summary-panel" role="presentation">
          <div
            className={`ag-button ag-paging-button ${page === 1 ? "ag-disabled" : ""}`}
            role="button"
            onClick={() => applyPage(1)}
          >
            <span className="ag-icon ag-icon-first" role="presentation"></span>
          </div>
          <div
            className={`ag-button ag-paging-button ${page === 1 ? "ag-disabled" : ""}`}
            role="button"
            onClick={() => applyPage(page - 1)}
          >
            <span className="ag-icon ag-icon-previous" role="presentation"></span>
          </div>
          <span className="ag-paging-description" role="status">
            <span>Page </span>
            <span className="ag-paging-number">{page}</span>
            <span> of </span>
            <span className="ag-paging-number">{totalPage}</span>
          </span>
          <div
            className={`ag-button ag-paging-button ${page === totalPage ? "ag-disabled" : ""}`}
            role="button"
            onClick={() => applyPage(page + 1)}
          >
            <span className="ag-icon ag-icon-next" role="presentation"></span>
          </div>
          <div
            className={`ag-button ag-paging-button ${page === totalPage ? "ag-disabled" : ""}`}
            role="button"
            onClick={() => applyPage(totalPage)}
          >
            <span className="ag-icon ag-icon-last" role="presentation"></span>
          </div>
        </span>
      </div>
    </div>
  );
};

export default CustomerPagination;
