import { Button, ISelectItem } from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import MultipleDropdownFilter from "../../../components/select/MultipleDropdownFilter";
import { getFormatMonth, getFormatQuarter, getFormatWeek } from "../../../utils";
import { IWeeksParam } from "../../api/supplier-fcst-res";

interface IArrayValueSelectItem extends ISelectItem<any> {
  value: number[];
}

export interface ISupplierTrendFilters {
  quarters: IArrayValueSelectItem[];
  months: IArrayValueSelectItem[];
  weeks: ISelectItem<any>[];
}

const SupplierFcstTrendFilter = ({
  onApply,
  defaultAvailableItems
}: {
  defaultAvailableItems: number[];
  onApply: (params: IWeeksParam) => void;
}) => {
  const [editedFilters, setEditedFilters] = useState<ISupplierTrendFilters>({
    quarters: [],
    months: [],
    weeks: []
  });

  const handleChange = (key: keyof ISupplierTrendFilters) => (item: ISelectItem<any>[]) =>
    setEditedFilters({ quarters: [], months: [], weeks: [], [key]: item });

  const { allAvailableItems } = useAllAvailableItems(defaultAvailableItems);
  const { defaultFilter } = useDefaultFilter(allAvailableItems);

  useEffect(() => {
    if (defaultFilter) {
      setEditedFilters(defaultFilter);
    }
  }, [defaultFilter]);

  const reset = () => {
    if (defaultFilter) {
      setEditedFilters(defaultFilter);
    }
  };

  const multiFilterLabel = (available: ISelectItem<any>[], selected?: ISelectItem<any>[]) =>
    `${selected ? selected.length : available.length} of ${available.length} selected`;

  const filterToApi = (filters: ISupplierTrendFilters): string => {
    let apiFilters: number[] = [];
    const { quarters, months, weeks } = filters;
    if (quarters.length > 0) {
      for (const { value } of quarters) {
        apiFilters = [...apiFilters, ...value];
      }
    } else if (months.length > 0) {
      for (const { value } of months) {
        apiFilters = [...apiFilters, ...value];
      }
    } else if (weeks.length > 0) {
      for (const { value } of months) {
        apiFilters = [...apiFilters, ...value];
      }
      apiFilters = weeks.map(({ value }) => value as number);
    }
    return apiFilters.sort((a, b) => a - b).toString();
  };

  return (
    <div className="scrollable-pane-content">
      <div className="scrollable-pane-controls">
        <MultipleDropdownFilter
          items={allAvailableItems.quarters}
          header="Quarter"
          label={multiFilterLabel(allAvailableItems.quarters, editedFilters.quarters)}
          onChange={handleChange("quarters")}
          selectedItems={editedFilters.quarters}
        />
        <MultipleDropdownFilter
          items={allAvailableItems.months}
          header="Month"
          label={multiFilterLabel(allAvailableItems.months, editedFilters.months)}
          onChange={handleChange("months")}
          selectedItems={editedFilters.months}
        />
        <MultipleDropdownFilter
          items={allAvailableItems.weeks}
          header="Week"
          label={multiFilterLabel(allAvailableItems.weeks, editedFilters.weeks)}
          onChange={handleChange("weeks")}
          selectedItems={editedFilters.weeks}
        />
      </div>
      <div className="scrollable-pane-actions">
        <Button onClick={reset}>Reset</Button>
        <Button primary onClick={() => onApply({ weeksString: filterToApi(editedFilters) })}>
          Apply
        </Button>
      </div>
    </div>
  );
};

const useAllAvailableItems = (defaultAvailableItems: number[]) => {
  const [allAvailableItems, setAllAvailableItems] = useState<ISupplierTrendFilters>({
    quarters: [],
    months: [],
    weeks: []
  });

  const unrepeatedSelects = (items: ISelectItem<any>[]): IArrayValueSelectItem[] => {
    const result: IArrayValueSelectItem[] = [];
    for (const { title, value } of items) {
      const sameTitleItem = result.find(item => item.title === title);
      if (sameTitleItem) {
        sameTitleItem.value.push(value as number);
      } else {
        result.push({
          title,
          value: [value as number]
        });
      }
    }
    return result;
  };

  useEffect(() => {
    if (defaultAvailableItems) {
      const parseAvailableItems = (parseFn: (time: number) => string): ISelectItem<any>[] =>
        defaultAvailableItems.map(time => ({
          title: parseFn(time),
          value: time
        }));
      setAllAvailableItems({
        quarters: unrepeatedSelects(parseAvailableItems(getFormatQuarter)),
        months: unrepeatedSelects(parseAvailableItems(getFormatMonth)),
        weeks: parseAvailableItems(getFormatWeek)
      });
    }
  }, [defaultAvailableItems]);

  return { allAvailableItems, setAllAvailableItems };
};

const useDefaultFilter = (allAvailableItems: ISupplierTrendFilters) => {
  const [defaultFilter, setDefaultFilter] = useState<ISupplierTrendFilters>();

  useEffect(() => {
    if (allAvailableItems.weeks.length > 0) {
      setDefaultFilter({
        quarters: [],
        months: [],
        weeks: allAvailableItems.weeks.slice(0, 12)
      });
    }
  }, [allAvailableItems]);

  return { defaultFilter };
};

export default SupplierFcstTrendFilter;
