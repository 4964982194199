import { useAppState } from "../../app-state";
import SingleSelect from "../../components/select/SingleSelect";
import "./global-settings.scss";

const useGlobalSettings = () => {
  const [{ spc }, setState] = useAppState();

  const indiaSettingsRender = () => {
    return (
      <SingleSelect
        type="input-single"
        className="select-search-global"
        items={spc.snapshotIndia.options}
        value={spc.snapshotIndia.currentSnapshot.title}
        clickItemTextChange={false}
        onChange={selectedItem =>
          setState(old => ({
            ...old,
            spc: {
              ...old.spc,
              snapshotIndia: {
                options: old.spc.snapshotIndia.options,
                currentSnapshot: selectedItem
              }
            }
          }))
        }
      />
    );
  };

  const seaoSettingsRender = () => {
    return (
      <SingleSelect
        type="input-single"
        className="select-search-global"
        items={spc.snapshotSeao.options}
        value={spc.snapshotSeao.currentSnapshot.title}
        clickItemTextChange={false}
        onChange={selectedItem =>
          setState(old => ({
            ...old,
            spc: {
              ...old.spc,
              snapshotSeao: {
                options: old.spc.snapshotSeao.options,
                currentSnapshot: selectedItem
              }
            }
          }))
        }
      />
    );
  };

  return {
    IndiaSettings: indiaSettingsRender,
    SeaoSettings: seaoSettingsRender,
    currentIndiaSnapshot: spc.snapshotIndia.currentSnapshot.value as number,
    currentSeaoSnapshot: spc.snapshotSeao.currentSnapshot.value as number
  };
};

export default useGlobalSettings;
