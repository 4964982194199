import { ApplicationLayer, Breadcrumb } from "@react-gcc-eds/core";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { useAppState } from "../app-state";
import { useAppMenu } from "../app-menu";

interface IDefinedBreadCrumbsItem {
  text: string;
  url?: string;
}

const View = ({
  title,
  actions,
  children,
  breadCrumbs = []
}: {
  title?: string | ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  breadCrumbs?: IDefinedBreadCrumbsItem[];
}) => {
  const [appState] = useAppState();
  const history = useHistory();

  return (
    <ApplicationLayer
      title={
        <>
          {breadCrumbs && breadCrumbs.length > 0 ? (
            <Breadcrumb
              items={breadCrumbs.map(b => {
                return {
                  title: b.text,
                  action: () => {
                    if (b.url) {
                      history.push(b.url);
                    }
                  }
                };
              })}
            ></Breadcrumb>
          ) : (
            title
          )}
        </>
      }
      menu={useAppMenu(appState?.userConfiguration.menus || [])}
      actions={actions}
    >
      {children}
    </ApplicationLayer>
  );
};

export default View;
