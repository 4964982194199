import { useEffect, useState } from "react";
import View from "../../views/app-view";
import {
  IGlobalOpenOrderFilterItem,
  IGlobalOpenOrderSortItem,
  useGetGlobalOpenOrderList,
  useGetGlobalOpenOrderLookup
} from "../api/tableau";
import GlobalOpenOrderEditor, {
  IFilterValuesModel
} from "../component/escalation-details/global-open-order-editor";
import { useHistory } from "react-router";

const PAGE_SIZE = 50;

const GlobalOpenOrderListView = () => {
  const [globalOpenOrderData, loadingGlobalOpenOrder, getGlobalOpenOrder] =
    useGetGlobalOpenOrderList();
  const [globalOpenOrderLookupData, loadingGlobalOpenOrderLookup, getGlobalOpenOrderLookup] =
    useGetGlobalOpenOrderLookup();
  const [filters, setFilters] = useState<IGlobalOpenOrderFilterItem>();
  const [sort, setSort] = useState<IGlobalOpenOrderSortItem | undefined>({
    orderBy: "soCreatedOnDate",
    orderByType: "desc"
  });
  const [page, setPage] = useState<number>(1);
  const history = useHistory();

  useEffect(() => {
    const materialDescParam = history.location.state
      ? [(history.location.state as any).materialDesc]
      : undefined;
    getGlobalOpenOrderLookup();
    getGlobalOpenOrder({
      offset: 0,
      limit: PAGE_SIZE,
      materialDescs: materialDescParam,
      ...(sort || {})
    });
  }, []);

  //filter
  const translateFilterToApi = (data: IFilterValuesModel[], header: string) => {
    const filterItem = data.find(v => v.name === header);
    return filterItem
      ? filterItem.values && filterItem.values.length > 0
        ? (filterItem.values as string[])
        : [null]
      : undefined;
  };

  const translateFilterStringToApi = (data: IFilterValuesModel[], header: string) => {
    const filterItem = data.find(v => v.name === header);
    return filterItem ? (filterItem.values as string) : undefined;
  };

  const handleFilterChange = (data: IFilterValuesModel[]) => {
    const apiFilter: IGlobalOpenOrderFilterItem = {
      materialL1s: translateFilterToApi(data, "Material L1"),
      materialDescs: translateFilterToApi(data, "MaterialDesc"),
      mdFinalExternalCustomerMarketAreas: translateFilterToApi(data, "MA"),
      plants: translateFilterToApi(data, "Plant"),
      finalExternalCustomerDescs: translateFilterToApi(data, "Customer"),
      salesOrder: translateFilterStringToApi(data, "SalesOrder"),
      vendors: translateFilterToApi(data, "Vendor"),
      soCreatedOnDateFrom: translateFilterStringToApi(data, "soCreatedOnDateFrom"),
      soCreatedOnDateTo: translateFilterStringToApi(data, "soCreatedOnDateTo")
    };
    setFilters(apiFilter);
    setPage(1);
    getGlobalOpenOrder({
      offset: 0,
      limit: PAGE_SIZE,
      ...apiFilter,
      ...(sort || {})
    });
  };

  //sort
  const handleSortChange = (filedId: string | undefined, order: "asc" | "desc" | undefined) => {
    const apiOrder = order ? { orderBy: filedId, orderByType: order } : undefined;
    setSort(apiOrder);
    setPage(1);
    getGlobalOpenOrder({
      offset: 0,
      limit: PAGE_SIZE,
      ...(filters || {}),
      ...(apiOrder || {})
    });
  };

  //pagination
  const onApplyPage = (page: number) => {
    setPage(page);
    getGlobalOpenOrder({
      offset: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...(filters || {}),
      ...(sort || {})
    });
  };

  return (
    <View
      breadCrumbs={[
        { text: "Escalation Management" },
        { text: "Visualization" },
        { text: "Global Open Order List" }
      ]}
    >
      <GlobalOpenOrderEditor
        total={globalOpenOrderData?.total || 0}
        pageSize={PAGE_SIZE}
        lookup={globalOpenOrderLookupData}
        tableData={globalOpenOrderData}
        onFilterChange={handleFilterChange}
        onSortChange={handleSortChange}
        onApplyPage={onApplyPage}
        page={page}
        loadingTable={loadingGlobalOpenOrderLookup}
        apiFilters={filters}
      />
    </View>
  );
};

export default GlobalOpenOrderListView;
