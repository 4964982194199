import { useState } from "react";
import { IRowNode } from "ag-grid-community";

export interface IIndex {
  [key: string]: string | number | object | undefined | boolean | null;
}

export interface ICellChange<T extends IIndex> {
  key: number | string;
  node: IRowNode;
  data: T;
  changes: { column: string; oldValue: string | number | boolean }[];
}

export interface ICellChanged<T extends {}> {
  node?: IRowNode;
  column: string;
  data: T;
  oldValue: string | number | boolean;
}

export interface IMultipleCellChanged<T extends {}> {
  node?: IRowNode;
  data: T;
  newChanges: { column: string; oldValue: string | number | boolean }[];
}

export const useTableEditing = <T extends IIndex>(
  keyPicker?: (node: IRowNode) => string | number
) => {
  const [editedCells, setEditedCells] = useState<ICellChange<T>[]>([]);

  const pendingChanges = editedCells.length > 0;

  const onCellChanged = ({ node, data, column, oldValue }: ICellChanged<T>) => {
    if (keyPicker) {
      if (!node) return;

      setEditedCells(cells => {
        if (cells.find(c => c.key === keyPicker(node))) {
          return cells.reduce((changes: ICellChange<T>[], change: ICellChange<T>) => {
            const newChange =
              change.key === keyPicker(node)
                ? { ...change, data, changes: [...change.changes, { column, oldValue }] }
                : change;
            changes.push(newChange);
            return changes;
          }, []);
        }
        return [
          ...cells,
          { key: keyPicker(node) || 0, node, data, changes: [{ column, oldValue }] }
        ];
      });
    } else {
      if (!node || node.rowIndex === null) return;

      setEditedCells(cells => {
        if (cells.find(c => c.key === node.rowIndex)) {
          return cells.reduce((changes: ICellChange<T>[], change: ICellChange<T>) => {
            const newChange =
              change.key === node.rowIndex
                ? { ...change, data, changes: [...change.changes, { column, oldValue }] }
                : change;
            changes.push(newChange);
            return changes;
          }, []);
        }

        return [...cells, { key: node.rowIndex || 0, node, data, changes: [{ column, oldValue }] }];
      });
    }
  };

  const onCellMultipleChanged = (cellChanges: IMultipleCellChanged<T>[]) => {
    if (keyPicker) {
      setEditedCells(cells => {
        let result = cells;
        cellChanges.forEach(({ node, data, newChanges }) => {
          if (!node) return;
          if (result.find(c => c.key === keyPicker(node))) {
            result = result.reduce((changes: ICellChange<T>[], change: ICellChange<T>) => {
              const newChange =
                change.key === keyPicker(node)
                  ? { ...change, data, changes: [...change.changes, ...newChanges] }
                  : change;
              changes.push(newChange);
              return changes;
            }, []);
          } else {
            result = [...result, { key: keyPicker(node) || 0, node, data, changes: newChanges }];
          }
        });
        return result;
      });
    } else {
      setEditedCells(cells => {
        let result = cells;
        cellChanges.forEach(({ node, data, newChanges }) => {
          if (!node || node.rowIndex === null) return;
          if (result.find(c => c.key === node.rowIndex)) {
            result = result.reduce((changes: ICellChange<T>[], change: ICellChange<T>) => {
              const newChange =
                change.key === node.rowIndex
                  ? { ...change, data, changes: [...change.changes, ...newChanges] }
                  : change;
              changes.push(newChange);
              return changes;
            }, []);
          } else {
            result = [...result, { key: node.rowIndex || 0, node, data, changes: newChanges }];
          }
        });
        return result;
      });
    }
  };

  const undoChanges = () => {
    editedCells.forEach(c => {
      c.changes.reverse().forEach(change => ((c.data as IIndex)[change.column] = change.oldValue));
      c.node.setData({ ...c.data });
    });
    setEditedCells([]);
  };

  const saveChanges = () => setEditedCells([]);

  return {
    editedCells,
    pendingChanges,
    onCellChanged,
    onCellMultipleChanged,
    undoChanges,
    saveChanges
  };
};
