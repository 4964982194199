export const AUTO_GROUP_COLUMN_PREFIX = "ag-Grid-AutoColumn-";

export const TEXT_FILTER_WITH_IS_CONTAINED_IN_OPTIONS = [
  "contains",
  "notContains",
  "equals",
  "notEqual",
  "startsWith",
  "endsWith",
  {
    displayKey: "isContainedIn",
    displayName: "Is contained in",
    predicate: ([filterValue]: string[], cellValue: string) =>
      !cellValue ||
      !filterValue ||
      filterValue
        .split(",")
        .map(v => v.trim().toLocaleUpperCase())
        .includes(cellValue.toLocaleUpperCase())
  },
  "blank",
  "notBlank"
];
