import { IServerModelGetRowRequest } from "./types";
import { AUTO_GROUP_COLUMN_PREFIX } from "./const";

interface ICondition {
  filterType: string;
  filter: string;
  filterTo: string;
  type: string;
  operator?: string;
  values?: string[];
  dateFrom?: string;
  dateTo?: string;
}

interface ICombinedCondition {
  filterType: string;
  operator: string;
  condition1: ICondition;
  condition2: ICondition;
}

const createFilterStatement = (
  mapper: (
    key: string,
    item: ICondition,
    syntax?: "sql" | "linq",
    filterTransformations?: IFilterTransformation[]
  ) => string,
  key: string,
  item: ICondition | ICombinedCondition,
  syntax?: "sql" | "linq",
  filterTransformations?: IFilterTransformation[]
) => {
  if (item.operator) {
    const condition1 = mapper(key, (item as ICombinedCondition).condition1, syntax);
    const condition2 = mapper(key, (item as ICombinedCondition).condition2, syntax);

    return "(" + condition1 + " " + item.operator + " " + condition2 + ")";
  }

  return mapper(key, item as ICondition, syntax, filterTransformations);
};

const textFilterMapper = (key: string, item: ICondition, syntax?: "sql" | "linq") => {
  if (syntax === "linq") {
    const filter = (clause: string, negate?: boolean) =>
      `${negate ? "!" : ""}${key}.${clause}("${item.filter}")`;
    switch (item.type) {
      case "equals":
        if (item.filter === null) {
          return `${key} == null`;
        }
        return filter("Equals");
      case "notEqual":
        return filter("Equals", true);
      case "contains":
        return filter("Contains");
      case "notContains":
        return filter("Contains", true);
      case "startsWith":
        return filter("StartsWith");
      case "endsWith":
        return filter("EndsWith");
      case "isContainedIn":
        const clause = item.filter
          .split(",")
          .map(k => k.trim())
          .filter(k => k)
          .reduce((result, k) => {
            return result + `${result ? " OR " : ""}${key}.Equals("${k}")`;
          }, "");
        return `(${clause})`;
      case "blank":
        return `(${key}.Equals("") || ${key} == null)`;
      case "notBlank":
        return `!(${key}.Equals("") || ${key} == null)`;
      case "copyFromExcel":
        const excelClause = item.filter
          .split("\n")
          .map(k => k.trim())
          .filter(k => k)
          .reduce((result, k) => {
            return result + `${result ? " OR " : ""}${key}.Equals("${k}")`;
          }, "");
        return `(${excelClause})`;
      default:
        throw new Error("Unknown text filter type: " + item.type);
    }
  } else {
    const upperFilter = item.filter
      ? item.filter.toLocaleUpperCase().replace("'", "''")
      : item.filter;
    switch (item.type) {
      case "equals":
        if (item.filter === null) {
          return `${key} IS NULL`;
        }
        return `UPPER(${key}) = '${upperFilter}'`;
      case "notEqual":
        return `UPPER(${key}) <> '${upperFilter}'`;
      case "contains":
        return `UPPER(${key}) LIKE '%${upperFilter}%'`;
      case "notContains":
        return `UPPER(${key}) NOT LIKE '%${upperFilter}%'`;
      case "startsWith":
        return `UPPER(${key}) LIKE '${upperFilter}%'`;
      case "endsWith":
        return `UPPER(${key}) LIKE '%${upperFilter}'`;
      case "isContainedIn":
        const clause = upperFilter
          .split(",")
          .map(k => k.trim())
          .filter(k => k)
          .reduce((result, k) => {
            return result + `${result ? " OR " : ""}UPPER(${key}) = '${k}'`;
          }, "");
        return `(${clause})`;
      case "blank":
        return `(${key} = '' OR ${key} IS NULL)`;
      case "notBlank":
        return `NOT(${key} = '' OR ${key} IS NULL)`;
      case "copyFromExcel":
        const excelClause = item.filter
          .split("\n")
          .map(k => k.trim())
          .filter(k => k)
          .reduce((result, k) => {
            return result + `${result ? " OR " : ""}UPPER(${key}) = '${k}'`;
          }, "");
        return `(${excelClause})`;

      default:
        throw new Error("Unknown text filter type: " + item.type);
    }
  }
};

const numberFilterMapper = (
  key: string,
  item: ICondition,
  syntax?: "sql" | "linq",
  filterTransformations?: IFilterTransformation[]
) => {
  let value = item.filter;
  let valueTo = item.filterTo;

  if (filterTransformations && filterTransformations.length > 0) {
    const transformationExists = filterTransformations.find(k => k[key]);
    if (transformationExists) {
      value = transformationExists[key](item.filter);
      if (item.filterTo) {
        valueTo = transformationExists[key](item.filterTo);
      }
    }
  }
  switch (item.type) {
    case "equals":
      return key + " = " + value;
    case "notEqual":
      return key + " != " + value;
    case "greaterThan":
      return key + " > " + value;
    case "greaterThanOrEqual":
      return key + " >= " + value;
    case "lessThan":
      return key + " < " + value;
    case "lessThanOrEqual":
      return key + " <= " + value;
    case "inRange":
      return "(" + key + " >= " + value + " and " + key + " <= " + valueTo + ")";
    default:
      throw new Error("Unknown number filter type: " + item.type);
  }
};

const setFilterMapper = (key: string, item: ICondition, syntax?: "sql" | "linq") => {
  const query = item.values?.reduce(
    (parts: string, v: string, index: number) =>
      parts +
      `${index > 0 ? " OR " : ""}${
        syntax === "sql"
          ? v == null
            ? `${key} IS NULL OR ${key} = ''`
            : `${key} = '${v}'`
          : v === null
          ? `${key} == null OR ${key} == ''`
          : `${key}.Equals("${v}")`
      }`,
    ""
  );
  return query ? `(${query})` : "";
};

const dateFilterMapper = (key: string, item: ICondition) => {
  let { dateFrom, dateTo } = item;
  dateFrom = dateFrom?.substring(0, 10);
  dateTo = dateTo?.substring(0, 10);
  switch (item.type) {
    case "equals":
      return `${key} = '${dateFrom}'`;
    case "notEqual":
      return `${key} != '${dateFrom}'`;
    case "greaterThan":
      return `${key} > '${dateFrom} 23:59:59.999'`;
    case "greaterThanOrEqual":
      return `${key} >= '${dateFrom}'`;
    case "lessThan":
      return `${key} < '${dateFrom}'`;
    case "lessThanOrEqual":
      return `${key} <= '${dateFrom} 23:59:59.999'`;
    case "inRange":
      return `(${key} >= '${dateFrom} 00:00:00' AND ${key} <= '${dateTo} 23:59:59.999')`;
    default:
      throw new Error("Unknown date filter type: " + item.type);
  }
};

const translateFilterModelToSqlList = (
  whereParts: string[],
  filterModel: any,
  syntax: "sql" | "linq",
  filterTransformations?: IFilterTransformation[],
  filterKeyMap?: { [key: string]: string }
) => {
  const stripAutoGroupedColumnPrefix = (col: string) =>
    col.startsWith(AUTO_GROUP_COLUMN_PREFIX) ? col.substring(AUTO_GROUP_COLUMN_PREFIX.length) : col;
  const handleBaseFilter = (item: ICondition | ICombinedCondition, key: string) => {
    const mappedKey = filterKeyMap
      ? filterKeyMap[stripAutoGroupedColumnPrefix(key)] || stripAutoGroupedColumnPrefix(key)
      : stripAutoGroupedColumnPrefix(key);
    switch (item.filterType) {
      case "text":
        whereParts.push(
          createFilterStatement(textFilterMapper, mappedKey, item, syntax, filterTransformations)
        );
        break;
      case "number":
        whereParts.push(
          createFilterStatement(numberFilterMapper, mappedKey, item, syntax, filterTransformations)
        );
        break;
      case "set":
        const filter = createFilterStatement(
          setFilterMapper,
          mappedKey,
          item,
          syntax,
          filterTransformations
        );
        if (filter) whereParts.push(filter);
        break;
      case "date":
        whereParts.push(
          createFilterStatement(dateFilterMapper, mappedKey, item, syntax, filterTransformations)
        );
        break;
      default:
        throw new Error("Unknown filter type: " + item.filterType);
    }
  };

  if (filterModel) {
    Object.keys(filterModel).forEach((key: string) => {
      const item = filterModel[key];

      if (item.filterType !== "multi") {
        handleBaseFilter(item, key);
      } else {
        item.filterModels
          .filter((fm: ICondition | ICombinedCondition) => fm !== null)
          .forEach((fm: ICondition | ICombinedCondition) => handleBaseFilter(fm, key));
      }
    });
  }
};

const whereClause = (
  request: IServerModelGetRowRequest,
  syntax: "sql" | "linq",
  filterTransformations?: IFilterTransformation[],
  filterKeyMap?: { [key: string]: string }
) => {
  const whereParts: string[] = [];
  const filterModel = request.filterModel;
  const groupKeys = request.groupKeys;
  const rowGroups = request.rowGroupCols;

  translateFilterModelToSqlList(
    whereParts,
    filterModel,
    syntax,
    filterTransformations,
    filterKeyMap
  );

  if (groupKeys && groupKeys.length) {
    groupKeys.forEach((key: string, index: number) =>
      whereParts.push(
        syntax === "sql"
          ? `${rowGroups[index].id} = ${typeof key === "string" ? `'${key}'` : key}`
          : `${rowGroups[index].id}.Equals(${typeof key === "string" ? `'${key}'` : key})`
      )
    );
  }

  if (whereParts.length > 0) {
    return whereParts.join(" AND ");
  }

  return "";
};

const orderByClause = (request: IServerModelGetRowRequest, groupBy: string) => {
  const sortModel = request.sortModel;

  if (sortModel.length === 0) return "";

  const sorts = sortModel
    .filter((s: { colId: string }) => !groupBy || s.colId === groupBy)
    .map((s: { colId: string; sort: string }) => {
      return s.colId + " " + s.sort.toUpperCase();
    });

  return sorts.join(", ");
};

const groupByClause = (request: IServerModelGetRowRequest) => {
  const rowGroups = request.rowGroupCols;
  const groupKeys = request.groupKeys;

  if (rowGroups.length <= groupKeys.length) return "";

  return rowGroups[groupKeys.length].id;
};

export interface IFilterTransformation {
  [key: string]: (v: string) => string;
}
export const buildQueryFromRequest = (
  request: IServerModelGetRowRequest,
  syntax: "sql" | "linq",
  filterTransformations?: IFilterTransformation[],
  filterKeyMap?: { [key: string]: string }
) => {
  const groupBy = groupByClause(request);
  return {
    filter: whereClause(request, syntax, filterTransformations, filterKeyMap),
    group: groupBy,
    order: orderByClause(request, groupBy)
  };
};

export const buildQueryFromFilterModel = (
  filterModel: any,
  syntax: "sql" | "linq",
  filterTransformations?: IFilterTransformation[],
  filterKeyMap?: { [key: string]: string }
) => {
  const sqlList: string[] = [];
  translateFilterModelToSqlList(sqlList, filterModel, syntax, filterTransformations, filterKeyMap);
  return sqlList.length > 0 ? sqlList.join(" AND ") : "";
};
