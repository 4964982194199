import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import cx from "classnames";
import { Icon, ISelectItem, ISingleSelect } from "@react-gcc-eds/core";
import { createDataAttributeObject, useClickOutside } from "../../utils";
import "./SingleSelect.scss";

interface IDefineSingleSelect extends ISingleSelect<any> {
  clickItemTextChange?: boolean;
  value?: string;
}

const SingleSelect = ({
  type,
  items,
  disabled,
  placeholder,
  value,
  label = "",
  topLabel,
  onChange,
  onOpen,
  onClose,
  onBlur,
  className,
  dataAttributes,
  clickItemTextChange = true
}: IDefineSingleSelect) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(label);
  const labelRef = useRef<HTMLLabelElement>(null);
  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setText(label);
  }, [label]);

  const [filteredItems, setFilteredItems] = useState<ISelectItem[]>([]);

  const filterOptions = (typedValue: string) => {
    if (items !== undefined) {
      setFilteredItems(
        items.filter(
          val => val.title.toLocaleLowerCase().indexOf(typedValue.toLocaleLowerCase()) > -1
        )
      );
    }
  };

  useEffect(() => {
    if (type === "input-single") {
      filterOptions(text);
    } else {
      setFilteredItems(items);
    }
  }, [items, text]);

  const validTypes = ["single", "input-single"];
  const selectedDataType = type !== undefined ? type : "single";

  const closeSelect = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };
  const containerRef = useClickOutside(closeSelect, open);

  const invalidType = (
    <div className="select closed" data-type="invalid-type">
      <div className="invalid_select">Invalid type</div>
    </div>
  );

  const renderItem = (item: ISelectItem) => (
    <div
      key={item.title}
      title={item.title}
      role="presentation"
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (!item.disabled) {
          if (clickItemTextChange) {
            setText(item.title);
          } else {
            setText("");
          }
          setOpen(false);

          if (onChange !== undefined) {
            onChange(item);
          }
          if (onClose) {
            onClose();
          }
        }
      }}
      className={cx(
        "item",
        { active: clickItemTextChange ? item.title === label : item.title === value },
        { disabled: item.disabled }
      )}
    >
      {item.title}
    </div>
  );

  const buttonType =
    selectedDataType === "input-single" ? (
      <div
        role="presentation"
        className="clickable current-options"
        onClick={() => {
          setOpen(true);
          if (onOpen) {
            onOpen();
          }
        }}
      >
        <input
          className={placeholder && !value ? "" : "select-value-dark-text"}
          value={text || ""}
          type="text"
          placeholder={placeholder ? value || placeholder : value}
          onFocus={() => {
            setOpen(true);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlur && onBlur(e)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === "") {
              setOpen(true);
            }
            setText(e.target.value);
            filterOptions(e.target.value);
          }}
        />
        <Icon
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            toggleOpen();
            if (!open) {
              setOpen(true);
              if (onOpen) {
                onOpen();
              }
            } else {
              setOpen(false);
              if (onClose) {
                onClose();
              }
            }
          }}
          name="chevron-down"
        />
      </div>
    ) : (
      <button
        type="button"
        role="presentation"
        onBlur={(e: any) => onBlur && onBlur(e)}
        className="btn current-options"
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          if (!open && onOpen) {
            onOpen();
          }
          if (!!open && onClose) {
            onClose();
          }
          setOpen(!open);
        }}
      >
        {label || placeholder || ""}
      </button>
    );

  const isOutOfViewport = (elem: HTMLDivElement) => {
    const itemHeightOffset = 32;
    const maxOffset = 130;
    const offsetHeight = items.length < 4 ? itemHeightOffset * items.length : maxOffset;
    const bounding = elem.getBoundingClientRect();

    return (
      bounding.bottom + offsetHeight > (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const outOfViewport = containerRef.current ? isOutOfViewport(containerRef.current) : false;

  if (validTypes.includes(selectedDataType)) {
    return (
      <>
        {topLabel && <label ref={labelRef}>{topLabel}</label>}
        <div
          {...(dataAttributes && createDataAttributeObject(dataAttributes))}
          title={label}
          ref={containerRef}
          role="presentation"
          className={cx(
            "select",
            { disabled },
            { closed: !open },
            { open },
            { "select-value-dark": !clickItemTextChange },
            className
          )}
          data-type={selectedDataType}
        >
          {buttonType}

          <div className={cx("options-list overflow", { above: outOfViewport })}>
            {type === "input-single" && filteredItems.length === 0 && text !== "" ? (
              <div className="no_results">No results found</div>
            ) : null}
            {filteredItems.map((item: ISelectItem) => {
              return renderItem(item);
            })}
          </div>
        </div>
      </>
    );
  }
  return invalidType;
};

export default SingleSelect;
