import { AxiosPromise } from "axios";
import {
  CellClassParams,
  IAggFuncParams,
  ICellRendererParams,
  SetFilterValuesFuncParams,
  ValueFormatterParams,
  ValueGetterParams
} from "ag-grid-community";
import { Icon } from "@react-gcc-eds/core";
import { IconNames } from "@react-gcc-eds/core/lib/types/components/icon/IIcon";
import { dateTimeFormatter, roundNumberToLocale } from "../utils";
import { CopyFromExcelFilter } from "../components/grid/copy-from-excel-filter";
import { ReactNode } from "react";
import { IColumnDefinition } from "../components/grid/grid";
import { TEXT_FILTER_WITH_IS_CONTAINED_IN_OPTIONS } from "../components/grid/const";

export const cell = (field: string, headerName?: string) => ({
  field: field,
  headerName: headerName
});

export const textCell = (field: string, headerName?: string, initiallyHidden?: boolean) => ({
  ...cell(field, headerName),
  filter: "agMultiColumnFilter",
  filterParams: {
    filters: [
      {
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: TEXT_FILTER_WITH_IS_CONTAINED_IN_OPTIONS
        }
      },
      {
        filter: CopyFromExcelFilter
      }
    ]
  },
  initialHide: initiallyHidden
});

export const groupCell = (enable = true) => ({
  rowGroup: true,
  enableRowGroup: enable
});

export const numericCell = (
  field: string,
  headerName?: string,
  decimals = 0,
  color?: string,
  aggFunc: string | ((p: IAggFuncParams) => void) | undefined = undefined,
  initiallyHidden?: boolean
) => ({
  ...cell(field, headerName),
  filter: "agNumberColumnFilter",
  cellStyle: { textAlign: "right", ...(color ? { color } : {}) },
  valueFormatter: (p: ValueFormatterParams) =>
    isNaN(p.value) || p.value === undefined || p.value === null
      ? p.value
      : `${roundNumberToLocale(p.value, decimals)}`,
  aggFunc: aggFunc,
  initialHide: initiallyHidden
});

export const restrictedSortingNumericCell = (
  field: string,
  headerName?: string,
  decimals = 0,
  initialSort?: "desc" | "asc",
  color?: string,
  aggFunc: string | undefined = undefined
): IColumnDefinition => ({
  ...numericCell(field, headerName, decimals, color, aggFunc),
  initialSort: initialSort,
  sortingOrder: ["asc", "desc"]
});

export const redNumberCell = (field: string, headerName?: string) =>
  numericCell(field, headerName, 0, "red");

export const yellowNumberCell = (field: string, headerName?: string) =>
  numericCell(field, headerName, 0, "#caa102");

export const percentageCell = (
  field: string,
  headerName?: string,
  decimals = 0,
  aggFunc: undefined | ((p: IAggFuncParams) => void) | string = undefined,
  color?: string
) => ({
  ...numericCell(field, headerName, decimals, color, aggFunc),
  valueFormatter: (params: ValueFormatterParams) =>
    params.value ? `${roundNumberToLocale(params.value as number, decimals)}%` : ""
});

export const dateCell = (
  field: string,
  headerName?: string,
  initiallyHidden?: boolean,
  hasTime = false
) => ({
  field,
  headerName,
  initialHide: initiallyHidden,
  filter: "agDateColumnFilter",
  filterParams: {
    buttons: ["clear"],
    suppressAndOrCondition: true,
    filterOptions: ["inRange", "lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual"],
    comparator: (filterLocalDate: any, cellValue: any) => {
      if (cellValue < filterLocalDate) {
        return -1;
      } else if (cellValue > filterLocalDate) {
        return 1;
      }
      return 0;
    }
  },
  valueFormatter: (p: ValueFormatterParams) =>
    p.value !== undefined && p.value !== null && p.value !== ""
      ? `${
          hasTime
            ? dateTimeFormatter(new Date(p.value), true)
            : dateTimeFormatter(new Date(p.value))
        }`
      : "",
  filterValueGetter: (params: ValueGetterParams) => new Date((params.data as any)[field])
});

export const grayedCell = (grayed: (data: any) => boolean | undefined) => ({
  cellStyle: ({ data }: CellClassParams) => ({
    opacity: grayed(data) ? 0.6 : 1
  })
});

export const multiFilterWithAsyncSet = (
  lookup: (params?: { columnId: string }) => AxiosPromise<string[] | undefined>
) => ({
  filter: "agMultiColumnFilter",
  filterParams: {
    filters: [
      {
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: TEXT_FILTER_WITH_IS_CONTAINED_IN_OPTIONS
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          defaultToNothingSelected: true,
          values: async (params: SetFilterValuesFuncParams) => {
            if (params.colDef.field) {
              const items = await lookup({ columnId: params.colDef.field });
              params.success(items.data || []);
            }
          }
        }
      }
    ]
  }
});

export const customerGroupCell = (
  showRowGroup: string,
  headerName: string,
  innerRenderer?: (p: ICellRendererParams) => ReactNode
): IColumnDefinition => ({
  colId: `Group-${showRowGroup}`,
  headerName: headerName,
  showRowGroup: showRowGroup,
  cellRenderer: "agGroupCellRenderer",
  filterValueGetter: (p: ValueGetterParams) => {
    return p.data[showRowGroup];
  },
  cellRendererParams: {
    innerRenderer: innerRenderer
  }
});

export const iconCell = <T extends {}>({
  iconName,
  tooltip,
  disabled,
  disabledTooltip,
  pinned = true,
  onClick
}: {
  iconName: IconNames;
  tooltip: string;
  disabled?: boolean;
  disabledTooltip?: string;
  pinned?: boolean | string;
  onClick?: (data: T) => void;
}) => ({
  colId: `icon-column-${iconName}`,
  valueFormatter: () => "",
  headerName: "",
  filter: false,
  sortable: false,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  suppressMovable: true,
  suppressMenu: true,
  floatingFilter: false,
  resizable: false,
  pinned,
  enableRowGroup: false,
  width: 50,
  maxWidth: 50,
  tooltipValueGetter: () => (disabled && disabledTooltip ? disabledTooltip : tooltip),
  cellRenderer: (params: ICellRendererParams) => (
    <Icon
      name={iconName}
      disabled={disabled}
      style={onClick && !disabled ? { cursor: "pointer" } : {}}
      onClick={() => onClick && !disabled && onClick(params.data as T)}
    />
  )
});

export const iconCommentCell = <T extends {}>({
  tooltip,
  disabled,
  disabledTooltip,
  pinned = true,
  onClick
}: {
  tooltip: string;
  disabled?: boolean;
  disabledTooltip?: string;
  pinned?: boolean | string;
  onClick?: (data: T) => void;
}) => iconCell({ iconName: "message-single", tooltip, disabled, disabledTooltip, pinned, onClick });

export const selectionCell = {
  colId: "selector",
  valueFormatter: () => "",
  headerName: "",
  filter: false,
  sortable: false,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  suppressMovable: true,
  suppressMenu: true,
  lockPosition: true,
  resizable: false,
  pinned: true,
  enableRowGroup: false,
  width: 40,
  maxWidth: 40,
  checkboxSelection: true
};
