import { SettingsItem, Dropdown } from "@react-gcc-eds/core";
import { useAppState } from "./app-state";
import {
  getLocalStorageValue,
  setLocalStorageValue,
  deleteLocalStorageKey
} from "./utils/local-storage";
import "./role-virtualization.scss";
import { TApiRole } from "./api/user-actions";
import { EMarketArea, Roles } from "./types";

const VIRTUAL_ROLE_KEY = "ef-virtual-role";

export const useVirtualRole = () => {
  const [appState] = useAppState();

  const saveVirtualRole = (role: TApiRole) =>
    setLocalStorageValue<TApiRole>(role, VIRTUAL_ROLE_KEY);

  const getVirtualRole = (): TApiRole | undefined =>
    getLocalStorageValue<TApiRole | undefined>(VIRTUAL_ROLE_KEY, undefined);

  const clearVirtualRole = () => deleteLocalStorageKey(VIRTUAL_ROLE_KEY);

  const RoleVirtualization = () => {
    const fakeRoles = [...Object.values(EMarketArea), ...Object.values(Roles)];
    return (
      <SettingsItem text="Virtual role" className="settings-virtual-role">
        {
          <Dropdown<TApiRole | undefined>
            label={appState.userConfiguration.virtualRole || "None"}
            items={[
              { title: "(Reset)", value: undefined },
              ...fakeRoles.map(r => ({ title: r, value: r }))
            ]}
            onItemClick={item => {
              if (item.value) {
                saveVirtualRole(item.value);
              } else {
                clearVirtualRole();
              }
              window.location.reload();
            }}
          />
        }
      </SettingsItem>
    );
  };

  return { RoleVirtualization, getVirtualRole };
};
