import { TableColumn } from "@react-gcc-eds/core";
import { textCell } from "../../helpers/grid-cells";
import { isValueNull } from "../../utils";
import { ReactNode } from "react";
import TicketError from "../component/ticket-base-dialog/ticket-error";

export const customTextCell = (
  field: string,
  headerName?: string,
  initiallyHidden?: boolean,
  setFilter?: boolean,
  minWidth?: number
) => ({
  ...textCell(field, headerName, initiallyHidden),
  ...(setFilter ? { filter: "agSetColumnFilter" } : {}),
  minWidth: isValueNull(minWidth) ? 140 : minWidth
});

export const edsText = (key: string, header: string): TableColumn => {
  return { key, header };
};

export const edsMdText = (key: string, header: string): TableColumn => {
  return { key, header, Cell: (row: any) => <div className="ef-table-md-cell">{row[key]}</div> };
};

export const edsValidateCell = (
  key: string,
  header: string,
  Cell?: (row: object) => ReactNode,
  showError = false,
  errorMsg = ""
): TableColumn => {
  return {
    key,
    header: (
      <div className="ticket-base-header-required">
        <label>{header}</label>
        {showError ? <TicketError msg={errorMsg} /> : <></>}
      </div>
    ),
    Cell
  };
};
