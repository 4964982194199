import { IColumnDefinition } from "../../../components/grid/grid";
import { cell, customerGroupCell, dateCell, groupCell } from "../../../helpers/grid-cells";
import { IGlobalOpenOrderItem, IGlobalOpenOrderLookupItem } from "../../api/tableau";

export const getGlobalOpenOrderColumns = ({
  lookup
}: {
  lookup: IGlobalOpenOrderLookupItem | undefined;
}): IColumnDefinition[] => {
  const noFilterCell = (field: string, header: string) => ({
    ...cell(field, header),
    filter: ""
  });

  const noFilterDateCell = (field: string, header: string) => ({
    ...dateCell(field, header),
    filter: ""
  });

  const groupCol = (field: string, headerName: string) => ({
    ...cell(field, headerName),
    ...groupCell(),
    initialHide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true
  });

  const setFilter = (id: keyof IGlobalOpenOrderLookupItem) => ({
    filter: "agSetColumnFilter",
    filterParams: {
      values: lookup?.[id]
    }
  });

  const noCountGroupCol = (
    field: keyof IGlobalOpenOrderItem,
    header: string,
    lookupId: keyof IGlobalOpenOrderLookupItem
  ) => ({
    ...customerGroupCell(field, header),
    ...setFilter(lookupId),
    suppressMovable: true,
    lockPosition: true,
    cellRendererParams: {
      suppressCount: true
    }
  });

  return [
    groupCol("materialL1", "Material L1"),
    {
      ...noCountGroupCol("materialL1", "Material L1", "materialL1s"),
      minWidth: 200
    },
    groupCol("materialDesc", "MaterialDesc"),
    { ...noCountGroupCol("materialDesc", "MaterialDesc", "materialDescs"), filter: "" },
    groupCol("mdFinalExternalCustomerMarketArea", "MA"),
    noCountGroupCol(
      "mdFinalExternalCustomerMarketArea",
      "MA",
      "mdFinalExternalCustomerMarketAreas"
    ),
    groupCol("plant", "Plant"),
    noCountGroupCol("plant", "Plant", "plants"),
    groupCol("finalExternalCustomerDesc", "Customer"),
    noCountGroupCol("finalExternalCustomerDesc", "Customer", "finalExternalCustomerDescs"),
    {
      ...cell("salesOrder", "SalesOrder"),
      filter: "agTextColumnFilter"
    },
    {
      ...cell("salesOrderItem", "SOItem"),
      ...setFilter("salesOrderItems")
    },
    {
      ...cell("vendor", "Vendor"),
      ...setFilter("vendors")
    },
    noFilterCell("purchaseOrder", "VPO"),
    { ...dateCell("soCreatedOnDate", "SOCreatedOnDate"), sort: "desc" },
    noFilterDateCell("soRequestedMaDateItemLatest", "RequestMAD"),
    noFilterDateCell("soConfMaDateItem", "ConfMAD"),
    noFilterCell("orderQty", "OrderQty")
  ];
};
