import { useState, useEffect } from "react";
import { useAppState } from "../app-state";
import {
  getLocalStorageValue,
  setLocalStorageValue,
  deleteLocalStorageKey
} from "../utils/local-storage";

export enum ViewId {
  VIS_GLOBAL = "VIS_GLOBAL",
  VIS_DETAIL = "VIS_DETAIL",
  VIS_TICKETLOG = "VIS_TICKETLOG",
  VIS_APPROVAL = "VIS_APPROVAL",
  VIS_FEEDBACK = "VIS_FEEDBACK"
}

const key = (viewId: ViewId, version: number) => `${viewId}_PREFS_v${version}`;

export const useViewPreferencesPersistence = <T extends {}>({
  viewId,
  version,
  defaultValue
}: {
  viewId: ViewId;
  version: number;
  defaultValue: T;
}) => {
  const [viewPreferences, setViewPreferences] = useState<T>(defaultValue);

  const [appState] = useAppState();

  // remove older versions
  useEffect(() => {
    Array.from(Array(version).keys()).forEach(v =>
      deleteLocalStorageKey(key(viewId, v), appState.user)
    );
  }, [appState.user, viewId, version]);

  // read key on mount
  useEffect(() => {
    const value = getLocalStorageValue<T>(key(viewId, version), undefined, appState.user);

    if (value) setViewPreferences(value);
  }, [appState.user, viewId, version]);

  useEffect(() => {
    const persistPreferences = (value: T) => {
      try {
        setLocalStorageValue<T>(value, key(viewId, version), appState.user);
      } catch {}
    };

    persistPreferences(viewPreferences);
  }, [viewPreferences, appState.user, viewId, version]);

  return { viewPreferences, setViewPreferences };
};
