import { InteractionRequiredAuthError } from "@azure/msal-common";
import { SystemLayer, AppBarItem, Button, Dialog, SignIn } from "@react-gcc-eds/core";
import { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { initialize as initializeApi, setVirtualRole } from "./api/api";
import { useAppState } from "./app-state";
import { ApiErrorManager, ApplicationError, useApiErrorsActions } from "./components/errors";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./auth/authConfig";
import AppRouter, { RELEASE_NOTE_ROUTE } from "./app-router";
import { useCheckPortrait, useGetCurrentUser, useGetSnapshot } from "./api/user-actions";
import { hasSPCRoles } from "./utils/user-helpers";
import { formatSnapShotToSeason } from "./spc/view/seao/seao-vendor-nominate-view";
import { EMarketArea, Roles, TUser } from "./types";
import { useVirtualRole } from "./role-virtualization";
import { HELP_ADMINISTRATOR } from "./management/helper";
import InitMgt from "./components/initMgt";

const AppShell = ({ version, apiUrl }: { version: string; apiUrl: string }) => {
  const [apiReady, setApiReady] = useState(false);
  const [welcomeUser, setWelcomeUser] = useState({
    firstName: "",
    lastName: ""
  });
  const [noRole, setNoRole] = useState(false);
  const [showIdmHelp, setShowIdmHelp] = useState(false);
  const [currentUser, loadingCurrentUser, getCurrentUser] = useGetCurrentUser();
  const [, , getSnapshot] = useGetSnapshot();
  const [, , checkPortrait] = useCheckPortrait();
  const [appState, setAppState] = useAppState();
  const { instance, accounts } = useMsal();
  const { pushError } = useApiErrorsActions();
  const history = useHistory();
  const { RoleVirtualization, getVirtualRole } = useVirtualRole();

  const notifyApiError = (message?: string) => message && pushError(message);

  useEffect(() => {
    const acquireToken = async () => {
      try {
        const auth = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        });
        return auth.accessToken;
      } catch (error) {
        console.log("Acquire token redirect");
        if (error instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect({
            ...loginRequest,
            account: accounts[0]
          });
          return undefined;
        } else {
          console.log("Acquire token failed", error);
        }
      }
    };

    initializeApi({
      baseUrl: apiUrl,
      onError: notifyApiError,
      onNoRoleAssigned: () => setNoRole(true),
      getToken: acquireToken
    });
    setApiReady(true);
  }, [initializeApi]);

  const virtualRole = () =>
    appState.settings?.enableRoleVirtualization ? getVirtualRole() : undefined;

  useEffect(() => {
    const fetchUserSettings = async () => {
      setVirtualRole(virtualRole());
      await getCurrentUser();
    };

    if (apiReady) {
      fetchUserSettings();
    }
  }, [apiReady, checkPortrait, getCurrentUser]);

  const filteredByList = <T extends Object>(data: T[], list: T[]) => {
    return data.filter(r => list.includes(r));
  };

  useEffect(() => {
    if (!loadingCurrentUser && currentUser && currentUser.userName) {
      //Backend sends name and email and role as IUserBase
      const firstName = currentUser.userName.split(" ")[0] || "";
      const lastName = currentUser.userName.replace(firstName, "").trim() || "";
      if (currentUser.roles.length > 0) {
        setWelcomeUser({ firstName, lastName });
        const userInfo: TUser = {
          firstName,
          lastName,
          userEmail: currentUser.userEmail,
          roles: filteredByList(currentUser.roles as Roles[], Object.values(Roles)),
          efRoles: currentUser.escalationFeedbackRoles,
          marketAreas: filteredByList(
            currentUser.roles as EMarketArea[],
            Object.values(EMarketArea)
          ),
          userName: currentUser.userName
        };

        const userConfiguration = {
          virtualRole: virtualRole(),
          menus: currentUser.validMenus || []
        };

        if (hasSPCRoles(currentUser.roles as Roles[])) {
          getSnapshot().then(({ data: snapshot }) => {
            const defaultSeaoSnapshot = snapshot.seaoSnapshots[0];
            const defaultIndiaSnapshot = snapshot.indianSnapshots[0];

            setTimeout(
              () =>
                setAppState(old => ({
                  ...old,
                  user: userInfo,
                  spc: {
                    snapshotIndia: {
                      currentSnapshot: {
                        title: `${defaultIndiaSnapshot}`,
                        value: defaultIndiaSnapshot
                      },
                      options: snapshot.indianSnapshots.map(i => ({ title: `${i}`, value: i }))
                    },
                    snapshotSeao: {
                      currentSnapshot: {
                        title: `${formatSnapShotToSeason(defaultSeaoSnapshot)}`,
                        value: defaultSeaoSnapshot
                      },
                      options: snapshot.seaoSnapshots.map(i => ({
                        title: `${formatSnapShotToSeason(i)}`,
                        value: i
                      }))
                    }
                  },
                  userConfiguration
                })),
              1000
            );
          });
        } else {
          setTimeout(
            () =>
              setAppState(old => ({
                ...old,
                user: userInfo,
                userConfiguration
              })),
            3000
          );
        }
      } else {
        setNoRole(true);
      }
    }
  }, [loadingCurrentUser, currentUser, setAppState]);

  const administrator = useMemo(
    () =>
      appState.user
        ? HELP_ADMINISTRATOR[appState.user.marketAreas[0]]
        : "carrgan.yang@ericsson.com",
    [appState]
  );

  return appState.user ? (
    <SystemLayer
      acronym="IMT"
      applicationName="Inbound Management Tools"
      appBarItems={[
        <AppBarItem
          key="relnotes"
          style={{ cursor: "pointer" }}
          tooltip="Contact us"
          text={`v${version}`}
          onClick={() => {
            history.push(RELEASE_NOTE_ROUTE);
          }}
        />
      ]}
      hideNotificationBell={false}
      user={welcomeUser}
      settingsContent={
        appState.settings?.enableRoleVirtualization
          ? [<RoleVirtualization key="role-virtualization" />]
          : []
      }
    >
      <ErrorBoundary FallbackComponent={ApplicationError}>
        <ApiErrorManager />
        <InitMgt />
        <AppRouter menus={appState.userConfiguration.menus || []} />
      </ErrorBoundary>
      <div id="app-portal" />
    </SystemLayer>
  ) : (
    <>
      <SignIn productName="Inbound Management Tools" signedInUser={welcomeUser} />
      {noRole && (
        <Dialog
          title="Not authorized"
          buttons={
            <>
              <Button
                primary
                onClick={() => {
                  window.location.href = "https://idm.internal.ericsson.com";
                }}
              >
                Visit IDM
              </Button>
              <Button
                onClick={() => {
                  setShowIdmHelp(true);
                }}
              >
                Troubles signing in?
              </Button>
            </>
          }
        >
          Please visit Identity Management (IDM). Click All IT Access Roles and find
          Application/MOAI Ecosystem/Inbound Management Tools
        </Dialog>
      )}
      {showIdmHelp && (
        <Dialog
          title="Not authorized"
          buttons={
            <Button
              primary
              onClick={() => {
                setShowIdmHelp(false);
              }}
            >
              Ok
            </Button>
          }
        >
          After your requested access role in IDM has been approved, it can take up to 48 hours
          until you are able to enter the application.
          <br />
          <br />
          If you are still unable to enter the application after 48 hours, please send Email to
          {administrator}
        </Dialog>
      )}
    </>
  );
};

export default AppShell;
