import { useApiGetForBlob, useApiPost, useApiPostForm, useApiPut } from "../../api/api";
import { IActionResult, IBasicSoItem, ITicketDetailSoItem } from "./ticket";

export interface IOaItem {
  id?: number;
  salesOrder: string;
  item: string;
  improvedOrderAcknowledgeDate: string | null;
}

interface IUpdateOaParam {
  improvedOrderAcknowledgeDateInfos: IOaItem[];
  ticketNumber: number;
}

const SALES_ORDER = "/v1/escalation-feedback/sales-order";

// Download so&item Template
export const useDownloadSoTemplate = () =>
  useApiGetForBlob<any>(SALES_ORDER + "/template/details-by-excel");

// check
export const useCheckSoItems = () =>
  useApiPost<{ soItems: IBasicSoItem[] }, ITicketDetailSoItem[]>(SALES_ORDER + "/details");

//upload so&item
export const useUploadSoItem = () =>
  useApiPostForm<IActionResult>(SALES_ORDER + "/details-by-excel");

//update oa
export const useUpdateOa = () =>
  useApiPut<IUpdateOaParam, IActionResult>(SALES_ORDER + "/order-acknowledge-date");
