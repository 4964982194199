import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useGetUserAvatar } from "../management/api/user-actions";

type IAvatars = { email: string; avatar: string | undefined }[];

const avatarState = () => {
  // Make a context for the store
  const context = createContext<any>(undefined);

  const AvatarStateProvider = ({ children }: { children: ReactNode }) => {
    const [avatars, setAvatars] = useState<IAvatars>([]);

    const [, , getUserAvatar] = useGetUserAvatar();

    const cacheAvatar = (email: string) => {
      setAvatars(prevAvatars => {
        return prevAvatars.find(p => p.email === email)
          ? prevAvatars
          : [...prevAvatars, { email, avatar: undefined }];
      });
    };

    const getAvatar = (email: string) => {
      return avatars.find(a => a.email === email)?.avatar;
    };

    useEffect(() => {
      if (avatars.find(a => a.avatar === undefined)) {
        const fetchAvatar = async () => {
          const newAvatars: IAvatars = [];
          for (const avatar of avatars) {
            if (avatar.avatar === undefined) {
              const { data } = await getUserAvatar({ userEmail: avatar.email });
              newAvatars.push({ email: avatar.email, avatar: data });
            } else {
              newAvatars.push(avatar);
            }
          }
          return newAvatars;
        };
        fetchAvatar().then(newAvatars => setAvatars(newAvatars));
      }
    }, [avatars]);

    // Provide the store to children
    return (
      <context.Provider value={{ avatars, cacheAvatar, getAvatar }}>{children}</context.Provider>
    );
  };

  const useAvatarState = (): {
    avatars: IAvatars;
    cacheAvatar: (email: string) => void;
    getAvatar: (email: string) => string | undefined;
  } => {
    return useContext(context);
  };

  return { AvatarStateProvider, useAvatarState };
};

export default avatarState;
