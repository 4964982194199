import { Button, Dialog } from "@react-gcc-eds/core";
import Table from "../../../components/table";
import "./ticket-so-item-table.scss";
import Loader from "../../../components/loader";
import { getEfSoItemColumns } from "../../grid/views-columns/ef-so-item";
import { ITicketDetailSoItem, ITicketEditedSoItem } from "../../api/ticket";
import { ITicketError } from "../../helpers/ef-validate";

const TicketSoItemTable = ({
  formValues,
  onCheck,
  onAdd,
  onDelete,
  onChange,
  onPreview,
  loading = false,
  error,
  disabled,
  scrollVertical = false,
  showProjectName
}: {
  formValues: ITicketDetailSoItem[];
  loading?: boolean;
  onAdd?: () => void;
  onDelete?: (rowNumber: number) => void;
  onCheck?: () => void;
  onChange?: (key: keyof ITicketEditedSoItem, rowNumber: number, value: string | number) => void;
  onPreview?: () => void;
  error: ITicketError | undefined;
  disabled?: boolean;
  scrollVertical?: boolean;
  showProjectName?: boolean;
}) => {
  return (
    <div className="ticket-basic-table">
      {loading && (
        <Dialog fullscreen closeOnEscape>
          <Loader />
        </Dialog>
      )}
      <Table
        widths={["", "", "", "", "", "", "", "", "", "", "", "160px", "140px", "", "", "120px"]}
        rows={formValues}
        columns={getEfSoItemColumns({
          formValues,
          error,
          disabled,
          showProjectName,
          onChange,
          onDelete
        })}
        scrollVertical={scrollVertical}
        topButtons={
          <>
            {!disabled && (
              <>
                <Button iconName="plus" onClick={onAdd}>
                  Add
                </Button>
                <Button iconName="check" onClick={onCheck}>
                  Check
                </Button>
              </>
            )}
            <Button iconName="eye" onClick={onPreview}>
              Preview
            </Button>
          </>
        }
        title={"SO & Item"}
      />
    </div>
  );
};

export default TicketSoItemTable;
