import { TAppSettings, TUser } from "../types";
import storeFactory from "./store-factory";
import { ISelectItem } from "@react-gcc-eds/core";
import avatarState from "./avatar-state";
import { TApiRole } from "../api/user-actions";

interface IApi {
  errors: string[];
}

export interface IViewAccess {
  name: string;
  properties: { propertyName: string }[];
}

interface IUserConfiguration {
  virtualRole?: TApiRole;
  menus: string[];
}

export interface IAppState {
  settings?: TAppSettings;
  user?: TUser;
  api: IApi;
  userConfiguration: IUserConfiguration;
  spc: {
    snapshotIndia: {
      currentSnapshot: ISelectItem<any>;
      options: ISelectItem<any>[];
    };
    snapshotSeao: {
      currentSnapshot: ISelectItem<any>;
      options: ISelectItem<any>[];
    };
  };
}

const stateDefault: IAppState = {
  settings: undefined,
  user: undefined,
  api: { errors: [] },
  userConfiguration: {
    menus: []
  },
  spc: {
    snapshotIndia: {
      currentSnapshot: { title: "", value: "" },
      options: []
    },
    snapshotSeao: {
      currentSnapshot: { title: "", value: "" },
      options: []
    }
  }
};

const { Provider: AppStateProvider, useStore: useAppState } = storeFactory<IAppState>();

const { AvatarStateProvider, useAvatarState } = avatarState();

export { AppStateProvider, useAppState, stateDefault, AvatarStateProvider, useAvatarState };
