import { Icon } from "@react-gcc-eds/core";

export interface ISelectAccordionOption {
  name: string;
  value: string;
  selected: boolean;
}

const SelectAccordionGroup = ({
  options,
  onChange
}: {
  options: ISelectAccordionOption[];
  onChange: (selectedOptions: ISelectAccordionOption[]) => void;
}) => {
  const handleClick = (index: number) => {
    const selectedOptions = options.map((item, i) =>
      i === index ? { ...item, selected: !item.selected } : item
    );
    onChange(selectedOptions);
  };

  return (
    <div>
      {options.map(({ name, selected }, index) => (
        <div
          key={`${name}-${index}`}
          className={`accordion-filter-row ${selected ? "accordion-filter-row-selected" : ""}`}
          onClick={() => handleClick(index)}
        >
          <div>{name}</div>
          {selected && <Icon name="check" />}
        </div>
      ))}
    </div>
  );
};

export default SelectAccordionGroup;
