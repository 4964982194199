import { useApiPost } from "../../api/api";
import { IBarChartData } from "../../components/charts";
import { CustomerRegion } from "../../management/helper";
import { EViewType } from "./lookup";

export interface IPerformanceParam {
  category: string;
  spcDisplayView: EViewType;
  spcPerspective: string;
  spcRegion: CustomerRegion;
  timeFrame: number;
}

const PERFORMANCE = "/v1/supply-performance/performance-query";

export const useGetPerformanceData = () =>
  useApiPost<IPerformanceParam, IBarChartData>(PERFORMANCE + "/query");
