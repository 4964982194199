import { Column, Row, Tile } from "@react-gcc-eds/core";
import { useEffect } from "react";
import { INDIA_VENDOR_NOMINATE_ROUTE } from "../../../app-router";
import DonutChart from "../../../components/charts/donut-chart";
import ContentLoader from "../../../components/content-loader";
import View from "../../../views/app-view";
import { useGetIndiaSpends } from "../../api/india-vendor-nominate";
import useGlobalSettings from "../../component/global-settings";
import "./india-vendor-nominate-view.scss";

const IndiaVendorNominateView = () => {
  // display
  const [spends, loadingSpends, getSpends] = useGetIndiaSpends();
  const { currentIndiaSnapshot, IndiaSettings } = useGlobalSettings();

  useEffect(() => {
    getSpends({ snapshot: currentIndiaSnapshot });
  }, [currentIndiaSnapshot, getSpends]);

  //time
  const formatSnapShot = (snapshot: number) => {
    const temporary = `${snapshot}`.split("");
    temporary.splice(4, 0, "-");
    return temporary.join("");
  };

  return (
    <View
      breadCrumbs={[
        { text: "Supplier Performance" },
        { text: "India" },
        { text: "Vendor Nominate", url: INDIA_VENDOR_NOMINATE_ROUTE }
      ]}
      actions={<IndiaSettings />}
    >
      <Row className="india-nomidate-row">
        <ContentLoader
          loading={loadingSpends}
          data={spends && spends.donuts && spends.donuts.length > 0}
          render={
            <>
              {spends &&
                spends.donuts &&
                spends.donuts.map(({ category, data }) => (
                  <Column lg={4} md={6} sm={12} key={category}>
                    <Tile
                      title="85% Spends"
                      style={{ height: 400 }}
                      subtitle={`${category} ${formatSnapShot(currentIndiaSnapshot)}`}
                    >
                      <DonutChart data={data} unitOfMeasure={"%"} valueDecimals={0} />
                    </Tile>
                  </Column>
                ))}
            </>
          }
        />
      </Row>
    </View>
  );
};

export default IndiaVendorNominateView;
