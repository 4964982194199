import { EMarketArea } from "../types";

export const TicketBaseInfoLabel = {
  escalationReason: {
    title: "Escalation Reason",
    error: false,
    message: "A Value Must be Selected !",
    required: true
  },
  customer: {
    title: "Customer",
    error: false,
    message: "A Value Must be Selected !",
    required: true
  },
  requesterName: {
    title: "Requester Name",
    error: false,
    message: "Can't be Empty ! ",
    required: false
  },
  escalationRegion: {
    title: "Escalation Region",
    error: false,
    message: "A Value Must be Selected !",
    required: true
  },
  productReportingGroup: {
    title: "Product Reporting Group",
    error: false,
    message: "A Value Must be Selected !",
    required: true
  },
  ticketCategory: {
    title: "Ticket category",
    error: false,
    message: "A Value Must be Selected !",
    required: true
  },
  orderCreationDate: {
    title: "Order Creation Date",
    error: false,
    message: "Can't be Empty !",
    required: true
  },
  currentRFSDate: {
    title: "Current RFS date",
    error: false,
    message: "Can't be Empty ! ",
    required: true
  },
  latestAcceptableRFSDate: {
    title: "Latest acceptable RFS date",
    error: false,
    message: "Can't be Empty ! ",
    required: true
  },
  minimumCriticalDemandPerWeek: {
    title: "Minimum critical demand per week",
    error: false,
    message: "Can't be Empty ! ",
    required: true
  },
  nsImpactPenalties: {
    title: "NS Impact / Penalties",
    error: false,
    message: "Can't be Empty ! ",
    required: true
  },
  businessImpact: {
    title: "Business impact (Net sales/Penalties)",
    error: false,
    message: "Can't be Empty ! ",
    required: true
  },
  projectName: {
    title: "Project Name/Deal",
    error: false,
    message: "Can't be Empty!",
    required: true
  },
  nroorBoxDelivery: {
    title: "NRO or Box Delivery",
    error: false,
    message: "Can't be Empty ! ",
    required: true
  },
  sipporNonSIPP: {
    title: "SIPP",
    error: false,
    message: "Can't be Empty!",
    required: true
  },
  forecasted: {
    title: "Within forecasted",
    error: false,
    message: "Can't be Empty!",
    required: true
  },
  hub: { title: "Borrow from", error: false, message: "Need selected hub", required: true },
  quantity: {
    title: "Borrow Quantity",
    error: false,
    message: "Can't be Empty !",
    required: true
  },
  partialDeliveryAcceptable: {
    title: "Partial Delivery Acceptable",
    error: false,
    message: "Can't be Empty ! ",
    required: true
  },
  globalStockCheck: {
    title: "Global Stock Check",
    error: false,
    message: "Can't be Empty ! ",
    required: true
  },
  globalStockCheckDetail: {
    title: "If Yes, please input the outcome of Global Stock Check",
    error: false,
    message: "Can't be Empty ! ",
    required: false
  },
  preEscalationFeedback: {
    title: "Pre escalation feedback",
    error: false,
    message: "Can't be Empty!",
    required: false
  },
  dailyImprovementChecklist: {
    title: "Pre Escalation Activity",
    error: false,
    message: "Not Empty",
    required: false
  },
  outcomeOfDailyImprovements: {
    title: "Outcome of Pre Escalation",
    error: false,
    message: "",
    required: false
  }
};

export const TicketFullInfoLabel = {
  ...TicketBaseInfoLabel,
  status: {
    title: "Escalation Response",
    error: false,
    message: "",
    required: false
  },
  escalationLevel: {
    title: "Escalation Level",
    error: false,
    message: "Not Null!",
    required: true
  },
  assignedUsers: {
    title: "Assign To",
    error: false,
    message: "Not null!",
    required: false
  },
  tag: {
    title: "Tag",
    error: false,
    message: "Not null!",
    required: true
  },
  comments: {
    title: "Comments",
    error: false,
    message: "Please full in reject reason !",
    required: true
  }
};

export enum EscalationLevel {
  region = "Region",
  continental = "Continental",
  global = "Global",
  none = ""
}

export enum EscalationStatus {
  pending = "Pending",
  approval = "Approval",
  rejectToRequester = "RejectToRequester",
  rejectToRegion = "RejectToRegion"
}

export enum Tag {
  A1P = "A1P",
  B2P = "B2P",
  C3P = "C3P"
}

export enum CustomerRegion {
  india = "India",
  seao = "SEAO",
  others = "Others"
}

export enum EscalationPriority {
  urgent = "Urgent",
  important = "Important",
  medium = "Medium",
  low = "Low"
}

export enum EscalationProgress {
  notStarted = "NotStarted",
  inProgress = "InProgress",
  completed = "Completed"
}

export const HELP_ADMINISTRATOR = {
  [EMarketArea.MOAI]: "chuanjin.gu@ericsson.com",
  [EMarketArea.MANA]: "administrator",
  [EMarketArea.MELA]: "administrator",
  [EMarketArea.MMEA]: "frances.janse.van.rensburg@ericsson.com",
  [EMarketArea.MNEA]: "administrator",
  [EMarketArea.GLOBAL]: "administrator"
};
