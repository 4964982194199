import { ISelectItem } from "@react-gcc-eds/core";
import { useApiGet } from "../../api/api";
import { CustomerRegion } from "../../management/helper";

export interface IVendor {
  id: number;
  vendor: string;
  vendorName: string;
  categoryName: string;
}

export interface IGeneticPrams {
  category: string;
  snapshot: number;
}

export enum EViewType {
  month = "Month",
  quarter = "Quarter"
}

export interface ISpcRegionsItem {
  viewType: EViewType;
  defaultTimeFrame: number;
  maxTimeFrame: number;
}

interface ISpcRegions {
  title: CustomerRegion;
  value: CustomerRegion;
  data: ISpcRegionsItem[];
  perspective: ISelectItem[];
}

export interface IPerformanceLookup {
  spcRegions: ISpcRegions[];
  spcCategories: ISelectItem[];
  spcPerspectives: ISelectItem[];
}

const LOOK_UP = "/v1/supply-performance/lookup";

export const useGetVendorByCategory = () =>
  useApiGet<IVendor[], IGeneticPrams>(LOOK_UP + "/vendors");

export const useGetAssignedVendorList = () =>
  useApiGet<IVendor[], IGeneticPrams>(LOOK_UP + "/assigned-vendors");

export const useGetPerformanceLookupData = () =>
  useApiGet<IPerformanceLookup, undefined>(LOOK_UP + "/performance-history");
