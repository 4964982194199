import { Button, Tile, useNotifications } from "@react-gcc-eds/core";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { SEAO_RESULT_ROUTE } from "../../../app-router";
import ContentLoader from "../../../components/content-loader";
import View from "../../../views/app-view";
import {
  ICategoryRadarItem,
  IVendorPerformanceItem,
  useExportResult,
  useGetCategoryRadar,
  useGetVendorPerformance,
  useGetVendorRadar
} from "../../api/seao-result";
import useGlobalSettings from "../../component/global-settings";
import ResultChartBoard from "../../component/result/result-chart-board";
import ResultTable, { ISEAOResultTableRow } from "../../component/result/result-table";
import {
  DELIVERY_CONFIRMED_SCORES,
  DELIVERY_REQUEST_SCORES,
  getLevel,
  LTWF_SCORES,
  SERVICE_SUPPORT_SCORES
} from "../../helper/result-helper";

const SeaoResultView = () => {
  const DEFAULT_LIMIT = 10;
  const { currentSeaoSnapshot, SeaoSettings } = useGlobalSettings();
  const { createNotification } = useNotifications();
  //radar
  const [categoryRadarList, loadingCategoryRadarList, getCategoryRadarList] = useGetCategoryRadar();
  useEffect(() => {
    getCategoryRadarList({ snapshot: currentSeaoSnapshot });
  }, [currentSeaoSnapshot]);

  //vendor
  const [vendorRadarList, loadingVendorRadarList, getVendorRadarList] = useGetVendorRadar();
  const [selectedCategory, setSelectedCategory] = useState<ICategoryRadarItem>();
  useEffect(() => {
    if (selectedCategory) {
      getVendorRadarList({
        snapshot: currentSeaoSnapshot,
        category: selectedCategory.category
      });
    }
  }, [selectedCategory, currentSeaoSnapshot]);

  //table
  const [vendorPerformanceList, loadingVendorPerformanceList, getVendorPerformanceList] =
    useGetVendorPerformance();
  const [currentPage, setCurrentPage] = useState(1);

  const transformResultTableRow = (data: IVendorPerformanceItem[]): ISEAOResultTableRow[] =>
    data.map(
      ({
        actualLeadTime,
        deliveryPerFirstConfirm,
        deliveryPerRequest,
        serviceAndSupport,
        vendorName
      }) => ({
        vendorName,
        serviceSupport: getLevel(serviceAndSupport, SERVICE_SUPPORT_SCORES),
        deliveryRequest: getLevel(deliveryPerRequest, DELIVERY_REQUEST_SCORES),
        deliveryConfirmed: getLevel(deliveryPerFirstConfirm, DELIVERY_CONFIRMED_SCORES),
        ltwf: getLevel(actualLeadTime, LTWF_SCORES)
      })
    );

  useEffect(() => {
    getVendorPerformanceList({
      snapshot: currentSeaoSnapshot,
      category: selectedCategory && selectedCategory.category,
      offset: (currentPage - 1) * DEFAULT_LIMIT,
      limit: DEFAULT_LIMIT
    });
  }, [currentSeaoSnapshot, selectedCategory, currentPage, getVendorPerformanceList]);

  //export
  const [exportExcel, loadingExportExcel, getExportExcel, , exportExcelName] = useExportResult();
  const handleExport = () => {
    getExportExcel({
      snapshot: currentSeaoSnapshot,
      category: selectedCategory && selectedCategory.category
    });
  };
  useEffect(() => {
    if (exportExcel) {
      if ((exportExcel as Blob).size !== 0) {
        fileDownload(exportExcel, exportExcelName || "result.xlsx");
      } else {
        createNotification("Warning !", "Not have any result!", "warning");
      }
    }
  }, [exportExcel]);

  return (
    <View
      breadCrumbs={[
        { text: "Supplier Performance" },
        { text: "SEAO" },
        { text: "Result", url: SEAO_RESULT_ROUTE }
      ]}
      actions={<SeaoSettings />}
    >
      <ContentLoader
        loading={loadingCategoryRadarList || loadingVendorRadarList}
        withTile
        data={
          selectedCategory
            ? vendorRadarList && vendorRadarList.length > 0
            : categoryRadarList && categoryRadarList.length > 0
        }
        render={
          <ResultChartBoard
            categoryList={categoryRadarList}
            vendorList={vendorRadarList}
            onSelect={setSelectedCategory}
            selectedCategory={selectedCategory}
          />
        }
      />
      <Tile
        title="Result"
        subtitle={selectedCategory ? `Vendors of ${selectedCategory.category}` : "All vendors"}
        style={{ marginTop: "16px" }}
        actions={
          <Button loading={loadingExportExcel} onClick={handleExport}>
            Export
          </Button>
        }
      >
        <ContentLoader
          loading={loadingVendorPerformanceList}
          data={vendorPerformanceList}
          render={
            vendorPerformanceList && (
              <ResultTable
                resultList={transformResultTableRow(vendorPerformanceList.data)}
                total={vendorPerformanceList.total}
                currentPage={currentPage}
                onPageChange={page => setCurrentPage(page)}
              />
            )
          }
        />
      </Tile>
    </View>
  );
};

export default SeaoResultView;
