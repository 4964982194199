import {
  IGridColumnsFilters,
  IGridColumnsPreferences,
  IGridPreferencesHandler
} from "../components/grid/grid";
import { useAppState } from "../app-state";
import {
  getLocalStorageValue,
  setLocalStorageValue,
  deleteLocalStorageKey
} from "../utils/local-storage";
import { ViewId } from "./view-preferences-handler";

export { ViewId } from "./view-preferences-handler";

export enum TableId {
  VIS_GLOBAL = "VIS_GLOBAL_2",
  VIS_DETAIL = "VIS_DETAIL",
  VIS_TICKETLOG = "VIS_TICKETLOG",
  VIS_APPROVAL = "VIS_APPROVAL",
  VIS_FEEDBACK = "VIS_FEEDBACK"
}

const layoutKey = (viewId: ViewId, tableId: TableId) => `${viewId}_${tableId}_TABLE_LAYOUT`;
const filtersKey = (viewId: ViewId, tableId: TableId) => `${viewId}_${tableId}_TABLE_FILTERS`;

export const useTablePreferencesHandler = (viewId: ViewId, gridId: TableId) => {
  const [appState] = useAppState();

  const persistColumnsLayout = (preferences: IGridColumnsPreferences) => {
    try {
      setLocalStorageValue<IGridColumnsPreferences>(
        preferences,
        layoutKey(viewId, gridId),
        appState.user
      );
    } catch {}
  };

  const getColumnsLayout = async () => {
    try {
      return await getLocalStorageValue<IGridColumnsPreferences>(
        layoutKey(viewId, gridId),
        undefined,
        appState.user
      );
    } catch {}
  };

  const deleteColumnsLayout = () => {
    try {
      deleteLocalStorageKey(layoutKey(viewId, gridId), appState.user);
    } catch {}
  };

  const persistFilters = (filters: IGridColumnsFilters) => {
    try {
      setLocalStorageValue<IGridColumnsFilters>(filters, filtersKey(viewId, gridId), appState.user);
    } catch {}
  };

  const getFilters = () => {
    try {
      return getLocalStorageValue<IGridColumnsFilters>(
        filtersKey(viewId, gridId),
        undefined,
        appState.user
      );
    } catch {}
  };

  const deleteFilters = () => {
    try {
      deleteLocalStorageKey(filtersKey(viewId, gridId), appState.user);
    } catch {}
  };

  const handler: IGridPreferencesHandler = {
    id: () => gridId,
    saveColumnsPreferences: persistColumnsLayout,
    getColumnsPreferences: getColumnsLayout,
    clearColumnsPreferences: deleteColumnsLayout,
    saveColumnsFilters: persistFilters,
    getColumnsFilters: getFilters,
    clearColumnsFilters: deleteFilters
  };

  return {
    preferencesHandler: handler
  };
};
