import { Icon, MultiPanelTile } from "@react-gcc-eds/core";
import { useEffect, useState } from "react";
import { PERFORMANCE_HISTORY_ROUTE } from "../../app-router";
import LineChart from "../../components/charts/line-chart";
import ContentLoader from "../../components/content-loader";
import { CustomerRegion } from "../../management/helper";
import View from "../../views/app-view";
import { useGetPerformanceLookupData } from "../api/lookup";
import { IPerformanceParam, useGetPerformanceData } from "../api/performance-history";
import PerformanceFilter, {
  getDefaultFilters,
  getViewLookup,
  numberToSelect
} from "../component/performance-filter";
import "./performance-history-view.scss";

const PerformanceHistoryView = () => {
  //filter
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const toggleFilters = () => setShowFilters(show => !show);
  const [performanceLookup, loadingGetPerformanceLookup, getPerformanceLookup] =
    useGetPerformanceLookupData();
  const [appliedFilters, setAppliedFilters] = useState<IPerformanceParam>();
  useEffect(() => {
    getPerformanceLookup();
  }, []);

  useEffect(() => {
    if (performanceLookup) {
      const { spcRegions, spcCategories } = performanceLookup;
      if (spcRegions && spcRegions.length > 0) {
        const seaoDefaultFilter = getDefaultFilters(
          CustomerRegion.seao,
          spcCategories,
          spcRegions[0].data[0].defaultTimeFrame,
          spcRegions[1].data[0].defaultTimeFrame
        );
        setAppliedFilters(seaoDefaultFilter);
        getPerformance(seaoDefaultFilter);
      }
    }
  }, [performanceLookup]);

  //display chart
  const [performanceData, loadingGetPerformance, getPerformance] = useGetPerformanceData();

  const handleSearch = (editedFilter: IPerformanceParam) => {
    getPerformance(editedFilter);
  };

  const getRegions = (region: CustomerRegion) =>
    performanceLookup.spcRegions.filter(({ value }) => value === region)[0];

  return (
    <View
      breadCrumbs={[
        { text: "Supplier Performance" },
        { text: "Performance History", url: PERFORMANCE_HISTORY_ROUTE }
      ]}
    >
      <MultiPanelTile
        className="performance-history"
        centerTitle="Performance History"
        centerLeftIcon={
          <Icon name="filter" onClick={toggleFilters} style={{ cursor: "pointer" }} />
        }
        leftTitle="Selections"
        leftClassName="scrollable-pane"
        leftContent={
          <ContentLoader
            loading={loadingGetPerformanceLookup}
            data={performanceLookup && performanceLookup.spcRegions}
            render={
              appliedFilters ? (
                <PerformanceFilter
                  appliedFilters={appliedFilters}
                  defaultAvailableItems={{
                    regions: performanceLookup.spcRegions,
                    displayViews: getViewLookup(performanceLookup.spcRegions[0].data),
                    timeFrame: numberToSelect(4),
                    categories: performanceLookup.spcCategories,
                    perspectives: performanceLookup.spcRegions[0].perspective
                  }}
                  snapshotData={{
                    seaoView: getRegions(CustomerRegion.seao).data,
                    indiaView: getRegions(CustomerRegion.india).data,
                    seaoPerspective: getRegions(CustomerRegion.seao).perspective,
                    indiaPerspective: getRegions(CustomerRegion.india).perspective
                  }}
                  onApply={handleSearch}
                />
              ) : (
                <></>
              )
            }
          />
        }
        leftVisible={showFilters}
        leftActions={<Icon name="cross" onClick={toggleFilters} style={{ cursor: "pointer" }} />}
        centerContent={
          <div className="performance-chart-content">
            <ContentLoader
              loading={loadingGetPerformance}
              data={performanceData}
              render={<LineChart data={performanceData} unitOfMeasure="%" />}
            />
          </div>
        }
      />
    </View>
  );
};

export default PerformanceHistoryView;
