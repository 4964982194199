import { useGrid } from "../../components/grid/grid";
import Loader from "../../components/loader";
import { getFeedbackColumns } from "../grid/views-columns/feedback";
import { useAppState } from "../../app-state";
import { useMemo } from "react";
import { EMarketArea } from "../../types";
import {
  TableId,
  ViewId,
  useTablePreferencesHandler
} from "../../helpers/table-preferences-handler";
import { ITicketTaskItem } from "../api/task";

const FeedbackTableView = ({
  data,
  loadingTable,
  onRowClick
}: {
  data: ITicketTaskItem[];
  loadingTable: boolean;
  onRowClick: (data: ITicketTaskItem) => void;
}) => {
  const [appState] = useAppState();
  const hasEscalationLevel = useMemo(
    () => Boolean(!appState.user?.marketAreas.includes(EMarketArea.MMEA)),
    [appState]
  );
  //persist filter
  const { preferencesHandler } = useTablePreferencesHandler(
    ViewId.VIS_FEEDBACK,
    TableId.VIS_FEEDBACK
  );
  //table
  const columns = useMemo(() => getFeedbackColumns({ hasEscalationLevel }), [hasEscalationLevel]);
  const { renderTable } = useGrid<ITicketTaskItem>({
    columns,
    clientModel: {
      data,
      rowKeySelector: data => data.taskId.toString()
    },
    showColumnsFiltersInfoAt: "left",
    preferencesHandler,
    onRowClicked: row => {
      onRowClick(row);
    }
  });

  return loadingTable ? <Loader noTile /> : <div className="feedback-table">{renderTable()}</div>;
};

export default FeedbackTableView;
