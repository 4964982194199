import React, { useCallback, useState } from "react";
import cx from "classnames";
import { ITable, SortingDirection, TableColumn } from "./ITable";
import { TextField } from "@react-gcc-eds/core";

interface IDataAttributes {
  [key: string]: string | number;
}

interface IDefineTable extends ITable {
  widths?: string[];
  title?: string;
}

const createDataAttributeObject = (dataAttribute: IDataAttributes) => {
  const dataAttribs: any = {};
  Object.keys(dataAttribute).forEach(key => {
    dataAttribs[`data-${key}`] = dataAttribute[key];
  });
  return dataAttribs;
};

const Table = ({
  columns,
  rows,
  size,
  rowsStyle,
  onRowClick,
  onCellClick,
  className,
  selectable = false,
  selectedRows,
  onSort,
  disabled,
  style,
  dataAttributes,
  tableInfo,
  paginaton,
  pagination,
  onEditedCell,
  splitCellStyle,
  scrollHorizontal,
  scrollVertical,
  showSettingsIcon,
  onSettingsIconClick,
  topButtons,
  hideHeader,
  widths,
  title
}: IDefineTable) => {
  const [editingColumns, setEditingColumns] = useState([] as string[]);

  const [editingColumnsValue, setEditingColumnsValue] = useState<{ [key: string]: any }>({});
  const setColumnValue = (key: string, value: string) => {
    const currentEditingColumnsValue = JSON.parse(JSON.stringify(editingColumnsValue));
    currentEditingColumnsValue[key] = value;
    setEditingColumnsValue(currentEditingColumnsValue);
  };

  const handleClick = (key: string, sortingDirection: SortingDirection) => {
    if (sortingDirection === SortingDirection.None && onSort) {
      onSort(key, SortingDirection.Ascending);
    } else if (sortingDirection === SortingDirection.Ascending && onSort) {
      onSort(key, SortingDirection.Descending);
    } else if (sortingDirection === SortingDirection.Descending && onSort) {
      onSort(key, SortingDirection.None);
    }
  };

  const showTableInfo = () => {
    return (
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info">
            <span className="count-show">{` ${tableInfo?.show.from} - ${tableInfo?.show.to} `}</span>
            <span className="count-total">of {tableInfo?.total} items</span>
            <span className="count-selected">| {tableInfo?.selected} selected</span>
          </div>
        </div>
      </div>
    );
  };

  const showTableTitle = () => {
    return <div className="table-top-left">{title}</div>;
  };

  const showTableSettings = () => {
    return (
      <>
        <button
          className="btn-icon table-settings"
          title="Manage columns"
          onClick={() => onSettingsIconClick && onSettingsIconClick()}
        >
          <i className="icon icon-settings"></i>
        </button>
      </>
    );
  };
  const usePagination = <div className="table-bottom">{paginaton || pagination}</div>;
  type scrollProps = { children: any };
  const ScrollWrapper = useCallback(
    ({ children }: scrollProps) => {
      if (scrollVertical) {
        return (
          // modify:change className="sticky"=>className="table-sticky"
          <div className="table-scroll table-sticky" style={{ height: "100%" }}>
            {children}
          </div>
        );
      }
      if (scrollHorizontal) {
        return (
          <div className="table-scroll" style={{ width: "100%" }}>
            {children}
          </div>
        );
      }
      return children;
    },
    [scrollHorizontal, scrollVertical]
  );

  const tableComponent = (
    <>
      <table
        {...(dataAttributes && createDataAttributeObject(dataAttributes))}
        className={cx(
          "table",
          size !== "normal" ? size : "",
          rowsStyle !== "none" ? rowsStyle : "",
          {
            sortable: columns.filter((c: TableColumn) => c.sortable).length > 0,
            disabled
          },
          splitCellStyle !== "none" ? `split-${splitCellStyle}` : "",
          className
        )}
        style={style}
      >
        {widths && (
          <colgroup>
            {widths.map((width, index) => (
              <col key={`table-define-${index}`} width={width}></col>
            ))}
          </colgroup>
        )}
        {!hideHeader && (
          <thead>
            <tr>
              {columns.map(c => (
                <th
                  key={`h_${c.key}`}
                  className={cx(
                    { "is-sortable": c.sortable },
                    { asc: c.sortingDirection === SortingDirection.Ascending },
                    {
                      desc: c.sortingDirection === SortingDirection.Descending
                    },
                    { freeze: c.freeze }
                  )}
                  onClick={() =>
                    c.sortable && onSort
                      ? handleClick(
                          c.key,
                          c.sortingDirection !== undefined
                            ? c.sortingDirection
                            : SortingDirection.None
                        )
                      : null
                  }
                  style={c.freeze ? { left: "0px" } : {}}
                >
                  {c.header}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map((o, rowIndex: number) => {
            const r = o as { key: string; [key: string]: any };
            const key = `r_${rowIndex}`;

            return (
              <tr
                key={key}
                className={cx({
                  selected: selectable && selectedRows?.includes(r.key)
                })}
              >
                {columns.map((c: TableColumn, columnIndex: number) => {
                  const renderCell = (label: string, columnKey: string) => {
                    if (c.editable) {
                      if (editingColumns.includes(columnKey)) {
                        return (
                          <div
                            className="editing"
                            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <div className="inline-field">
                              <TextField
                                focus
                                onChange={(e: string) => {
                                  setColumnValue(columnKey, e);
                                }}
                                value={
                                  typeof editingColumnsValue[columnKey] !== "undefined"
                                    ? editingColumnsValue[columnKey]
                                    : label
                                }
                              />
                            </div>
                            <div className="inline-buttons">
                              <button
                                className="btn-icon"
                                data-action="cancel"
                                onClick={() => {
                                  const currentEditingColumns = editingColumns.slice(0);
                                  currentEditingColumns.splice(
                                    currentEditingColumns.indexOf(columnKey),
                                    1
                                  );
                                  setEditingColumns(currentEditingColumns);
                                  setColumnValue(columnKey, label);
                                }}
                              >
                                <i className="icon icon-cross"></i>
                              </button>
                              <button
                                className="btn-icon"
                                data-action="submit"
                                onClick={() => {
                                  if (onEditedCell) {
                                    const editedCell = JSON.parse(JSON.stringify(r));
                                    editedCell[c.key] = editingColumnsValue[columnKey]
                                      ? editingColumnsValue[columnKey]
                                      : label;
                                    onEditedCell(editedCell, rowIndex);
                                  }
                                  const currentEditingColumns = editingColumns.slice(0);
                                  currentEditingColumns.splice(
                                    currentEditingColumns.indexOf(columnKey),
                                    1
                                  );
                                  setEditingColumns(currentEditingColumns);
                                }}
                              >
                                <i className="icon icon-check"></i>
                              </button>
                            </div>
                          </div>
                        );
                      }
                      return <div className="editable">{label}</div>;
                    }
                    if (
                      typeof label === "string" ||
                      typeof label === "number" ||
                      label === null ||
                      label === undefined
                    ) {
                      return label;
                    } else {
                      return Object.values(label).map(l => <div>{l as string}</div>);
                    }
                  };

                  const handleRowClick = (r: { key: string }, columnKey = "", editable = false) => {
                    if (editable) {
                      const currentEditingColumns = editingColumns.slice(0);
                      currentEditingColumns.push(columnKey);
                      setEditingColumns(currentEditingColumns);
                    }
                    if (onRowClick) {
                      onRowClick(r);
                    }
                    if (onCellClick) {
                      onCellClick(r, rowIndex, columnIndex);
                    }
                  };
                  return (
                    <td
                      role="presentation"
                      onClick={() => handleRowClick(r, `r_${key}_${c.key}`, c.editable)}
                      key={`r_${key}_${c.key}`}
                      className={`${c.editable ? "cell-edit" : ""}
                      ${c.enableDropdownMenu ? "cell-actions" : ""}
                      ${
                        typeof r[c.key] !== "string" && typeof r[c.key] !== "number" && !c.Cell
                          ? "cell-split"
                          : ""
                      } ${c.freeze ? "freeze" : ""}
                      `}
                      style={c.freeze ? { whiteSpace: "nowrap", left: "0px" } : {}}
                    >
                      {c.Cell ? c.Cell(r) : renderCell(r[c.key], `r_${key}_${c.key}`)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
  return (
    <>
      {(tableInfo || showSettingsIcon || topButtons) && (
        <div className="table-top">
          {tableInfo ? showTableInfo() : title ? showTableTitle() : <></>}

          <div className="table-top-right">
            {topButtons}
            {showSettingsIcon && <div className="table-actions">{showTableSettings()}</div>}
          </div>
        </div>
      )}

      <ScrollWrapper>{tableComponent}</ScrollWrapper>
      {(paginaton || pagination) && usePagination}
    </>
  );
};

export default Table;
