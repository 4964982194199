import { Pill } from "@react-gcc-eds/core";
import "./index.scss";
import { useMemo, useState } from "react";

export interface IQuickFilterItem {
  name: string;
  selected: boolean;
}

const QuickFilter = ({
  title,
  data,
  onChange
}: {
  title: string;
  data: string[];
  onChange: (data: string[]) => void;
}) => {
  const translateFromApi = (data: string[]) => data.map(item => ({ name: item, selected: true }));
  const [editedData, setEditedData] = useState<IQuickFilterItem[]>(translateFromApi(data));

  const selectedNumber = useMemo(() => {
    const selectedItems = editedData.filter(item => item.selected);
    return selectedItems.length;
  }, [editedData]);

  const onSelectedPill = (name: string) => {
    const newItems = editedData.map(item => {
      if (selectedNumber === editedData.length) {
        return { ...item, selected: item.name === name };
      } else if (selectedNumber === 1 && name === editedData.find(item => item.selected)?.name) {
        return { ...item, selected: true };
      } else {
        return item.name === name ? { ...item, selected: !item.selected } : item;
      }
    });
    setEditedData(newItems);
    onChange(newItems.filter(item => item.selected).map(({ name }) => name));
  };

  return (
    <div className="quick-filter">
      <span className="quick-filter-title">{title}</span>
      <div>
        {editedData.map(({ name, selected }, index) => (
          <Pill
            key={`exception-sate-pill-${index}`}
            toggled={!selected}
            onToggle={() => onSelectedPill(name)}
          >
            {name}
          </Pill>
        ))}
      </div>
    </div>
  );
};

export default QuickFilter;
