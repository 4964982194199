import { Button, TableColumn } from "@react-gcc-eds/core";
import { ReactElement } from "react";
import Table from "../../components/table";
import "./sticky-table.scss";

const StickyTable = ({
  columns,
  rows,
  className,
  onRowClick,
  widths,
  emptyMsg = "No data found !",
  topButtons
}: {
  columns: TableColumn[];
  rows?: object[];
  className?: string;
  onRowClick?: (data: object) => void;
  emptyMsg?: string;
  widths?: string[];
  topButtons?: ReactElement<typeof Button>[] | ReactElement<typeof Button>;
}) => (
  <div className={`${className} sticky sticky-table-content`}>
    {rows && rows.length > 0 ? (
      <Table
        rows={rows}
        columns={columns}
        scrollVertical
        className="sticky-scroll-table"
        onRowClick={onRowClick}
        widths={widths}
        topButtons={topButtons}
      />
    ) : (
      <div className="empty-state">
        <div className="message">
          <p>{emptyMsg}</p>
        </div>
      </div>
    )}
  </div>
);

export default StickyTable;
