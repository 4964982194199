import { useEffect } from "react";
import { ApplicationLayer, Button, useNotifications } from "@react-gcc-eds/core";
import { FallbackProps } from "react-error-boundary";
import FullScreenPanel from "../full-screen-panel";
import { useAppState } from "../../app-state";

const useApiErrorsActions = () => {
  const [appState, setAppState] = useAppState();

  const pushError = (message: string) => {
    setAppState(old => ({ ...old, api: { ...old.api, errors: [...old.api.errors, message] } }));
  };

  const popError = (): string => {
    if (!appState.api.errors.length) {
      return "";
    }

    const message = appState.api.errors[0];
    setAppState(old => ({
      ...old,
      api: { ...old.api, errors: old.api.errors.filter((_: string, idx: number) => idx > 0) }
    }));
    return message;
  };

  return { pushError, popError };
};

const ApiErrorManager = () => {
  const [appState] = useAppState();

  const { popError } = useApiErrorsActions();
  const { createNotification } = useNotifications();

  const notifyNextError = () => {
    const message = popError();

    if (message) {
      createNotification("Error", message, "warning", "triangle-warning");
    }
  };

  useEffect(() => {
    notifyNextError();
  }, [appState.api.errors]);

  return null;
};

const ApplicationError = ({ error, resetErrorBoundary }: FallbackProps) => {
  const resetError = () => {
    resetErrorBoundary();
    window.location.replace("/");
  };

  return (
    <ApplicationLayer title="Application error">
      <FullScreenPanel>
        <div style={{ marginBottom: 10 }}>Ops. Something went wrong.</div>
        {error && <div>{error.message}</div>}
        <Button onClick={resetError}>Go to Home</Button>
      </FullScreenPanel>
    </ApplicationLayer>
  );
};

const PageNotFound = () => {
  return (
    <ApplicationLayer title="Application error">
      <FullScreenPanel>
        <div style={{ marginBottom: 10 }}>Ops. The requested page doesn't exist.</div>
        <Button onClick={() => window.location.replace("/")}>Go to Home</Button>
      </FullScreenPanel>
    </ApplicationLayer>
  );
};

export { ApiErrorManager, ApplicationError, PageNotFound, useApiErrorsActions };
