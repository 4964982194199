import React, { useMemo } from "react";
import { Card, Icon, Pill } from "@react-gcc-eds/core";
import "./feedbackCard.scss";
import { EscalationPriority, EscalationProgress } from "../helper";
import ProgressCircle from "./progress-circle";
import { People } from "@microsoft/mgt-react";
import { ITaskMaterialItem } from "../api/task";
import { getUTCDate } from "../../utils";

const FeedbackCard = ({
  ticketNumber,
  customer,
  materialDetails,
  priority,
  progress,
  taskAssignedUsers,
  dueDate,
  onClick,
  ticketCategory
}: {
  ticketNumber: number;
  customer: string;
  materialDetails: ITaskMaterialItem[];
  ticketCategory: string;
  priority: EscalationPriority;
  progress: EscalationProgress;
  onClick: () => void;
  dueDate: string | undefined;
  taskAssignedUsers: string[];
}) => {
  const materialDetailTable = (
    <table className="card-material-table">
      <thead>
        <tr>
          <th>Code</th>
          {/*<th>Description</th>*/}
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        {materialDetails.slice(0, 4).map((i, index) => (
          <tr key={`materialDetails-${ticketNumber}-${index}`}>
            <td>{i.material}</td>
            {/*<td>{i.descriptionForMaterial}</td>*/}
            <td>{i.totalQuantity}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderPriority = useMemo(() => {
    switch (priority) {
      case EscalationPriority.urgent:
        return <Icon style={{ color: "red" }} name={"notification-ring"} />;
      case EscalationPriority.important:
        return <span style={{ fontSize: "medium", fontWeight: "bolder", color: "red" }}>!</span>;
      case EscalationPriority.low:
        return <Icon name={"arrow-down"} />;
      case EscalationPriority.medium:
        return <Icon style={{ color: "green" }} name={"alarm-level2"} />;
    }
  }, [priority]);

  const content = (
    <div>
      <div>{materialDetailTable}</div>
      <div className="feedback-card-status">
        <div className="feedback-card-priority action">{renderPriority}</div>
        <div className="feedback-card-progress action">
          <ProgressCircle progress={progress} />
        </div>
        <div className="feedback-card-due-date action">
          {dueDate && (
            <>
              <div style={{ paddingBottom: "8px" }}>
                <Icon name="calendar" style={{ marginBottom: "8px" }} />
              </div>
              {getUTCDate(dueDate)}
            </>
          )}
        </div>
      </div>
      <div style={{ minHeight: "30px" }}>
        <People
          key={`${ticketNumber}-${taskAssignedUsers.toString()}`}
          showMax={10}
          userIds={taskAssignedUsers}
          onClick={e => e.stopPropagation()}
        ></People>
      </div>
    </div>
  );
  return (
    <div onClick={onClick}>
      <Card
        className="feedback-card"
        selectable={false}
        headerActions={<Pill>{ticketCategory || "(none)"}</Pill>}
        title={`Ticket ${ticketNumber}`}
        subTitle={customer}
        content={content}
      />
    </div>
  );
};

export default FeedbackCard;
