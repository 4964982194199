import "proxy-polyfill";
import React from "react";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import "@react-gcc-eds/core/lib/styling.css";
import "./style/app.scss";
import { getIEVersion } from "./utils";
import AppWrapper from "./app-warpper";
import { AppStateProvider, AvatarStateProvider, stateDefault } from "./app-state";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig";
import "./style/recharts.scss";

const notSupportedBrowser = () => {
  return (
    <div className="dark" style={{ height: "100%" }}>
      <div className="signin">
        <div className="watermark">
          <i className="icon icon-econ" />
          <div className="product">Ericsson Customer Order Dashboard</div>
        </div>
        <div className="unsupported-browser">
          Sorry, we are not supporting current browser.
          <br />
          <br />
          Please use Microsoft Egde ©, Google Chrome © or Mozilla Firefox ©.
        </div>
        <div className="footer">
          © Ericsson AB 2013-2020 - All rights reserved. <br />
          No part of this software may be reproduced in any form without the written permission of
          the copyright holder.
        </div>
      </div>
    </div>
  );
};

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  getIEVersion() > 0 ? (
    notSupportedBrowser()
  ) : (
    <BrowserRouter>
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <AppStateProvider initialValue={stateDefault}>
            <AvatarStateProvider>
              <AppWrapper />
            </AvatarStateProvider>
          </AppStateProvider>
        </MsalProvider>
      </React.StrictMode>
    </BrowserRouter>
  ),
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
