import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import cx from "classnames";
import { ISelectItem, IMultiSelect, Icon } from "@react-gcc-eds/core";

const MultiSelect = ({
  type,
  items,
  disabled,
  placeholder,
  label,
  topLabel,
  selectedItems,
  onChange,
  onOpen,
  onClose,
  onBlur,
  className,
  style
}: IMultiSelect<any>) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const labelRef = useRef<HTMLLabelElement>(null);
  const [filteredItems, setFilteredItems] = useState<ISelectItem[]>([]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const optionscontainerRef = useRef<HTMLDivElement>(null);

  const filterOptions = (typedValue: string) => {
    if (items !== undefined) {
      setFilteredItems(
        items.filter(
          val =>
            (val.title || "").toLocaleLowerCase().indexOf((typedValue || "").toLocaleLowerCase()) >
            -1
        )
      );
    }
  };

  useEffect(() => {
    if (type === "input-multi") {
      filterOptions(text);
    } else {
      setFilteredItems(items);
    }
  }, [items]);

  const validTypes = ["multi", "multi-pills", "input-multi"];
  const selectedDataType = type !== undefined ? type : "multi";

  const closeSelect = () => {
    if (open && onClose) {
      onClose();
    }
    setOpen(false);
  };

  const containerRef = useClickOutside(closeSelect, open);

  const invalidType = (
    <div className="select closed" data-type="invalid-type">
      <div className="invalid_select">Invalid type</div>
    </div>
  );

  const renderMultiItem = (item: ISelectItem) => {
    const id = uniqueId();
    const selectedLabels = selectedItems !== undefined ? selectedItems.map(cur => cur.title) : [];
    const checked = selectedItems !== undefined && selectedLabels.includes(item.title);
    return (
      <div
        style={style}
        className={cx("item", { disabled: item.disabled })}
        key={item.title}
        title={item.title}
      >
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlur && onBlur(e)}
          onChange={() => {
            if (onChange !== undefined && !item.disabled) {
              let returnValues;
              if (checked) {
                returnValues =
                  selectedItems !== undefined
                    ? selectedItems.filter((l: ISelectItem) => l.title !== item.title)
                    : [];
              } else {
                returnValues = selectedItems !== undefined ? [item, ...selectedItems] : [item];
              }
              onChange(returnValues);
            }
          }}
        />
        <label
          htmlFor={id}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          {item.title}
        </label>
      </div>
    );
  };

  const buttonType =
    selectedDataType === "input-multi" ? (
      <div
        role="presentation"
        className="clickable current-options"
        onClick={() => {
          setOpen(true);
          if (onOpen) {
            onOpen();
          }
        }}
      >
        <input
          style={{ border: "1px solid", borderRadius: 3, backgroundColor: "transparent" }}
          value={open ? text : label}
          type="text"
          placeholder={placeholder}
          onFocus={() => {
            setOpen(true);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlur && onBlur(e)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === "") {
              setOpen(true);
            }
            setText(e.target.value);
            filterOptions(e.target.value);
          }}
        />
        <Icon
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            toggleOpen();
            if (!open) {
              setOpen(true);
              if (onOpen) {
                onOpen();
              }
            } else {
              setOpen(false);
              if (onClose) {
                onClose();
              }
            }
          }}
          name="chevron-down"
        />
      </div>
    ) : (
      <button
        type="button"
        className="btn current-options"
        role="presentation"
        onBlur={(e: any) => onBlur && onBlur(e)}
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();

          setOpen(!open);
          if (!open && onOpen) {
            onOpen();
          }
          if (!!open && onClose) {
            onClose();
          }
        }}
      >
        {label || placeholder || ""}
      </button>
    );

  const isOutOfViewport = (elem: HTMLDivElement) => {
    const itemHeightOffset = 32;
    const maxOffset = 130;
    const offsetHeight = items.length < 4 ? itemHeightOffset * items.length : maxOffset;
    const bounding = elem.getBoundingClientRect();

    return (
      bounding.bottom + offsetHeight > (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const outOfViewport = containerRef.current ? isOutOfViewport(containerRef.current) : false;
  const optionscontainerHeight = optionscontainerRef.current
    ? optionscontainerRef.current.offsetHeight
    : 0;

  if (validTypes.includes(selectedDataType)) {
    return (
      <>
        {topLabel && <label ref={labelRef}>{topLabel}</label>}
        <div title={label}>
          <div
            ref={containerRef}
            role="presentation"
            className={cx("select", { disabled }, { closed: !open }, { open }, className)}
            data-type={selectedDataType}
          >
            {buttonType}

            <div
              className={cx("options-list overflow", { above: outOfViewport })}
              style={
                outOfViewport &&
                (selectedDataType === "multi-pills" || selectedDataType === "input-multi")
                  ? { bottom: `${28 + optionscontainerHeight}px` }
                  : undefined
              }
            >
              {type === "input-multi" && filteredItems.length === 0 && text !== "" ? (
                <div className="no_results">No results found</div>
              ) : null}
              {filteredItems.map((item: ISelectItem) => {
                return renderMultiItem(item);
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
  return invalidType;
};

const uniqueId = (prefix?: string) => {
  return `${prefix || "id"}-${Math.random().toString(36).substr(2, 16)}`;
};

const useClickOutside = (handler: EventListener, dependencies?: any) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseClick = (e: Event) => {
    if (containerRef.current && containerRef.current.contains(e.target as Node)) {
      // inside click
      return;
    }

    // outside click
    handler(e);
  };

  useEffect(() => {
    document.addEventListener("click", handleMouseClick, true);
    return () => {
      document.removeEventListener("click", handleMouseClick, true);
    };
  }, [dependencies]);

  return containerRef;
};

export default MultiSelect;
