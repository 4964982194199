import { ProgressBar, Tile } from "@react-gcc-eds/core";
import FullScreenPanel from "./full-screen-panel";
import "../style/full-loader.scss";
import { CSSProperties } from "react";

const Loader = ({ noTile, style }: { noTile?: boolean; style?: CSSProperties }) => {
  const LoaderPanel = () => (
    <FullScreenPanel>
      <div className="fullscreen-loader" style={style}>
        <ProgressBar
          className="progress-bar"
          thin
          format="none"
          max={1}
          value={0}
          indeterminate={true}
        />
      </div>
    </FullScreenPanel>
  );
  return noTile ? (
    <LoaderPanel />
  ) : (
    <Tile style={{ width: "100%", height: "100%", ...style }}>
      <LoaderPanel />
    </Tile>
  );
};

export default Loader;
