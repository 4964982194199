import { ICellRendererParams, ValueFormatterParams } from "ag-grid-community";
import { IColumnDefinition } from "../../../components/grid/grid";
import { dateCell } from "../../../helpers/grid-cells";
import { Icon, Pill } from "@react-gcc-eds/core";
import { EscalationStatus } from "../../helper";
import "./ticket-status-progress.scss";
import { ProgressBar } from "../../../components/progress-bar";
import { customTextCell } from "../../helpers/ef-table";
import { capitalize } from "../../../utils";

export const escalationStatusCell = (p: ICellRendererParams) => {
  const { value } = p;
  return (
    <Pill
      className={
        value === EscalationStatus.approval
          ? "ticket-status-approval"
          : value === EscalationStatus.pending
          ? "ticket-status-pending"
          : "ticket-status-reject"
      }
    >
      {capitalize(value)}
    </Pill>
  );
};

export const getTicketLogColumns = (): IColumnDefinition[] => {
  const progressTooltip = (props: any) => {
    const { location } = props;
    if (location === "header") {
      return (
        <div className="ticket-log-progress-tooltip">
          <div className="ticket-log-progress-tooltip-row">
            <Icon name={"severity-cleared"} style={{ color: "rgb(50, 152, 100)" }} />
            Completed
          </div>
          <div className="ticket-log-progress-tooltip-row">
            <Icon name={"severity-cleared"} style={{ color: "rgb(212, 102, 23)" }} />
            In Progress
          </div>
          <div className="ticket-log-progress-tooltip-row">
            <Icon name={"severity-cleared"} style={{ color: "#767676" }} />
            Not Started
          </div>
        </div>
      );
    } else {
      const { completedTasks, inProgressTasks, notStartedTasks } = props.data;
      return (
        <div className="ticket-log-progress-tooltip">
          <div className="ticket-log-progress-tooltip-row">
            <span className="ticket-log-progress-tooltip-label">Completed:</span>
            {completedTasks}
          </div>
          <div className="ticket-log-progress-tooltip-row">
            <span className="ticket-log-progress-tooltip-label">In Progress:</span>
            {inProgressTasks}
          </div>
          <div className="ticket-log-progress-tooltip-row">
            <span className="ticket-log-progress-tooltip-label">Not Started:</span>
            {notStartedTasks}
          </div>
        </div>
      );
    }
  };

  return [
    customTextCell("ticketNumber", "Ticket number"),
    customTextCell("requesterName", "Requester name", false, true),
    {
      ...dateCell("raiseTime", "Raise time", false, true),
      minWidth: 180
    },
    customTextCell("customers", "Customer", false, true, 200),
    customTextCell("modifyUserName", "Modified user name", false, true),
    customTextCell("ticketCategories", "Ticket category", false, true),
    customTextCell("productReportingGroups", "Product reporting group", false, true, 180),
    {
      ...customTextCell("escalationStatus", "Status", false, true, 180),
      cellRenderer: escalationStatusCell
    },
    {
      ...customTextCell("progress", "Handling progress", false, true, 300),
      tooltipComponent: progressTooltip,
      headerTooltip: "progress",
      tooltipField: "completedTasks",
      cellRenderer: (p: ICellRendererParams) => {
        const { completedTasks, inProgressTasks, notStartedTasks } = p.data;
        return (
          <div style={{ paddingRight: "15%" }}>
            <ProgressBar
              values={[completedTasks, inProgressTasks, notStartedTasks]}
              colors={["#329864", "#d46617"]}
            />
          </div>
        );
      },
      valueGetter: p => {
        const { completedTasks, inProgressTasks, notStartedTasks } = p.data;
        if (inProgressTasks > 0) {
          return "In Progress";
        } else if (completedTasks > 0 && notStartedTasks === 0) {
          return "Completed";
        } else {
          return "Not Started";
        }
      }
    },
    {
      ...customTextCell("epcTagStatus", "EPC tag status", false, true),
      valueFormatter: (p: ValueFormatterParams) => {
        return capitalize(p.value || "");
      }
    }
  ];
};
