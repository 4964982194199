import axios, { AxiosInstance, AxiosPromise } from "axios";
import useAxios, { configure } from "axios-hooks";
import qs from "qs";
import { useRef } from "react";
import { getFileNameFromResponse } from "../utils";
import { IErrorResponse } from "../types";

let axiosInstance: AxiosInstance | undefined;

let virtualRole: string | undefined;

export const initialize = ({
  baseUrl,
  log,
  onError,
  getToken,
  onNoRoleAssigned
}: {
  baseUrl: string;
  log?: boolean;
  getToken: () => Promise<string | undefined>;
  onError?: (message: string) => void;
  onNoRoleAssigned: () => void;
}) => {
  axiosInstance = axios.create({
    baseURL: baseUrl
  });

  axiosInstance.interceptors.request.use(async request => {
    log && console.log("URL called", request.url);

    const token = await getToken();
    // Token should be in cache here
    // const token = Authentication.getCachedToken();
    request.headers = {
      ...request.headers,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || ""}`
    };

    if (virtualRole) {
      request.headers = { ...request.headers, "x-impersonation-role": virtualRole };
    }

    return request;
  });

  axiosInstance.interceptors.response.use(
    response => {
      log && console.log(`${response.config.url} response`, response);
      return response;
    },
    async error => {
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log("err-data", error.response.data);
        console.log("err-status", error.response.status);
        console.log("err-headers", error.response.headers);

        const originalRequest = error.config;

        if (error.response.status === 403) {
          onNoRoleAssigned();
        } else if (
          (error.response.status === 401 || error.response.status === 407) &&
          !originalRequest._retry &&
          axiosInstance
        ) {
          // TODO: token expired or session expired
          // debugger;
          originalRequest._retry = true;

          const token = await getToken();

          axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token || ""}`;
          return axiosInstance(error.config);
        } else {
          let msg = "An unexpected error occurred";
          if (error.response && error.response.data) {
            const { message, messages } = error.response.data as IErrorResponse;
            if (message) {
              msg = `${JSON.stringify(message)}`;
            }
            if (messages) {
              msg = `${JSON.stringify(messages.toString())}`;
            }
          }
          onError && onError(msg);
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log("Request error", error.request);

        onError && onError("API call failed");
      } else {
        if (!axios.isCancel(error)) {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error);

          onError && onError("An unexpected error occurred");
        }
      }

      return Promise.reject(error);
    }
  );

  configure({ axios: axiosInstance });
};

const checkApiInitialized = () => {
  if (!axiosInstance) {
    throw new Error("API not initialized");
  }
};

export const setVirtualRole = (role?: string) => {
  checkApiInitialized();
  virtualRole = role;
};

const paramsSerializer = (params: any) => {
  const newP = qs.stringify(params, {
    arrayFormat: "repeat",
    skipNulls: true
  });
  // log && console.log(newP);
  return newP;
};

export const useApiGet = <Answer extends {} | undefined, Params extends {} | undefined = undefined>(
  method: string,
  autoCancel?: boolean
): [data: Answer, loading: boolean, get: (params?: Params) => AxiosPromise<Answer>] => {
  const [{ data, loading }, call] = useAxios(
    {
      url: method,
      paramsSerializer
    },
    { manual: true, autoCancel: autoCancel !== undefined ? autoCancel : true }
  );

  const get = (params?: Params) => {
    checkApiInitialized();
    return call({ params });
  };
  const getRef = useRef(get);

  return [data, loading, getRef.current];
};

export const useApiPost = <Data extends {} | undefined, Answer extends {} | undefined = undefined>(
  method: string,
  autoCancel?: boolean
): [data: Answer, loading: boolean, post: (data?: Data) => AxiosPromise<Answer>] => {
  const [{ data, loading }, call] = useAxios(
    { url: method, method: "POST" },
    { manual: true, autoCancel: autoCancel !== undefined ? autoCancel : true }
  );

  const post = (data?: Data) => {
    checkApiInitialized();
    return call({ data });
  };

  const getRef = useRef(post);

  return [data, loading, getRef.current];
};

export const useApiPostForm = <Answer extends {} | undefined>(
  method: string
): [data: Answer, loading: boolean, post: (data?: object) => AxiosPromise<Answer>] => {
  const [{ data, loading }, call] = useAxios({ url: method, method: "POST" }, { manual: true });

  const post = (body?: { [key: string]: any }) => {
    checkApiInitialized();

    const formData = new FormData();
    if (body) {
      const keys = Object.keys(body);
      keys.forEach(key => {
        body[key] && formData.append(key, body[key]);
      });
    }
    return call({ data: formData });
  };

  const getRef = useRef(post);

  return [data, loading, getRef.current];
};

export const useApiPut = <Data extends {} | undefined, Answer extends {} | undefined = undefined>(
  method: string | ((data?: Data) => string)
): [data: Answer, loading: boolean, put: (data?: Data) => AxiosPromise<Answer>] => {
  const [{ data, loading }, call] = useAxios({ method: "PUT" }, { manual: true });
  const put = (data?: Data) => {
    checkApiInitialized();
    return call({ data, url: typeof method === "string" ? (method as string) : method(data) });
  };
  const getRef = useRef(put);
  return [data, loading, getRef.current];
};

export const useApiPutForm = <
  Data extends { [key: string]: any } | undefined,
  Answer extends {} | undefined = undefined
>(
  method: string
): [data: Answer, loading: boolean, put: (data?: Data) => AxiosPromise<Answer>] => {
  const [{ data, loading }, call] = useAxios({ method: "PUT" }, { manual: true });
  const put = (body?: Data) => {
    checkApiInitialized();

    const formData = new FormData();
    if (body) {
      const keys = Object.keys(body);
      keys.forEach(key => {
        body[key] && formData.append(key, body[key]);
      });
    }

    return call({ data: formData, url: method });
  };
  const getRef = useRef(put);
  return [data, loading, getRef.current];
};

export const useApiGetForBlob = <
  Answer extends {} | undefined,
  Params extends {} | undefined = undefined
>(
  method: string,
  autoCancel?: boolean
): [
  data: Answer,
  loading: boolean,
  get: (params?: Params) => AxiosPromise,
  cancel: () => void,
  fileName?: string
] => {
  const [{ data, loading, response }, call, cancel] = useAxios(
    {
      url: method,
      responseType: "blob",
      paramsSerializer
    },
    { manual: true, autoCancel: autoCancel !== undefined ? autoCancel : true }
  );
  const get = (params?: Params) => {
    checkApiInitialized();
    return call({ params });
  };
  const getRef = useRef(get);
  return [data, loading, getRef.current, cancel, response && getFileNameFromResponse(response)];
};

export const useApiPostForBlob = <
  Data extends {} | undefined,
  Answer extends {} | undefined = undefined
>(
  method: string,
  autoCancel?: boolean
): [
  data: Answer,
  loading: boolean,
  post: (data?: Data) => AxiosPromise<Answer>,
  cancel: () => void,
  fileName?: string
] => {
  const [{ data, loading, response }, call, cancel] = useAxios(
    { method: "POST", responseType: "blob" },
    { manual: true, autoCancel: autoCancel !== undefined ? autoCancel : true }
  );

  const post = (data?: Data) => {
    checkApiInitialized();
    return call({ data, url: method });
  };

  const getRef = useRef(post);

  return [data, loading, getRef.current, cancel, response && getFileNameFromResponse(response)];
};
