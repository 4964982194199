import { TableColumn } from "@react-gcc-eds/core";
import { ISippRow } from "../../component/gft/PublishStatusWeekly";

const text = (key: keyof ISippRow, header: string) => {
  return { key, header };
};

export const getPublishStatusTableColumns = (): TableColumn[] => [
  text("consumingSCN", "Consuming SCN"),
  text("nonSipp", "Non-SIPP"),
  text("sipp", "SIPP"),
  text("total", "Grand Total"),
  text("remarks", "Remarks")
];
