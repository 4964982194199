import View from "./app-view";
import FullScreenPanel from "../components/full-screen-panel";
import Loader from "../components/loader";

const AppViewLoader = () => {
  return (
    <View breadCrumbs={[]}>
      <FullScreenPanel>
        <Loader noTile />
      </FullScreenPanel>
    </View>
  );
};

export default AppViewLoader;
