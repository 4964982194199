import { IEscalationDetailsLookupItem } from "../../api/tableau";
import { Button, IDropdownItem } from "@react-gcc-eds/core";
import MultipleDropdownFilter, {
  multiFilterLabel
} from "../../../components/select/MultipleDropdownFilter";
import { useEffect, useState } from "react";
import { apiToSelect } from "../../helper/tableau";
import { simpleObjectEqual } from "../../../utils/user-helpers";

export interface IEscalationDetailsFilterItems {
  escalationStatuses: IDropdownItem<number>[];
  pcodes: IDropdownItem[];
  customerUnits: IDropdownItem[];
  excludePackages: IDropdownItem[];
}

export const ESCALATION_STATUS_OPTION = [
  { title: "Not Escalated", value: -1 },
  { title: "Not Started", value: 0 },
  { title: "In Progress", value: 1 },
  { title: "Close", value: 2 }
];

export const DEFAULT_FILTER: IEscalationDetailsFilterItems = {
  escalationStatuses: [{ title: "In Progress", value: 1 }],
  pcodes: [],
  customerUnits: [],
  excludePackages: [{ title: "YES", value: "YES" }]
};

const EscalationDetailsFilter = ({
  allAvailableItems,
  onApply,
  appliedFilters,
  defaultFilters
}: {
  allAvailableItems: IEscalationDetailsLookupItem | undefined;
  onApply: (value: IEscalationDetailsFilterItems) => void;
  appliedFilters: IEscalationDetailsFilterItems;
  defaultFilters: IEscalationDetailsFilterItems;
}) => {
  const { editedFilters, setEditedFilters } = useEditedFilters(defaultFilters);

  const onReset = () => {
    setEditedFilters(defaultFilters);
  };

  const filterChanged =
    (key: keyof IEscalationDetailsFilterItems) => (value: IDropdownItem<string | number>[]) => {
      setEditedFilters(f => ({ ...f, [key]: value }));
    };

  return (
    <div className="scrollable-pane-content">
      <div className="scrollable-pane-scrollable-content">
        <MultipleDropdownFilter
          items={ESCALATION_STATUS_OPTION}
          header="Escalation Status"
          label={multiFilterLabel(ESCALATION_STATUS_OPTION, editedFilters.escalationStatuses)}
          onChange={filterChanged("escalationStatuses")}
          selectedItems={editedFilters.escalationStatuses || []}
        />
        <MultipleDropdownFilter
          items={apiToSelect(allAvailableItems?.customerUnits)}
          header="Customer Unit"
          label={multiFilterLabel(
            allAvailableItems?.customerUnits || [],
            editedFilters.customerUnits
          )}
          onChange={filterChanged("customerUnits")}
          selectedItems={editedFilters.customerUnits || []}
        />
        <MultipleDropdownFilter
          items={apiToSelect(allAvailableItems?.pcodes)}
          header="P-Code"
          label={multiFilterLabel(allAvailableItems?.pcodes || [], editedFilters.pcodes)}
          onChange={filterChanged("pcodes")}
          selectedItems={editedFilters.pcodes || []}
        />
        <MultipleDropdownFilter
          items={apiToSelect(allAvailableItems?.excludePackages)}
          header="Exclude package"
          label={multiFilterLabel(
            allAvailableItems?.excludePackages || [],
            editedFilters.excludePackages
          )}
          onChange={filterChanged("excludePackages")}
          selectedItems={editedFilters.excludePackages || []}
        />
      </div>
      <div className="scrollable-pane-actions">
        <Button onClick={onReset}>Reset</Button>
        <Button
          primary
          disabled={simpleObjectEqual(editedFilters, appliedFilters)}
          onClick={() => {
            onApply(editedFilters);
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

const useEditedFilters = (defaultFilters: IEscalationDetailsFilterItems) => {
  const [editedFilters, setEditedFilters] = useState<IEscalationDetailsFilterItems>(DEFAULT_FILTER);

  useEffect(() => {
    if (defaultFilters) {
      setEditedFilters(defaultFilters);
    }
  }, [defaultFilters]);

  return { editedFilters, setEditedFilters };
};

export default EscalationDetailsFilter;
