import { useEffect, useMemo, useState } from "react";
import { useGrid } from "../../../components/grid/grid";
import { getEscalationDetailsColumns } from "../../grid/views-columns/vis-escalation-detail";
import {
  IEscalationDetailsItem,
  IEscalationDetailsLookupItem,
  IEscalationDetailsPagedQuery,
  useExportEscalationDetails
} from "../../api/tableau";
import FullScreenPanel from "../../../components/full-screen-panel";
import Loader from "../../../components/loader";
import { IServerModelGetRowRequest } from "../../../components/grid/types";
import { Button, MultiPanelTile, Tile } from "@react-gcc-eds/core";
import "./escalation-details-editor.scss";
import EscalationDetailsFilter, {
  IEscalationDetailsFilterItems
} from "./escalation-details-filter";
import { useHistory } from "react-router";
import { GLOBAL_OPEN_ORDER_LIST_ROUTE } from "../../../app-router";
import { GetContextMenuItemsParams } from "ag-grid-community";
import {
  TableId,
  ViewId,
  useTablePreferencesHandler
} from "../../../helpers/table-preferences-handler";
import SummaryFilter from "../../../components/summary-filter/summary-filter";
import { simpleObjectEqual } from "../../../utils/user-helpers";
import { useAppState } from "../../../app-state";
import Export from "../../../components/export";

const EscalationDetailsEditor = ({
  loadingTable,
  getRows,
  pageSize,
  lookup,
  onApply,
  appliedFilters,
  defaultFilters,
  apiFilters
}: {
  loadingTable: boolean;
  getRows: (params: IServerModelGetRowRequest) => Promise<[IEscalationDetailsItem[], number]>;
  pageSize: number;
  lookup: IEscalationDetailsLookupItem | undefined;
  onApply: (value: IEscalationDetailsFilterItems) => void;
  appliedFilters: IEscalationDetailsFilterItems;
  defaultFilters: IEscalationDetailsFilterItems;
  apiFilters: IEscalationDetailsPagedQuery | undefined;
}) => {
  const columns = useMemo(() => getEscalationDetailsColumns({ lookup }), [lookup]);
  const [showFilers, setShowFilters] = useState(true);
  const history = useHistory();
  const [appState] = useAppState();

  //persist filter
  const { preferencesHandler } = useTablePreferencesHandler(ViewId.VIS_DETAIL, TableId.VIS_DETAIL);

  const toggleFilters = () => setShowFilters(visible => !visible);

  const defaultTableFilters = useMemo(() => {
    return {
      escalationTagDate: {
        dateFrom: "2023-01-01 00:00:00",
        dateTo: "2024-01-01 00:00:00",
        filterType: "date",
        type: "inRange"
      },
      marketArea: {
        filterType: "set",
        values: appState.user?.marketAreas
      }
    };
  }, [appState.user]);

  const { renderTable } = useGrid<IEscalationDetailsItem>({
    columns,
    serverModel: {
      getRows,
      pageSize
    },
    getCustomContextMenuItems: (params: GetContextMenuItemsParams) => {
      return [
        {
          name: "Get SO in Global",
          action: () => {
            history.push(GLOBAL_OPEN_ORDER_LIST_ROUTE, {
              materialDesc: params.node?.data.materialDesc
            });
          }
        }
      ];
    },
    preferencesHandler,
    showColumnsFiltersInfoAt: "right"
  });

  // filter summary
  const [filterStatus, setFilterStatus] = useState<boolean>(false);

  useEffect(() => {
    if (defaultFilters && appliedFilters) {
      setFilterStatus(!simpleObjectEqual(defaultFilters, appliedFilters));
    }
  }, [appliedFilters]);

  const onResetFilters = () => {
    onApply(defaultFilters);
  };

  return (
    <Tile className="escalation-details">
      {loadingTable ? (
        <FullScreenPanel>
          <Loader />
        </FullScreenPanel>
      ) : (
        <MultiPanelTile
          centerTitle="Escalation details overview"
          centerLeftIcon={
            <SummaryFilter
              filterActive={filterStatus}
              toggleFilters={toggleFilters}
              onResetFilters={onResetFilters}
            />
          }
          centerRightActions={
            <Export<IEscalationDetailsPagedQuery | undefined>
              downloader={useExportEscalationDetails}
              element={<Button>Export</Button>}
              params={
                apiFilters ? { ...apiFilters, offset: undefined, limit: undefined } : apiFilters
              }
            />
          }
          centerContent={<div className="escalation-details-content">{renderTable()}</div>}
          leftTitle="Filter by"
          leftVisible={showFilers}
          leftClassName="scrollable-pane"
          leftContent={
            <EscalationDetailsFilter
              allAvailableItems={lookup}
              onApply={onApply}
              appliedFilters={appliedFilters}
              defaultFilters={defaultFilters}
            />
          }
        />
      )}
    </Tile>
  );
};

export default EscalationDetailsEditor;
