import stringify from "json-stable-stringify";
import { useAppState } from "../app-state";
import { Roles, SPCRoles } from "../types";

export interface IMenuConfig {
  id: MenuId;
  route: string;
  Component: React.FC;
}

export enum MenuId {
  TicketLog = "TicketLog",
  Approval = "Approval",
  Feedback = "Feedback",
  Subscription = "Subscription",
  EscalationPerformance = "EscalationPerformance",
  PerformanceTrend = "PerformanceTrend",
  EscalationDetails = "EscalationDetails",
  GlobalOpenOrderList = "GlobalOpenOrderList",
  Admin = "Admin",
  SpcPerformanceHistory = "SpcPerformanceHistory",
  SpcSeaoVendorNominate = "SpcSeaoVendorNominate",
  SpcSeaoVendorEvaluation = "SpcSeaoVendorEvaluation",
  SpcSeaoResult = "SpcSeaoResult",
  SpcIndiaVendorNominate = "SpcIndiaVendorNominate",
  SpcIndiaResult = "SpcIndiaResult",
  GftSupplierResponseReport = "GftSupplierResponseReport"
}

const EfMenus = [
  MenuId.TicketLog,
  MenuId.Approval,
  MenuId.Feedback,
  MenuId.Subscription,
  MenuId.EscalationPerformance,
  MenuId.PerformanceTrend,
  MenuId.EscalationDetails,
  MenuId.GlobalOpenOrderList,
  MenuId.Admin
];

const SpcMenus = [
  MenuId.SpcPerformanceHistory,
  MenuId.SpcSeaoVendorNominate,
  MenuId.SpcSeaoVendorEvaluation,
  MenuId.SpcSeaoResult,
  MenuId.SpcIndiaVendorNominate,
  MenuId.SpcIndiaResult,
  MenuId.GftSupplierResponseReport
];

export const useUserHelpers = () => {
  const [appState] = useAppState();

  const hasRoles = (roleStrings: string[]) =>
    roleStrings.some(
      s =>
        appState.user !== undefined &&
        appState.user.roles !== undefined &&
        appState.user.roles.includes(s)
    );

  const canAccessFeature = (featureMenus: MenuId[], menus?: string[]) =>
    (menus || []).filter(r => featureMenus.map(fm => fm as string).includes(r)).some(r => r);

  const canAccessEf = (menus?: string[]) => canAccessFeature(EfMenus, menus);

  const canAccessSpc = (menus?: string[]) => canAccessFeature(SpcMenus, menus);

  const canAccessView = (viewId: MenuId, menus?: string[]) =>
    (menus || []).find(m => m === (viewId as string)) !== undefined;

  const isHome = (role: Roles): boolean => {
    return appState.user
      ? appState.user.roles.length > 0
        ? appState.user.roles[0] === role
        : false
      : false;
  };

  return { isHome, canAccessEf, canAccessSpc, canAccessView };
};

export const getWeek = (time: Date) => {
  const jan = new Date(time.getFullYear(), 0, 1);
  return Math.ceil(((time.getTime() - jan.getTime()) / 86400000 + jan.getDay() + 1) / 7);
};

export const deepCopy = <T,>(object: T): T => {
  return JSON.parse(JSON.stringify(object)) as T;
};

//TODO: rename to areJsonEquals
export const simpleObjectEqual = <T,>(left?: T, right?: T) =>
  left && right && stringify(left) === stringify(right);

export const getUserReadAllToken = () =>
  JSON.parse(
    sessionStorage.getItem(
      Object.keys(sessionStorage).filter(
        key => key.indexOf("accesstoken") > 0 && key.indexOf("user.read.all") > 0
      )[0]
    ) || ""
  ).secret as string;

const hasRoles = (checkRoles: Roles[], allRoles: Roles[]) =>
  checkRoles.filter(role => allRoles.includes(role)).length > 0;

export const hasSPCRoles = (allRoles: Roles[]) => hasRoles(SPCRoles, allRoles);
