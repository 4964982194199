import { useEffect, useMemo, useState } from "react";
import View from "../../views/app-view";
import { useNotifications } from "@react-gcc-eds/core";
import fileDownload from "js-file-download";
import { APPROVAL_ROUTE } from "../../app-router";
import TicketBaseInfoDialog from "../component/ticket-base-dialog/ticket-base-info-dialog";
import { useAppState } from "../../app-state";
import { EEfRoles, EMarketArea } from "../../types";
import HelpTip from "../component/help-tip";
import ApprovalEditor from "../component/approval/approval-editor";
import { HELP_ADMINISTRATOR } from "../helper";
import {
  ITicketApprovalEditedItems,
  ITicketHistory,
  useDownloadTicketAttachments,
  useGetTicketDetails,
  useGetTicketHistory,
  useSetApprovalStatus
} from "../api/ticket";
import {
  IAssignUserParams,
  useGetAllEfUsers,
  useGetDefaultAssignedUsers,
  useGetTicketSelections
} from "../api/lookup";

const ApprovalView = () => {
  // common
  const [allAvailableUsers, loadingAllAvailableUsers, getAllAvailableUsers] = useGetAllEfUsers();
  const [ticketHistory, loadingTicketHistory, getTicketHistory] = useGetTicketHistory();
  const [allAvailableItems, loadingAllAvailableItems, getAllAvailableItems] =
    useGetTicketSelections();
  const [ticketDetail, loadingTicketDetail, getTicketDetail] = useGetTicketDetails();
  const [defaultAssignees, loadingDefaultAssignees, getDefaultAssignees] =
    useGetDefaultAssignedUsers();
  const [approvalStatusData, loadingSetApprovalStatus, setApprovalStatus] = useSetApprovalStatus();
  const { createNotification } = useNotifications();

  useEffect(() => {
    getAllAvailableUsers();
    getTicketHistory();
    getAllAvailableItems();
  }, []);

  // Table
  const [activeRow, setActiveRow] = useState<ITicketHistory>();

  const handleRowClick = (row: ITicketHistory) => {
    setActiveRow(row);
    setShowEscalationDialog(true);
    getTicketDetail({ ticketNumber: row.ticketNumber });
  };

  // Dialog
  const [showEscalationDialog, setShowEscalationDialog] = useState(false);

  useEffect(() => {
    if (approvalStatusData) {
      const { success } = approvalStatusData;
      if (success) {
        createNotification("Succeed!", "Good job!");
        getTicketHistory();
      } else {
        setShowEscalationDialog(true);
      }
    }
  }, [approvalStatusData]);

  const handleSubmit = (formData: ITicketApprovalEditedItems) => {
    setApprovalStatus({ ...formData, ticketNumber: ticketDetail.ticketNumber });
    setShowEscalationDialog(false);
  };

  const handleAutoFill = (data: IAssignUserParams) => {
    getDefaultAssignees(data);
  };

  //multiple download
  const [
    downloadAttachments,
    loadingDownloadAttachments,
    downloadMultipleAttachments,
    ,
    downloadFileName
  ] = useDownloadTicketAttachments();

  const handleMultipleDownload = (attachmentIds: number[]) => {
    downloadMultipleAttachments({ attachmentIds, ticketNumber: activeRow?.ticketNumber });
  };

  useEffect(() => {
    if (downloadAttachments) {
      if ((downloadAttachments as Blob).size !== 0) {
        fileDownload(downloadAttachments, downloadFileName || "result.zip");
      } else {
        createNotification("Warning !", "Not have any result!", "warning");
      }
    }
  }, [downloadAttachments]);

  //role
  const [appState] = useAppState();
  const canEdit = useMemo(
    (): boolean =>
      Boolean(
        appState.user?.efRoles.find(
          role => role === EEfRoles.EFSuperOwner || role === EEfRoles.EFTicketApprover
        )
      ),
    [appState]
  );
  const hasEscalationLevel = useMemo(
    () => Boolean(!appState.user?.marketAreas.includes(EMarketArea.MMEA)),
    [appState]
  );
  const administrator = useMemo(
    () => (appState.user ? HELP_ADMINISTRATOR[appState.user.marketAreas[0]] : "administrator"),
    [appState]
  );

  return (
    <View
      breadCrumbs={[{ text: "Escalation Management" }, { text: "Approval", url: APPROVAL_ROUTE }]}
      actions={
        <HelpTip
          tooltip={`In order to get new ticket permission, please contact ${administrator}.`}
        />
      }
    >
      <ApprovalEditor
        loadingTable={loadingTicketHistory || loadingAllAvailableItems}
        data={ticketHistory || []}
        ticketCategories={allAvailableItems?.ticketCategories || []}
        regions={allAvailableItems?.regionCategories || []}
        onRowClick={handleRowClick}
        hasEscalationLevel={hasEscalationLevel}
      />
      <TicketBaseInfoDialog
        showApproval
        loadingDialog={loadingTicketDetail || loadingSetApprovalStatus}
        dropdownItems={allAvailableItems}
        title={`Approval Ticket: ${ticketDetail?.ticketNumber}`}
        formData={ticketDetail}
        defaultAssignees={defaultAssignees?.categoryDefaultAssignees}
        userList={allAvailableUsers}
        show={showEscalationDialog}
        downloadAttachments={downloadAttachments}
        loadingDownload={loadingDownloadAttachments}
        loadingAutoFill={loadingDefaultAssignees}
        disableBasicInfo={true}
        disableApprovalInfo={!canEdit || !activeRow?.canEditApproval}
        hasEscalationLevel={hasEscalationLevel}
        onClose={() => setShowEscalationDialog(false)}
        onSubmit={data => handleSubmit(data as any)}
        onDownload={handleMultipleDownload}
        onAutoFill={handleAutoFill}
      />
    </View>
  );
};

export default ApprovalView;
